export default function NewsArticle({ img, content }) {
  const defineStyles = (i) => (content.length > 1 ? { fontWeight: i === 0 ? 500 : 400 } : {});

  return (
    <div className="telegram-article">
      {img && <img src={img} alt="preview" />}
      {content?.map((paragraph, index) => (
        <p key={index} style={defineStyles(index)}>
          {paragraph}
        </p>
      ))}
    </div>
  );
}
