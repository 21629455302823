import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';
import MandatoryConfirmationEmail from '../../components/MandatoryConfirmationEmail/index.js';

import CurrentClubCard from '../../components/CurrentClubCard';
import ClientButton from '../../components/ClientButton/ClientButton';
import SectionName from '../../components/SectionName';
import { Spin } from '../../components';
import { WebinarSwiper } from './WebinarSwiper/webinarSwiper';
import { error, request } from '../../tools';
import { usePayContractDebt } from '../../tools/hooks';
import { NewsSwiper } from './newsSwiper';
import OfficeSwiper from './OfficeSwiper';
import WantedBlock from './WantedBlock.jsx';

import ContractsImage from './images/contracts.svg';
import HotlineImage from './images/hotline.svg';

import './Homepage.scss';
import DebtContractItem from '../../components/DebtContractItem';

const Homepage = () => {
  const history = useHistory();

  const clientInfo = useSelector((state) => state.common.clientInfo);
  const contracts = useSelector((state) => state.common.contracts) ?? [];
  const hotlines = useSelector((state) => state.common.hotlines) ?? [];
  const lang = useSelector((state) => state.common.language);
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const [debtContracts, setDebtContracts] = useState([]);

  const isEmailVerified = clientAuth?.emailVerified;
  const oneMonthAgo = dayjs().subtract(1, 'month');
  const moreMonthHasPassed = dayjs(clientInfo.createdAt).isBefore(oneMonthAgo);

  const [loading, setLoading] = useState(false);

  const countContractsDebt = () => {
    const notArchivedContracts = contracts.filter((contract) => !contract?.ad);
    setLoading(true);
    const onSuccess = (res) => {
      const debtContracts = res?.data?.filter((item) => item?.sumToPayWithoutAssignments > 0 || item?.assignmentsToPay?.length > 0) || [];
      setDebtContracts(debtContracts);
      setLoading(false);
    };

    const onError = (err) => {
      error(err);
      setLoading(false);
    };
    request('/contracts/calculateContractsDebt', { contracts: notArchivedContracts }, onSuccess, onError);
  };

  useEffect(() => {
    if (!clientInfo?._id) return;

    countContractsDebt();
  }, [contracts]);

  const { payContractDebt } = usePayContractDebt();

  const DebtContractsRender = () => {
    return debtContracts.map((item) => (
      <DebtContractItem key={item.contractId} contract={item} payContractDebt={payContractDebt} clientInfo={clientInfo} lang={lang} />
    ));
  };

  const combinedNotifEventsPartHotline = hotlines?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
    [combinedNotifCorrespondence, combinedNotifTasks, combinedNotifEvents] = [
      contracts.reduce((combined, contract) => {
        combined.push(
          ...(contract.correspondence || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)),
        );
        return combined;
      }, [])?.length ?? 0,
      contracts.reduce((combined, contract) => {
        combined.push(...(contract.tasks || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)));
        return combined;
      }, [])?.length ?? 0,
      combinedNotifEventsPartHotline +
        (hotlines?.filter((item) => item.s)?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0) +
        (contracts.reduce((combined, contract) => {
          combined.push(...(contract.assignments || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)));
          return combined;
        }, [])?.length ?? 0),
    ],
    multiNotificationsInContracts = combinedNotifCorrespondence + combinedNotifTasks + combinedNotifEvents;

  if (!clientAuth.phoneVerified) {
    history.push(CLIENT_ROUTES.CLIENT_VERIFICATION_PHONE.route);
    return;
  }
  if (!clientAuth.policyConfirmed) {
    history.push(CLIENT_ROUTES.CLIENT_POLICY_CONFIRM.route);
    return;
  }
  if (clientInfo.createdFromPartner) return <Redirect to="/partnerHotlines" />;

  return (
    <Spin spinning={loading} tip={translation.loader1[lang]}>
      <div className="home-page-content">
        {moreMonthHasPassed && !isEmailVerified ? <MandatoryConfirmationEmail text={translation.emailVerification[lang]} /> : <WantedBlock />}
        <DebtContractsRender />
        <SectionName text={translation.clubCard[lang]} />
        <div className="homepage-club-card">
          <CurrentClubCard />
        </div>
        <div className="homepage-menu">
          <div className="client-menu">
            <ClientButton
              text={translation.myBusiness[lang]}
              link={CLIENT_ROUTES.CLIENT_CONTRACTS.route}
              icon={ContractsImage}
              badge={contracts?.length > 0 ? multiNotificationsInContracts : 0}
            />
            <ClientButton
              text={translation.myConsultations[lang]}
              link={CLIENT_ROUTES.CLIENT_HOTLINE.route}
              icon={HotlineImage}
              badge={combinedNotifEventsPartHotline}
            />
          </div>
        </div>

        <WebinarSwiper />
        <SectionName text={translation.news[lang]} />
        <NewsSwiper />
        <SectionName text={translation.office[lang]} />
        <OfficeSwiper />
      </div>
    </Spin>
  );
};

export default Homepage;
