import { useSelector } from 'react-redux';
import './NewsSwiper.scss';
import { useState } from 'react';
import ModalWindow from '../../Modals/ModalWindow';
import NewsArticle from './NewsArticle';

export const NewsSwiper = () => {
  const news = useSelector((state) => state.common.newsFromSite) ?? [];
  const [current, setCurrent] = useState();

  const setModal = (value) => {
    if (!value) {
      setCurrent(null);
    }
  };

  const transformTitle = (title) => {
    const splittedText = title?.split(' ');
    if (splittedText?.length > 10) {
      return splittedText?.slice(0, 10)?.join(' ') + '...';
    }
    return splittedText?.join(' ');
  };

  return (
    <>
      <div className="news-swiper">
        <swiper-container
          class="mySwiper"
          space-between="-12"
          slides-per-view="auto"
          free-mode="true"
          initial-slide="news"
          speed={250}
          touchRatio={1.3}
          touchAngle={35}
        >
          {news &&
            news.map((item) => (
              <swiper-slide key={item.data_post}>
                <button onClick={() => setCurrent(item)}>
                  <div className="news-content">
                    <img src={item.message_photo?.length > 0 ? item.message_photo[0] : '/ao-zahist.jpg'} alt={'preview'} />
                    <div className="news-title">{transformTitle(item.paragraphs?.[0])}</div>
                  </div>
                </button>
              </swiper-slide>
            ))}
        </swiper-container>
      </div>
      <ModalWindow modal={current} setModal={setModal}>
        <NewsArticle img={current?.message_photo[0]} content={current?.paragraphs} />
      </ModalWindow>
    </>
  );
};
