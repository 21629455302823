import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';

import { Spin } from '../../components';
import HotlineMeetInvite from '../../components/HotlineMeetInvite';
import { request, error } from '../../tools';
import { setHotlineMeetData } from '../../store/commonReducer';

import './HotlineMeetInfo.scss';

const HotlineMeetInfo = () => {
  const history = useHistory();
  const { hash } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const meetData = useSelector((state) => state.common.hotlineMeetData);
  const [filii, clientAuth] = [useSelector((state) => state.common.filii), useSelector((state) => state.common.clientAuth)];
  const lang = useSelector((state) => state.common.language);
  const { ph, countryPhCode } = useSelector((state) => state.common.clientInfo);
  if (hash?.length !== 24) history.push(CLIENT_ROUTES.CLIENT_HOME.route);

  const getInfo = () => {
    request(
      '/anon/getHotlineMeet',
      { hash, countryPhCode, ph },
      (req) => {
        dispatch(setHotlineMeetData(req.data));
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    if (hash?.length === 24) getInfo();
  }, []);

  return (
    <div className="hotline-meet-info-page">
      <div className="miniheader">
        <a aria-label="open to map_zahist" href="https://zahist.ua/" className="bg">
          <img src="/logoold.svg" alt="zahist" />
          <img src="/logoold.svg" alt="zahist" className="de" />
          <div className="text de">{translation.Germany['de']}</div>
          <img src="/logoold.svg" alt="zahist" className="cs" />
          <div className="text cs">{translation.CzechRepublic['cs']}</div>
          <img src="/logoold.svg" alt="zahist" className="pl" />
          <div className="text pl">{translation.Poland['cs']}</div>
          <img src="/logoold.svg" alt="zahist" className="ua" />
          <div className="text ua">{translation.Ukraine['ua']}</div>
        </a>
      </div>
      <div className="title">
        {translation._companyName[lang]}
        <br />
        <small>{translation._companyDescription[lang]}</small>
      </div>

      {loading ? (
        <div>
          <Spin spinning={loading} tip={translation.loader1[lang]} />
        </div>
      ) : (
        <HotlineMeetInvite hash={hash} filii={filii} />
      )}
    </div>
  );
};

export default React.memo(HotlineMeetInfo);
