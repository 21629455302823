import React from 'react';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

const ZahistCred = () => {
  const lang = useSelector((state) => state.common.language);

  return (
    <h6 style={{ textAlign: 'center' }}>
      © {new Date().getFullYear()} {translation.clientRegPageTitle[lang]}
    </h6>
  );
};

export default ZahistCred;
