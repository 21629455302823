import React, { useEffect, useState } from 'react';
import { translation } from 'common_constants/translation';

import { SelectNew } from '../../../components';
import { useSelector } from 'react-redux';
import { getBookedTimeForClient } from 'common_components/src/HotlineStep1/Step1/timeUtils';
import { useForm } from 'react-hook-form';

const SelectFil = ({
  onChangeFil,
  fil,
  activeFilii,
  date,
  type,
  token,
  host,
  filteredState,
  state,
  filteredDistrict,
  filteredPartOfDistrict,
  restArr,
  currentTopFilials,
}) => {
  const [filiiRes, setFiliiRes] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = useSelector((state) => state.common.language);
  const { control } = useForm();

  useEffect(() => {
    if (date && type) {
      getBookedTimeForClient('ALL', type, date, setLoading, setFiliiRes, token, host);
    }
  }, [date]);

  const checkFilStatus = (index, status) => {
    const item = filiiRes.find((item) => item.fil === index);

    if (item) {
      return item[status] || false;
    }
    return false;
  };

  let options = [];

  // if (currentTopFilials?.length && type == 3) {
  //   options.push({
  //     label: translation.clientHotline.topFilials[lang],
  //     options: currentTopFilials.map((item = {}) => ({
  //       value: item.value,
  //       label: (
  //         <div className="select-option-div">
  //           {item.label}
  //           <span
  //             className={`select-option-div__status ${checkFilStatus(item.value + '', 'filOnline') ? 'online' : ''} ${
  //               checkFilStatus(item.value + '', 'filClose') ? 'close' : ''
  //             } `}
  //           ></span>
  //         </div>
  //       ),
  //     })),
  //   });
  // }

  if (state === undefined) {
    options.push({
      label: translation.allFiliaOptGroup[lang],
      options: activeFilii.map((item = {}) => ({
        value: item.value,
        label: (
          <div className="select-option-div">
            {item.label}
            <span
              className={`select-option-div__status ${checkFilStatus(item.value + '', 'filOnline') ? 'online' : ''} ${
                checkFilStatus(item.value + '', 'filClose') ? 'close' : ''
              } `}
            ></span>
          </div>
        ),
      })),
    });
  }

  if (filteredDistrict?.length > 0) {
    options.push({
      label: translation.clientHotline.district[lang],
      options: filteredDistrict.map((item = {}) => ({
        value: item.i + '',
        label: (
          <div className="select-option-div">
            {item.name}
            <span
              className={`select-option-div__status ${checkFilStatus(item.i + '', 'filOnline') ? 'online' : ''} ${
                checkFilStatus(item.i + '', 'filClose') ? 'close' : ''
              } `}
            ></span>
          </div>
        ),
      })),
    });
  }

  if (filteredState?.length > 0) {
    options.push({
      label: translation.clientHotline.byRegion[lang],
      options: filteredState.map((item = {}) => ({
        value: item.i + '',
        label: (
          <div className="select-option-div">
            {item.name}
            <span
              className={`select-option-div__status ${checkFilStatus(item.i + '', 'filOnline') ? 'online' : ''} ${
                checkFilStatus(item.i + '', 'filClose') ? 'close' : ''
              } `}
            ></span>
          </div>
        ),
      })),
    });
  }

  if (filteredPartOfDistrict?.length > 0) {
    options.push({
      label: translation.clientHotline.includedInDistrict[lang],
      options: filteredPartOfDistrict.map((item = {}) => ({
        value: item.i + '',
        label: (
          <div className="select-option-div">
            {item.name}
            <span
              className={`select-option-div__status ${checkFilStatus(item.i + '', 'filOnline') ? 'online' : ''} ${
                checkFilStatus(item.i + '', 'filClose') ? 'close' : ''
              } `}
            ></span>
          </div>
        ),
      })),
    });
  }

  if (restArr?.length > 0) {
    options.push({
      label: translation.clientHotline.rest[lang],
      options: restArr.map((item = {}) => ({
        value: item.i + '',
        label: (
          <div className="select-option-div">
            {item.name}
            <span
              className={`select-option-div__status ${checkFilStatus(item.i + '', 'filOnline') ? 'online' : ''} ${
                checkFilStatus(item.i + '', 'filClose') ? 'close' : ''
              } `}
            ></span>
          </div>
        ),
      })),
    });
  }

  const filterOption = (option, input) => {
    return option?.label?.props?.children?.[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
  return (
    <SelectNew
      className="fil"
      placeholder={translation.selectFilWithRoleTitle[lang]}
      filterOption={filterOption}
      value={fil}
      name="fil"
      disabled={type === 6 || loading}
      onChange={onChangeFil}
      options={options} // передаем опции в компонент SelectNew
      control={control}
      isSearchable={true}
      flattened
    />
  );
};

export default SelectFil;
