import { useEffect, useState, useMemo } from 'react';
import { EnvironmentOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { WEBVIEW_MESSAGE_TYPES } from 'common_constants/business';
import { Switch } from 'common_components';
import Input from '../../Input';
import Button from '../../Button';
import { editClientInfo } from '../../../store/commonReducer';
import Avatar from '../../Avatar';
import MainLoader from '../../MainLoader';
import { setMainLoader } from '../../../store/uiReducer';
import { ChangePhoneModal, ChangeEmailModal } from '.';
import { useToaster } from '../../../tools/hooks';
import { Upload } from '../../Upload';
import reactNativeService from '../../../tools/reactNativeService';

import './EditProfileForm.scss';

const EditClientProfileForm = ({ isProfileEditing, setIsProfileEditing }) => {
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { email } = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const biometricEnabled = useSelector((state) => state.common.biometricEnabled);

  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [changePhoneModal, setChangePhoneModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);

  const { success, error } = useToaster();

  useEffect(() => {
    setImageUrl(clientInfo?.upa ? process.env.REACT_APP_API + `/avatars/${clientInfo?.upa}.jpeg` : null);
  }, [clientInfo.upa]);

  const { register, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      return { n: clientInfo.n, ph: clientInfo.ph, address: clientInfo.address, email };
    }, [clientInfo?.ph, clientInfo?.n, clientInfo?.address, email]),
  });

  const toggleBiometric = () => {
    reactNativeService.sendMessageToWebview({
      type: WEBVIEW_MESSAGE_TYPES.TOGGLE_BIOMETRIC,
      data: { value: !biometricEnabled },
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('file', uploadFile);
    formData.append('_id', clientInfo._id);
    formData.append('ph', clientInfo.ph);
    formData.append('address', data.address);
    formData.append('n', data.n);

    if (clientInfo?.upa) {
      formData.append('prevAvatarId', clientInfo.upa);
    }

    const token = window.localStorage.getItem('clientAccessToken');

    await fetch(process.env.REACT_APP_API + '/clients/clientUpdate', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData || null,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status) {
          dispatch(editClientInfo({ n: data.n, address: data.address, ph: data.ph, upa: result?.upa ? result.upa : clientInfo?.upa }));
          setIsProfileEditing(false);
          setIsLoading(false);
          success(translation.clientProfileSuccess[lang]);
        }
      })
      .catch((err) => {
        error(translation.clientProfileError3[lang]);
        setIsLoading(false);
      });
  };

  const toggleEditing = () => {
    setImageUrl(clientInfo?.upa ? process.env.REACT_APP_API + `/avatars/${clientInfo?.upa}.jpeg` : null);
    setIsProfileEditing((prev) => !prev);
  };

  useEffect(() => {
    reset({ n: clientInfo.n, ph: clientInfo.ph, address: clientInfo.address, email });
  }, [clientInfo?.ph, clientInfo?.n, clientInfo?.address, email]);

  if (!clientInfo?.ph) {
    dispatch(setMainLoader(true));
    return;
  }
  dispatch(setMainLoader(false));

  return (
    <div className="edit-profile-form-wrapper">
      <MainLoader />
      <div className="form-header">
        {isProfileEditing ? (
          <Upload
            setUploadFile={setUploadFile}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setImageUrl={setImageUrl}
            lang={lang}
            imageUrl={imageUrl}
          />
        ) : (
          <Avatar size={60} src={imageUrl} shape="square" className="form-avatar" icon={<UserOutlined />} />
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div>
          <span className="input-label">{translation.clientProfileFullName[lang]}:</span>
          <Input {...register('n')} prefix={<UserOutlined style={{ fontSize: '1.5rem', marginLeft: '6px' }} />} disabled={false} />
        </div>
        <div>
          <span className="input-label">{translation.clientBtn3[lang]}:</span>
          <Input
            {...register('ph')}
            prefix={
              <>
                <PhoneOutlined style={{ fontSize: '1.5rem', marginLeft: '6px' }} />
                <span style={{ fontSize: '16px', marginLeft: '10px', marginRight: '-10px' }}>+{clientInfo.countryPhCode}</span>
              </>
            }
            disabled
          />
          {isProfileEditing && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setChangePhoneModal(true);
              }}
              className="change-data_btn"
            >
              {translation.changePhoneBtn[lang]}
              <div className="menu-item-text-arrow"></div>
            </button>
          )}
        </div>
        <div>
          <span className="input-label">{translation.clientProfileEmail[lang]}:</span>
          <Input {...register('email')} prefix={<MailOutlined style={{ fontSize: '1.5rem', marginLeft: '6px' }} />} disabled />
          {isProfileEditing && (
            <button
              className="change-data_btn"
              onClick={(e) => {
                e.preventDefault();
                setChangeEmailModal(true);
              }}
            >
              {translation.changeEmailBtn[lang]}
              <div className="menu-item-text-arrow"></div>
            </button>
          )}
        </div>
        <div>
          <span className="input-label">{translation.clientContactUsInfo1[lang]}</span>
          <Input {...register('address')} prefix={<EnvironmentOutlined style={{ fontSize: '1.5rem', marginLeft: '6px' }} />} />
        </div>
        {window?.ReactNativeWebView && biometricEnabled !== null && (
          <div>
            <span className="input-label">{translation.clientProfileEnableBiometric[lang]}</span>
            <Switch value={biometricEnabled} onToggle={toggleBiometric} activeColor="#334768" />
          </div>
        )}
        <div className="form-buttons">
          {isProfileEditing && (
            <>
              <Button type="submit" disabled={isLoading} className="submit-button">
                {isLoading ? translation.loader[lang] : translation.saveChanges[lang]}
              </Button>
              <Button onClick={toggleEditing} className="edit-button">
                {translation.accountDeletionButton1[lang]}
              </Button>
            </>
          )}
          {!isProfileEditing && (
            <Button onClick={toggleEditing} className="edit-button">
              {translation.accountDeletionButton2[lang]}
            </Button>
          )}
        </div>
      </form>
      <ChangePhoneModal
        lang={lang}
        setChangePhoneModal={setChangePhoneModal}
        changePhoneModal={changePhoneModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <ChangeEmailModal
        lang={lang}
        setChangeEmailModal={setChangeEmailModal}
        changeEmailModal={changeEmailModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};
export default EditClientProfileForm;
