import { useDispatch, useSelector } from 'react-redux';
import { getFormattedDateWithRelativeDays } from 'common_components';
import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';
import { request } from '../../../tools';
import { setContractChat, setModal } from '../../../store/commonReducer';
import { Spin } from '../../../components';
import { translation } from 'common_constants/translation';
import { ASSIGNMENTS_SERVICES_TYPE, SERVICE_PAYMENT_TYPE, SERVICE_PAYMENT_TYPE_FOR_EADVOKAT } from 'common_constants/business';
import Badge from '../../../components/Badge/Badge';
import SmallRoundedArrow from '../../../components/SmallRoundedArrow/SmallRoundedArrow';
import { Link } from 'react-router-dom';
import { DoubleRightOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import ModalWindow from '../../../Modals/ModalWindow/index';
import * as MODALS from 'common_constants/modals';
import './LastEvents.scss';

const LastEvents = ({ contract, hotlines, userId, tasks, users, FILII, contractTransactions, eventsOpenModal, tasksOpenModal, openChat }) => {
  const contractId = contract?._id;
  const assignments = contract?.assignments ?? [];
  const contractChats = useSelector((state) => state.common.contractChats);
  const lang = useSelector((state) => state.common.language);
  const [isLoading, setIsLoading] = useState(false);
  const currentChat = contractChats?.find((item) => item.chatId === contractId) ?? [];
  const dispatch = useDispatch();
  const taskComments = tasks.flatMap((task) => task.comments ?? []);
  const [isOpenEvents, setIsOpenEvents] = useState(false);

  const renderEvent = (item, index) => {
    let notif;
    let title, date, desc, time, onClick, href;
    if (!item) return null;

    // hotline
    if (item.crAt) {
      notif = item.notif?.find((n) => n.watched) ? undefined : 1;
      title = translation.clientCInfConsultTitle[lang];
      date = getFormattedDateWithRelativeDays(item.date, lang);
      desc = FILII[item.fil];
      time = item.startT;
      href = '/' + item._id;
    }
    //assignment
    else if (item.createdAt) {
      notif = item.notif?.find((n) => n.watched) ? undefined : 1;
      title = ASSIGNMENTS_SERVICES_TYPE[item.serviceType];
      date = getFormattedDateWithRelativeDays(item.date, lang);
      desc = item.commentForClient;
      time = item.startT ?? item.selectedTime;
      onClick = eventsOpenModal;
    }
    //task comment
    else if (item.created) {
      notif = item.userNotSaw ? 1 : undefined;
      title = (
        <>
          <b>{translation.lastEventsTaskComment[lang]}: </b> {tasks.find((task) => task?.comments?.find((comm) => Object.is(comm, item)))?.title}
        </>
      );
      date = getFormattedDateWithRelativeDays(item.created, lang);
      desc = item.comment;
      const d = dayjs(item.created);
      time = d.hour() + ':' + d.minute().toString().padStart(2, '0');
      onClick = tasksOpenModal;
    }
    //chat message
    else if (item.sender) {
      notif = !item.saw.find((id) => id === userId) ? 1 : undefined;
      title = (
        <>
          <b>{translation.lastEventsMessageFrom[lang]} </b>{' '}
          {users?.find((user) => user._id === item.sender)?.name || translation.chatForContractError4[lang]}
        </>
      );
      date = getFormattedDateWithRelativeDays(item.date, lang);
      desc = item.message;
      const d = dayjs(item.date);
      time = d.hour() + ':' + d.minute().toString().padStart(2, '0');
      onClick = openChat;
    }
    //transaction
    else if (item.amount) {
      title = SERVICE_PAYMENT_TYPE_FOR_EADVOKAT?.find((s) => s.value === item.type)?.translations[lang] || translation.lastEventsTransaction[lang];
      date = getFormattedDateWithRelativeDays(item.date, lang);
      desc = item.amount + '₴';
      const d = dayjs(item.date);
      time = d.hour() + ':' + d.minute().toString().padStart(2, '0');
    } else return null;
    const clickable = href || onClick ? 'clickable' : '';

    const content = (
      <>
        <Badge count={notif} className="menu-item-badge">
          <div className="event-row">
            <span>{title}</span> <span>{date}</span>
          </div>
          <div className="event-row">
            <span>{desc}</span> <span>{time}</span>
          </div>
        </Badge>
        <SmallRoundedArrow />
      </>
    );
    if (href) {
      return (
        <Link key={item._id ?? index} to={href} className={'event ' + clickable}>
          {content}
        </Link>
      );
    }

    return (
      <div key={item._id ?? index} className={'event ' + clickable} onClick={onClick}>
        {content}
      </div>
    );
  };

  const events = [...assignments, ...hotlines, ...taskComments, ...contractTransactions];

  useEffect(() => {
    setIsLoading(true);
    request('/chatPrivate/getchatClient', { documentId: contractId }, (req) => {
      setIsLoading(false);
      dispatch(setContractChat({ chatId: contractId, data: req.data }));
    });
  }, []);

  const messages = currentChat?.messages?.filter((elem) => elem.sender !== userId) || [];

  const sorted = [...messages, ...events].sort((a, b) => {
    const date = (i) => new Date(i.crAt || i.createdAt);
    let bVal;
    if (b.sender || b.amount) {
      bVal = new Date(b.date);
    } else if (b.created) {
      bVal = new Date(b.created);
    } else {
      bVal = date(b);
    }
    let aVal;
    if (a.sender || a.amount) {
      aVal = new Date(a.date);
    } else if (a.created) {
      aVal = new Date(a.created);
    } else {
      aVal = date(a);
    }
    return bVal - aVal;
  });

  return (
    <>
      <Spin spinning={isLoading}>
        <div className={clsx('last-events')}>
          <span className="events-heading">{translation.lastEvents[lang]}</span>
          {sorted.map((elem, index) => renderEvent(elem, index)).slice(0, 3)}
          <button
            onClick={() => setIsOpenEvents(true)}
            className="btn-expand"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <span style={{ marginBottom: 6 }}>{translation.showAll[lang]}</span>
            <DoubleRightOutlined style={{ transform: 'rotate(90deg)', fontSize: '16px' }} />
          </button>
        </div>
        <ModalWindow title={translation.lastEvents[lang]} modal={isOpenEvents} setModal={setIsOpenEvents}>
          {sorted.map((elem, index) => renderEvent(elem, index))}
        </ModalWindow>
      </Spin>
    </>
  );
};

export default memo(LastEvents);
