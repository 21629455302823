import React from 'react';
import './AdvantagesClubCard.scss';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import AssistantSilver from '../../../images/assistant-silver.png';
import GoldAvatar from '../../../images/assistant-gold.png';
import PlatinumAssistant from '../../../images/assistant-platinum.png';

const cardTypes = ['silver', 'gold', 'platinum'];

const AdvantagesClubCard = ({ type }) => {
  const lang = useSelector((state) => state.common.language);

  const currentType = cardTypes[type];
  const advantages = translation.clubCardAdvantages[currentType];

  const isSilver = currentType === cardTypes[0];
  const isGold = currentType === cardTypes[1];
  const isPlatinum = currentType === cardTypes[2];

  return (
    <div className="block">
      <div className={`typeCardPurchased-wrapper advantages ${currentType.toLowerCase()}`}>
        {isSilver && <img src={AssistantSilver} alt="silverAvatar" className="assistant" />}
        {isGold && <img src={GoldAvatar} alt="goldAvatar" className="assistant" />}
        {isPlatinum && <img src={PlatinumAssistant} alt="platinumAssistant" className="assistant" />}
        <ul className="advantages-text">{advantages && Object.keys(advantages).map((key) => <li key={key}>✅{advantages[key][lang]}</li>)}</ul>
      </div>
    </div>
  );
};

export default AdvantagesClubCard;
