import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QUESTIONNAIRE_ARCHIVE } from 'common_constants/modals';
import { translation } from 'common_constants/translation';
import Switch from 'rc-switch';
import reactNativeService from '../../../tools/reactNativeService';
import { CONTRACT_AUTOPAY_CASHBACK } from 'common_constants/business';

import { Button, EditableContractName, Badge, UserAvatar, Popconfirm, Spin } from '../../../components';
import { setModal, setContract, setContracts } from '../../../store/commonReducer';
import { request, error, success } from '../../../tools';

import './style.scss';
const { Ribbon } = Badge;

const ContractItem = ({ data, contracts, clientInfo, title, contractDebtCalculated }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const users = useSelector((state) => state.common.usersData);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPayByClient, setTotalPayByClient] = useState(0);
  const [remainingMoney, setRemainingMoney] = useState(0);
  const [isPaidStatus, setIsPaidStatus] = useState({
    firstPayment: false,
    monthlyPayments: [],
    paymentManagement: [],
    assignments: [],
  });

  const handleSwitchAutoPay = (autoPay) => {
    setLoading(true);
    const onSuccess = () => {
      success(translation.autoPayModalSuccess[lang]);
      dispatch(setContracts(contracts.map((item) => (item._id === data._id ? { ...item, autoPay } : item))));
      setLoading(false);
    };
    const onError = () => {
      error(translation.error[lang]);
      setLoading(false);
    };
    request('/contracts/setAutoPay', { _id: data._id, autoPay }, onSuccess, onError);
  };

  const curator = users?.find((user) => user._id === data?.us) || null;

  const handleOpenPayWindow = () => {
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location.href = res.checkoutUrl;
      }
    };
    const onError = (_, __, axiosError) => error('', 'axiosError.error');
    request('/payment/mono/bindCard/link', { redirectUrl: `/contractInfo/${data._id}` }, onSuccess, onError);
  };

  const countRemainingMoney = () => {
    setLoading(true);

    const reqData = {
      contractI: data?.i,
    };

    request(
      '/contracts/paymentManagementRemainingMoneyClient',
      reqData,
      ({ data }) => {
        setIsPaidStatus(data?.paymentManagementPaid);
        setRemainingMoney(data?.remainingMoney);
        setTotalPayByClient(data?.totalClientPaid);
        setLoading(false);
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  return (
    <div className="menu-item-badge-autopay">
      {' '}
      <Ribbon color="#334768" text="Архівний" style={{ display: !data.ad && 'none' }}>
        <div className="col">
          {title && (
            <div className="client-contract__item">
              <div className="contract-curator-photo">
                <UserAvatar
                  style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  user={curator}
                />
              </div>
              <div className="contract-info">
                <EditableContractName contract={data} onClick={(e) => e.preventDefault()} setIsEditing={setIsEditing} />
                <div className="contract-info__curator">
                  {translation.CuratorTitle[lang]}
                  <span className="curator-purple">{curator?.name}</span>
                </div>
              </div>
            </div>
          )}

          <div style={{ marginTop: 20 }}>
            <div style={{ marginBottom: 10 }}>
              {data?.mg ? (
                <>
                  <div className="payment-info">
                    <div className="payment-info-left">
                      <p>
                        {translation.fullFee[lang]}: {data?.pa ?? 0}₴
                      </p>
                      <p>
                        {translation.firstPayment[lang]}:{' '}
                        <span className={clsx(isPaidStatus.firstPayment ? 'paid' : 'not-paid')}>{data?.fp ?? 0}₴</span>
                      </p>
                      <p>
                        {translation.firstPaymentDate[lang]}: {data?.md ? dayjs(data.md)?.utc(true)?.format('DD.MM.YYYY') ?? '' : ''}
                      </p>
                      <p>
                        {translation.exitsOfLawyer[lang]}: {data?.ea ?? 0}₴
                      </p>
                    </div>
                    <div>
                      <p>
                        {translation.totalPaid[lang]}: {totalPayByClient ?? 0}₴
                      </p>
                      <p>
                        {translation.installment[lang]}: {data?.pa - data?.fp ?? 0}₴
                      </p>
                      <p>
                        {translation.numberOfMonths[lang]}: {data?.installmentMonths ?? 0}
                      </p>
                      <p>
                        {translation.monthlyPayment[lang]}: {data?.monthlyFee ?? data?.mg ?? 0}₴
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div>{translation.autoPayModalText2[lang]}</div>
              )}

              <br />
              <i style={{ fontSize: 12 }}>{translation.autoPayModalText3[lang]}</i>
            </div>
            {data?.autoPay && clientInfo.paymentCard ? (
              <div className="info-autopay good">
                {' '}
                <h4>{translation.autoPayGood_1[lang]}</h4>
                <p>{`${translation.autoPayGood_2[lang]} ${parseInt(
                  data?.monthlyFee
                    ? data?.monthlyFee * CONTRACT_AUTOPAY_CASHBACK * (data?.installmentMonths ? data?.installmentMonths : 1)
                    : data?.mg * CONTRACT_AUTOPAY_CASHBACK * (data?.installmentMonths ? data?.installmentMonths : 1),
                )} ${translation.currency[lang]}`}</p>
              </div>
            ) : (
              <div className="info-autopay bad">
                {' '}
                <h4>{`${translation.autoPayBad_1_1[lang]}  ${CONTRACT_AUTOPAY_CASHBACK * 100}% ${translation.autoPayBad_1_2[lang]}`}</h4>
                <p>{`${translation.autoPayBad_2[lang]} ${parseInt(
                  data?.monthlyFee
                    ? data?.monthlyFee * CONTRACT_AUTOPAY_CASHBACK * (data?.installmentMonths ? data?.installmentMonths : 1)
                    : data?.mg * CONTRACT_AUTOPAY_CASHBACK * (data?.installmentMonths ? data?.installmentMonths : 1),
                )} ${translation.currency[lang]}`}</p>
              </div>
            )}
            {clientInfo.paymentCard ? (
              (data?.mg || data?.monthlyFee) && (
                <Spin spinning={loading}>
                  <div style={{ marginBottom: 10 }}>
                    {translation.autoPayModalText4[lang]}
                    <Switch checked={data?.autoPay} onChange={handleSwitchAutoPay} style={{ marginLeft: 10 }} />
                  </div>
                </Spin>
              )
            ) : (
              <div>
                <p className="autoPayModalText5">{translation.autoPayModalText5[lang]}</p>
                <Popconfirm
                  title={translation.popconfirmTitle[lang]}
                  description={
                    <div>
                      <div>{translation.autoPayModalText6[lang]}</div>
                      <div>{translation.autoPayModalText7[lang]}</div>
                    </div>
                  }
                  onConfirm={handleOpenPayWindow}
                  okText={translation.yes[lang]}
                  cancelText={translation.no[lang]}
                >
                  <div className="cardForPayments_button">+</div>
                </Popconfirm>
                <br />
              </div>
            )}
          </div>

          {data.sa === true && data.qa === undefined && (
            <div className="btn">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setModal({ name: QUESTIONNAIRE_ARCHIVE, data: { C: data._id } }));
                  dispatch(setContract(data));
                }}
              >
                {translation.qualityArchiveBtn[lang]}
              </Button>
            </div>
          )}
        </div>
      </Ribbon>
    </div>
  );
};

export default ContractItem;
