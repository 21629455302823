import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CHAT_FOR_DOZHIMS_AI } from 'common_constants/modals';
import { FEMALE_ASSISTANTS, MALE_ASSISTANTS, CLUB_CARDS_TYPES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';

import UserAvatar from '../../components/UserAvatar';
import { setAccountantChats, setModal, setPersonalConsultantChat } from '../../store/commonReducer';
import { request, error } from '../../tools';
import MandatoryConfirmationEmail from '../../components/MandatoryConfirmationEmail/index.js';
import ChatsItem from './ChatsItem';
import AccountantChatItem from './AccountantChatItem.jsx';
import PersonalConsultantChatItem from './PersonalConsultantChatItem.jsx';

import './style.scss';

const Chats = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const contracts = useSelector((state) => state.common.contracts) ?? [];
  const accountantChats = useSelector((state) => state.common.accountantChats) ?? [];
  const personalConsultantChat = useSelector((state) => state.common.personalConsultantChat);

  const [avatars, setAvatars] = useState({});
  const [displayContractAI, setDisplayContractAI] = useState(false);
  const [displayConsultationAI, setDisplayConsultationAI] = useState(false);

  const [loading, setLoading] = useState(false);
  const [lastMessage, setLastMessage] = useState('');
  const allAssistants = [...MALE_ASSISTANTS, ...FEMALE_ASSISTANTS];

  const isEmailVerified = clientAuth?.emailVerified;
  const oneMonthAgo = dayjs().subtract(1, 'month');
  const moreMonthHasPassed = dayjs(clientInfo.createdAt).isBefore(oneMonthAgo);

  const openChat = () => {
    dispatch(
      setModal({
        name: CHAT_FOR_DOZHIMS_AI,
      }),
    );
  };

  const getDisplay = async () => {
    await request('/dozhimsAI/getDates', { userId: clientAuth._id }, ({ date, dateConsultation }) => {
      if (date) {
        setDisplayContractAI(true);
      }
      if (dateConsultation) {
        setDisplayConsultationAI(true);
      }
    });
  };

  const getLastMessage = () => {
    setLoading(true);

    request(
      '/dozhimsAI/getLastMessageChat',
      { userId: clientAuth._id },
      ({ lastMessage }) => {
        if (lastMessage) {
          setLastMessage(lastMessage);
        } else {
          setLastMessage(translation.notMessages[lang]);
        }
        setLoading(false);
      },
      error,
    );
  };

  const getPersonalConsultantChat = async () => {
    if (personalConsultantChat?._id) return;

    const clubCard = clientInfo?.clubCard;

    if (!clubCard || ![CLUB_CARDS_TYPES.GOLD, CLUB_CARDS_TYPES.PLATINUM].includes(clubCard.cardType)) return;

    setLoading(true);

    await request(
      '/chatPrivate/getPersonalConsultantChat',
      {},
      ({ data }) => {
        dispatch(setPersonalConsultantChat(data));
      },
      error,
    );

    setLoading(false);
  };

  useEffect(() => {
    if (accountantChats) {
      new Set(accountantChats.map((elem) => elem.assistantIndex)).forEach((elem) => {
        if (!avatars[elem]) {
          const avatarName = allAssistants.find((asst) => asst.index === elem)?.avatar;
          import('../../images/' + avatarName).then((avatar) => setAvatars((prev) => ({ ...prev, [elem]: avatar?.default })));
        }
      });
    }
  }, [accountantChats]);

  const getAccountantChats = () => {
    setLoading(true);

    request('/accountantChat/getAllForClient', { userId: clientAuth._id }, (data) => {
      const dataWithThreads = data.data.map((elem) => ({ ...elem, threadId: accountantChats.find((chat) => chat._id === elem._id)?.threadId }));
      dispatch(setAccountantChats(dataWithThreads));
      setLoading(false);
    });
  };

  useEffect(() => {
    getDisplay();
    getLastMessage();
    getAccountantChats();
  }, []);

  useEffect(() => {
    getPersonalConsultantChat();
  }, [clientInfo?.clubCard]);

  return (
    <>
      {moreMonthHasPassed && !isEmailVerified ? (
        <MandatoryConfirmationEmail text={translation.chatAccess[lang]} />
      ) : (
        <div className="chats-page">
          <div className="content">
            {personalConsultantChat ? <PersonalConsultantChatItem loading={loading} /> : null}
            {contracts.map((item) => (
              <ChatsItem key={item._id} data={item} />
            ))}
            {(displayContractAI || displayConsultationAI) && (
              <div className="chats" onClick={openChat}>
                <UserAvatar size={50} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }} />
                <div className="info">
                  <span className="name">{translation.chatForDozhimsAITitle[lang]}</span>
                  {loading ? <div className="spinner-opacity"></div> : <span className="message">{lastMessage}</span>}
                </div>
              </div>
            )}
            {accountantChats?.map((elem, index) => (
              <AccountantChatItem elem={elem} index={index} avatars={avatars} loading={loading} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Chats;
