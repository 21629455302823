const languageOptions = [
  {
    value: 'ua',
    label: 'UA',
  },
  {
    value: 'cs',
    label: 'CS',
  },
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'ru',
    label: 'RU',
  },
  {
    value: 'fr',
    label: 'FR',
  },
  {
    value: 'es',
    label: 'ES',
  },
];

const translation = {
  _appTitle: {
    ua: 'єАдвокат',
    en: 'eLawyer',
    de: 'eAnwalt',
    ru: 'еАдвокат',
    fr: 'eAvocat',
    es: 'eAbogado',
    cs: 'eAdvokát',
  },
  _companyName: {
    ua: 'Адвокатське об’єднання «Захист»',
    en: 'Advocacy Association «Zahist»',
    de: 'Anwaltsverband «Zahist»',
    ru: 'Адвокатское объединение «Защита»',
    fr: "Association d'avocats «Zahist»",
    es: 'Asociación de Abogados «Zahist»',
    cs: 'Advokátní sdružení «Zahist»',
  },
  _companyDescription: {
    ua: 'міжнародна юридична компанія',
    en: 'international law company',
    de: 'internationale Anwaltskanzlei',
    ru: 'международная юридическая компания',
    fr: "cabinet d'avocats international",
    es: 'firma de abogados internacional',
    cs: 'mezinárodní právní společnost',
  },
  _appDownload1: {
    ua: 'Завантажте мобільний додаток',
    en: 'Download the mobile app',
    de: 'Laden Sie die mobile App herunter',
    ru: 'Скачайте мобильное приложение',
    fr: "Téléchargez l'application mobile",
    es: 'Descargue la aplicación móvil',
    cs: 'Stáhněte si mobilní aplikaci',
  },
  _appDownload2: {
    ua: 'на свій смартфон та отримайте:',
    en: 'on your phone and get:',
    de: 'auf Ihr Smartphone und erhalten Sie:',
    ru: 'на свой телефон и получите:',
    fr: 'sur votre téléphone et obtenez :',
    es: 'en su teléfono y obtenga:',
    cs: 'do svého telefonu a získejte:',
  },
  _appBenefit1: {
    ua: 'Знижку на консультацію;',
    en: 'Discount on consultation;',
    de: 'Rabatt auf Beratung;',
    ru: 'Скидку на консультацию;',
    fr: 'Réduction sur la consultation ;',
    es: 'Descuento en consulta;',
    cs: 'Sleva na konzultaci;',
  },
  _appBenefit2: {
    ua: 'Повернення суми за консультацію на баланс;',
    en: 'Refund of the consultation amount to the balance;',
    de: 'Rückerstattung des Beratungsbetrags auf das Guthaben;',
    ru: 'Возврат суммы за консультацию на баланс;',
    fr: 'Remboursement du montant de la consultation sur le solde ;',
    es: 'Reembolso del importe de la consulta al saldo;',
    cs: 'Vrácení částky za konzultaci na zůstatek;',
  },
  _appBenefit3: {
    ua: 'Бонус у сумі 300 грн;',
    en: 'Bonus of 300 UAH;',
    de: 'Bonus von 300 UAH;',
    ru: 'Бонус в размере 300 грн;',
    fr: 'Bonus de 300 UAH ;',
    es: 'Bono de 300 UAH;',
    cs: 'Bonus 300 UAH;',
  },
  _learnMore: {
    ua: 'Дізнатися більше про компанію',
    en: 'Learn more about the company',
    de: 'Erfahren Sie mehr über das Unternehmen',
    ru: 'Узнать больше о компании',
    fr: 'En savoir plus sur la société',
    es: 'Más información sobre la empresa',
    cs: 'Zjistit více o společnosti',
  },
  _checkPortfolio: {
    ua: 'Переглянути портфоліо адвоката',
    en: "View lawyer's portfolio",
    de: 'Anwaltsportfolio anzeigen',
    ru: 'Посмотреть портфолио адвоката',
    fr: "Voir le portfolio de l'avocat",
    es: 'Ver el portafolio del abogado',
    cs: 'Zobrazit portfolio advokáta',
  },
  _learnMoreApp: {
    ua: 'Дізнатися більше про єАдвокат',
    en: 'Learn more about eAdvokat',
    de: 'Erfahren Sie mehr über eAdvokat',
    ru: 'Узнать больше о еАдвокате',
    fr: 'En savoir plus sur eAvocat',
    es: 'Más información sobre eAdvokat',
    cs: 'Zjistit více o eAdvokátovi',
  },
  // common phrases
  info: {
    ua: 'Інформація',
    en: 'Information',
    de: 'Information',
    ru: 'Информация',
    fr: 'Information',
    es: 'Información',
    cs: 'Informace',
  },
  rate: {
    ua: 'Оцінити',
    en: 'Rate',
    de: 'Bewerten',
    ru: 'Оценить',
    fr: 'Évaluer',
    es: 'Calificar',
    cs: 'Hodnotit',
  },
  name: {
    ua: 'Ім`я',
    en: 'Name',
    de: 'Name',
    ru: 'Имя',
    fr: 'Nom',
    es: 'Nombre',
    cs: 'Jméno',
  },
  phone: {
    ua: 'Телефон',
    en: 'Phone',
    de: 'Telefon',
    ru: 'Телефон',
    fr: 'Téléphone',
    es: 'Teléfono',
    cs: 'Telefon',
  },
  date: {
    ua: 'Дата',
    en: 'Date',
    de: 'Datum',
    ru: 'Дата',
    fr: 'Date',
    es: 'Fecha',
    cs: 'Datum',
  },
  sum: {
    ua: 'Сума',
    en: 'Amount',
    de: 'Betrag',
    ru: 'Сумма',
    fr: 'Montant',
    es: 'Monto',
    cs: 'Částka',
  },
  file: {
    ua: 'Файл',
    en: 'File',
    de: 'Datei',
    ru: 'Файл',
    fr: 'Fichier',
    es: 'Archivo',
    cs: 'Soubor',
  },
  term: {
    ua: 'Термін',
    en: 'Term',
    de: 'Begriff',
    ru: 'Срок',
    fr: 'Terme',
    es: 'Plazo',
    cs: 'Doba',
  },
  proposal: {
    ua: 'Пропозиція',
    en: 'Proposal',
    de: 'Vorschlag',
    ru: 'Предложение',
    fr: 'Proposition',
    es: 'Propuesta',
    cs: 'Návrh',
  },
  result: {
    ua: 'Результат',
    en: 'Result',
    de: 'Ergebnis',
    ru: 'Результат',
    fr: 'Résultat',
    es: 'Resultado',
    cs: 'Výsledek',
  },
  agree: {
    ua: 'Погодитися',
    en: 'Agree',
    de: 'Einverstanden',
    ru: 'Согласиться',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Souhlasit',
  },
  chat: {
    ua: 'Чат',
    en: 'Chat',
    de: 'Chat',
    ru: 'Чат',
    fr: 'Chat',
    es: 'Chat',
    cs: 'Chat',
  },
  send: {
    ua: 'Надіслати',
    en: 'Send',
    de: 'Senden',
    ru: 'Отправить',
    fr: 'Envoyer',
    es: 'Enviar',
    cs: 'Odeslat',
  },
  transactions: {
    ua: 'Транзакції',
    en: 'Transactions',
    de: 'Transaktionen',
    ru: 'Транзакции',
    fr: 'Transactions',
    es: 'Transacciones',
    cs: 'Transakce',
  },
  welcome: {
    ua: 'Ласкаво просимо',
    en: 'Welcome',
    de: 'Willkommen',
    ru: 'Добро пожаловать',
    fr: 'Bienvenue',
    es: 'Bienvenido',
    cs: 'Vítejte',
  },
  welcomeText: {
    ua: 'Увійдіть, щоб отримати доступ до свого облікового запису',
    en: 'Log in to access your account',
    de: 'Melden Sie sich an, um auf Ihr Konto zuzugreifen',
    ru: 'Войдите, чтобы получить доступ к своему аккаунту',
    fr: 'Connectez-vous pour accéder à votre compte',
    es: 'Inicie sesión para acceder a su cuenta',
    cs: 'Přihlaste se pro přístup k vašemu účtu',
  },
  enter: {
    ua: 'Увійти',
    en: 'Log in',
    de: 'Einloggen',
    ru: 'Войти',
    fr: 'Se connecter',
    es: 'Iniciar sesión',
    cs: 'Přihlásit se',
  },
  register: {
    ua: 'Зареєструватися',
    en: 'Register',
    de: 'Registrieren',
    ru: 'Зарегистрироваться',
    fr: "S'inscrire",
    es: 'Registrarse',
    cs: 'Registrovat se',
  },
  password: {
    ua: 'Пароль',
    en: 'Password',
    de: 'Passwort',
    ru: 'Пароль',
    fr: 'Mot de passe',
    es: 'Contraseña',
    cs: 'Heslo',
  },
  confirmPassword: {
    ua: 'Підтвердіть пароль',
    en: 'Confirm Password',
    de: 'Bestätigen Passwort',
    ru: 'Подтвердите пароль',
    fr: 'Confirmer le mot de passe',
    es: 'Confirmar contraseña',
    cs: 'Potvrďte heslo',
  },
  notMessages: {
    ua: 'Немає повідомлень',
    en: 'No messages',
    de: 'Keine Nachrichten',
    ru: 'Нет сообщений',
    fr: 'Pas de messages',
    es: 'No hay mensajes',
    cs: 'Žádné zprávy',
  },
  deleted: {
    ua: 'Видалено',
    en: 'Deleted',
    de: 'Gelöscht',
    ru: 'Удалено',
    fr: 'Supprimé',
    es: 'Borrado',
    cs: 'Smazáno',
  },
  edited: {
    ua: 'Відредаговано',
    en: 'Edited',
    de: 'Bearbeitet',
    ru: 'Отредактировано',
    fr: 'Édité',
    es: 'Editado',
    cs: 'Upraveno',
  },
  price: {
    ua: 'Ціна',
    ru: 'Цена',
    de: 'Preis',
    en: 'Price',
    fr: 'Prix',
    es: 'Precio',
    cs: 'Cena',
  },
  time: {
    ua: 'Час',
    en: 'Time',
    de: 'Zeit',
    ru: 'Время',
    fr: 'Heure',
    es: 'Hora',
    cs: 'Čas',
  },
  comment: {
    ua: 'Коментар',
    en: 'Comment',
    de: 'Kommentar',
    ru: 'Комментарий',
    fr: 'Commentaire',
    es: 'Comentario',
    cs: 'Komentář',
  },
  note: {
    ua: 'Примітка',
    en: 'Note',
    de: 'Hinweis',
    ru: 'Примечание',
    fr: 'Remarque',
    es: 'Nota',
    cs: 'Poznámka',
  },
  page: {
    ua: 'Сторінка',
    en: 'Page',
    de: 'Seite',
    ru: 'Страница',
    fr: 'Page',
    es: 'Página',
    cs: 'Stránka',
  },
  client: {
    ua: 'Клієнт',
    en: 'Client',
    de: 'Kunde',
    ru: 'Клиент',
    fr: 'Client',
    es: 'Cliente',
    cs: 'Klient',
  },
  tryAgain: {
    ua: 'Будь ласка, спробуйте ще раз',
    en: 'Please try again',
    de: 'Bitte versuchen Sie es erneut',
    ru: 'Пожалуйста, попробуйте еще раз',
    fr: 'Veuillez réessayer',
    es: 'Por favor, inténtelo de nuevo',
    cs: 'Zkuste to prosím znovu',
  },
  approvement: {
    ua: 'Підтвердження',
    en: 'Approvement',
    de: 'Bestätigung',
    ru: 'Подтверждение',
    fr: 'Approbation',
    es: 'Aprobación',
    cs: 'Schválení',
  },
  status: {
    ua: 'Статус',
    en: 'Status',
    de: 'Status',
    ru: 'Статус',
    fr: 'Statut',
    es: 'Estado',
    cs: 'Stav',
  },

  // page titles
  home: {
    ua: 'Головна',
    en: 'Homepage',
    de: 'Hauptseite',
    ru: 'Главная',
    fr: 'Accueil',
    es: 'Inicio',
    cs: 'Domů',
  },
  homePage: {
    ua: 'Головна сторінка',
    en: 'Homepage',
    de: 'Hauptseite',
    ru: 'Главная страница',
    fr: "Page d'accueil",
    es: 'Página principal',
    cs: 'Hlavní stránka',
  },
  hotline: {
    ua: 'Запис на консультацію',
    en: 'Appointment for consultation',
    de: 'Termin zur Beratung',
    ru: 'Запись на консультацию',
    fr: 'Rendez-vous pour une consultation',
    es: 'Cita para consulta',
    cs: 'Schůzka na konzultaci',
  },
  partnerHotline: {
    ua: 'Партнерські консультації',
    en: 'Partner consultations',
    de: 'Partnerberatungen',
    ru: 'Партнерские консультации',
    fr: 'Consultations de partenaires',
    es: 'Consultas de socios',
    cs: 'Konzultace partnerů',
  },
  myConsultations: {
    ua: 'Мої консультації',
    en: 'My consultations',
    de: 'Meine Beratungen',
    ru: 'Мои консультации',
    fr: 'Mes consultations',
    es: 'Mis consultas',
    cs: 'Moje konzultace',
  },
  clientChats: {
    ua: 'Чати',
    en: 'Chats',
    de: 'Chats',
    ru: 'Чаты',
    fr: 'Chats',
    es: 'Chats',
    cs: 'Chaty',
  },
  quickQuestion: {
    ua: 'Швидке питання',
    en: 'Quick question',
    de: 'Schnelle Frage',
    ru: 'Быстрый вопрос',
    fr: 'Question rapide',
    es: 'Pregunta rápida',
    cs: 'Rychlá otázka',
  },
  myBusiness: {
    ua: 'Мої справи',
    en: 'My affairs',
    de: 'Meine Angelegenheiten',
    ru: 'Мои дела',
    fr: 'Mes affaires',
    es: 'Mis asuntos',
    cs: 'Moje záležitosti',
  },
  tenders: {
    ua: 'Тендери',
    en: 'Tenders',
    de: 'Ausschreibungen',
    ru: 'Тендеры',
    fr: "Appels d'offres",
    es: 'Licitaciones',
    cs: 'Zakázky',
  },
  insuranceCard: {
    ua: 'Юридична страховка',
    en: 'Legal insurance',
    de: 'Rechtsschutzversicherung',
    ru: 'Юридическая страховка',
    fr: 'Assurance juridique',
    es: 'Seguro legal',
    cs: 'Právní pojištění',
  },
  balance: {
    ua: 'Баланс',
    en: 'Balance',
    de: 'Balance',
    ru: 'Баланс',
    fr: 'Solde',
    es: 'Saldo',
    cs: 'Zůstatek',
  },
  inviteFriend: {
    ua: 'Запросити друга',
    en: 'Invite friend',
    de: 'Freund einladen',
    ru: 'Пригласить друга',
    fr: 'Inviter un ami',
    es: 'Invitar a un amigo',
    cs: 'Pozvat přítele',
  },
  inviteClient: {
    ua: 'Запросити клієнта',
    en: 'Invite client',
    de: 'Kunden einladen',
    ru: 'Пригласить клиента',
    fr: 'Inviter un client',
    es: 'Invitar a un cliente',
    cs: 'Pozvat klienta',
  },
  otherWayToInvite: {
    ua: 'Інший спосіб запрошення',
    en: 'Another way to invite',
    de: 'Andere Möglichkeit einzuladen',
    ru: 'Другой способ приглашения',
    fr: "Autre moyen d'inviter",
    es: 'Otra forma de invitar',
    cs: 'Jiný způsob pozvání',
  },
  partnerCabinet: {
    ua: 'Кабінет партнера',
    en: 'Partner cabinet',
    de: 'Partnerkabinett',
    ru: 'Кабинет партнера',
    fr: 'Cabinet partenaire',
    es: 'Gabinete de socio',
    cs: 'Partner kabinet',
  },
  inviteText: {
    ua: `1. Встановіть застосунок за посиланням $LINK$ і зареєструйся за 5 хвилин
2. Запишіться на консультацію
3. Отримайте 300грн. бонусів
4. Отримуйте срібну Клубну Карту Адвокатського обʼєднання «Захист»`,
    en: `1. Install the application by the link $LINK$ and register in 5 minutes
2. Sign up for a consultation
3. Get 300 UAH bonuses
4. Get a silver Club Card of the Advocacy Association "Zahist"`,
    de: `1. Installieren Sie die Anwendung über den Link $LINK$ und registrieren Sie sich in 5 Minuten
2. Melden Sie sich für eine Beratung an
3. Erhalten Sie 300 UAH Boni
4. Erhalten Sie eine silberne Clubkarte des Anwaltsverbandes "Zahist"`,
    ru: `1. Установите приложение по ссылке $LINK$ и зарегистрируйтесь за 5 минут
2. Запишитесь на консультацию
3. Получите 300 грн бонусов
4. Получите серебряную Клубную Карту Адвокатского объединения «Защита»`,
    fr: `1. Installez l'application via le lien $LINK$ et inscrivez-vous en 5 minutes
2. Inscrivez-vous pour une consultation
3. Obtenez 300 UAH de bonus
4. Obtenez une carte de club en argent de l'Association des avocats "Zahist"`,
    es: `1. Instale la aplicación a través del enlace $LINK$ y regístrate en 5 minutos
2. Regístrese para una consulta
3. Obtenga 300 UAH de bonificación
4. Obtenga una tarjeta de club plateada de la Asociación de Abogados "Zahist"`,
    cs: `1. Nainstalujte aplikaci pomocí odkazu $LINK$ a zaregistrujte se za 5 minut
2. Přihlaste se na konzultaci
3. Získejte 300 UAH bonusů
4. Získejte stříbrnou klubovou kartu Advokátního sdružení "Zahist"`,
  },
  clientAlreadyUsedServices: {
    ua: 'Клієнт вже користувався послугами нашого додатку "єАдвокат"',
    en: 'The client has already used the services of our "eLawyer" app',
    de: 'Der Kunde hat bereits die Dienste unserer "eAnwalt"-App genutzt',
    ru: 'Клиент уже пользовался услугами нашего приложения "еАдвокат"',
    fr: 'Le client a déjà utilisé les services de notre application "eAvocat"',
    es: 'El cliente ya ha utilizado los servicios de nuestra aplicación "eAbogado"',
    cs: 'Klient již využil služby naší aplikace "eAdvokát"',
  },
  limitExceededTryLater: {
    ua: 'Ліміт перевищено, спробуйте, будь ласка, пізніше',
    en: 'Limit exceeded, please try again later',
    de: 'Limit überschritten, bitte versuchen Sie es später noch einmal',
    ru: 'Превышен лимит, попробуйте позже',
    fr: 'Limite dépassée, veuillez réessayer plus tard',
    es: 'Límite excedido, por favor intente más tarde',
    cs: 'Překročeno omezení, zkuste to prosím později',
  },
  linkAlreadySentToClient: {
    ua: 'Ви вже надсилали посилання даному клієнту',
    en: 'You have already sent a link to this client',
    de: 'Sie haben diesem Kunden bereits einen Link gesendet',
    ru: 'Вы уже отправляли ссылку этому клиенту',
    fr: 'Vous avez déjà envoyé un lien à ce client',
    es: 'Ya ha enviado un enlace a este cliente',
    cs: 'Odkaz jste již tomuto klientovi odeslali',
  },
  contacts: {
    ua: 'Контакти',
    en: 'Contacts',
    de: 'Kontakte',
    ru: 'Контакты',
    fr: 'Contacts',
    es: 'Contactos',
    cs: 'Kontakty',
  },
  reviews: {
    ua: 'Відгуки',
    en: 'Feedback',
    de: 'Rückmeldungen',
    ru: 'Отзывы',
    fr: 'Commentaires',
    es: 'Reseñas',
    cs: 'Recenze',
  },
  buyAlarmButton: {
    ua: 'Тривожна кнопка',
    en: 'Alarm button',
    de: 'Alarmknopf',
    ru: 'Тревожная кнопка',
    fr: "Bouton d'alarme",
    es: 'Botón de alarma',
    cs: 'Tlačítko alarmu',
  },
  buyButton: {
    ua: 'Купити кнопку',
    en: 'Buy button',
    de: 'Kaufen Sie den Button',
    ru: 'Купить кнопку',
    fr: 'Acheter le bouton',
    es: 'Comprar botón',
    cs: 'Koupit tlačítko',
  },
  bonusCard: {
    ua: 'Бонусна карта',
    en: 'Bonus card',
    de: 'Bonuskarte',
    ru: 'Бонусная карта',
    fr: 'Carte bonus',
    es: 'Tarjeta de bonificación',
    cs: 'Bonusová karta',
  },
  bonusTransactionSuccess: {
    ua: 'Бонуси успішно зараховані',
    ru: 'Бонусы успешно зачислены',
    de: 'Boni erfolgreich gutgeschrieben',
    en: 'Bonuses successfully credited',
    fr: 'Les bonus ont été crédités avec succès',
    es: 'Bonos acreditados con éxito',
    cs: 'Bonusy byly úspěšně připsány',
  },
  bonusTransactionFailure: {
    ua: 'Бонусна транзакція не пройшла, спробуйте ще раз',
    ru: 'Бонусная транзакция не прошла, попробуйте еще раз',
    de: 'Bonus-Transaktion fehlgeschlagen, bitte versuchen Sie es erneut',
    en: 'Bonus transaction failed, please try again',
    fr: 'La transaction de bonus a échoué, veuillez réessayer',
    es: 'Transacción de bonificación fallida, por favor intente nuevamente',
    cs: 'Transakce s bonusem neproběhla, zkuste to znovu',
  },

  bonusCashbackTransactions: {
    ua: [
      'Оплата гонорару за договором',
      'Щомісячна оплата за договором',
      'Оформлення юридичної страховки',
      'Продовження юридичної страховки',
      'Щомісячна оплата за ведення справи',
      'Оплата за консультацію',
      'Оплата за запитання',
      'Автоматична оплата послуг',
      'Оплата за тривожну кнопку',
      'Оплата за страховку',
      'Покупка клубної карти',
      'Оплата заборговоності з балансу',
      'Оплата за вебінар',
    ],
    ru: [
      'Оплата гонорара по договору',
      'Ежемесячная оплата по договору',
      'Оформление юридической страховки',
      'Продление юридической страховки',
      'Ежемесячная оплата за ведение дела',
      'Оплата за консультацию',
      'Оплата за вопрос',
      'Автоматическая оплата услуг',
      'Оплата за тревожную кнопку',
      'Оплата за страховку',
      'Покупка клубной карты',
      'Оплата задолженности с баланса',
      'Оплата за вебинар',
    ],
    en: [
      'Payment of fee under the contract',
      'Monthly payment under the contract',
      'Legal insurance arrangement',
      'Legal insurance renewal',
      'Monthly payment for case management',
      'Payment for consultation',
      'Payment for question',
      'Automatic payment for services',
      'Payment for panic button',
      'Payment for insurance',
      'Purchase of club card',
      'Debt payment from balance',
      'Payment for webinar',
    ],
    de: [
      'Zahlung der Gebühr gemäß Vertrag',
      'Monatliche Zahlung gemäß Vertrag',
      'Abschluss einer Rechtsversicherung',
      'Verlängerung der Rechtsversicherung',
      'Monatliche Zahlung für Fallverwaltung',
      'Zahlung für Beratung',
      'Zahlung für Frage',
      'Automatische Zahlung für Dienstleistungen',
      'Zahlung für Paniktaste',
      'Zahlung für Versicherung',
      'Kauf einer Clubkarte',
      'Schuldenzahlung vom Guthaben',
      'Zahlung für Webinar',
    ],
    fr: [
      'Paiement des honoraires en vertu du contrat',
      'Paiement mensuel en vertu du contrat',
      "Arrangement d'assurance juridique",
      "Renouvellement de l'assurance juridique",
      'Paiement mensuel pour la gestion de cas',
      'Paiement pour consultation',
      'Paiement pour question',
      'Paiement automatique pour services',
      "Paiement pour bouton d'alarme",
      'Paiement pour assurance',
      'Achat de carte de membre',
      'Paiement de la dette à partir du solde',
      'Paiement pour webinaire',
    ],
    es: [
      'Pago de honorarios bajo el contrato',
      'Pago mensual bajo el contrato',
      'Arreglo de seguro legal',
      'Renovación del seguro legal',
      'Pago mensual por gestión de casos',
      'Pago por consulta',
      'Pago por pregunta',
      'Pago automático por servicios',
      'Pago por botón de pánico',
      'Pago por seguro',
      'Compra de tarjeta de club',
      'Pago de deudas desde el saldo',
      'Pago por seminario web',
    ],
    cs: [
      'Platba za honorář podle smlouvy',
      'Měsíční platba podle smlouvy',
      'Uzavření právního pojištění',
      'Obnovení právního pojištění',
      'Měsíční platba za správu případů',
      'Platba za konzultaci',
      'Platba za otázku',
      'Automatická platba za služby',
      'Platba za tlačítko paniky',
      'Platba za pojištění',
      'Nákup klubové karty',
      'Platba dluhu zůstatku',
      'Platba za webinář',
    ],
  },

  bonusMainTitle: {
    ua: 'Інформація про бонуси',
    en: 'Information about bonuses',
    de: 'Informationen über Boni',
    ru: 'Информация о бонусах',
    fr: 'Informations sur les bonus',
    es: 'Información sobre bonificaciones',
    cs: 'Informace o bonusech',
  },
  bonusHowToUseTitle: {
    ua: 'Як використати бонуси ?',
    en: 'How to use bonuses',
    de: 'Wie benutzt man Boni?',
    ru: 'Как использовать бонусы?',
    fr: 'Comment utiliser les bonus ?',
    es: 'Cómo usar bonificaciones',
    cs: 'Jak využít bonusy?',
  },
  bonusHowToUse: {
    ua: 'Як Ви можете використати наявні бонуси?',
    en: 'How can you use available bonuses?',
    de: 'Wie können Sie verfügbare Boni nutzen?',
    ru: 'Как вы можете использовать доступные бонусы?',
    fr: 'Comment pouvez-vous utiliser les bonus disponibles ?',
    es: '¿Cómo puedes utilizar las bonificaciones disponibles?',
    cs: 'Jak můžete využít dostupné bonusy?',
  },
  bonusButton: {
    ua: 'Для того, щоб скористатись бонусами, необхідно натиснути на кнопку',
    en: 'To use bonuses, you need to press the button',
    de: 'Um Boni zu nutzen, müssen Sie auf die Schaltfläche drücken',
    ru: 'Чтобы воспользоваться бонусами, нажмите кнопку',
    fr: 'Pour utiliser les bonus, appuyez sur le bouton',
    es: 'Para usar bonificaciones, presione el botón',
    cs: 'Chcete-li použít bonusy, stiskněte tlačítko',
  },
  bonusLimits: {
    ua: 'Що варто враховувати при використанні бонусів ?',
    en: 'What should you consider when using bonuses?',
    de: 'Was sollten Sie bei der Nutzung von Boni beachten?',
    ru: 'Что следует учитывать при использовании бонусов?',
    fr: "Que faut-il prendre en compte lors de l'utilisation des bonus ?",
    es: '¿Qué debes considerar al usar bonificaciones?',
    cs: 'Co byste měli vzít v úvahu při používání bonusů?',
  },
  bonusLimit1: {
    ua: 'З моменту транзакції має пройти не більше ',
    en: 'No more than ',
    de: 'Seit der Transaktion dürfen nicht mehr als ',
    ru: 'С момента транзакции должно пройти не более ',
    fr: "Il ne doit pas s'écouler plus de ",
    es: 'No más de ',
    cs: 'Od transakce by nemělo uplynout více než ',
  },
  bonusLimit1Days: {
    ua: '30 днів',
    en: '30 days',
    de: '30 Tage',
    ru: '30 дней',
    fr: '30 jours',
    es: '30 días',
    cs: '30 dní',
  },
  bonusLimit2: {
    ua: 'Бонусів має вистачати щоб повернути',
    en: 'There must be enough bonuses to cover',
    de: 'Es müssen genügend Boni vorhanden sein, um zurückzuzahlen',
    ru: 'Бонусов должно хватать, чтобы покрыть',
    fr: 'Il doit y avoir suffisamment de bonus pour couvrir',
    es: 'Debe haber suficientes bonificaciones para cubrir',
    cs: 'Musí být dostatek bonusů na pokrytí',
  },
  bonusLimit2Percents: {
    ua: '30% від транзакції',
    en: '30% of the transaction',
    de: '30% der Transaktion',
    ru: '30% от транзакции',
    fr: '30% de la transaction',
    es: '30% de la transacción',
    cs: '30% transakce',
  },
  bonusLimit3: {
    ua: 'Кешбек може бути отриманий по наступним ',
    en: 'Cashback can be earned on the following ',
    de: 'Cashback kann für die folgenden ',
    ru: 'Кешбэк может быть получен по следующим ',
    fr: 'Le cashback peut être obtenu sur les transactions suivantes :',
    es: 'El reembolso puede obtenerse en las siguientes ',
    cs: 'Cashback lze získat na následujících ',
  },
  bonusLimit3Transactions: {
    ua: 'транзакціях',
    en: 'transactions',
    de: 'Transaktionen',
    ru: 'транзакциям',
    fr: 'transactions',
    es: 'transacciones',
    cs: 'transakcích',
  },

  createTender: {
    ua: 'Створити тендер',
    en: 'Create tender',
    de: 'Eine Ausschreibung erstellen',
    ru: 'Создать тендер',
    fr: "Créer un appel d'offres",
    es: 'Crear licitación',
    cs: 'Vytvořit výběrové řízení',
  },
  tenderDetails: {
    ua: 'Деталі тендеру',
    en: 'Tender details',
    de: 'Ausschreibungsdetails',
    ru: 'Детали тендера',
    fr: "Détails de l'appel d'offres",
    es: 'Detalles de la licitación',
    cs: 'Podrobnosti výběrového řízení',
  },
  deleteTender: {
    ua: 'Тендер видалено',
    ru: 'Тендер удален',
    de: 'Ausschreibung gelöscht',
    en: 'Tender deleted',
    fr: 'Appel d’offres supprimé',
    es: 'Licitar eliminado',
    cs: 'Výběrové řízení smazáno',
  },
  portfolio: {
    ua: 'Портфоліо',
    en: 'Portfolio',
    de: 'Portfolio',
    ru: 'Портфолио',
    fr: 'Portfolio',
    es: 'Portafolio',
    cs: 'Portfolio',
  },
  registerPage: {
    ua: 'Реєстри',
    en: 'Registers',
    de: 'Register',
    ru: 'Реестры',
    fr: 'Registres',
    es: 'Registros',
    cs: 'Registry',
  },

  // modals
  topUpYourBalance: {
    ua: 'Поповнити баланс',
    en: 'Recharge balance',
    de: 'Guthaben aufladen',
    ru: 'Пополнить баланс',
    fr: 'Recharger le solde',
    es: 'Recargar saldo',
    cs: 'Dobít zůstatek',
  },
  scanQRcode: {
    ua: 'QR code',
    en: 'QR code',
    de: 'QR-Code',
    ru: 'QR-код',
    fr: 'Code QR',
    es: 'Código QR',
    cs: 'QR kód',
  },
  scanQRcodeModalTitle: {
    ua: 'Сканування QR code',
    en: 'QR code scanning',
    de: 'QR-Code-Scannen',
    ru: 'Сканирование QR-кода',
    fr: 'Scanner le code QR',
    es: 'Escaneo de código QR',
    cs: 'Skenování QR kódu',
  },
  scanQRcod: {
    ua: 'Скануйте QR-код',
    en: 'Scan QR Code',
    de: 'QR-Code scannen',
    ru: 'Сканируйте QR-код',
    fr: 'Scannez le code QR',
    es: 'Escanee el código QR',
    cs: 'Naskenujte QR kód',
  },

  startModal: {
    ua: 'Потрібен адвокат?',
    en: 'Need a lawyer?',
    de: 'Brauchen Sie einen Anwalt?',
    ru: 'Нужен адвокат?',
    fr: "Besoin d'un avocat ?",
    es: '¿Necesita un abogado?',
    cs: 'Potřebujete advokáta?',
  },
  startModal1: {
    ua: 'Подзвонити на гарячу лінію',
    en: 'Call the hotline',
    de: 'Bei der Hotline anrufe',
    ru: 'Позвонить на горячую линию',
    fr: 'Appeler la hotline',
    es: 'Llamar a la línea directa',
    cs: 'Zavolat na horkou linku',
  },
  startModal2: {
    ua: 'Записатися власноруч',
    en: 'Sign up for an appointment manually',
    de: 'Sich manuell für einen Termin anmelden',
    ru: 'Записаться самостоятельно',
    fr: 'Prendre un rendez-vous manuellement',
    es: 'Registrarse manualmente',
    cs: 'Zaregistrujte se ručně',
  },
  startModal3: {
    ua: 'Задати швидке питання',
    en: 'Ask a quick question.',
    de: 'Eine schnelle Frage stellen',
    ru: 'Задать быстрый вопрос',
    fr: 'Poser une question rapide',
    es: 'Hacer una pregunta rápida',
    cs: 'Položit rychlou otázku',
  },
  startModal4: {
    ua: 'Створити тендер',
    en: 'Create a tender',
    de: 'Eine Ausschreibung erstellen',
    ru: 'Создать тендер',
    fr: "Créer un appel d'offres",
    es: 'Crear una licitación',
    cs: 'Vytvořit výběrové řízení',
  },
  modalWindow: {
    ua: 'Дані не додані',
    en: 'Data not added',
    de: 'Daten nicht hinzugefügt',
    ru: 'Данные не добавлены',
    fr: 'Données non ajoutées',
    es: 'Datos no añadidos',
    cs: 'Data nebyla přidána',
  },
  payContractDebt: {
    ua: 'Заборгованість по договору успішно оплачена',
    en: 'Contract debt successfully paid',
    de: 'Verpflichtungsbetrag erfolgreich bezahlt',
    ru: 'Долг по договору успешно оплачен',
    fr: 'Dette contractuelle payée avec succès',
    es: 'Deuda de contrato pagada con éxito',
    cs: 'Dluh ze smlouvy byl úspěšně zaplacen',
  },
  debtName: {
    ua: 'Заборгованість',
    en: 'Debt',
    de: 'Verschuldung',
    ru: 'Задолженность',
    fr: 'Dette',
    es: 'Deuda',
    cs: 'Dluh',
  },
  debtContentContract: {
    ua: 'За договором',
    en: 'Under contract',
    de: 'Unter Vertrag',
    ru: 'По договору',
    fr: 'Sous contrat',
    es: 'Bajo contrato',
    cs: 'Pod smlouvou',
  },
  consultaionSelectBranch: {
    ua: 'Спочатку потрібно обрати філію',
    en: 'First, you need to select a branch',
    de: 'Zuerst müssen Sie eine Filiale auswählen',
    ru: 'Сначала выберите филиал',
    fr: "D'abord, vous devez sélectionner une agence",
    es: 'Primero, debe seleccionar una sucursal',
    cs: 'Nejprve musíte vybrat pobočku',
  },

  // ClientHotlineSubmitBtn

  title: {
    ua: 'Записатись?',
    en: 'Sign up?',
    de: 'Anmelden?',
    ru: 'Записаться?',
    fr: "S'inscrire ?",
    es: '¿Inscribirse?',
    cs: 'Přihlásit se?',
  },
  branch: {
    ua: 'Філія: ',
    en: 'Branch: ',
    de: 'Zweigstelle: ',
    ru: 'Филиал: ',
    fr: 'Agence : ',
    es: 'Sucursal: ',
    cs: 'Pobočka: ',
  },
  service: {
    ua: 'Послуга',
    en: 'Service',
    de: 'Dienstleistung',
    ru: 'Услуга',
    fr: 'Service',
    es: 'Servicio',
    cs: 'Služba',
  },
  from: {
    ua: 'З: ',
    en: 'From: ',
    de: 'Von: ',
    ru: 'От: ',
    fr: 'De : ',
    es: 'De: ',
    cs: 'Od: ',
  },
  of: {
    ua: 'з',
    en: 'of',
    de: 'von',
    ru: 'из',
    fr: 'de',
    es: 'de',
    cs: 'z',
  },
  toClient: {
    ua: 'По: ',
    en: 'To: ',
    de: 'Bis: ',
    ru: 'До: ',
    fr: 'À : ',
    es: 'Hasta: ',
    cs: 'Do: ',
  },
  confirm: {
    ua: 'Підтвердити',
    en: 'Confirm',
    de: 'Bestätigen',
    ru: 'Подтвердить',
    fr: 'Confirmer',
    es: 'Confirmar',
    cs: 'Potvrdit',
  },
  cancel: {
    ua: 'Скасувати',
    en: 'Cancel',
    de: 'Stornieren',
    ru: 'Отмена',
    fr: 'Annuler',
    es: 'Cancelar',
    cs: 'Zrušit',
  },
  signUp: {
    ua: 'Записатись',
    en: 'Sign up',
    de: 'Anmelden',
    ru: 'Записаться',
    fr: "S'inscrire",
    es: 'Registrarse',
    cs: 'Přihlásit se',
  },

  contractConditions: {
    ua: 'Умови договору юридичної страховки',
    en: 'Legal Insurance Contract Terms',
    de: 'Bedingungen des Rechtsschutzvertrags',
    ru: 'Условия договора юридической страховки',
    fr: "Conditions du contrat d'assurance juridique",
    es: 'Términos del contrato de seguro legal',
    cs: 'Podmínky právní pojistné smlouvy',
  },
  definitionOfTerms: {
    ua: 'Визначення термінів',
    en: 'Definition of Terms',
    de: 'Begriffsbestimmungen',
    ru: 'Определение терминов',
    fr: 'Définition des termes',
    es: 'Definición de términos',
    cs: 'Definice pojmů',
  },
  legalInsurance: {
    ua: 'Юридична страховка',
    en: 'Legal Insurance',
    de: 'Rechtsschutzversicherung',
    ru: 'Юридическая страховка',
    fr: 'Assurance juridique',
    es: 'Seguro legal',
    cs: 'Právní pojištění',
  },
  executor: {
    ua: 'Виконавець',
    en: 'Executor',
    de: 'Ausführender',
    ru: 'Исполнитель',
    fr: 'Exécuteur',
    es: 'Ejecutor',
    cs: 'Výkonavatel',
  },
  customer: {
    ua: 'Замовник',
    en: 'Customer',
    de: 'Kunde',
    ru: 'Заказчик',
    fr: 'Client',
    es: 'Cliente',
    cs: 'Zákazník',
  },
  insuranceCase: {
    ua: 'Страховий випадок',
    en: 'Insurance Case',
    de: 'Versicherungsfall',
    ru: 'Страховой случай',
    fr: "Cas d'assurance",
    es: 'Caso de seguro',
    cs: 'Pojistná událost',
  },
  insuranceAction: {
    ua: 'Дія юридичної страховки',
    en: 'Legal Insurance Action',
    de: 'Rechtsschutzversicherungsdauer',
    ru: 'Действие юридической страховки',
    fr: "Durée de l'assurance juridique",
    es: 'Duración del seguro legal',
    cs: 'Doba právního pojištění',
  },
  insuranceAmount: {
    ua: 'Страхова сума',
    en: 'Insurance Amount',
    de: 'Versicherungssumme',
    ru: 'Страховая сумма',
    fr: "Montant de l'assurance",
    es: 'Monto del seguro',
    cs: 'Pojistná částka',
  },
  insurancePayment: {
    ua: 'Страховий платіж',
    en: 'Insurance Payment',
    de: 'Versicherungsbeitrag',
    ru: 'Страховой платеж',
    fr: "Paiement de l'assurance",
    es: 'Pago de seguro',
    cs: 'Pojistné',
  },
  insuranceTerm: {
    ua: 'Страховий строк',
    en: 'Insurance Term',
    de: 'Versicherungsdauer',
    ru: 'Срок страхования',
    fr: "Durée de l'assurance",
    es: 'Plazo del seguro',
    cs: 'Doba pojištění',
  },
  priceList: {
    ua: 'Прейскурант цін',
    en: 'Price List',
    de: 'Preisliste',
    ru: 'Прейскурант',
    fr: 'Liste de prix',
    es: 'Lista de precios',
    cs: 'Ceník',
  },
  activation: {
    ua: 'Активація юридичної страховки',
    en: 'Legal Insurance Activation',
    de: 'Aktivierung der Rechtsschutzversicherung',
    ru: 'Активация юридической страховки',
    fr: "Activation de l'assurance juridique",
    es: 'Activación del seguro legal',
    cs: 'Aktivace právního pojištění',
  },
  cases: {
    ua: 'Страхові випадки',
    en: 'Insurance Cases',
    de: 'Versicherungsfälle',
    ru: 'Страховые случаи',
    fr: "Cas d'assurance",
    es: 'Casos de seguro',
    cs: 'Pojistné případy',
  },
  insuranceCost: {
    ua: 'Вартість юридичної страховки',
    en: 'Legal Insurance Cost',
    de: 'Kosten der Rechtsschutzversicherung',
    ru: 'Стоимость юридической страховки',
    fr: "Coût de l'assurance juridique",
    es: 'Costo del seguro legal',
    cs: 'Cena právního pojištění',
  },
  saving: {
    ua: 'Економія',
    en: 'Saving',
    de: 'Ersparnis',
    ru: 'Экономия',
    fr: 'Économies',
    es: 'Ahorro',
    cs: 'Úspora',
  },
  months6: {
    ua: '6 місяців',
    en: '6 months',
    de: '6 Monate',
    ru: '6 месяцев',
    fr: '6 mois',
    es: '6 meses',
    cs: '6 měsíců',
  },
  year1: {
    ua: '1 рік',
    en: '1 year',
    de: '1 Jahr',
    ru: '1 год',
    fr: '1 an',
    es: '1 año',
    cs: '1 rok',
  },
  years3: {
    ua: '3 роки',
    en: '3 years',
    de: '3 Jahre',
    ru: '3 года',
    fr: '3 ans',
    es: '3 años',
    cs: '3 roky',
  },
  perHour: {
    ua: 'грн за годину',
    en: 'UAH per hour',
    de: 'UAH pro Stunde',
    ru: 'грн за час',
    fr: 'UAH par heure',
    es: 'UAH por hora',
    cs: 'UAH za hodinu',
  },
  activationText: {
    ua: 'У разі активації юридичної страховки, Виконавець бере на себе зобов’язання надати Замовнику юридичні послуги, направлені на вирішення правової проблеми, спричиненої страховим випадком, вартість яких визначена прейскурантом цін, в межах страхової суми.',
    en: 'Upon activation of legal insurance, the Executor undertakes to provide the Customer with legal services aimed at resolving the legal problem caused by the insured event, the cost of which is determined by the price list, within the insurance amount.',
    de: 'Im Falle der Aktivierung der Rechtsschutzversicherung verpflichtet sich der Ausführende, dem Kunden Rechtsdienstleistungen zur Lösung des durch das Versicherungsereignis verursachten rechtlichen Problems zu erbringen, deren Kosten gemäß der Preisliste innerhalb der Versicherungssumme festgelegt sind.',
    ru: 'В случае активации юридической страховки Исполнитель обязуется предоставить Заказчику юридические услуги, направленные на решение правовой проблемы, вызванной страховым случаем, стоимость которых определяется прейскурантом, в пределах страховой суммы.',
    fr: "En cas d'activation de l'assurance juridique, l'Exécuteur s'engage à fournir au Client des services juridiques visant à résoudre le problème juridique causé par l'événement assuré, dont le coût est déterminé par la liste des prix, dans la limite de la somme assurée.",
    es: 'Al activar el seguro legal, el Ejecutor se compromete a proporcionar al Cliente servicios jurídicos destinados a resolver el problema legal causado por el siniestro asegurado, cuyo costo está determinado por la lista de precios, dentro del monto asegurado.',
    cs: 'V případě aktivace právního pojištění se Výkonný zavazuje poskytnout zákazníkovi právní služby zaměřené na řešení právního problému způsobeného pojistnou událostí, jejichž náklady jsou určeny ceníkem, v rámci pojistné částky.',
  },
  legalInsuranceDescription: {
    ua: ' спеціальна пропозиція Адвокатського об’єднання «Захист», яка полягає у наданні безкоштовних юридичних послуг Клієнту при настанні страхового випадку.',
    en: ' a special offer by the Law Firm "Protection" that involves providing free legal services to the Client in the event of an insurance case.',
    de: ' ein spezielles Angebot der Anwaltskanzlei „Schutz“, das die Bereitstellung kostenloser Rechtsdienstleistungen an den Kunden im Falle eines Versicherungsfalls umfasst.',
    ru: ' специальное предложение адвокатской компании «Защита», которое предусматривает предоставление бесплатных юридических услуг клиенту в случае страхового случая.',
    fr: " une offre spéciale de la société d'avocats «Protection», qui consiste à fournir des services juridiques gratuits au client en cas de sinistre assuré.",
    es: ' una oferta especial del bufete de abogados "Protección" que incluye la prestación de servicios jurídicos gratuitos al Cliente en caso de siniestro.',
    cs: ' speciální nabídka advokátní kanceláře „Ochrana“, která zahrnuje poskytování bezplatných právních služeb klientovi v případě pojistné události.',
  },

  // FillInfo.jsx
  branchInfo: {
    en: 'Branch Information',
    de: 'Filialinformationen',
    ua: 'Інформація про філію',
    ru: 'Информация о филиале',
    fr: "Informations sur l'agence",
    es: 'Información de la sucursal',
    cs: 'Informace o pobočce',
  },
  address: {
    en: 'Address:',
    de: 'Adresse:',
    ua: 'Адреса:',
    ru: 'Адрес:',
    fr: 'Adresse :',
    es: 'Dirección:',
    cs: 'Adresa:',
  },
  zone: {
    en: 'Zone:',
    de: 'Zone:',
    ua: 'Території:',
    ru: 'Зона:',
    fr: 'Zone :',
    es: 'Zona:',
    cs: 'Zóna:',
  },
  landmark: {
    ua: 'Орієнтир:',
    en: 'Landmark:',
    de: 'Wahrzeichen:',
    ru: 'Ориентир:',
    fr: 'Point de repère :',
    es: 'Punto de referencia:',
    cs: 'Orientace:',
  },
  // HotLineTimePicker.jsx
  clear: {
    en: 'Clear',
    de: 'Löschen',
    ua: 'Очистити',
    ru: 'Очистить',
    fr: 'Effacer',
    es: 'Borrar',
    cs: 'Vymazat',
  },

  // Modals - by folders

  // AddInsurance
  addInsuranceWarn: {
    ua: 'Додавання страховки',
    en: 'Adding insurance',
    de: 'Versicherung hinzufügen',
    ru: 'Добавление страховки',
    fr: "Ajout d'assurance",
    es: 'Agregar seguro',
    cs: 'Přidání pojištění',
  },
  addInsuranceWarn1: {
    ua: 'Потрібно обрати період',
    en: 'Period must be selected',
    de: 'Zeitraum muss ausgewählt werden',
    ru: 'Необходимо выбрать период',
    fr: 'La période doit être sélectionnée',
    es: 'Debe seleccionarse un período',
    cs: 'Musíte vybrat období',
  },
  addInsuranceWarn2: {
    ua: 'Потрібно обрати тип',
    en: 'Type must be selected',
    de: 'Typ muss ausgewählt werden',
    ru: 'Необходимо выбрать тип',
    fr: 'Le type doit être sélectionné',
    es: 'Debe seleccionarse un tipo',
    cs: 'Musíte vybrat typ',
  },
  addInsuranceWarnName: {
    ua: `Потрібно вказати ім'я`,
    en: 'Name is required',
    de: 'Name ist erforderlich',
    ru: 'Имя обязательно',
    fr: 'Le nom est requis',
    es: 'Se requiere el nombre',
    cs: 'Jméno je povinné',
  },
  addInsuranceSuccess: {
    ua: 'Страховка успішно додана!',
    en: 'Insurance successfully added!',
    de: 'Versicherung erfolgreich hinzugefügt!',
    ru: 'Страховка успешно добавлена!',
    fr: 'Assurance ajoutée avec succès !',
    es: 'Seguro agregado con éxito',
    cs: 'Pojištění úspěšně přidáno!',
  },
  addInsuranceError: {
    ua: 'Помилка оплати!',
    en: 'Payment error!',
    de: 'Zahlungsfehler!',
    ru: 'Ошибка оплаты!',
    fr: 'Erreur de paiement !',
    es: 'Error de pago',
    cs: 'Chyba platby!',
  },
  addInsuranceTitle: {
    ua: 'Замовити юридичну страховку',
    en: 'Order legal insurance',
    de: 'Rechtsschutzversicherung bestellen',
    ru: 'Заказать юридическую страховку',
    fr: 'Commander une assurance juridique',
    es: 'Solicitar seguro legal',
    cs: 'Objednat právní pojištění',
  },
  addInsuranceTypeTitle: {
    ua: 'Тип страховки',
    en: 'Insurance type',
    de: 'Versicherungstyp',
    ru: 'Тип страховки',
    fr: "Type d'assurance",
    es: 'Tipo de seguro',
    cs: 'Typ pojištění',
  },
  addInsuranceTypePlaceholder: {
    ua: 'Тип',
    en: 'Type',
    de: 'Typ',
    ru: 'Тип',
    fr: 'Type',
    es: 'Tipo',
    cs: 'Typ',
  },
  addInsurancePeriodTitle: {
    ua: 'Період',
    en: 'Period',
    de: 'Zeitraum',
    ru: 'Период',
    fr: 'Période',
    es: 'Período',
    cs: 'Období',
  },
  addInsurancePeriodPlaceholder: {
    ua: 'Термін',
    en: 'Term',
    de: 'Laufzeit',
    ru: 'Срок',
    fr: 'Durée',
    es: 'Plazo',
    cs: 'Doba',
  },
  addInsuranceText1: {
    ua: 'Збільшити страхову суму',
    en: 'Increase insurance coverage',
    de: 'Versicherungssumme erhöhen',
    ru: 'Увеличить страховую сумму',
    fr: "Augmenter la couverture d'assurance",
    es: 'Aumentar cobertura de seguro',
    cs: 'Zvýšit pojistné krytí',
  },
  addInsuranceText2: {
    ua: 'Вартість не може бути меньше мінімальної',
    en: 'Cost cannot be less than minimum',
    de: 'Kosten dürfen nicht weniger als das Minimum sein',
    ru: 'Стоимость не может быть меньше минимальной',
    fr: 'Le coût ne peut pas être inférieur au minimum',
    es: 'El costo no puede ser menor que el mínimo',
    cs: 'Cena nesmí být nižší než minimální',
  },
  addInsuranceCost: {
    ua: 'Вартість:',
    en: 'Cost:',
    de: 'Kosten:',
    ru: 'Стоимость:',
    fr: 'Coût :',
    es: 'Costo:',
    cs: 'Cena:',
  },
  addInsuranceProfit: {
    ua: 'Вигода:',
    en: 'Benefit:',
    de: 'Nutzen:',
    ru: 'Выгода:',
    fr: 'Avantage :',
    es: 'Beneficio:',
    cs: 'Výhoda:',
  },
  addClientNameForInsurance: {
    ua: `Ваше прізвище ім'я по батькові`,
    en: 'Insured name',
    de: 'Name des Versicherten',
    ru: 'Имя застрахованного',
    fr: "Nom de l'assuré",
    es: 'Nombre del asegurado',
    cs: 'Jméno pojištěného',
  },
  addClientNameForInsurancePlaceholder: {
    ua: `Введіть ім'я клієнта`,
    en: 'Enter the insured name',
    de: 'Geben Sie den Namen des Versicherten ein',
    ru: 'Введите имя застрахованного',
    fr: "Entrez le nom de l'assuré",
    es: 'Ingrese el nombre del asegurado',
    cs: 'Zadejte jméno pojištěného',
  },

  addTipsModalTitle: {
    ua: 'Залиште чайові',
    en: 'Leave a tip',
    de: 'Trinkgeld geben',
    ru: 'Оставить чаевые',
    fr: 'Laisser un pourboire',
    es: 'Dejar propina',
    cs: 'Nechat spropitné',
  },
  addTipsModalPlaceholder: {
    ua: 'Введіть суму чайових',
    en: 'Enter the tip amount',
    de: 'Geben Sie den Trinkgeldbetrag ein',
    ru: 'Введите сумму чаевых',
    fr: 'Entrez le montant du pourboire',
    es: 'Ingrese el monto de la propina',
    cs: 'Zadejte částku spropitného',
  },
  addTipsModalButton: {
    ua: 'Сплатити',
    en: 'Pay',
    de: 'Bezahlen',
    ru: 'Оплатить',
    fr: 'Payer',
    es: 'Pagar',
    cs: 'Zaplatit',
  },
  addTipsModalSuccess: {
    ua: 'Чайові відправлено',
    en: 'Tip sent',
    de: 'Trinkgeld gesendet',
    ru: 'Чаевые отправлены',
    fr: 'Pourboire envoyé',
    es: 'Propina enviada',
    cs: 'Spropitné odesláno',
  },
  autoPay: {
    ua: 'Автооплати',
    en: 'Auto Payments',
    de: 'Automatische Zahlungen',
    ru: 'Автоплатежи',
    fr: 'Paiements automatiques',
    es: 'Pagos automáticos',
    cs: 'Automatické platby',
  },
  autoPayGood_1: {
    ua: 'Автоматичні оплати увімкнені!',
    en: 'Automatic payments are enabled!',
    de: 'Automatische Zahlungen sind aktiviert!',
    ru: 'Автоматические платежи включены!',
    fr: 'Les paiements automatiques sont activés !',
    es: '¡Pagos automáticos habilitados!',
    cs: 'Automatické platby jsou povoleny!',
  },
  autoPayGood_2: {
    ua: 'Ви заощаджуєте:',
    en: 'You are saving:',
    de: 'Sie sparen:',
    ru: 'Вы экономите:',
    fr: 'Vous économisez :',
    es: 'Está ahorrando:',
    cs: 'Šetříte:',
  },
  autoPayBad_1_1: {
    ua: 'Увімкніть автоматичні оплати та отримайте',
    en: 'Enable automatic payments and receive',
    de: 'Aktivieren Sie automatische Zahlungen und erhalten Sie',
    ru: 'Включите автоматические платежи и получите',
    fr: 'Activez les paiements automatiques et recevez',
    es: 'Habilite los pagos automáticos y reciba',
    cs: 'Povolte automatické platby a získejte',
  },
  autoPayBad_1_2: {
    ua: 'кешбеку з кожного платежу.',
    en: 'cashback on each payment.',
    de: 'Cashback auf jede Zahlung.',
    ru: 'кешбэк с каждого платежа.',
    fr: 'du cashback sur chaque paiement.',
    es: 'reembolso en cada pago.',
    cs: 'cashback z každé platby.',
  },
  autoPayBad_2: {
    ua: 'Ви можете заощадити:',
    en: 'You can save:',
    de: 'Sie können sparen:',
    ru: 'Вы можете сэкономить:',
    fr: 'Vous pouvez économiser :',
    es: 'Puede ahorrar:',
    cs: 'Můžete ušetřit:',
  },
  autoPayModalSuccess: {
    ua: 'Зміни збережено',
    en: 'Changes saved',
    de: 'Änderungen gespeichert',
    ru: 'Изменения сохранены',
    fr: 'Modifications enregistrées',
    es: 'Cambios guardados',
    cs: 'Změny uloženy',
  },
  error: {
    ua: 'Помилка',
    en: 'Error',
    de: 'Fehler',
    ru: 'Ошибка',
    fr: 'Erreur',
    es: 'Error',
    cs: 'Chyba',
  },
  paymentSuccess: {
    ua: 'Оплата пройшла успішно',
    en: 'The payment was successful',
    de: 'Die Zahlung war erfolgreich',
    ru: 'Оплата прошла успешно',
    fr: 'Le paiement a été effectué avec succès',
    es: 'El pago fue exitoso',
    cs: 'Platba byla úspěšná',
  },
  paymentError: {
    ua: 'Помилка оплати',
    en: 'Payment error',
    de: 'Zahlungsfehler',
    ru: 'Ошибка оплаты',
    fr: 'Erreur de paiement',
    es: 'Error de pago',
    cs: 'Chyba platby',
  },
  paymentCheckoutUrlGenerated: {
    ua: 'Згенеровано посилання для оплати',
    en: 'A payment link has been generated',
    de: 'Es wurde ein Zahlungslink generiert',
    ru: 'Создана ссылка для оплаты',
    fr: 'Un lien de paiement a été généré',
    es: 'Se ha generado un enlace de pago',
    cs: 'Byl vygenerován odkaz na platbu',
  },
  autoPayModalTitle: {
    ua: 'Автоматична оплата послуг',
    en: 'Automatic service payment',
    de: 'Automatische Servicezahlung',
    ru: 'Автоматическая оплата услуг',
    fr: 'Paiement automatique des services',
    es: 'Pago automático de servicios',
    cs: 'Automatická platba za služby',
  },
  autoPayModalText1: {
    ua: 'Сумма щомісячної оплати:',
    en: 'Monthly payment amount:',
    de: 'Monatlicher Zahlungsbetrag:',
    ru: 'Сумма ежемесячной оплаты:',
    fr: 'Montant du paiement mensuel :',
    es: 'Monto de pago mensual:',
    cs: 'Výše měsíční platby:',
  },
  autoPayModalText2: {
    ua: 'Потрібно звернутись до свого юриста для отримання можливості автоматичної оплати.',
    en: 'You need to contact your lawyer to enable automatic payment.',
    de: 'Sie müssen sich an Ihren Anwalt wenden, um die automatische Zahlung zu aktivieren.',
    ru: 'Необходимо связаться с вашим юристом для включения автоплатежа.',
    fr: 'Vous devez contacter votre avocat pour activer le paiement automatique.',
    es: 'Debe contactar a su abogado para habilitar el pago automático.',
    cs: 'Musíte kontaktovat svého právníka pro povolení automatických plateb.',
  },
  autoPayModalText3: {
    ua: 'Якщо у вас виникли питання стосовно суми зверніться до свого куратора.',
    en: 'If you have any questions about the amount, please contact your curator.',
    de: 'Wenn Sie Fragen zum Betrag haben, wenden Sie sich bitte an Ihren Kurator.',
    ru: 'Если у вас есть вопросы по сумме, обратитесь к вашему куратору.',
    fr: 'Si vous avez des questions sur le montant, contactez votre responsable.',
    es: 'Si tiene preguntas sobre el monto, comuníquese con su encargado.',
    cs: 'Pokud máte otázky ohledně částky, kontaktujte svého kurátora.',
  },
  autoPayModalText4: {
    ua: 'Дозволити щомісячну оплату:',
    en: 'Enable monthly payment:',
    de: 'Monatliche Zahlung aktivieren:',
    ru: 'Разрешить ежемесячную оплату:',
    fr: 'Activer le paiement mensuel :',
    es: 'Habilitar pago mensual:',
    cs: 'Povolit měsíční platbu:',
  },
  autoPayModalText5: {
    ua: 'Для автоматичної оплати послуг необхідно прив`язати карту:',
    en: 'To enable automatic payment for services, you need to link a card:',
    de: 'Um die automatische Zahlung für Dienstleistungen zu aktivieren, müssen Sie eine Karte verknüpfen:',
    ru: 'Для автоматической оплаты услуг необходимо привязать карту:',
    fr: 'Pour activer le paiement automatique des services, vous devez lier une carte :',
    es: 'Para habilitar el pago automático de servicios, necesita vincular una tarjeta:',
    cs: 'Pro povolení automatických plateb za služby je nutné propojit kartu:',
  },
  autoPayModalText6: {
    ua: 'Буде проведено оплату на 1грн.',
    en: 'A payment of 1 UAH will be made.',
    de: 'Eine Zahlung von 1 UAH wird erfolgen.',
    ru: 'Будет произведен платеж на 1 грн.',
    fr: 'Un paiement de 1 UAH sera effectué.',
    es: 'Se realizará un pago de 1 UAH.',
    cs: 'Bude provedena platba 1 UAH.',
  },
  autoPayModalText7: {
    ua: 'Кошти повернуться назад.',
    en: 'The funds will be returned.',
    de: 'Das Geld wird zurückgegeben.',
    ru: 'Средства будут возвращены.',
    fr: 'Les fonds seront remboursés.',
    es: 'Los fondos serán devueltos.',
    cs: 'Prostředky budou vráceny.',
  },
  autoPayModalText8: {
    ua: 'При кожній автоматичній оплаті на Ваш баланс буде зараховано ',
    en: 'With each automatic payment, a cashback of ',
    de: 'Bei jeder automatischen Zahlung erhalten Sie einen Cashback von ',
    ru: 'С каждым автоматическим платежом на ваш баланс будет зачислен кэшбэк ',
    fr: 'À chaque paiement automatique, un cashback de ',
    es: 'Con cada pago automático, se le otorgará un reembolso de ',
    cs: 'S každou automatickou platbou obdržíte cashback ',
  },
  autoPayModalText8_1: {
    ua: 'кешбек у розмірі',
    en: 'cashback in the amount of',
    de: 'Cashback in Höhe von',
    ru: 'в размере',
    fr: 'de',
    es: 'en el monto de',
    cs: 'v částce',
  },
  autoPayModalText8_2: {
    ua: ' від суми списання.',
    en: ' from the deduction amount.',
    de: ' vom Abzugsbetrag.',
    ru: ' от суммы списания.',
    fr: ' du montant de la déduction.',
    es: ' del monto deducido.',
    cs: ' z odečtené částky.',
  },
  autoPayModalText9: {
    ua: 'Для зміни суми регулярного платежу зверніться до свого юриста.',
    en: 'To change the amount of the regular payment, contact your lawyer.',
    de: 'Um die Höhe der regelmäßigen Zahlung zu ändern, wenden Sie sich an Ihren Anwalt.',
    ru: 'Для изменения суммы регулярного платежа обратитесь к вашему юристу.',
    fr: 'Pour modifier le montant du paiement régulier, contactez votre avocat.',
    es: 'Para cambiar el monto del pago regular, comuníquese con su abogado.',
    cs: 'Pro změnu výše pravidelné platby kontaktujte svého právníka.',
  },
  autoPayModalText10_1: {
    ua: 'Увімкнути автооплату для всіх контрактів:',
    en: 'Enable autopay for all contracts:',
    de: 'Autopay für alle Verträge aktivieren:',
    ru: 'Включить автоплатеж для всех контрактов:',
    fr: 'Activer le paiement automatique pour tous les contrats :',
    es: 'Habilitar autopago para todos los contratos:',
    cs: 'Povolit automatické platby pro všechny smlouvy:',
  },
  autoPayModalText10_2: {
    ua: 'Ви можете додатково заощаджувати',
    en: 'You can additionally save',
    de: 'Sie können zusätzlich sparen',
    ru: 'Вы можете дополнительно экономить',
    fr: 'Vous pouvez économiser en plus',
    es: 'Además, puede ahorrar',
    cs: 'Můžete navíc ušetřit',
  },
  autoPayModalText10_3: {
    ua: 'кожного місяця',
    en: 'every month',
    de: 'jeden Monat',
    ru: 'каждый месяц',
    fr: 'chaque mois',
    es: 'cada mes',
    cs: 'každý měsíc',
  },
  autoPayModalText10_4: {
    ua: 'Автооплати для всіх контрактів увімкнено!',
    en: 'Autopay for all contracts is enabled!',
    de: 'Autopay für alle Verträge ist aktiviert!',
    ru: 'Автоплатеж для всех контрактов включен!',
    fr: 'Le paiement automatique pour tous les contrats est activé !',
    es: '¡Autopago para todos los contratos habilitado!',
    cs: 'Automatické platby pro všechny smlouvy jsou povoleny!',
  },
  autoPayModalText10_5: {
    ua: 'Ви заощаджуєте',
    en: 'You save',
    de: 'Sie sparen',
    ru: 'Вы экономите',
    fr: 'Vous économisez',
    es: 'Usted ahorra',
    cs: 'Šetříte',
  },
  chatUnverifiedPhoneOverlay: {
    ua: 'Будь ласка, підтвердьте свій номер телефону для безпеки та повного доступу до наших послуг. Це займе лише кілька хвилин. Дякуємо за розуміння.',
    en: 'Please verify your phone number to ensure security and full access to our services. It will only take a few minutes. Thank you for your understanding.',
    de: 'Bitte bestätigen Sie Ihre Telefonnummer, um Sicherheit und vollen Zugang zu unseren Diensten zu gewährleisten. Dies dauert nur wenige Minuten. Vielen Dank für Ihr Verständnis.',
    ru: 'Пожалуйста, подтвердите свой номер телефона для безопасности и полного доступа к нашим услугам. Это займет всего несколько минут. Спасибо за понимание.',
    fr: "Veuillez vérifier votre numéro de téléphone pour garantir la sécurité et l'accès complet à nos services. Cela ne prendra que quelques minutes. Merci de votre compréhension.",
    es: 'Por favor, verifique su número de teléfono para garantizar la seguridad y el acceso completo a nuestros servicios. Solo tomará unos minutos. Gracias por su comprensión.',
    cs: 'Potvrďte prosím své telefonní číslo pro zajištění bezpečnosti a plný přístup k našim službám. Zabere to jen pár minut. Děkujeme za pochopení.',
  },
  chatForContractError1: {
    ua: 'Пусте повідомлення',
    en: 'Empty message',
    de: 'Leere Nachricht',
    ru: 'Пустое сообщение',
    fr: 'Message vide',
    es: 'Mensaje vacío',
    cs: 'Prázdná zpráva',
  },
  chatForContractErrorCallback: {
    ua: 'Не вказана функція callback',
    en: 'Callback function not specified',
    de: 'Callback-Funktion nicht angegeben',
    ru: 'Не указана функция callback',
    fr: 'Fonction de rappel non spécifiée',
    es: 'Función de devolución no especificada',
    cs: 'Funkce zpětného volání není specifikována',
  },
  chatForContractError2: {
    ua: 'Відсутні дані для відправки',
    en: 'No data to send',
    de: 'Keine Daten zum Senden',
    ru: 'Нет данных для отправки',
    fr: 'Pas de données à envoyer',
    es: 'No hay datos para enviar',
    cs: 'Žádná data k odeslání',
  },
  chatForContractError3: {
    ua: 'Відсутній ID файлу',
    en: 'File ID is missing',
    de: 'Datei-ID fehlt',
    ru: 'Отсутствует ID файла',
    fr: 'ID de fichier manquant',
    es: 'Falta el ID de archivo',
    cs: 'Chybí ID souboru',
  },
  chatForContractError4: {
    ua: 'Без імені',
    en: 'Nameless',
    de: 'Namenslos',
    ru: 'Без имени',
    fr: 'Sans nom',
    es: 'Sin nombre',
    cs: 'Bez jména',
  },
  chatForContractError5: {
    ua: 'Відсутнє ID договору',
    en: 'Contract ID is missing',
    de: 'Vertrags-ID fehlt',
    ru: 'Отсутствует ID договора',
    fr: 'ID du contrat manquant',
    es: 'Falta el ID del contrato',
    cs: 'Chybí ID smlouvy',
  },
  chatForTenderTitle: {
    ua: 'Чат по тендеру',
    en: 'Tender Chat',
    de: 'Ausschreibungschat',
    ru: 'Чат по тендеру',
    fr: "Chat d'appel d'offres",
    es: 'Chat de licitación',
    cs: 'Chat k zakázce',
  },
  chatForDozhimsAITitle: {
    ua: 'Чат з техпідтримкою',
    en: 'Tech support chat',
    de: 'Technischer Support Chat',
    ru: 'Чат с техподдержкой',
    fr: 'Chat de support technique',
    es: 'Chat de soporte técnico',
    cs: 'Chat s technickou podporou',
  },
  chatAccountant: {
    ua: 'Чат з бухгалтером',
    en: 'Chat with an accountant',
    de: 'Chat mit einem Buchhalter',
    ru: 'Чат с бухгалтером',
    fr: 'Chat avec un comptable',
    es: 'Chat con un contador',
    cs: 'Chat s účetním',
  },
  edit: {
    ua: 'Редагувати',
    en: 'Edit',
    de: 'Bearbeiten',
    ru: 'Редактировать',
    fr: 'Éditer',
    es: 'Editar',
    cs: 'Upravit',
  },
  personalConsultantChatTitle: {
    ua: 'Особистий консультант',
    en: 'Personal consultant',
    de: 'Persönlicher Berater',
    ru: 'Личный консультант',
    fr: 'Consultant personnel',
    es: 'Consultor personal',
    cs: 'Osobní konzultant',
  },
  confirmEmailAnonimTitle: {
    ua: 'Підтвердження пошти',
    en: 'Email Confirmation',
    de: 'E-Mail-Bestätigung',
    ru: 'Подтверждение почты',
    fr: "Confirmation de l'e-mail",
    es: 'Confirmación de correo electrónico',
    cs: 'Potvrzení e-mailu',
  },
  contractTasksTitle1: {
    ua: 'Назва завдання',
    en: 'Task Title',
    de: 'Aufgabentitel',
    ru: 'Название задачи',
    fr: 'Titre de la tâche',
    es: 'Título de la tarea',
    cs: 'Název úkolu',
  },
  contractTasksTitle2: {
    ua: 'Завдання по договору',
    en: 'Contract Tasks',
    de: 'Vertragsaufgaben',
    ru: 'Задачи по договору',
    fr: 'Tâches du contrat',
    es: 'Tareas del contrato',
    cs: 'Úkoly podle smlouvy',
  },
  contractTasksText1: {
    ua: 'Відхилено',
    en: 'Rejected',
    de: 'Abgelehnt',
    ru: 'Отклонено',
    fr: 'Rejeté',
    es: 'Rechazado',
    cs: 'Odmítnuto',
  },
  contractTasksText2: {
    ua: 'Дедлайн:',
    en: 'Deadline:',
    de: 'Frist:',
    ru: 'Срок:',
    fr: 'Date limite :',
    es: 'Fecha límite:',
    cs: 'Uzávěrka:',
  },
  contractTasksText3: {
    ua: 'Додати завдання',
    en: 'Add Task',
    de: 'Aufgabe hinzufügen',
    ru: 'Добавить задачу',
    fr: 'Ajouter une tâche',
    es: 'Agregar tarea',
    cs: 'Přidat úkol',
  },
  contractTasksTextSaveTask: {
    ua: 'Зберегти завдання',
    en: 'Save Task',
    de: 'Aufgabe speichern',
    ru: 'Сохранить задачу',
    fr: 'Enregistrer la tâche',
    es: 'Guardar tarea',
    cs: 'Uložit úkol',
  },
  contractTasksTextFilter: {
    ua: 'Фільтрувати по даті',
    en: 'Filter by date',
    de: 'Nach Datum filtern',
    ru: 'Фильтр по дате',
    fr: 'Filtrer par date',
    es: 'Filtrar por fecha',
    cs: 'Filtrovat podle data',
  },
  contractTasksInputPlaceholder: {
    ua: 'Опишіть завдання...',
    en: 'Describe the task...',
    de: 'Beschreiben Sie die Aufgabe...',
    ru: 'Опишите задачу...',
    fr: 'Décrivez la tâche...',
    es: 'Describa la tarea...',
    cs: 'Popište úkol...',
  },
  contractTasksText4: {
    ua: 'Виконано',
    en: 'Done',
    de: 'Erledigt',
    ru: 'Выполнено',
    fr: 'Fait',
    es: 'Hecho',
    cs: 'Hotovo',
  },
  taskDoneConfirmText: {
    ua: 'Бажаєте відмітити задачу як виконану?',
    en: 'Do you want to mark the task as completed?',
    de: 'Möchten Sie die Aufgabe als erledigt markieren?',
    ru: 'Хотите отметить задачу как выполненную?',
    fr: 'Voulez-vous marquer la tâche comme terminée ?',
    es: '¿Quiere marcar la tarea como completada?',
    cs: 'Chcete označit úkol jako dokončený?',
  },
  taskRejectConfirmText: {
    ua: 'Бажаєте скасувати задачу?',
    en: 'Do you want to cancel the task?',
    de: 'Möchten Sie die Aufgabe stornieren?',
    ru: 'Хотите отменить задачу?',
    fr: 'Voulez-vous annuler la tâche ?',
    es: '¿Quiere cancelar la tarea?',
    cs: 'Chcete úkol zrušit?',
  },
  firstLoginAutoPayText1: {
    ua: 'Бажаєте підключити автоматичну оплату по договору',
    en: 'Would you like to enable automatic payment for the contract',
    de: 'Möchten Sie die automatische Zahlung für den Vertrag aktivieren',
    ru: 'Хотите включить автоматическую оплату по договору',
    fr: 'Souhaitez-vous activer le paiement automatique pour le contrat',
    es: '¿Le gustaría habilitar el pago automático para el contrato?',
    cs: 'Chcete povolit automatickou platbu za smlouvu',
  },
  firstLoginAutoPayText2: {
    ua: 'від',
    en: 'from',
    de: 'von',
    ru: 'от',
    fr: 'de',
    es: 'de',
    cs: 'od',
  },
  leaveSuggestionModalWarn: {
    ua: 'Ваша пропозиція має бути довжиною не менше 20 символів',
    en: 'Your suggestion must be at least 20 characters long',
    de: 'Ihr Vorschlag muss mindestens 20 Zeichen lang sein',
    ru: 'Ваше предложение должно быть не менее 20 символов',
    fr: 'Votre suggestion doit comporter au moins 20 caractères',
    es: 'Su sugerencia debe tener al menos 20 caracteres',
    cs: 'Váš návrh musí mít alespoň 20 znaků',
  },
  leaveSuggestionModalSuccess: {
    ua: 'Ваша пропозиція відправлена',
    en: 'Your suggestion has been sent',
    de: 'Ihr Vorschlag wurde gesendet',
    ru: 'Ваше предложение отправлено',
    fr: 'Votre suggestion a été envoyée',
    es: 'Su sugerencia ha sido enviada',
    cs: 'Váš návrh byl odeslán',
  },
  leaveSuggestionModalError: {
    ua: 'Помилка, Спробуйте ще раз',
    en: 'Error, Please try again',
    de: 'Fehler, Bitte versuchen Sie es erneut',
    ru: 'Ошибка, попробуйте еще раз',
    fr: 'Erreur, veuillez réessayer',
    es: 'Error, intente de nuevo',
    cs: 'Chyba, zkuste to prosím znovu',
  },
  leaveSuggestionModalTitle: {
    ua: 'Залиште Ваші пропозиції',
    en: 'Leave Your Suggestions',
    de: 'Hinterlassen Sie Ihre Vorschläge',
    ru: 'Оставьте свои предложения',
    fr: 'Laissez vos suggestions',
    es: 'Deje sus sugerencias',
    cs: 'Nechte své návrhy',
  },
  leaveSuggestionModalText1: {
    ua: 'Якщо у Вас є скарги або пропозиції для вдосконалення роботи додатку єАдвокат - напишіть їх сюди, та',
    en: 'If you have any complaints or suggestions for improving the work of the app eAdvokat - write them here, and',
    de: 'Wenn Sie Beschwerden oder Vorschläge zur Verbesserung der App eAdvokat haben - schreiben Sie sie hier hin, und',
    ru: 'Если у вас есть жалобы или предложения по улучшению работы приложения еАдвокат - напишите их здесь, и',
    fr: "Si vous avez des réclamations ou suggestions pour améliorer l'application eAdvokat - écrivez-les ici, et",
    es: 'Si tiene quejas o sugerencias para mejorar la aplicación eAdvokat - escríbalas aquí, y',
    cs: 'Pokud máte stížnosti nebo návrhy na zlepšení aplikace eAdvokat - napište je sem, a',
  },
  leaveSuggestionModalText2: {
    ua: 'отримайте бонусну транзакцію у сумі 50грн.',
    en: 'receive a bonus transaction of 50 UAH.',
    de: 'erhalten Sie eine Bonus-Transaktion von 50 UAH.',
    ru: 'получите бонусную транзакцию в размере 50 грн.',
    fr: 'recevez une transaction bonus de 50 UAH.',
    es: 'reciba una transacción de bonificación de 50 UAH.',
    cs: 'obdržíte bonusovou transakci ve výši 50 UAH.',
  },
  notNowBtn: {
    ua: 'Не зараз',
    en: 'Not now',
    de: 'Nicht jetzt',
    ru: 'Не сейчас',
    fr: 'Pas maintenant',
    es: 'No ahora',
    cs: 'Teď ne',
  },
  // FunctionsNotUsedModal
  functionsNotUsedModalTitle: {
    ua: 'Яким функціоналом Ви не користувалися?',
    en: 'Which functionality have you not used?',
    de: 'Welche Funktionen haben Sie nicht genutzt?',
    ru: 'Каким функционалом вы не пользовались?',
    fr: "Quelles fonctionnalités n'avez-vous pas utilisées ?",
    es: '¿Qué funcionalidad no has utilizado?',
    cs: 'Kterou funkci jste nepoužili?',
  },
  functionsNotUsedModalText: {
    ua: 'Позначте функціонал, яким Ви не користувалися, та',
    en: `Mark the functionality you haven't used, and`,
    de: 'Markieren Sie den Funktionsumfang, den Sie nicht genutzt haben, und',
    ru: 'Отметьте функционал, которым вы не пользовались, и',
    fr: "Marquez les fonctionnalités que vous n'avez pas utilisées, et",
    es: 'Marca la funcionalidad que no has usado, y',
    cs: 'Označte funkce, které jste nepoužili, a',
  },
  functionsNotUsedModalSuccess: {
    ua: 'Ваша відповідь відправлена',
    en: 'Your response has been sent',
    de: 'Ihre Antwort wurde gesendet',
    ru: 'Ваш ответ отправлен',
    fr: 'Votre réponse a été envoyée',
    es: 'Tu respuesta ha sido enviada',
    cs: 'Vaše odpověď byla odeslána',
  },

  functionsNotUsedContent: {
    consultation: {
      ua: 'Запис на консультацію',
      en: 'Consultation booking',
      ru: 'Запись на консультацию',
      de: 'Terminvereinbarung',
      fr: 'Prise de rendez-vous',
      es: 'Reserva de consulta',
      cs: 'Rezervace konzultace',
    },
    myConsultations: {
      ua: 'Мої консультації',
      en: 'My consultations',
      ru: 'Мои консультации',
      de: 'Meine Beratungen',
      fr: 'Mes consultations',
      es: 'Mis consultas',
      cs: 'Moje konzultace',
    },
    chats: {
      ua: 'Чати',
      en: 'Chats',
      ru: 'Чаты',
      de: 'Chats',
      fr: 'Chats',
      es: 'Chats',
      cs: 'Chaty',
    },
    quickQuestion: {
      ua: 'Швидке питання',
      en: 'Quick questions',
      ru: 'Быстрые вопросы',
      de: 'Schnelle Fragen',
      fr: 'Questions rapides',
      es: 'Preguntas rápidas',
      cs: 'Rychlé otázky',
    },
    myCases: {
      ua: 'Мої справи',
      en: 'My cases',
      ru: 'Мои дела',
      de: 'Meine Fälle',
      fr: 'Mes affaires',
      es: 'Mis casos',
      cs: 'Moje případy',
    },
    tenders: {
      ua: 'Тендери',
      en: 'Tenders',
      ru: 'Тендеры',
      de: 'Ausschreibungen',
      fr: "Appels d'offres",
      es: 'Licitaciones',
      cs: 'Výběrová řízení',
    },
    insuranceCard: {
      ua: 'Юридична страховка',
      en: 'Legal insurance',
      ru: 'Юридическая страховка',
      de: 'Rechtsschutzversicherung',
      fr: 'Assurance juridique',
      es: 'Seguro jurídico',
      cs: 'Právní pojištění',
    },
    balance: {
      ua: 'Баланс',
      en: 'Balance',
      ru: 'Баланс',
      de: 'Guthaben',
      fr: 'Solde',
      es: 'Saldo',
      cs: 'Zůstatek',
    },
    cashback: {
      ua: '5% кешбек',
      en: '5% cashback',
      ru: '5% кешбек',
      de: '5% Cashback',
      fr: '5% cashback',
      es: '5% de reembolso',
      cs: '5% cashback',
    },
    partnerCard: {
      ua: 'Партнерська карта',
      en: 'Partner card',
      ru: 'Партнерская карта',
      de: 'Partnerkarte',
      fr: 'Carte partenaire',
      es: 'Tarjeta de socio',
      cs: 'Partnerská karta',
    },
    clubCard: {
      ua: 'Клубна карта',
      en: 'Club card',
      ru: 'Клубная карта',
      de: 'Clubkarte',
      fr: 'Carte de club',
      es: 'Tarjeta de club',
      cs: 'Klubová karta',
    },
    reviews: {
      ua: 'Відгуки',
      en: 'Reviews',
      ru: 'Отзывы',
      de: 'Bewertungen',
      fr: 'Avis',
      es: 'Opiniones',
      cs: 'Recenze',
    },
    topUpBalance: {
      ua: 'Поповнити баланс',
      en: 'Top up balance',
      ru: 'Пополнить баланс',
      de: 'Guthaben aufladen',
      fr: 'Recharger le solde',
      es: 'Recargar saldo',
      cs: 'Dobít zůstatek',
    },
    scanQRCode: {
      ua: 'Сканувати QR-код',
      en: 'Scan QR code',
      ru: 'Сканировать QR-код',
      de: 'QR-Code scannen',
      fr: 'Scanner le QR code',
      es: 'Escanear código QR',
      cs: 'Skenovat QR kód',
    },
  },

  // Notification
  notificationTitle: {
    ua: 'Прив`язку пошти завершено!',
    en: 'Email Confirmation Completed!',
    de: 'E-Mail-Bestätigung abgeschlossen!',
    ru: 'Подтверждение электронной почты завершено!',
    fr: "Confirmation de l'email terminée!",
    es: '¡Confirmación de correo completada!',
    cs: 'Potvrzení e-mailu dokončeno!',
  },
  notificationText: {
    ua: 'До вашого облікового запису успішно прив`язано пошту',
    en: 'Your email has been successfully linked to your account',
    de: 'Ihre E-Mail-Adresse wurde erfolgreich mit Ihrem Konto verknüpft',
    ru: 'Ваш email успешно привязан к аккаунту',
    fr: 'Votre email a été lié avec succès à votre compte',
    es: 'Tu correo ha sido vinculado exitosamente a tu cuenta',
    cs: 'Váš e-mail byl úspěšně připojen k vašemu účtu',
  },

  // PaymentFrame
  paymentFrameType1: {
    ua: 'Оплата за записом',
    en: 'Appointment Payment',
    de: 'Terminzahlung',
    ru: 'Оплата за запись',
    fr: 'Paiement pour le rendez-vous',
    es: 'Pago de cita',
    cs: 'Platba za schůzku',
  },
  paymentFrameType2: {
    ua: 'Оплата за записом',
    en: 'Appointment Payment',
    de: 'Terminzahlung',
    ru: 'Оплата за запись',
    fr: 'Paiement pour le rendez-vous',
    es: 'Pago de cita',
    cs: 'Platba za schůzku',
  },
  paymentFrameType3: {
    ua: 'Поповнення балансу',
    en: 'Balance Top-up',
    de: 'Kontostand aufladen',
    ru: 'Пополнение баланса',
    fr: 'Recharge de solde',
    es: 'Recarga de saldo',
    cs: 'Doplnění zůstatku',
  },
  paymentFrameType4: {
    ua: 'Оплата за договором',
    en: 'Contract Payment',
    de: 'Vertragszahlung',
    ru: 'Оплата по договору',
    fr: 'Paiement pour le contrat',
    es: 'Pago de contrato',
    cs: 'Platba za smlouvu',
  },
  paymentFrameType5: {
    ua: 'Оплата за тривожну кнопку',
    en: 'Panic Button Payment',
    de: 'Panikknopfzahlung',
    ru: 'Оплата тревожной кнопки',
    fr: 'Paiement pour le bouton de panique',
    es: 'Pago del botón de pánico',
    cs: 'Platba za tlačítko paniky',
  },
  paymentFrameType6: {
    ua: 'Оплата за питання',
    en: 'Question Payment',
    de: 'Fragezahlung',
    ru: 'Оплата за вопрос',
    fr: 'Paiement pour la question',
    es: 'Pago de pregunta',
    cs: 'Platba za otázku',
  },
  paymentFrameType7: {
    ua: 'Подяка юристу',
    en: 'Lawyer Gratuity',
    de: 'Anwalt Trinkgeld',
    ru: 'Благодарность юристу',
    fr: "Gratification pour l'avocat",
    es: 'Gratitud al abogado',
    cs: 'Odměna právníkovi',
  },
  // Questionnaires
  questionnaireSuccess: {
    ua: 'Дякуюємо за відповідь !',
    en: 'Thank you for your response !',
    de: 'Danke für Ihre Antwort !',
    ru: 'Спасибо за ваш ответ!',
    fr: 'Merci pour votre réponse !',
    es: '¡Gracias por tu respuesta!',
    cs: 'Děkujeme za vaši odpověď!',
  },
  questionnaireError: {
    ua: 'Помилка збереження відповіді !',
    en: 'Error saving response !',
    de: 'Fehler beim Speichern der Antwort !',
    ru: 'Ошибка при сохранении ответа!',
    fr: 'Erreur de sauvegarde de la réponse !',
    es: 'Error al guardar la respuesta !',
    cs: 'Chyba při ukládání odpovědi!',
  },
  // QuestionnaireArchive
  questionnaireArchiveWarn1: {
    ua: 'Несанкціонований доступ. Система вас не упізнає.',
    en: 'Unauthorized access. The system does not recognize you.',
    de: 'Nicht autorisierter Zugriff. Das System erkennt Sie nicht.',
    ru: 'Несанкционированный доступ. Система вас не узнает.',
    fr: 'Accès non autorisé. Le système ne vous reconnaît pas.',
    es: 'Acceso no autorizado. El sistema no te reconoce.',
    cs: 'Neoprávněný přístup. Systém vás nepoznává.',
  },
  questionnaireArchiveWarn2: {
    ua: 'Для анкетування потрібно вказати оцінку якості.',
    en: 'To fill out the questionnaire, you need to provide a quality rating.',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine Qualitätsbewertung angeben.',
    ru: 'Для заполнения анкеты необходимо указать оценку качества.',
    fr: 'Pour remplir le questionnaire, vous devez fournir une note de qualité.',
    es: 'Para completar el cuestionario, debe proporcionar una calificación de calidad.',
    cs: 'Pro vyplnění dotazníku musíte uvést hodnocení kvality.',
  },
  questionnaireArchiveWarn3: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Задоволенні роботою адвоката?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Satisfaction with the lawyer\'s work?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Zufriedenheit mit der Arbeit des Anwalts?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Удовлетворены работой адвоката?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Satisfaction avec le travail de l\'avocat ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Satisfacción con el trabajo del abogado?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Spokojenost s prací právníka?"',
  },
  questionnaireArchiveWarn4: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Задоволенні комунікацією з нашим адвокатом?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Satisfaction with communication with our lawyer?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Zufriedenheit mit der Kommunikation mit unserem Anwalt?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Удовлетворены ли вы общением с нашим адвокатом?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Satisfaction de la communication avec notre avocat ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Satisfacción con la comunicación con nuestro abogado?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Spokojenost s komunikací s naším právníkem?"',
  },
  questionnaireArchiveWarn5: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Звернулися б ще до нашої компанії?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Would you turn to our company again?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Würden Sie sich wieder an unser Unternehmen wenden?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Обратились бы снова в нашу компанию?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Reviendriez-vous vers notre entreprise ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Volverías a nuestra empresa?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Obrátili byste se znovu na naši společnost?"',
  },
  questionnaireArchiveWarn6: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Порадили комусь нашу компанію?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Have you recommended our company to anyone?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Haben Sie unser Unternehmen jemandem empfohlen?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Рекомендовали ли вы нашу компанию кому-нибудь?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Avez-vous recommandé notre entreprise à quelqu\'un ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Ha recomendado nuestra empresa a alguien?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Doporučili jste naši společnost někomu?"',
  },
  questionnaireArchiveWarn7: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Довіряєте нашій компанії?"',
    en: 'To fill out the questionnaire, you need to choose one of the options for the question: "Do you trust our company?"',
    de: 'Um den Fragebogen auszufüllen, müssen Sie eine der Optionen für die Frage angeben: "Vertrauen Sie unserem Unternehmen?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Доверяете ли вы нашей компании?"',
    fr: 'Pour remplir le questionnaire, vous devez choisir une des options pour la question : "Faites-vous confiance à notre entreprise ?"',
    es: 'Para completar el cuestionario, debe elegir una de las opciones para la pregunta: "¿Confía en nuestra empresa?"',
    cs: 'Pro vyplnění dotazníku musíte vybrat jednu z možností k otázce: "Důvěřujete naší společnosti?"',
  },
  questionnaireArchiveWarn8: {
    ua: 'Для анкетування потрібно дати свою пропозицію по покращенню або скаргу.',
    en: 'To participate in the survey, you need to provide your suggestion for improvement or complaint.',
    de: 'Um an der Umfrage teilzunehmen, müssen Sie Ihren Verbesserungsvorschlag oder Ihre Beschwerde abgeben.',
    ru: 'Для участия в опросе необходимо предоставить предложение по улучшению или жалобу.',
    fr: "Pour participer au sondage, vous devez fournir une suggestion d'amélioration ou une plainte.",
    es: 'Para participar en la encuesta, debe proporcionar una sugerencia de mejora o una queja.',
    cs: 'Pro účast v průzkumu musíte poskytnout návrh na zlepšení nebo stížnost.',
  },
  questionnaireArchiveWarn9: {
    ua: 'Для анкетування потрібно написати текст пропозиції довше за 20 символів.',
    en: 'To participate in the survey, you need to write a suggestion text longer than 20 characters.',
    de: 'Um an der Umfrage teilzunehmen, müssen Sie einen Vorschlagstext schreiben, der länger als 20 Zeichen ist.',
    ru: 'Для участия в опросе необходимо написать предложение длиннее 20 символов.',
    fr: 'Pour participer au sondage, vous devez écrire un texte de suggestion de plus de 20 caractères.',
    es: 'Para participar en la encuesta, debe escribir un texto de sugerencia de más de 20 caracteres.',
    cs: 'Pro účast v průzkumu musíte napsat návrh delší než 20 znaků.',
  },
  questionnaireArchiveInfo: {
    ua: 'Дякуємо за приділений час. Ваш бонусний рахунок поповнено на 500 грн.',
    en: 'Thank you for your time. Your bonus account has been credited with 500 UAH.',
    de: 'Vielen Dank für Ihre Zeit. Ihr Bonuskonto wurde mit 500 UAH aufgeladen.',
    ru: 'Спасибо за уделенное время. Ваш бонусный счет пополнен на 500 грн.',
    fr: 'Merci pour votre temps. Votre compte bonus a été crédité de 500 UAH.',
    es: 'Gracias por su tiempo. Su cuenta de bonificación ha sido acreditada con 500 UAH.',
    cs: 'Děkujeme za váš čas. Váš bonusový účet byl navýšen o 500 UAH.',
  },
  questionnaireArchiveText: {
    ua: '1 - найнижчий показник, 10 - найвищий показник',
    en: '1 - lowest score, 10 - highest score',
    de: '1 - niedrigste Punktzahl, 10 - höchste Punktzahl',
    ru: '1 - самый низкий балл, 10 - самый высокий балл',
    fr: '1 - score le plus bas, 10 - score le plus élevé',
    es: '1 - puntuación más baja, 10 - puntuación más alta',
    cs: '1 - nejnižší skóre, 10 - nejvyšší skóre',
  },
  // QuestionnaireArchive
  questionnaireArchivePlaceholder: {
    ua: 'Оберіть оцінку:',
    en: 'Choose rating:',
    de: 'Bewertung wählen:',
    ru: 'Выберите оценку:',
    fr: 'Choisissez une note :',
    es: 'Elija una calificación:',
    cs: 'Vyberte hodnocení:',
  },
  placeholderChooseOption: {
    ua: 'Оберіть один із варіантів:',
    en: 'Choose one of the options:',
    de: 'Wählen Sie eine der Optionen:',
    ru: 'Выберите один из вариантов:',
    fr: 'Choisissez une des options :',
    es: 'Elija una de las opciones:',
    cs: 'Vyberte jednu z možností:',
  },
  questionnaireArchiveAdvertisingInfo: {
    ua: 'Ви можете переглянути цю інформацію знову в кабінеті партнера.',
    en: 'You can review this information again in the partner’s account.',
    de: 'Sie können diese Informationen erneut im Partnerkonto einsehen.',
    ru: 'Вы можете снова просмотреть эту информацию в кабинете партнера.',
    fr: 'Vous pouvez revoir ces informations dans le compte partenaire.',
    es: 'Puede revisar esta información nuevamente en la cuenta del socio.',
    cs: 'Tyto informace si můžete znovu prohlédnout v partnerském účtu.',
  },
  questionnaireArchiveAdvertisingFeedbackText1_1: {
    ua: 'Залиште відгук, та отримайте',
    ru: 'Оставьте отзыв и получите',
    de: 'Hinterlassen Sie ein Feedback und erhalten Sie',
    en: 'Leave a review and get',
    fr: 'Laissez un avis et obtenez',
    es: 'Deja un comentario y recibe',
    cs: 'Zanechte recenzi a získejte',
  },
  questionnaireArchiveAdvertisingFeedbackText1_2: {
    ua: '500 грн',
    ru: '500 грн',
    de: '500 UAH',
    en: '500 UAH',
    fr: '500 UAH',
    es: '500 UAH',
    cs: '500 UAH',
  },
  questionnaireArchiveAdvertisingFeedbackText1_3: {
    ua: 'на бонусний рахунок!',
    ru: 'на бонусный счёт!',
    de: 'auf das Bonuskonto!',
    en: 'to your bonus account!',
    fr: 'sur votre compte bonus!',
    es: 'en tu cuenta de bonificación!',
    cs: 'na váš bonusový účet!',
  },
  questionnaireArchiveAdvertisingFeedbackText1_4: {
    ua: 'Вітаємо! Ви отримали 500 грн на бонусний рахунок.',
    ru: 'Поздравляем! Вы получили 500 грн на бонусный счёт.',
    de: 'Herzlichen Glückwunsch! Sie haben 500 UAH auf Ihr Bonuskonto erhalten.',
    en: 'Congratulations! You have received 500 UAH to your bonus account.',
    fr: 'Félicitations ! Vous avez reçu 500 UAH sur votre compte bonus.',
    es: '¡Felicidades! Has recibido 500 UAH en tu cuenta de bonificación.',
    cs: 'Gratulujeme! Dostali jste 500 UAH na svůj bonusový účet.',
  },
  questionnaireArchiveAdvertisingText1: {
    ua: 'Дякуємо за вашу позитивну оцінку',
    en: 'Thank you for your positive feedback',
    de: 'Danke für Ihr positives Feedback',
    ru: 'Спасибо за вашу положительную оценку',
    fr: 'Merci pour votre avis positif',
    es: 'Gracias por su comentario positivo',
    cs: 'Děkujeme za vaše pozitivní hodnocení',
  },
  questionnaireArchiveAdvertisingText2: {
    ua: 'СПЕЦІАЛЬНА ПРОПОЗИЦІЯ',
    en: 'SPECIAL OFFER',
    de: 'SONDERANGEBOT',
    ru: 'СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ',
    fr: 'OFFRE SPÉCIALE',
    es: 'OFERTA ESPECIAL',
    cs: 'SPECIÁLNÍ NABÍDKA',
  },
  questionnaireArchiveAdvertisingText3: {
    ua: 'Отримуйте',
    en: 'Get',
    de: 'Erhalte',
    ru: 'Получайте',
    fr: 'Recevez',
    es: 'Obtenga',
    cs: 'Získejte',
  },
  questionnaireArchiveAdvertisingText3_1: {
    ua: 'за рекомендацію',
    en: 'for recommendation',
    de: 'für Empfehlung',
    ru: 'за рекомендацию',
    fr: 'pour recommandation',
    es: 'por recomendación',
    cs: 'za doporučení',
  },
  questionnaireArchiveAdvertisingText4_1: {
    ua: 'Пропонуємо Вам стати нашим',
    en: 'We offer you to become our',
    de: 'Wir bieten Ihnen an, unser',
    ru: 'Предлагаем вам стать нашим',
    fr: 'Nous vous proposons de devenir notre',
    es: 'Le ofrecemos convertirse en nuestro',
    cs: 'Nabízíme vám stát se naším',
  },
  questionnaireArchiveAdvertisingText4_2: {
    ua: 'Партнером',
    en: 'Partner',
    de: 'Partner',
    ru: 'партнером',
    fr: 'Partenaire',
    es: 'Socio',
    cs: 'Partnerem',
  },
  questionnaireArchiveAdvertisingText4_3: {
    ua: 'та отримувати',
    en: 'and receive',
    de: 'und erhalten',
    ru: 'и получать',
    fr: 'et recevoir',
    es: 'y recibir',
    cs: 'a dostávat',
  },
  questionnaireArchiveAdvertisingText4_4: {
    ua: 'пасивний дохід',
    en: 'passive income',
    de: 'passives Einkommen',
    ru: 'пассивный доход',
    fr: 'un revenu passif',
    es: 'ingresos pasivos',
    cs: 'pasivní příjem',
  },
  questionnaireArchiveAdvertisingText4_5: {
    ua: 'за добрі рекомендації про нашу компанію.',
    en: 'for good recommendations about our company.',
    de: 'für gute Empfehlungen über unser Unternehmen.',
    ru: 'за хорошие рекомендации о нашей компании.',
    fr: 'pour de bonnes recommandations sur notre entreprise.',
    es: 'por buenas recomendaciones sobre nuestra empresa.',
    cs: 'za dobrá doporučení naší společnosti.',
  },
  questionnaireArchiveAdvertisingText5: {
    ua: 'Відео презентація Партнерської програми АО «Захист»:',
    en: 'Video presentation of the Partnership Program of LLC "Zakhyst":',
    de: 'Video-Präsentation des Partnerschaftsprogramms der GmbH "Zakhyst":',
    ru: 'Видео-презентация Партнерской программы ООО "Защита":',
    fr: 'Présentation vidéo du programme de partenariat de LLC "Zakhyst" :',
    es: 'Presentación en vídeo del Programa de Asociación de LLC "Zakhyst":',
    cs: 'Videoprezentace partnerského programu společnosti LLC "Zakhyst":',
  },
  learnMoreBtn: {
    ua: 'Дізнатися більше',
    en: 'Learn more',
    de: 'Erfahren Sie mehr',
    ru: 'Узнать больше',
    fr: 'En savoir plus',
    es: 'Saber más',
    cs: 'Zjistit více',
  },
  // QuestionnaireHotline
  questionnaireHotlineWarn1: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Оцініть якість консультації?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Rate the quality of the consultation?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "Bewerten Sie die Qualität der Beratung?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Оцените качество консультации?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "Évaluez la qualité de la consultation ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Califique la calidad de la consulta?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Ohodnoťte kvalitu konzultace?"',
  },
  questionnaireHotlineWarn2: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Оцініть привітність персоналу АО "Захист"?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Rate the friendliness of the staff of the "Zakhyst" company?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "Bewerten Sie die Freundlichkeit des Personals der Firma "Zakhyst"?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Оцените приветливость персонала АО "Защита"?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "Évaluez la convivialité du personnel de la société "Zakhyst" ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Califique la amabilidad del personal de la empresa "Zakhyst"?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Ohodnoťte přívětivost personálu společnosti "Zakhyst"?"',
  },
  questionnaireHotlineWarn3: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Чи вчасно розпочалася консультація?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Did the consultation start on time?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "Hat die Beratung pünktlich begonnen?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Консультация началась вовремя?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "La consultation a-t-elle commencé à l\'heure ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿La consulta comenzó a tiempo?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Začala konzultace včas?"',
  },
  questionnaireHotlineWarn4: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Чи влаштувала Вас ціна консультації?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Was the consultation fee satisfactory for you?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "War die Beratungsgebühr für Sie zufriedenstellend?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Вас устроила стоимость консультации?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "Le prix de la consultation vous a-t-il satisfait ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Fue satisfactoria la tarifa de la consulta?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Byla cena konzultace pro vás uspokojivá?"',
  },
  questionnaireHotlineWarn5: {
    ua: 'Для анкетування потрібно вказати один із варіантів з питання: "Чи зрозумілою для Вас була консультація?"',
    en: 'To complete the survey, you need to select one of the options for the question: "Was the consultation understandable for you?"',
    de: 'Um die Umfrage abzuschließen, müssen Sie eine der Optionen für die Frage auswählen: "War die Beratung für Sie verständlich?"',
    ru: 'Для заполнения анкеты необходимо выбрать один из вариантов ответа на вопрос: "Консультация была понятной для вас?"',
    fr: 'Pour compléter le sondage, vous devez sélectionner une des options pour la question : "La consultation était-elle compréhensible pour vous ?"',
    es: 'Para completar la encuesta, debe seleccionar una de las opciones para la pregunta: "¿Fue comprensible la consulta para usted?"',
    cs: 'Pro dokončení průzkumu musíte vybrat jednu z možností k otázce: "Byla konzultace pro vás srozumitelná?"',
  },
  // QuestionnaireHotline
  questionnaireHotlineInfo2: {
    ua: 'Дякуємо за приділений час. Вам ще залишилося завершити реєстрацію.',
    en: 'Thank you for your time. You still need to complete the registration.',
    de: 'Vielen Dank für Ihre Zeit. Sie müssen Ihre Registrierung noch abschließen.',
    ru: 'Спасибо за уделенное время. Вам нужно завершить регистрацию.',
    fr: "Merci pour votre temps. Vous devez encore terminer l'inscription.",
    es: 'Gracias por su tiempo. Aún necesita completar el registro.',
    cs: 'Děkujeme za váš čas. Musíte ještě dokončit registraci.',
  },
  questionnaireHotlineInfo3: {
    ua: 'Дякуємо за приділений час.',
    en: 'Thank you for your time.',
    de: 'Vielen Dank für Ihre Zeit.',
    ru: 'Спасибо за уделенное время.',
    fr: 'Merci pour votre temps.',
    es: 'Gracias por su tiempo.',
    cs: 'Děkujeme za váš čas.',
  },
  questionnaireHotlineInfo4: {
    ua: 'Будь ласка, потім заповніть анкету. Нам важлива ваша думка.',
    en: 'Please fill out the questionnaire afterwards. Your opinion is important to us.',
    de: 'Bitte füllen Sie anschließend den Fragebogen aus. Ihre Meinung ist uns wichtig.',
    ru: 'Пожалуйста, заполните анкету позже. Нам важно ваше мнение.',
    fr: 'Veuillez remplir le questionnaire par la suite. Votre opinion est importante pour nous.',
    es: 'Por favor, complete el cuestionario más tarde. Su opinión es importante para nosotros.',
    cs: 'Vyplňte prosím dotazník později. Na vašem názoru nám záleží.',
  },
  questionnaireHotlineTitle: {
    ua: 'Оцінити якість консультації',
    en: 'Evaluate the quality of consultation',
    de: 'Bewerten Sie die Qualität der Beratung',
    ru: 'Оценить качество консультации',
    fr: 'Évaluer la qualité de la consultation',
    es: 'Evaluar la calidad de la consulta',
    cs: 'Ohodnoťte kvalitu konzultace',
  },
  questionnaireHotlineText1: {
    ua: 'Анкета є конфіденційною та не підлягає розголошенню перед адвокатами та юристами!',
    en: 'The questionnaire is confidential and will not be disclosed to lawyers and attorneys!',
    de: 'Der Fragebogen ist vertraulich und wird nicht an Anwälte und Juristen weitergegeben!',
    ru: 'Анкета является конфиденциальной и не подлежит разглашению перед адвокатами и юристами!',
    fr: 'Le questionnaire est confidentiel et ne sera pas divulgué aux avocats et juristes!',
    es: 'El cuestionario es confidencial y no se divulgará a abogados y juristas!',
    cs: 'Dotazník je důvěrný a nebude zveřejněn právníkům a advokátům!',
  },
  questionnaireHotlineText2: {
    ua: 'Оцініть якість консультації',
    en: 'Evaluate the quality of consultation',
    de: 'Bewerten Sie die Qualität der Beratung',
    ru: 'Оцените качество консультации',
    fr: 'Évaluez la qualité de la consultation',
    es: 'Evalúe la calidad de la consulta',
    cs: 'Ohodnoťte kvalitu konzultace',
  },
  questionnaireHotlineText3: {
    ua: 'Оцініть привітність персоналу',
    en: 'Rate the friendliness of the staff',
    de: 'Bewerten Sie die Freundlichkeit des Personals',
    ru: 'Оцените приветливость персонала',
    fr: 'Évaluez la convivialité du personnel',
    es: 'Califique la amabilidad del personal',
    cs: 'Ohodnoťte přívětivost personálu',
  },
  questionnaireHotlineText4: {
    ua: 'Чи вчасно розпочалася консультація?',
    en: 'Did the consultation start on time?',
    de: 'Hat die Beratung pünktlich begonnen?',
    ru: 'Консультация началась вовремя?',
    fr: "La consultation a-t-elle commencé à l'heure ?",
    es: '¿La consulta comenzó a tiempo?',
    cs: 'Začala konzultace včas?',
  },
  questionnaireHotlineText5: {
    ua: 'Чи влаштувала Вас ціна консультації?',
    en: 'Was the consultation fee satisfactory for you?',
    de: 'Hat Sie die Beratungsgebühr zufriedengestellt?',
    ru: 'Вас устроила стоимость консультации?',
    fr: 'Le prix de la consultation vous a-t-il satisfait ?',
    es: '¿Fue satisfactoria la tarifa de la consulta?',
    cs: 'Byla cena konzultace pro vás uspokojivá?',
  },
  questionnaireHotlineText6: {
    ua: 'Чи зрозумілою для Вас була консультація?',
    en: 'Was the consultation understandable for you?',
    de: 'War die Beratung für Sie verständlich?',
    ru: 'Консультация была понятной для вас?',
    fr: 'La consultation était-elle compréhensible pour vous ?',
    es: '¿Fue comprensible la consulta para usted?',
    cs: 'Byla konzultace pro vás srozumitelná?',
  },
  questionnaireHotlineText7: {
    ua: 'Чи зрозумілою для Вас мовою проводилася консультація?',
    en: 'Was the consultation conducted in a language that was clear to you?',
    de: 'Wurde die Beratung in einer für Sie verständlichen Sprache durchgeführt?',
    ru: 'Консультация проводилась на понятном для вас языке?',
    fr: 'La consultation a-t-elle été réalisée dans une langue que vous compreniez ?',
    es: '¿La consulta se realizó en un idioma comprensible para usted?',
    cs: 'Byla konzultace vedena v jazyce, kterému rozumíte?',
  },
  questionnaireHotlineText8: {
    ua: 'Чи розповіли Вам про додаток «єАдвокат»?',
    en: 'Were you informed about the "eLawyer" application?',
    de: 'Wurden Sie über die "eLawyer"-Anwendung informiert?',
    ru: 'Вам рассказали о приложении «еАдвокат»?',
    fr: 'Vous a-t-on informé de l\'application "eLawyer" ?',
    es: '¿Le informaron sobre la aplicación "eLawyer"?',
    cs: 'Byli jste informováni o aplikaci "eLawyer"?',
  },
  questionnaireHotlineText9: {
    ua: 'Чи уклали Ви договір з нашою компанією?',
    en: 'Did you sign a contract with our company?',
    de: 'Haben Sie einen Vertrag mit unserem Unternehmen unterzeichnet?',
    ru: 'Вы заключили договор с нашей компанией?',
    fr: 'Avez-vous signé un contrat avec notre entreprise ?',
    es: '¿Firmó un contrato con nuestra empresa?',
    cs: 'Podepsali jste smlouvu s naší společností?',
  },
  questionnaireHotlineText10: {
    ua: 'Місце для скарг чи пропозицій:',
    en: 'Place for complaints or suggestions:',
    de: 'Platz für Beschwerden oder Vorschläge:',
    ru: 'Место для жалоб или предложений:',
    fr: 'Espace pour les plaintes ou suggestions :',
    es: 'Espacio para quejas o sugerencias:',
    cs: 'Místo pro stížnosti nebo návrhy:',
  },
  questionnaireHotlinePlaceholder: {
    ua: 'Як нам стати краще?',
    en: 'How can we improve?',
    de: 'Wie können wir uns verbessern?',
    ru: 'Как нам стать лучше?',
    fr: 'Comment pouvons-nous nous améliorer ?',
    es: '¿Cómo podemos mejorar?',
    cs: 'Jak se můžeme zlepšit?',
  },
  leaveForLaterBtn: {
    ua: 'Залишити на потім',
    en: 'Leave for later',
    de: 'Später verlassen',
    ru: 'Оставить на потом',
    fr: 'Laisser pour plus tard',
    es: 'Dejar para más tarde',
    cs: 'Nechat na později',
  },
  // RedButtonContactModal
  specialist: {
    ua: 'Фахівець',
    en: 'Specialist',
    de: 'Fachmann',
    ru: 'Специалист',
    fr: 'Spécialiste',
    es: 'Especialista',
    cs: 'Specialista',
  },
  // redButtonModalCall: {
  //   ua: 'Терміновий дзвінок',
  //   en: 'Urgent call',
  //   de: 'Wichtiger Anruf',
  // },
  redButtonModalMessage: {
    ua: 'Написати повідомлення',
    en: 'Send a message',
    de: 'Eine Nachricht senden',
    ru: 'Отправить сообщение',
    fr: 'Envoyer un message',
    es: 'Enviar un mensaje',
    cs: 'Odeslat zprávu',
  },
  // redButtonModalFile: {
  //   ua: 'Відправити файл',
  //   en: 'Send an urgent file',
  //   de: 'Eine dringende Datei senden',
  // },
  redButtonModalTitle: {
    ua: 'Оберіть термінову послугу',
    en: 'Select an urgent service',
    de: 'Wählen Sie einen dringenden Service',
    ru: 'Выберите срочную услугу',
    fr: 'Choisissez un service urgent',
    es: 'Seleccione un servicio urgente',
    cs: 'Vyberte naléhavou službu',
  },
  // rulesModal ============
  consultation_button_text_1: {
    ua: 'Оплатити консультацію клубною картою',
    en: 'Pay for consultation with a club card',
    de: 'Beratung mit einer Clubkarte bezahlen',
    ru: 'Оплатить консультацию клубной картой',
    fr: 'Payer la consultation avec une carte de club',
    es: 'Pague la consulta con una tarjeta de club',
    cs: 'Zaplatit konzultaci klubovou kartou',
  },
  consultation_button_text_2: {
    ua: 'Оплатити VIP консультацію клубною картою',
    en: 'Pay for VIP consultation with a club card',
    de: 'VIP-Beratung mit einer Clubkarte bezahlen',
    ru: 'Оплатить VIP-консультацию клубной картой',
    fr: 'Payer une consultation VIP avec une carte de club',
    es: 'Pague una consulta VIP con una tarjeta de club',
    cs: 'Zaplatit VIP konzultaci klubovou kartou',
  },
  consultation_button_text_3: {
    ua: 'Оплатити зустріч з адвокатом клубною картою',
    en: 'Pay for a meeting with a lawyer using a club card',
    de: 'Treffen mit einem Anwalt mit einer Clubkarte bezahlen',
    ru: 'Оплатить встречу с адвокатом клубной картой',
    fr: 'Payer une rencontre avec un avocat avec une carte de club',
    es: 'Pague una reunión con un abogado con una tarjeta de club',
    cs: 'Zaplatit schůzku s právníkem klubovou kartou',
  },

  buy_club_card_btn: {
    ua: 'Купити клубну карту',
    en: 'Buy a club card',
    de: 'Clubkarte kaufen',
    ru: 'Купить клубную карту',
    fr: 'Acheter une carte de club',
    es: 'Comprar una tarjeta de club',
    cs: 'Koupit klubovou kartu',
  },
  buy_club_card_slogan: {
    ua: 'Придбай клубну карту, та заощаджуй свої гроші',
    en: 'Buy a club card and save your money',
    de: 'Kaufe eine Clubkarte und spare dein Geld',
    ru: 'Купите клубную карту и экономьте деньги',
    fr: "Achetez une carte de club et économisez de l'argent",
    es: 'Compre una tarjeta de club y ahorre dinero',
    cs: 'Kupte si klubovou kartu a ušetřete peníze',
  },

  // Need to fix paragraphs with currency

  rulesModalTitle: {
    ua: 'Правила використання бонусного рахунку',
    en: 'Rules for using the bonus account',
    de: 'Regeln für die Verwendung des Bonuskontos',
    ru: 'Правила использования бонусного счета',
    fr: "Règles pour l'utilisation du compte bonus",
    es: 'Reglas para usar la cuenta de bonificación',
    cs: 'Pravidla používání bonusového účtu',
  },
  rulesModal1: {
    ua: '1 бонус = 1 гривня',
    en: '1 bonus = 1 hryvnia',
    de: '1 Bonus = 1 Griwna',
    ru: '1 бонус = 1 гривня',
    fr: '1 bonus = 1 hryvnia',
    es: '1 bono = 1 hryvnia',
    cs: '1 bonus = 1 hřivna',
  },

  rulesModal2: {
    ua: 'На що можна витрачати бонуси?',
    en: 'What can bonuses be spent on?',
    de: 'Wofür können Boni ausgegeben werden?',
    ru: 'На что можно потратить бонусы?',
    fr: 'À quoi peuvent servir les bonus?',
    es: '¿En qué se pueden gastar los bonos?',
    cs: 'Na co lze utratit bonusy?',
  },
  rulesModal3: {
    ua: 'Ви можете використовувати бонусний рахунок для оплати послуг Адвокатського об’єднання «Захист»',
    en: 'You can use the bonus account to pay for services of the Advocacy Association "Zahist"',
    de: 'Sie können das Bonuskonto verwenden, um die Dienste des Anwaltsverbandes "Zahist" zu bezahlen',
    ru: 'Вы можете использовать бонусный счет для оплаты услуг адвокатского объединения «Защит»',
    fr: 'Vous pouvez utiliser le compte bonus pour payer les services de l\'Association de défense "Zahist"',
    es: 'Puede usar la cuenta de bonificación para pagar los servicios de la Asociación de Defensa "Zahist"',
    cs: 'Bonusový účet můžete použít k úhradě služeb advokátního sdružení „Zahist“',
  },
  rulesModal4: {
    ua: 'Порядок розрахунку бонусами',
    en: 'Calculation order for bonuses',
    de: 'Berechnungsreihenfolge für Boni',
    ru: 'Порядок расчета бонусами',
    fr: 'Ordre de calcul des bonus',
    es: 'Orden de cálculo para bonos',
    cs: 'Pořadí výpočtu bonusů',
  },
  rulesModal5: {
    ua: 'Бонуси покривають 30% Вашої оплати наших послуг.',
    en: 'Bonuses cover 30% of your payment for our services.',
    de: 'Boni decken 30% Ihrer Zahlung für unsere Dienstleistungen ab.',
    fr: 'Les bonus couvrent 30% de votre paiement pour nos services.',
    es: 'Los bonos cubren el 30% de su pago por nuestros servicios.',
    cs: 'Bonusy pokrývají 30 % vaší platby za naše služby.',
  },
  rulesModal6: {
    ua: 'Наприклад: якщо на Вашому бонусному рахунку 3000 грн., при цьому Ви бажаєте здійснити оплату послуг АО «Захист» на суму 1000 грн., то збонусного рахунку Ви можете списати 300 грн. (30% від 1000 грн.), решту 700 грн. Ви оплачуєте готівкою або безготівковим переказом. У такомувипадку на бонусному рахунку у Вас залишиться 2700 грн.',
    en: 'For example: if you have 3000 UAH on your bonus account, and you want to pay for services of the "Zahist" Advocacy Association in the amount of 1000 UAH, then from the bonus account you can deduct 300 UAH (30% of 1000 UAH), the rest of 700 UAH you pay in cash or by bank transfer. In this case, you will have 2700 UAH left on the bonus account.',
    de: 'Beispiel: Wenn Sie 3000 UAH auf Ihrem Bonuskonto haben und Sie die Dienste des Anwaltsverbands "Zahist" in Höhe von 1000 UAH bezahlen möchten, können Sie vom Bonuskonto 300 UAH abziehen (30% von 1000 UAH), die restlichen 700 UAH zahlen Sie bar oder per Banküberweisung. In diesem Fall haben Sie noch 2700 UAH auf dem Bonuskonto.',
    ru: 'Например: если на вашем бонусном счете 3000 грн, и вы хотите оплатить услуги адвокатского объединения «Защита» на сумму 1000 грн, то с бонусного счета вы можете списать 300 грн (30% от 1000 грн), оставшиеся 700 грн вы оплачиваете наличными или безналичным переводом. В этом случае на бонусном счете у вас останется 2700 грн.',
    fr: 'Par exemple : si vous avez 3000 UAH sur votre compte bonus et que vous souhaitez payer les services de l\'association de défense "Zahist" pour un montant de 1000 UAH, alors vous pouvez déduire 300 UAH (30 % de 1000 UAH) du compte bonus, et le reste de 700 UAH est payé en espèces ou par virement bancaire. Dans ce cas, il vous restera 2700 UAH sur le compte bonus.',
    es: 'Por ejemplo: si tienes 3000 UAH en tu cuenta de bonificación y deseas pagar los servicios de la Asociación de Defensa "Zahist" por un monto de 1000 UAH, puedes deducir 300 UAH (30 % de 1000 UAH) de la cuenta de bonificación, y el resto de 700 UAH se paga en efectivo o mediante transferencia bancaria. En este caso, te quedarán 2700 UAH en la cuenta de bonificación.',
    cs: 'Například: pokud máte na svém bonusovém účtu 3000 Kč a chcete zaplatit za služby advokátního sdružení "Zahist" částku 1000 Kč, pak z bonusového účtu můžete odepsat 300 Kč (30 % z 1000 Kč), zbytek 700 Kč zaplatíte v hotovosti nebo bankovním převodem. V tomto případě vám na bonusovém účtu zůstane 2700 Kč.',
  },

  rulesModal7: {
    ua: 'Куди тиснути щоб оплатити послуги бонусами?',
    en: 'Where to click to pay for services with bonuses?',
    de: 'Wo klicken, um Dienstleistungen mit Boni zu bezahlen?',
    ru: 'Куда нажать, чтобы оплатить услуги бонусами?',
    fr: 'Où cliquer pour payer les services avec des bonus?',
    es: '¿Dónde hacer clic para pagar los servicios con bonos?',
    cs: 'Kam kliknout pro zaplacení služeb pomocí bonusů?',
  },

  rulesModal8: {
    ua: '1. Натисніть на кнопку «Оплатити бонусами» на головному екрані;',
    en: '1. Click on the «Pay with bonuses» button on the main screen;',
    de: '1. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen» auf dem Hauptbildschirm;',
    ru: '1. Нажмите кнопку «Оплатить бонусами» на главном экране;',
    fr: "1. Cliquez sur le bouton «Payer avec des bonus» sur l'écran principal;",
    es: '1. Haga clic en el botón «Pagar con bonos» en la pantalla principal;',
    cs: '1. Klikněte na tlačítko «Platba pomocí bonusů» na hlavní obrazovce;',
  },

  rulesModal9: {
    ua: '2. Введіть в центральне поле суму, яку Ви бажаєте сплатити (по аналогії з вище приведеним прикладом - 1000 грн.);',
    en: '2. Enter the amount you want to pay in the central field (similar to the example above - 1000 UAH);',
    de: '2. Geben Sie den Betrag ein, den Sie bezahlen möchten, in das zentrale Feld ein (ähnlich dem obigen Beispiel - 1000 UAH);',
    ru: '2. Введите сумму, которую вы хотите оплатить, в центральное поле (по аналогии с вышеуказанным примером - 1000 грн.);',
    fr: "2. Entrez le montant que vous souhaitez payer dans le champ central (similaire à l'exemple ci-dessus - 1000 UAH);",
    es: '2. Ingrese la cantidad que desea pagar en el campo central (similar al ejemplo anterior - 1000 UAH);',
    cs: '2. Zadejte částku, kterou chcete zaplatit, do centrálního pole (podobně jako v uvedeném příkladu - 1000 Kč);',
  },

  rulesModal10: {
    ua: '3. Нижче, система вирахує максимальну суму списання бонусів з урахуванням Вашого балансу (по аналогії з вище приведеним прикладом 300 грн.);',
    en: '3. Below, the system will calculate the maximum amount of bonus deduction taking into account your balance (similar to the example above - 300 UAH);',
    de: '3. Darunter wird das System den maximalen Betrag der Bonusabbuchung unter Berücksichtigung Ihres Kontostands berechnen (ähnlich dem obigen Beispiel - 300 UAH);',
    ru: '3. Ниже система рассчитает максимальную сумму списания бонусов с учетом вашего баланса (по аналогии с вышеуказанным примером - 300 грн.);',
    fr: "3. En dessous, le système calculera le montant maximal de déduction des bonus en fonction de votre solde (similaire à l'exemple ci-dessus - 300 UAH);",
    es: '3. A continuación, el sistema calculará la cantidad máxima de deducción de bonos teniendo en cuenta su saldo (similar al ejemplo anterior - 300 UAH);',
    cs: '3. Níže systém vypočítá maximální částku odečtu bonusů s ohledem na váš zůstatek (podobně jako v uvedeném příkladu - 300 Kč);',
  },

  rulesModal11: {
    ua: '4. Натисніть кнопку «Сплатити бонусами». Після цього з Вашого бонусного рахунку спишеться сума бонусів, що відобразиться в історії транзакцій;',
    en: '4. Click the «Pay with bonuses» button. After that, the amount of bonuses will be debited from your bonus account, which will be reflected in the transaction history;',
    de: '4. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen». Danach wird der Betrag der Boni von Ihrem Bonuskonto abgebucht, was im Transaktionsverlauf angezeigt wird;',
    ru: '4. Нажмите кнопку «Оплатить бонусами». После этого с вашего бонусного счета спишется сумма бонусов, что отразится в истории транзакций;',
    fr: "4. Cliquez sur le bouton «Payer avec des bonus». Ensuite, le montant des bonus sera débité de votre compte bonus, ce qui sera reflété dans l'historique des transactions;",
    es: '4. Haga clic en el botón «Pagar con bonos». Después de eso, la cantidad de bonos se debitará de su cuenta de bonificación, lo que se reflejará en el historial de transacciones;',
    cs: '4. Klikněte na tlačítko «Platba pomocí bonusů». Poté bude z vašeho bonusového účtu odečtena částka bonusů, což se zobrazí v historii transakcí;',
  },

  rulesModal12: {
    ua: '5. Сплатіть решту суми готівкою або безготівковим переказом (по аналогії з вище приведеним прикладом 700 грн.) та не забудьте сповістити про це свого куратора по справі.',
    en: "5. Pay the remaining amount in cash or by bank transfer (similar to the example above - 700 UAH) and don't forget to notify your case curator.",
    de: '5. Zahlen Sie den Restbetrag bar oder per Banküberweisung (ähnlich dem obigen Beispiel - 700 UAH) und vergessen Sie nicht, Ihren Fallbetreuer zu benachrichtigen.',
    ru: '5. Оплатите оставшуюся сумму наличными или банковским переводом (по аналогии с вышеуказанным примером - 700 грн) и не забудьте уведомить вашего куратора по делу.',
    fr: "5. Payez le montant restant en espèces ou par virement bancaire (similaire à l'exemple ci-dessus - 700 UAH) et n'oubliez pas de prévenir votre tuteur de dossier.",
    es: '5. Pague el monto restante en efectivo o mediante transferencia bancaria (similar al ejemplo anterior - 700 UAH) y no olvide notificar a su curador de caso.',
    cs: '5. Zaplaťte zbývající částku v hotovosti nebo bankovním převodem (podobně jako v uvedeném příkladu - 700 Kč) a nezapomeňte informovat svého správce případu.',
  },

  // ClubCardGiftModal
  clubCardGiftValidity: {
    ua: 'Термін дії',
    en: 'Validity',
    de: 'Gültigkeit',
    ru: 'Срок действия',
    fr: 'Validité',
    es: 'Validez',
    cs: 'Platnost',
  },

  clubCardGiftAccept: {
    ua: 'Прийняти',
    en: 'Accept',
    de: 'Akzeptieren',
    ru: 'Принять',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Přijmout',
  },
  ClubCardGift: {
    ua: 'Даруємо Вам клубну карту',
    en: 'We give you a club card',
    de: 'Wir geben Ihnen eine Clubkarte',
    ru: 'Мы дарим вам клубную карту',
    fr: 'Nous vous offrons une carte de club',
    es: 'Le regalamos una tarjeta de club',
    cs: 'Darujeme vám klubovou kartu',
  },

  // FirstLoginBonusModal

  firstLoginBonusTitle: {
    ua: 'Вітаємо з першим входом',
    en: 'Congratulations on your first login',
    de: 'Herzlichen Glückwunsch zum ersten Login',
    ru: 'Поздравляем с первым входом',
    fr: 'Félicitations pour votre première connexion',
    es: 'Felicidades por su primer inicio de sesión',
    cs: 'Gratulujeme k prvnímu přihlášení',
  },
  firstLoginBonusText1: {
    ua: 'Вам нараховано 300 бонусів',
    en: 'You have been credited with 300 bonuses',
    de: 'Ihnen wurden 300 Boni gutgeschrieben',
    ru: 'Вам начислено 300 бонусов',
    fr: 'Vous avez été crédité de 300 bonus',
    es: 'Se le han acreditado 300 bonos',
    cs: 'Bylo vám připsáno 300 bonusů',
  },
  firstLoginAccept: {
    ua: 'Прийняти',
    en: 'Accept',
    de: 'Akzeptieren',
    ru: 'Принять',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Přijmout',
  },

  // FirstHotlineBonusModal

  firstHotlineBonusTitle: {
    ua: 'Вам повернено гроші за консультацію',
    en: 'You have been refunded for the consultation',
    de: 'Sie haben eine Rückerstattung für die Beratung erhalten',
    ru: 'Вам возвращены деньги за консультацию',
    fr: 'Vous avez été remboursé pour la consultation',
    es: 'Se le ha reembolsado por la consulta',
    cs: 'Byly vám vráceny peníze za konzultaci',
  },
  firstHotlineBonusText1: {
    ua: 'На баланс буде нараховано:',
    en: 'The balance will be credited with:',
    de: 'Der Kontostand wird gutgeschrieben mit:',
    ru: 'На баланс будет зачислено:',
    fr: 'Le solde sera crédité de :',
    es: 'El saldo será acreditado con:',
    cs: 'Na zůstatek bude připsáno:',
  },
  firstHotlineAccept: {
    ua: 'Прийняти',
    en: 'Accept',
    de: 'Akzeptieren',
    ru: 'Принять',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Přijmout',
  },
  // RulesBalance
  rulesBalanceTitle: {
    ua: 'Правила використання балансу',
    en: 'Balance usage rules',
    de: 'Regeln zur Verwendung des Guthabens',
    ru: 'Правила использования баланса',
    fr: "Règles d'utilisation du solde",
    es: 'Reglas de uso del saldo',
    cs: 'Pravidla používání zůstatku',
  },
  rulesBalanceText1: {
    ua: 'Правила безпеки:',
    en: 'Security rules:',
    de: 'Sicherheitsregeln:',
    ru: 'Правила безопасности:',
    fr: 'Règles de sécurité :',
    es: 'Reglas de seguridad:',
    cs: 'Bezpečnostní pravidla:',
  },
  rulesBalanceText2: {
    ua: 'Автентифікація:',
    en: 'Authentication:',
    de: 'Authentifizierung:',
    ru: 'Аутентификация:',
    fr: 'Authentification :',
    es: 'Autenticación:',
    cs: 'Autentizace:',
  },
  rulesBalanceText3: {
    ua: 'Вимагайте автентифікації користувачів перед здійсненням фінансових транзакцій. Використовуйте паролі, біометрію, аутентифікацію двофакторним методом тощо.',
    en: 'Require user authentication before performing financial transactions. Use passwords, biometrics, two-factor authentication, etc.',
    de: 'Verlangen Sie die Authentifizierung von Benutzern vor der Durchführung von Finanztransaktionen. Verwenden Sie Passwörter, Biometrie, Zwei-Faktor-Authentifizierung usw.',
    ru: 'Требуйте аутентификацию пользователей перед выполнением финансовых транзакций. Используйте пароли, биометрию, двухфакторную аутентификацию и т.д.',
    fr: "Exigez l'authentification des utilisateurs avant d'effectuer des transactions financières. Utilisez des mots de passe, la biométrie, l'authentification à deux facteurs, etc.",
    es: 'Requiere autenticación de usuario antes de realizar transacciones financieras. Usa contraseñas, biometría, autenticación de dos factores, etc.',
    cs: 'Vyžadujte autentizaci uživatelů před prováděním finančních transakcí. Používejte hesla, biometriku, dvoufaktorovou autentizaci atd.',
  },
  rulesBalanceText4: {
    ua: 'Шифрування:',
    en: 'Encryption:',
    de: 'Verschlüsselung:',
    ru: 'Шифрование:',
    fr: 'Cryptage :',
    es: 'Cifrado:',
    cs: 'Šifrování:',
  },
  rulesBalanceText5: {
    ua: 'Захищайте дані користувачів, використовуючи шифрування для передачі і зберігання даних.',
    en: 'Protect user data by using encryption for data transmission and storage.',
    de: 'Schützen Sie Benutzerdaten durch Verschlüsselung für die Datenübertragung und -speicherung.',
    ru: 'Защищайте данные пользователей, используя шифрование для передачи и хранения данных.',
    fr: 'Protégez les données des utilisateurs en utilisant le cryptage pour la transmission et le stockage des données.',
    es: 'Proteja los datos de los usuarios utilizando cifrado para la transmisión y almacenamiento de datos.',
    cs: 'Chraňte uživatelská data pomocí šifrování pro přenos a ukládání dat.',
  },
  rulesBalanceText6: {
    ua: 'Моніторинг:',
    en: 'Monitoring:',
    de: 'Überwachung:',
    ru: 'Мониторинг:',
    fr: 'Surveillance :',
    es: 'Monitoreo:',
    cs: 'Monitorování:',
  },

  rulesBalanceText7: {
    ua: 'Встановіть систему моніторингу для виявлення незвичайних або підозрілих транзакцій.',
    en: 'Set up a monitoring system to detect unusual or suspicious transactions.',
    de: 'Richten Sie ein Überwachungssystem ein, um ungewöhnliche oder verdächtige Transaktionen zu erkennen.',
    ru: 'Настройте систему мониторинга для обнаружения необычных или подозрительных транзакций.',
    fr: 'Mettez en place un système de surveillance pour détecter les transactions inhabituelles ou suspectes.',
    es: 'Configure un sistema de monitoreo para detectar transacciones inusuales o sospechosas.',
    cs: 'Nastavte monitorovací systém pro detekci neobvyklých nebo podezřelých transakcí.',
  },
  rulesBalanceText8: {
    ua: 'Захист від шахраїв:',
    en: 'Protection against fraud:',
    de: 'Schutz vor Betrug:',
    ru: 'Защита от мошенничества:',
    fr: 'Protection contre la fraude :',
    es: 'Protección contra fraudes:',
    cs: 'Ochrana proti podvodům:',
  },
  rulesBalanceText9: {
    ua: 'Розробіть стратегії для захисту від шахраїв, такі як визначення підозрілих активностей та блокування їх.',
    en: 'Develop strategies to protect against fraud, such as identifying suspicious activities and blocking them.',
    de: 'Entwickeln Sie Strategien zum Schutz vor Betrug, wie z.B. Identifizierung verdächtiger Aktivitäten und deren Blockierung.',
    ru: 'Разработайте стратегии защиты от мошенничества, такие как выявление подозрительных активностей и их блокировка.',
    fr: "Développez des stratégies pour vous protéger contre la fraude, telles que l'identification des activités suspectes et leur blocage.",
    es: 'Desarrolle estrategias para protegerse contra el fraude, como identificar actividades sospechosas y bloquearlas.',
    cs: 'Vypracujte strategie na ochranu proti podvodům, jako je identifikace podezřelých aktivit a jejich blokování.',
  },
  rulesBalanceText10: {
    ua: 'Обмеження сум і частоти:',
    en: 'Limits on amounts and frequency:',
    de: 'Grenzen für Beträge und Häufigkeit:',
    ru: 'Ограничения по суммам и частоте:',
    fr: 'Limites sur les montants et la fréquence :',
    es: 'Límites en cantidades y frecuencia:',
    cs: 'Limity na částky a frekvenci:',
  },
  rulesBalanceText11: {
    ua: 'Встановіть ліміти на максимальну суму грошей, яку користувач може витратити за однією транзакцією або протягом певного періоду.',
    en: 'Set limits on the maximum amount of money a user can spend in a single transaction or within a certain period.',
    de: 'Legen Sie Grenzen für den maximalen Betrag fest, den ein Benutzer in einer einzelnen Transaktion oder innerhalb eines bestimmten Zeitraums ausgeben kann.',
    ru: 'Установите лимиты на максимальную сумму денег, которую пользователь может потратить за одну транзакцию или в течение определенного периода.',
    fr: "Fixez des limites sur le montant maximum d'argent qu'un utilisateur peut dépenser en une seule transaction ou sur une période donnée.",
    es: 'Establezca límites en la cantidad máxima de dinero que un usuario puede gastar en una sola transacción o dentro de un período determinado.',
    cs: 'Stanovte limity na maximální částku, kterou může uživatel utratit při jedné transakci nebo v určitém období.',
  },
  rulesBalanceText12: {
    ua: 'Встановіть обмеження на частоту транзакцій, щоб уникнути великої кількості малих транзакцій.',
    en: 'Set limits on the frequency of transactions to avoid a large number of small transactions.',
    de: 'Legen Sie Grenzen für die Häufigkeit von Transaktionen fest, um eine große Anzahl von kleinen Transaktionen zu vermeiden.',
    ru: 'Установите ограничения на частоту транзакций, чтобы избежать большого количества мелких транзакций.',
    fr: 'Fixez des limites sur la fréquence des transactions pour éviter un grand nombre de petites transactions.',
    es: 'Establezca límites en la frecuencia de las transacciones para evitar una gran cantidad de transacciones pequeñas.',
    cs: 'Nastavte limity na frekvenci transakcí, aby se zabránilo velkému počtu malých transakcí.',
  },

  // RulesBonuses
  rulesBonusesTitle: {
    ua: 'Правила використання бонусів',
    en: 'Bonus Usage Rules',
    de: 'Regeln zur Verwendung von Bonuspunkten',
    ru: 'Правила использования бонусов',
    fr: "Règles d'utilisation des bonus",
    es: 'Reglas de uso de bonos',
    cs: 'Pravidla používání bonusů',
  },
  rulesBonusesText1: {
    ua: '1 бонус = 1 гривня',
    en: '1 bonus = 1 hryvnia',
    de: '1 Bonuspunkt = 1 Hrywnja',
    ru: '1 бонус = 1 гривня',
    fr: '1 bonus = 1 hryvnia',
    es: '1 bono = 1 hryvnia',
    cs: '1 bonus = 1 hřivna',
  },
  rulesBonusesText2: {
    ua: 'На що можна витрачати бонуси?',
    en: 'What can bonuses be spent on?',
    de: 'Wofür können Bonuspunkte ausgegeben werden?',
    ru: 'На что можно потратить бонусы?',
    fr: 'À quoi peuvent servir les bonus?',
    es: '¿En qué se pueden gastar los bonos?',
    cs: 'Na co lze utratit bonusy?',
  },
  rulesBonusesText3: {
    ua: 'Ви можете використовувати бонусний рахунок для оплати послуг Адвокатського об’єднання «Захист»',
    en: 'You can use your bonus account to pay for the services of the «Zakhyst» Advocacy Association',
    de: 'Sie können Ihr Bonuskonto verwenden, um die Dienste des Anwaltsverbandes «Zakhyst» zu bezahlen',
    ru: 'Вы можете использовать бонусный счет для оплаты услуг адвокатского объединения «Защита»',
    fr: "Vous pouvez utiliser le compte bonus pour payer les services de l'Association de défense «Zakhyst»",
    es: 'Puede usar la cuenta de bonificación para pagar los servicios de la Asociación de Defensa «Zakhyst»',
    cs: 'Bonusový účet můžete použít k úhradě služeb advokátního sdružení „Zakhyst“',
  },
  rulesBonusesText4: {
    ua: 'Порядок розрахунку бонусами',
    en: 'Procedure for calculating bonuses',
    de: 'Verfahren zur Berechnung von Bonuspunkten',
    ru: 'Порядок расчета бонусами',
    fr: 'Ordre de calcul des bonus',
    es: 'Orden de cálculo para bonos',
    cs: 'Pořadí výpočtu bonusů',
  },
  rulesBonusesText5: {
    ua: 'Бонуси покривають 30% Вашої оплати наших послуг.',
    en: 'Bonuses cover 30% of your payment for our services.',
    de: 'Bonuspunkte decken 30% Ihrer Zahlung für unsere Dienstleistungen ab.',
    ru: 'Бонусы покрывают 30% вашей оплаты за наши услуги.',
    fr: 'Les bonus couvrent 30% de votre paiement pour nos services.',
    es: 'Los bonos cubren el 30% de su pago por nuestros servicios.',
    cs: 'Bonusy pokrývají 30 % vaší platby za naše služby.',
  },
  rulesBonusesText6: {
    ua: 'Наприклад: якщо на Вашому бонусному рахунку 3000 грн., при цьому Ви бажаєте здійснити оплату послуг АО «Захист» на суму 1000 грн., то з бонусного рахунку Ви можете списати 300 грн. (30% від 1000 грн.), решту 700 грн. Ви оплачуєте готівкою або безготівковим переказом. У такому випадку на бонусному рахунку у Вас залишиться 2700 грн.',
    en: 'For example: if you have 3000 UAH on your bonus account, and you want to pay for the services of the «Zakhyst» Advocacy Association in the amount of 1000 UAH, then from the bonus account you can deduct 300 UAH (30% of 1000 UAH), the rest 700 UAH you pay in cash or by bank transfer. In this case, you will have 2700 UAH left on the bonus account.',
    de: 'Beispiel: Wenn Sie 3000 UAH auf Ihrem Bonuskonto haben und die Dienste des Anwaltsverbandes «Zakhyst» in Höhe von 1000 UAH bezahlen möchten, können Sie vom Bonuskonto 300 UAH abziehen (30% von 1000 UAH), die restlichen 700 UAH zahlen Sie bar oder per Banküberweisung. In diesem Fall haben Sie noch 2700 UAH auf dem Bonuskonto.',
    ru: 'Например: если на вашем бонусном счете 3000 грн, и вы хотите оплатить услуги адвокатского объединения «Защита» на сумму 1000 грн, то с бонусного счета вы можете списать 300 грн (30% от 1000 грн), оставшиеся 700 грн вы оплачиваете наличными или безналичным переводом. В этом случае на бонусном счете у вас останется 2700 грн.',
    fr: 'Par exemple : si vous avez 3000 UAH sur votre compte bonus et que vous souhaitez payer les services de l\'association de défense "Zakhyst" pour un montant de 1000 UAH, alors vous pouvez déduire 300 UAH (30 % de 1000 UAH) du compte bonus, et le reste de 700 UAH est payé en espèces ou par virement bancaire. Dans ce cas, il vous restera 2700 UAH sur le compte bonus.',
    es: 'Por ejemplo: si tienes 3000 UAH en tu cuenta de bonificación y deseas pagar los servicios de la Asociación de Defensa "Zakhyst" por un monto de 1000 UAH, puedes deducir 300 UAH (30 % de 1000 UAH) de la cuenta de bonificación, y el resto de 700 UAH se paga en efectivo o mediante transferencia bancaria. En este caso, te quedarán 2700 UAH en la cuenta de bonificación.',
    cs: 'Například: pokud máte na svém bonusovém účtu 3000 Kč a chcete zaplatit za služby advokátního sdružení „Zakhyst“ částku 1000 Kč, pak z bonusového účtu můžete odepsat 300 Kč (30 % z 1000 Kč), zbytek 700 Kč zaplatíte v hotovosti nebo bankovním převodem. V tomto případě vám na bonusovém účtu zůstane 2700 Kč.',
  },
  rulesBonusesText7: {
    ua: 'Куди тиснути щоб оплатити послуги бонусами?',
    en: 'Where to click to pay for services with bonuses?',
    de: 'Wo klicken Sie, um Dienstleistungen mit Bonuspunkten zu bezahlen?',
    ru: 'Куда нажать, чтобы оплатить услуги бонусами?',
    fr: 'Où cliquer pour payer les services avec des bonus?',
    es: '¿Dónde hacer clic para pagar los servicios con bonos?',
    cs: 'Kam kliknout pro zaplacení služeb pomocí bonusů?',
  },
  rulesBonusesText8: {
    ua: ' 1. Натисніть на кнопку «Оплатити бонусами» на головному екрані;',
    en: '1. Click on the «Pay with Bonuses» button on the main screen;',
    de: '1. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen» auf dem Hauptbildschirm;',
    ru: '1. Нажмите кнопку «Оплатить бонусами» на главном экране;',
    fr: "1. Cliquez sur le bouton «Payer avec des bonus» sur l'écran principal;",
    es: '1. Haga clic en el botón «Pagar con bonos» en la pantalla principal;',
    cs: '1. Klikněte na tlačítko «Platba pomocí bonusů» na hlavní obrazovce;',
  },
  rulesBonusesText9: {
    ua: '2. Введіть в центральне поле суму, яку Ви бажаєте сплатити (по аналогії з вище приведеним прикладом - 1000 грн.);',
    en: '2. Enter the amount you want to pay in the central field (similar to the example above - 1000 UAH);',
    de: '2. Geben Sie den Betrag ein, den Sie bezahlen möchten, in das zentrale Feld ein (ähnlich wie im obigen Beispiel - 1000 UAH);',
    ru: '2. Введите сумму, которую вы хотите оплатить, в центральное поле (по аналогии с вышеуказанным примером - 1000 грн.);',
    fr: "2. Entrez le montant que vous souhaitez payer dans le champ central (similaire à l'exemple ci-dessus - 1000 UAH);",
    es: '2. Ingrese la cantidad que desea pagar en el campo central (similar al ejemplo anterior - 1000 UAH);',
    cs: '2. Zadejte částku, kterou chcete zaplatit, do centrálního pole (podobně jako v uvedeném příkladu - 1000 Kč);',
  },
  rulesBonusesText10: {
    ua: '3. Нижче, система вирахує максимальну суму списання бонусів з урахуванням Вашого балансу (по аналогії з вище приведеним прикладом 300 грн.);',
    en: '3. Below, the system will calculate the maximum amount of bonus deduction taking into account your balance (similar to the example above - 300 UAH);',
    de: '3. Darunter berechnet das System den maximalen Betrag für die Bonusabhebung unter Berücksichtigung Ihres Kontostands (ähnlich wie im obigen Beispiel - 300 UAH);',
    ru: '3. Ниже система рассчитает максимальную сумму списания бонусов с учетом вашего баланса (по аналогии с вышеуказанным примером - 300 грн.);',
    fr: "3. En dessous, le système calculera le montant maximal de déduction des bonus en fonction de votre solde (similaire à l'exemple ci-dessus - 300 UAH);",
    es: '3. A continuación, el sistema calculará la cantidad máxima de deducción de bonos teniendo en cuenta su saldo (similar al ejemplo anterior - 300 UAH);',
    cs: '3. Níže systém vypočítá maximální částku odečtu bonusů s ohledem na váš zůstatek (podobně jako v uvedeném příkladu - 300 Kč);',
  },
  rulesBonusesText11: {
    ua: '4. Натисніть кнопку «Сплатити бонусами». Після цього з Вашого бонусного рахунку спишеться сума бонусів, що відобразиться в історії транзакцій;',
    en: '4. Click the «Pay with Bonuses» button. After that, the amount of bonuses will be debited from your bonus account, which will be reflected in the transaction history;',
    de: '4. Klicken Sie auf die Schaltfläche «Mit Boni bezahlen». Danach wird der Betrag der Bonusabhebung von Ihrem Bonuskonto abgebucht, was im Transaktionsverlauf angezeigt wird;',
    ru: '4. Нажмите кнопку «Оплатить бонусами». После этого с вашего бонусного счета спишется сумма бонусов, что отразится в истории транзакций;',
    fr: "4. Cliquez sur le bouton «Payer avec des bonus». Ensuite, le montant des bonus sera débité de votre compte bonus, ce qui sera reflété dans l'historique des transactions;",
    es: '4. Haga clic en el botón «Pagar con bonos». Después de eso, la cantidad de bonos se debitará de su cuenta de bonificación, lo que se reflejará en el historial de transacciones;',
    cs: '4. Klikněte na tlačítko «Platba pomocí bonusů». Poté bude z vašeho bonusového účtu odečtena částka bonusů, což se zobrazí v historii transakcí;',
  },
  rulesBonusesText12: {
    ua: '5. Сплатіть решту суми готівкою або безготівковим переказом (по аналогії з вище приведеним прикладом 700 грн.) та не забудьте сповістити про це свого куратора по справі.',
    en: '5. Pay the rest of the amount in cash or by bank transfer (similar to the example above - 700 UAH) and do not forget to notify your case manager about this.',
    de: '5. Zahlen Sie den Restbetrag bar oder per Banküberweisung (ähnlich wie im obigen Beispiel - 700 UAH) und vergessen Sie nicht, Ihren Sachbearbeiter darüber zu informieren.',
    ru: '5. Оплатите оставшуюся сумму наличными или банковским переводом (по аналогии с вышеуказанным примером - 700 грн) и не забудьте уведомить вашего куратора по делу.',
    fr: "5. Payez le montant restant en espèces ou par virement bancaire (similaire à l'exemple ci-dessus - 700 UAH) et n'oubliez pas de prévenir votre tuteur de dossier.",
    es: '5. Pague el monto restante en efectivo o mediante transferencia bancaria (similar al ejemplo anterior - 700 UAH) y no olvide notificar a su curador de caso.',
    cs: '5. Zaplaťte zbývající částku v hotovosti nebo bankovním převodem (podobně jako v uvedeném příkladu - 700 Kč) a nezapomeňte informovat svého správce případu.',
  },

  // calculation of the limit
  clientIDRulesCalculationLimit: {
    ua: 'Вам нараховується бонусний ліміт',
    en: 'You are entitled to a bonus limit',
    de: 'Es wird Ihnen ein Bonuslimit gutgeschrieben',
    ru: 'Вам начисляется бонусный лимит',
    fr: 'Vous avez droit à une limite de bonus',
    es: 'Tiene derecho a un límite de bonificación',
    cs: 'Máte nárok na bonusový limit',
  },
  // Terms Rules (almost everything from the previous rules)
  termsRulesPanelTitle1: {
    ua: 'Вартість юридичної страховки?',
    en: 'Cost of legal insurance?',
    de: 'Kosten der Rechtsversicherung?',
    ru: 'Стоимость юридической страховки?',
    fr: "Coût de l'assurance juridique?",
    es: 'Costo del seguro legal?',
    cs: 'Cena právního pojištění?',
  },
  termsRulesPanelTitle2: {
    ua: 'Прайс',
    en: 'Price',
    de: 'Preis',
    ru: 'Цена',
    fr: 'Prix',
    es: 'Precio',
    cs: 'Cena',
  },
  termsRulesPanelText1_1: {
    ua: 'При оформлені юридичної страховки, Адвокатське об’єднання «Захист» видає Вам',
    en: 'When applying for legal insurance, the Advocacy Association "Protection" issues you a',
    de: 'Bei Abschluss einer Rechtsschutzversicherung stellt Ihnen der Anwaltsverband "Schutz" eine',
    ru: 'При оформлении юридической страховки, Адвокатское объединение «Защита» выдает вам',
    fr: "Lors de la souscription d'une assurance juridique, l'association d'avocats \"Protection\" vous remet",
    es: 'Al solicitar un seguro legal, la Asociación de Abogados "Protección" le emite una',
    cs: 'Při žádosti o právní pojištění vám Advokátní sdružení "Ochrana" vydává',
  },
  termsRulesPanelText1_2: {
    ua: 'Клубну карту, на яку нараховує кошти',
    en: 'Club card, on which funds are credited',
    de: 'Clubkarte, auf die Gelder gutgeschrieben werden',
    ru: 'Клубную карту, на которую начисляются средства',
    fr: 'Carte de club, sur laquelle des fonds sont crédités',
    es: 'Tarjeta de club, en la que se acreditan fondos',
    cs: 'Členskou kartu, na kterou jsou připsány prostředky',
  },
  termsRulesPanelText1_3: {
    ua: 'для оплати юридичних послуг при ДТП, або складенні протоколу по факту правопорушення.',
    en: 'for payment of legal services in case of a traffic accident or for drawing up a protocol for the offense.',
    de: 'zur Zahlung von Rechtsdienstleistungen im Falle eines Verkehrsunfalls oder zur Erstellung eines Protokolls über die Ordnungswidrigkeit.',
    ru: 'для оплаты юридических услуг при ДТП или составлении протокола по факту правонарушения.',
    fr: "pour le paiement des services juridiques en cas d'accident de la route ou pour établir un procès-verbal d'infraction.",
    es: 'para el pago de servicios legales en caso de un accidente de tráfico o para redactar un protocolo sobre la infracción.',
    cs: 'na úhradu právních služeb v případě dopravní nehody nebo pro sepsání protokolu o přestupku.',
  },
  termsRulesPanelText2: {
    ua: 'Вказаними сумами Ви маєте право розраховуватися за послуги Адвокатського об’єднання «Захист» у випадку настання ДТП, або складення протоколу по факту іншого порушення ПДР, яке мало місце не раніше наступного календарного дня після укладання Договору юридичної страховки та до закінчення відповідного строку дії Договору.',
    ru: 'Указанными суммами Вы имеете право рассчитываться за услуги Адвокатского объединения «Защита» в случае наступления ДТП или составления протокола по факту другого нарушения ПДД, которое имело место не ранее следующего календарного дня после заключения Договора юридической страховки и до окончания соответствующего срока действия Договора.',
    de: 'Mit den angegebenen Beträgen haben Sie das Recht, sich für die Dienstleistungen des Anwaltsverbandes "Schutz" im Falle eines Unfalls oder der Erstellung eines Protokolls für eine andere Verkehrsverletzung zu entscheiden, die nicht früher als der nächste Kalendertag nach Abschluss des Rechtsschutzversicherungsvertrags und vor Ablauf der jeweiligen Vertragslaufzeit aufgetreten ist.',
    en: 'With the specified amounts, you have the right to settle for the services of the Advocacy Association "Protection" in case of an accident or drafting a protocol for another traffic violation, which occurred no earlier than the next calendar day after concluding the legal insurance contract and before the end of the respective contract period.',
    fr: "Avec les montants spécifiés, vous avez le droit de régler pour les services de l'Association d'Avocats \"Protection\" en cas d'accident ou de rédaction d'un protocole pour une autre infraction au code de la route, survenue au plus tôt le lendemain calendaire suivant la conclusion du contrat d'assurance juridique et avant la fin de la période de validité du contrat.",
    es: 'Con los montos especificados, tiene derecho a liquidar por los servicios de la Asociación de Abogados "Protección" en caso de un accidente o la redacción de un protocolo por otra infracción de tráfico, que ocurrió no antes del día calendario siguiente a la conclusión del contrato de seguro legal y antes de la finalización del período respectivo del contrato.',
    cs: 'S uvedenými částkami máte právo vyrovnat se za služby Advokátní asociace "Ochrana" v případě nehody nebo vypracování protokolu o jiném porušení dopravních předpisů, ke kterému došlo nejdříve následující kalendářní den po uzavření smlouvy o právním pojištění a před uplynutím příslušného období platnosti smlouvy.',
  },
  termsRulesPanelText3: {
    ua: 'На підставі Договору, ми надаємо Вам юридичні послуги щодо захисту Ваших прав та законних інтересів, в процесі чого з Вашого рахунку Клубної карти відбувається грошове списання відповідно до Прайс-листа, зазначеного нижче.',
    ru: 'На основании Договора мы предоставляем Вам юридические услуги по защите Ваших прав и законных интересов, в процессе чего с Вашего счета Клубной карты происходит списание денег в соответствии с указанным ниже Прайс-листом.',
    de: 'Auf der Grundlage des Vertrags bieten wir Ihnen rechtliche Dienstleistungen zum Schutz Ihrer Rechte und legitimen Interessen an, bei denen Geld von Ihrem Clubkartenkonto gemäß der unten angegebenen Preisliste abgebucht wird.',
    en: 'Based on the Agreement, we provide you with legal services to protect your rights and legitimate interests, during which money is debited from your Club Card account according to the Price List specified below.',
    fr: "Sur la base de l'accord, nous vous fournissons des services juridiques pour protéger vos droits et intérêts légitimes, au cours desquels de l'argent est débité de votre compte de Carte de Club selon le Tarif indiqué ci-dessous.",
    es: 'Con base en el Acuerdo, le proporcionamos servicios legales para proteger sus derechos e intereses legítimos, durante los cuales se debita dinero de su cuenta de Tarjeta de Club de acuerdo con la Lista de Precios especificada a continuación.',
    cs: 'Na základě smlouvy vám poskytujeme právní služby na ochranu vašich práv a zákonných zájmů, přičemž z vašeho účtu Klubu karty dochází k debetu peněz podle níže uvedeného Ceníku.',
  },
  // bonusesModal ============
  bonusesModalTitle: {
    ua: 'Використати бонуси',
    ru: 'Использовать бонусы',
    de: 'Bonusse verwenden',
    en: 'Use Bonuses',
    fr: 'Utiliser des bonus',
    es: 'Usar Bonos',
    cs: 'Použít bonusy',
  },
  bonusesModalText: {
    ua: 'Для використання бонусів потрібно стати Клієнтом АО «Захист»',
    ru: 'Для использования бонусов нужно стать Клиентом АО «Защита»',
    de: 'Um Boni zu verwenden, müssen Sie Kunde des «Zahist» Anwaltsverbandes werden',
    en: 'To use bonuses, you need to become a client of the «Zahist» Advocacy Association',
    fr: "Pour utiliser des bonus, vous devez devenir client de l'Association d'Avocats «Zahist»",
    es: 'Para usar los bonos, debes convertirte en cliente de la Asociación de Abogados «Zahist»',
    cs: 'Abyste mohli použít bonusy, musíte se stát klientem Advokátní asociace «Zahist»',
  },
  // PriceList Modal ============
  priceListModalTitle: {
    ua: 'Прайс лист',
    ru: 'Прайс-лист',
    de: 'Preisliste',
    en: 'Price List',
    fr: 'Liste des prix',
    es: 'Lista de precios',
    cs: 'Ceník',
  },
  priceListModalText1: {
    ua: 'Консультація адвоката по ДТП',
    ru: 'Консультация адвоката по ДТП',
    de: 'Beratung durch einen Anwalt bei Verkehrsunfällen',
    en: 'Consultation with a lawyer on road accidents',
    fr: 'Consultation avec un avocat sur les accidents de la route',
    es: 'Consulta con un abogado sobre accidentes de tráfico',
    cs: 'Konzultace s právníkem ohledně dopravních nehod',
  },
  priceListModalText2: {
    ua: 'Вивчення документів та формування правової позиції по справі',
    ru: 'Изучение документов и формирование правовой позиции по делу',
    de: 'Studium von Dokumenten und Bildung einer rechtlichen Position zum Fall',
    en: 'Study of documents and formation of a legal position on the case',
    fr: "Étude des documents et formation d'une position juridique sur l'affaire",
    es: 'Estudio de documentos y formación de una posición legal sobre el caso',
    cs: 'Studie dokumentů a vytvoření právní pozice k případu',
  },
  priceListModalText3: {
    ua: 'Ознайомлення з матеріалами справи',
    ru: 'Знакомство с материалами дела',
    de: 'Vertrautmachen mit Fallmaterialien',
    en: 'Familiarization with case materials',
    fr: "Familiarisation avec les documents de l'affaire",
    es: 'Familiarización con los materiales del caso',
    cs: 'Seznámení s materiály případu',
  },
  priceListModalText4_1: {
    ua: 'Досудове врегулювання спору – від',
    ru: 'Досудебное урегулирование спора – от',
    de: 'Außergerichtliche Beilegung des Streits - ab',
    en: 'Pre-trial settlement of the dispute - from',
    fr: 'Règlement amiable du litige - à partir de',
    es: 'Solución extrajudicial de la disputa - desde',
    cs: 'Mimosoudní vyřešení sporu - od',
  },
  priceListModalText4_2: {
    ua: 'в залежності від складності справи;',
    ru: 'в зависимости от сложности дела;',
    de: 'abhängig von der Komplexität des Falles;',
    en: 'depending on the complexity of the case;',
    fr: "en fonction de la complexité de l'affaire;",
    es: 'dependiendo de la complejidad del caso;',
    cs: 'v závislosti na složitosti případu;',
  },
  priceListModalText5_1: {
    ua: 'Фіксований гонорар за ведення адміністративної справи по ДТП',
    ru: 'Фиксированный гонорар за ведение административного дела по ДТП',
    de: 'Feste Gebühr für die Bearbeitung von Verwaltungsverfahren bei Verkehrsunfällen',
    en: 'Fixed fee for handling administrative proceedings on road accidents',
    fr: 'Frais fixes pour le traitement des procédures administratives concernant les accidents de la route',
    es: 'Tarifa fija por la tramitación de procedimientos administrativos sobre accidentes de tráfico',
    cs: 'Fixační poplatek za zpracování správních řízení o dopravních nehodách',
  },
  priceListModalText6_1: {
    ua: 'Фіксований гонорар за ведення кримінальної справи по ДТП',
    ru: 'Фиксированный гонорар за ведение уголовного дела по ДТП',
    de: 'Feste Gebühr für die Bearbeitung von Strafverfahren bei Verkehrsunfällen',
    en: 'Fixed fee for handling criminal proceedings on road accidents',
    fr: 'Frais fixes pour le traitement des procédures pénales concernant les accidents de la route',
    es: 'Tarifa fija por la tramitación de procedimientos penales sobre accidentes de tráfico',
    cs: 'Fixační poplatek za zpracování trestních řízení o dopravních nehodách',
  },
  priceListModalText56_2: {
    ua: 'на місяць на поштово-канцелярські витрати;',
    ru: 'в месяц на почтово-канцелярские расходы;',
    de: 'pro Monat für Porto- und Büromaterialkosten;',
    en: 'per month for postage and stationery expenses;',
    fr: 'par mois pour les frais de poste et de papeterie;',
    es: 'por mes para gastos de correo y papelería;',
    cs: 'měsíčně na poštovné a kancelářské potřeby;',
  },
  priceListModalText7_1: {
    ua: 'Фіксований гонорар за ведення адміністративної справи щодо оскарження протоколу по факту іншого порушення ПДР',
    ru: 'Фиксированный гонорар за ведение административного дела по оспариванию протокола по факту другого нарушения ПДД',
    de: 'Feste Gebühr für die Bearbeitung von Verwaltungsverfahren zur Anfechtung des Protokolls einer anderen Verkehrsverletzung',
    en: 'Fixed fee for handling administrative proceedings challenging the protocol of another traffic violation',
    fr: "Frais fixes pour le traitement des procédures administratives contestant le protocole d'une autre infraction au code de la route",
    es: 'Tarifa fija por la tramitación de procedimientos administrativos desafiando el protocolo de otra infracción de tráfico',
    cs: 'Fixační poplatek za zpracování správních řízení pro zpochybnění protokolu o jiném porušení dopravních předpisů',
  },
  priceListModalText7_2: {
    ua: 'на місяць на поштово-канцелярські витрати;',
    ru: 'в месяц на почтово-канцелярские расходы;',
    de: 'pro Monat für Porto- und Büromaterialkosten;',
    en: 'per month for postage and stationery expenses;',
    fr: 'par mois pour les frais de poste et de papeterie;',
    es: 'por mes para gastos de correo y papelería;',
    cs: 'měsíčně na poštovné a kancelářské potřeby;',
  },
  priceListModalText8_1: {
    ua: 'Виїзд адвоката на місце пригоди',
    ru: 'Выезд адвоката на место происшествия',
    de: 'Besuch des Anwalts am Unfallort',
    en: `Lawyer's visit to the scene of the accident`,
    fr: "Visite de l'avocat sur les lieux de l'accident",
    es: 'Visita del abogado al lugar del accidente',
    cs: 'Návštěva právníka na místě nehody',
  },
  // costsForTransport
  priceListModalText9_1: {
    ua: 'Підготовка юридичного документу – від',
    ru: 'Подготовка юридического документа – от',
    de: 'Vorbereitung des Rechtsdokuments - ab',
    en: 'Preparation of legal document – from',
    fr: 'Préparation de document juridique – à partir de',
    es: 'Preparación de documento legal – desde',
    cs: 'Příprava právního dokumentu – od',
  },
  priceListModalText9_2: {
    ua: 'в залежності від складності;',
    ru: 'в зависимости от сложности;',
    de: 'abhängig von der Komplexität;',
    en: 'depending on the complexity;',
    fr: 'en fonction de la complexité;',
    es: 'dependiendo de la complejidad;',
    cs: 'v závislosti na složitosti;',
  },
  priceListModalText10: {
    ua: 'Підготовка позовної заяви',
    ru: 'Подготовка искового заявления',
    de: 'Vorbereitung der Klageschrift',
    en: 'Preparation of statement of claim',
    fr: 'Préparation de la déclaration de créance',
    es: 'Preparación de la demanda',
    cs: 'Příprava žaloby',
  },
  priceListModalText11: {
    ua: 'Підготовка апеляційної скарги',
    ru: 'Подготовка апелляционной жалобы',
    de: 'Vorbereitung der Berufungsbegründung',
    en: 'Preparation of appellate complaint',
    fr: 'Préparation du recours',
    es: 'Preparación de la apelación',
    cs: 'Příprava odvolání',
  },
  priceListModalText12: {
    ua: 'Підготовка касаційної скарги',
    ru: 'Подготовка кассационной жалобы',
    de: 'Vorbereitung der Revisionsschrift',
    en: 'Preparation of cassation complaint',
    fr: 'Préparation du pourvoi en cassation',
    es: 'Preparación del recurso de casación',
    cs: 'Příprava kasační stížnosti',
  },
  priceListModalText13: {
    ua: 'Вихід адвоката (до поліції, в суд, тощо)',
    ru: 'Выезд адвоката (в полицию, в суд и т.д.)',
    de: 'Auftritt des Anwalts (bei der Polizei, vor Gericht usw.)',
    en: `Lawyer's appearance (to the police, to court, etc.)`,
    fr: "Apparition de l'avocat (à la police, au tribunal, etc.)",
    es: 'Aparición del abogado (ante la policía, ante el tribunal, etc.)',
    cs: 'Vystoupení právníka (u policie, u soudu atd.)',
  },
  priceListModalText14: {
    ua: 'Проведення претензійної роботи з опонентом',
    ru: 'Проведение претензионной работы с оппонентом',
    de: 'Durchführung von Anspruchsarbeit mit dem Gegner',
    en: 'Conducting claim work with the opponent',
    fr: "Réalisation de travaux de réclamation avec l'opposant",
    es: 'Realización de trabajos de reclamación con el oponente',
    cs: 'Provádění nárokové práce s protivníkem',
  },
  priceListModalText15_1: {
    ua: 'Участь адвоката в слідчих діях',
    ru: 'Участие адвоката в следственных действиях',
    de: `Teilnahme des Anwalts an Ermittlungshandlungen`,
    en: `Lawyer's participation in investigative actions`,
    fr: "Participation de l'avocat aux actes d'enquête",
    es: 'Participación del abogado en acciones de investigación',
    cs: 'Účast právníka na vyšetřovacích akcích',
  },
  priceListModalText15_2: {
    ua: 'у годину;',
    ru: 'в час;',
    de: 'pro Stunde;',
    en: 'per hour;',
    fr: 'par heure;',
    es: 'por hora;',
    cs: 'za hodinu;',
  },
  priceListModalText16: {
    ua: 'Інші послуги надаються та тарифікуються за згодою сторін.',
    ru: 'Другие услуги предоставляются и тарифицируются по согласованию сторон.',
    de: 'Andere Dienstleistungen werden auf Vereinbarung der Parteien erbracht und berechnet.',
    en: 'Other services are provided and charged upon agreement of the parties.',
    fr: "D'autres services sont fournis et facturés sur accord des parties.",
    es: 'Otros servicios se proporcionan y se cobran de acuerdo con lo acordado por las partes.',
    cs: 'Další služby jsou poskytovány a účtovány na základě dohody stran.',
  },

  // costsForTransport

  // buttons
  sendButton: {
    ua: 'Відправити',
    ru: 'Отправить',
    de: 'Senden',
    en: 'Send',
    fr: 'Envoyer',
    es: 'Enviar',
    cs: 'Odeslat',
  },
  confirmButton: {
    ua: 'Підтвердити',
    ru: 'Подтвердить',
    de: 'Bestätigen',
    en: 'Confirm',
    fr: 'Confirmer',
    es: 'Confirmar',
    cs: 'Potvrdit',
  },
  changePhoneButton: {
    ua: 'Змінити номер',
    ru: 'Изменить номер',
    de: 'Nummer ändern',
    en: 'Change number',
    fr: 'Changer de numéro',
    es: 'Cambiar número',
    cs: 'Změnit číslo',
  },
  consultationButton: {
    ua: 'Записатись на консультацію',
    ru: 'Записаться на консультацию',
    de: 'Einen Beratungstermin vereinbaren',
    en: 'Book a consultation',
    fr: 'Réserver une consultation',
    es: 'Reservar una consulta',
    cs: 'Zarezervovat konzultaci',
  },
  consultationButton2: {
    ua: 'Записатись на консультацію в додатку',
    ru: 'Записаться на консультацию в приложении',
    de: 'Beratungstermin vereinbaren in der Anwendung',
    en: 'Book a consultation in the application',
    fr: "Réserver une consultation dans l'application",
    es: 'Reservar una consulta en la aplicación',
    cs: 'Zarezervovat konzultaci v aplikaci',
  },
  viewCert: {
    ua: 'Переглянути',
    ru: 'Просмотреть',
    de: 'Sehen',
    en: 'Review',
    fr: 'Aperçu',
    es: 'Revisar',
    cs: 'Zobrazit',
  },
  payBtn: {
    ua: 'Оплатити',
    ru: 'Оплатить',
    de: 'Bezahlen',
    en: 'Pay',
    fr: 'Payer',
    es: 'Pagar',
    cs: 'Platba',
  },
  payWithBonusesBtn: {
    ua: 'Оплатити бонусами',
    ru: 'Оплатить бонусами',
    de: 'Bezahlen Sie mit Boni',
    en: 'Pay with bonuses',
    fr: 'Payer avec des bonus',
    es: 'Pagar con bonos',
    cs: 'Platba bonusy',
  },
  cnlBtn: {
    ua: 'Скасувати',
    ru: 'Отменить',
    de: 'Stornieren',
    en: 'Cancel',
    fr: 'Annuler',
    es: 'Cancelar',
    cs: 'Zrušit',
  },
  callBtn: {
    ua: 'Подзвонити',
    ru: 'Позвонить',
    de: 'Anrufen',
    en: 'Call',
    fr: 'Appeler',
    es: 'Llamar',
    cs: 'Zavolat',
  },
  sendFileBtn: {
    ua: 'Відправити файл',
    ru: 'Отправить файл',
    de: 'Datei senden',
    en: 'Send file',
    fr: 'Envoyer le fichier',
    es: 'Enviar archivo',
    cs: 'Odeslat soubor',
  },
  uploadBtn: {
    ua: 'Завантажити',
    ru: 'Загрузить',
    de: 'Hochladen',
    en: 'Upload',
    fr: 'Télécharger',
    es: 'Subir',
    cs: 'Nahrát',
  },
  changePhoneBtn: {
    ua: 'Змінити телефон',
    ru: 'Изменить телефон',
    de: 'Telefonnummer ändern',
    en: 'Change phone',
    fr: 'Changer de téléphone',
    es: 'Cambiar teléfono',
    cs: 'Změnit telefon',
  },
  changeEmailBtn: {
    ua: 'Змінити email',
    ru: 'Изменить email',
    de: 'E-Mail ändern',
    en: 'Change email',
    fr: "Changer l'email",
    es: 'Cambiar email',
    cs: 'Změnit email',
  },
  uploadFileBtn: {
    ua: 'Завантажити файл',
    ru: 'Загрузить файл',
    de: 'Datei hochladen',
    en: 'Upload file',
    fr: 'Télécharger le fichier',
    es: 'Subir archivo',
    cs: 'Nahrát soubor',
  },
  deleteBtn: {
    ua: 'Видалити',
    ru: 'Удалить',
    de: 'Löschen',
    en: 'Delete',
    fr: 'Supprimer',
    es: 'Eliminar',
    cs: 'Smazat',
  },
  agreeBtn: {
    ua: 'Погодитись',
    ru: 'Согласиться',
    de: 'Zustimmen',
    en: 'Agree',
    fr: 'Accepter',
    es: 'Aceptar',
    cs: 'Souhlasit',
  },
  inviteBtn: {
    ua: 'Запросити',
    ru: 'Пригласить',
    de: 'Einladen',
    en: 'Invite',
    fr: 'Inviter',
    es: 'Invitar',
    cs: 'Pozvat',
  },
  // clientButtons
  clientBtn1: {
    ua: 'Правила',
    ru: 'Правила',
    de: 'Regeln',
    en: 'Rules',
    fr: 'Règles',
    es: 'Reglas',
    cs: 'Pravidla',
  },
  clientBtn2_1: {
    ua: 'Розрахунок бонусами',
    ru: 'Расчет бонусами',
    de: 'Bonusberechnung',
    en: 'Bonus Calculation',
    fr: 'Calcul des bonus',
    es: 'Cálculo de bonificaciones',
    cs: 'Výpočet bonusů',
  },
  clientBtn2_2: {
    ua: 'Прайс лист',
    ru: 'Прайс лист',
    de: 'Preisliste',
    en: 'Price List',
    fr: 'Liste de prix',
    es: 'Lista de precios',
    cs: 'Ceník',
  },
  clientBtn3: {
    ua: 'Телефон',
    ru: 'Телефон',
    de: 'Telefon',
    en: 'Phone',
    fr: 'Téléphone',
    es: 'Teléfono',
    cs: 'Telefon',
  },

  // navigation
  backToTheSite: {
    ua: 'Повернутися на сайт',
    ru: 'Вернуться на сайт',
    de: 'Zurück zur Website',
    en: 'Return to website',
    fr: 'Retourner au site',
    es: 'Regresar al sitio web',
    cs: 'Vrátit se na web',
  },
  exitTheApplication: {
    ua: 'Вийти з додатку',
    ru: 'Выйти из приложения',
    de: 'Die Anwendung verlassen',
    en: 'Exit the application',
    fr: "Quitter l'application",
    es: 'Salir de la aplicación',
    cs: 'Opustit aplikaci',
  },
  back: {
    ua: 'Назад',
    ru: 'Назад',
    de: 'Zurück',
    en: 'Back',
    fr: 'Retour',
    es: 'Atrás',
    cs: 'Zpět',
  },

  // home page
  clubCard: {
    ua: 'Клубна карта',
    ru: 'Клубная карта',
    de: 'Clubkarte',
    en: 'Club card',
    fr: 'Carte de club',
    es: 'Tarjeta del club',
    cs: 'Členská karta',
  },
  news: {
    ua: 'Новини',
    ru: 'Новости',
    de: 'Nachrichten',
    en: 'News',
    fr: 'Nouvelles',
    es: 'Noticias',
    cs: 'Novinky',
  },
  webinars: {
    ua: 'Вебінари',
    ru: 'Вебинары',
    de: 'Webinare',
    en: 'Webinars',
    fr: 'Webinaires',
    es: 'Seminarios web',
    cs: 'Webináře',
  },
  freeConsultations: {
    ua: 'Безкоштовні консультації',
    ru: 'Бесплатные консультации',
    de: 'Kostenlose Beratung',
    en: 'Free Consultations',
    fr: 'Consultations gratuites',
    es: 'Consultas gratuitas',
    cs: 'Bezplatné konzultace',
  },
  office: {
    ua: 'Офіси',
    ru: 'Офисы',
    de: 'Büros',
    en: 'Offices',
    fr: 'Bureaux',
    es: 'Oficinas',
    cs: 'Kanceláře',
  },

  //wanted block
  WantedSearchedStatus: {
    ua: 'Ваше ім’я в розшуку!',
    ru: 'Ваше имя в розыске!',
    de: 'Ihr Name wird gesucht!',
    en: 'Your name is wanted!',
    fr: 'Votre nom est recherché!',
    es: '¡Su nombre está en busca!',
    cs: 'Vaše jméno je hledáno!',
  },
  WantedNotSearchedStatus: {
    ua: 'Ви не в розшуку!',
    ru: 'Вы не в розыске!',
    de: 'Sie werden nicht gesucht!',
    en: 'You are not wanted!',
    fr: "Vous n'êtes pas recherché!",
    es: '¡No está en busca!',
    cs: 'Nejste hledán!',
  },
  Region: {
    ua: 'Регіон',
    ru: 'Регион',
    de: 'Region',
    en: 'Region',
    fr: 'Région',
    es: 'Región',
    cs: 'Region',
  },
  LostDate: {
    ua: 'Дата зникнення',
    ru: 'Дата исчезновения',
    de: 'Datum des Verschwindens',
    en: 'Date of disappearance',
    fr: 'Date de disparition',
    es: 'Fecha de desaparición',
    cs: 'Datum zmizení',
  },
  Category: {
    ua: 'Категорія',
    ru: 'Категория',
    de: 'Kategorie',
    en: 'Category',
    fr: 'Catégorie',
    es: 'Categoría',
    cs: 'Kategorie',
  },
  Article: {
    ua: 'Стаття',
    ru: 'Статья',
    de: 'Artikel',
    en: 'Article',
    fr: 'Article',
    es: 'Artículo',
    cs: 'Článek',
  },
  Updated: {
    ua: 'Оновлено',
    ru: 'Обновлено',
    de: 'Aktualisiert',
    en: 'Updated',
    fr: 'Mis à jour',
    es: 'Actualizado',
    cs: 'Aktualizováno',
  },

  // notifications
  PrivacyPolicySuccess: {
    ua: 'Політика конфіденційності успішно підтверджена',
    ru: 'Политика конфиденциальности успешно подтверждена',
    de: 'Datenschutzrichtlinie erfolgreich bestätigt',
    en: 'Privacy policy successfully confirmed',
    fr: 'Politique de confidentialité confirmée avec succès',
    es: 'Política de privacidad confirmada con éxito',
    cs: 'Zásady ochrany osobních údajů byly úspěšně potvrzeny',
  },
  PrivacyPolicyFailure: {
    ua: 'Помилка підтвердження політики конфіденційності',
    ru: 'Ошибка подтверждения политики конфиденциальности',
    de: 'Fehler bei der Bestätigung der Datenschutzrichtlinie',
    en: 'Privacy Policy Confirmation Error',
    fr: 'Erreur de confirmation de la politique de confidentialité',
    es: 'Error de confirmación de la política de privacidad',
    cs: 'Chyba potvrzení zásad ochrany osobních údajů',
  },
  RegistrationSuccess: {
    ua: 'Дякуємо за реєстрацію',
    ru: 'Спасибо за регистрацию',
    de: 'Danke für die Registrierung',
    en: 'Thank you for registration',
    fr: 'Merci pour votre inscription',
    es: 'Gracias por registrarte',
    cs: 'Děkujeme za registraci',
  },
  changedConsultation: {
    ua: 'Зміни у вашій консультації',
    ru: 'Изменения в вашей консультации',
    de: 'Änderungen in Ihrer Beratung',
    en: 'Changes in your consultation',
    fr: 'Changements dans votre consultation',
    es: 'Cambios en su consulta',
    cs: 'Změny ve vaší konzultaci',
  },
  newConsultation: {
    ua: 'Призначена консультація',
    ru: 'Назначенная консультация',
    de: 'Bestellte Beratung',
    en: 'Appointed consultation',
    fr: 'Consultation prévue',
    es: 'Consulta programada',
    cs: 'Naplánovaná konzultace',
  },
  newContract: {
    ua: 'У Вас новий договір',
    ru: 'У вас новый договор',
    de: 'Sie haben einen neuen Vertrag',
    en: 'You have a new contract',
    fr: 'Vous avez un nouveau contrat',
    es: 'Tienes un nuevo contrato',
    cs: 'Máte novou smlouvu',
  },

  // MY CONSULTATIONS
  noConsultation: {
    ua: 'Консультації не призначені',
    ru: 'Консультации не назначены',
    de: 'Beratungen nicht geplant',
    en: 'Consultations not appointed',
    fr: 'Consultations non programmées',
    es: 'Consultas no programadas',
    cs: 'Konzultace nejsou naplánovány',
  },
  typeConsultation: {
    ua: 'Запис на',
    ru: 'Запись на',
    de: 'Termin für',
    en: 'Booking for',
    fr: 'Réservation pour',
    es: 'Reserva para',
    cs: 'Rezervace na',
  },
  okConsultation: {
    ua: 'Консультація відбулась',
    ru: 'Консультация прошла',
    de: 'Die Beratung fand statt',
    en: 'The consultation took place',
    fr: 'La consultation a eu lieu',
    es: 'La consulta tuvo lugar',
    cs: 'Konzultace proběhla',
  },
  missedConsultation: {
    ua: 'Консультація не відбулась',
    ru: 'Консультация не состоялась',
    de: 'Die Beratung hat nicht stattgefunden',
    en: 'Consultation did not take place',
    fr: "La consultation n'a pas eu lieu",
    es: 'La consulta no tuvo lugar',
    cs: 'Konzultace se nekonala',
  },
  inProgressConsultation: {
    ua: 'В опрацюванні',
    ru: 'В процессе',
    de: 'In Bearbeitung',
    en: 'In process',
    fr: 'En cours',
    es: 'En proceso',
    cs: 'Probíhá',
  },
  payConsultation: {
    ua: 'Оплатити консультацію',
    ru: 'Оплатить консультацию',
    de: 'Beratung bezahlen',
    en: 'Pay for consultation',
    fr: 'Payer pour la consultation',
    es: 'Pagar la consulta',
    cs: 'Platba za konzultaci',
  },
  payedConsultation: {
    ua: 'Консультація оплачена',
    ru: 'Консультация оплачена',
    de: 'Beratungsgebühr bezahlt',
    en: 'Consultation paid',
    fr: 'Consultation payée',
    es: 'Consulta pagada',
    cs: 'Konzultace zaplacena',
  },
  payedTag: {
    ua: 'Оплачено',
    ru: 'Оплачено',
    de: 'Bezahlt',
    en: 'Paid',
    fr: 'Payé',
    es: 'Pagado',
    cs: 'Zaplaceno',
  },
  quality: {
    ua: 'Оцінити якість консультації',
    ru: 'Оценить качество консультации',
    de: 'Die Qualität der Beratung bewerten',
    en: 'Evaluate the quality of consultation',
    fr: 'Évaluer la qualité de la consultation',
    es: 'Evaluar la calidad de la consulta',
    cs: 'Hodnotit kvalitu konzultace',
  },
  qualityOk: {
    ua: 'Дякуємо за Ваш відгук!',
    ru: 'Спасибо за ваш отзыв!',
    de: 'Vielen Dank für Ihr Feedback!',
    en: 'Thank you for your feedback!',
    fr: 'Merci pour vos retours!',
    es: '¡Gracias por tu opinión!',
    cs: 'Děkujeme za vaši zpětnou vazbu!',
  },
  tipsToTheLawyerTitle: {
    ua: 'Подяка юристу',
    ru: 'Благодарность юристу',
    de: 'Danke an den Anwalt',
    en: 'Thank you to the lawyer',
    fr: 'Merci au avocat',
    es: 'Agradecimiento al abogado',
    cs: 'Poděkování právníkovi',
  },
  giveTipsToLawyer: {
    ua: 'Залишити подяку юристу',
    ru: 'Оставить благодарность юристу',
    de: 'Dem Anwalt danken',
    en: 'Thank the lawyer',
    fr: "Remercier l'avocat",
    es: 'Agradecer al abogado',
    cs: 'Poděkovat právníkovi',
  },

  //QuestionnaireHotlineCertificate
  cert0: {
    ua: 'У вас {chn} сертифікати на \n безкоштовну консультацію',
    ru: 'У вас {chn} сертификатов на \n бесплатную консультацию',
    de: 'Sie haben {chn} Gutscheine \n für eine kostenlose Beratung',
    en: 'You have {chn} certificates for \n a free consultation',
    fr: 'Vous avez {chn} certificats pour \n une consultation gratuite',
    es: 'Tiene {chn} certificados para \n una consulta gratuita',
    cs: 'Máte {chn} certifikátů na \n bezplatnou konzultaci',
  },
  cert1: {
    ua: 'Вам було надано подарунковий сертифікат на',
    ru: 'Вам был предоставлен подарочный сертификат на',
    de: 'Ihnen wurde ein Geschenkgutschein für ',
    en: 'You have been provided with a gift certificate for ',
    fr: 'Vous avez reçu un certificat-cadeau pour ',
    es: 'Se le ha proporcionado un certificado de regalo para ',
    cs: 'Byl Vám poskytnut dárkový certifikát na ',
  },
  cert2: {
    ua: '3 безкоштовних юридичних консультацій.',
    ru: '3 бесплатные юридические консультации.',
    de: '3 kostenlose Rechtsberatungen zur Verfügung gestellt.',
    en: '3 free legal consultations.',
    fr: '3 consultations juridiques gratuites.',
    es: '3 consultas legales gratuitas.',
    cs: '3 bezplatné právní konzultace.',
  },
  cert3: {
    ua: 'Даним сертифікатом встановлюється право на отримання трьох безкоштовних юридичних консультацій в Адвокатському об`єднанні «Захист»',
    ru: 'Данным сертификатом устанавливается право на получение трех бесплатных юридических консультаций в Адвокатском объединении «Защита»',
    de: 'Dieses Zertifikat berechtigt zur Inanspruchnahme von drei kostenlosen Rechtsberatungen bei der Anwaltsvereinigung «Zahist»',
    en: 'This certificate entitles the holder to receive three free legal consultations at the Advocates Association «Zahist»',
    fr: "Ce certificat donne droit à trois consultations juridiques gratuites auprès de l'Association des Avocats «Zahist»",
    es: 'Este certificado da derecho a recibir tres consultas legales gratuitas en la Asociación de Abogados «Zahist»',
    cs: 'Tento certifikát opravňuje držitele k získání tří bezplatných právních konzultací u Advokátní asociace «Zahist»',
  },
  cert4: {
    ua: 'з сімейних, кредитних, земельних правовідносин, та правовідносин, які виникають у зв`язку з дорожньо-транспортною пригодою.',
    ru: 'по вопросам семейных, кредитных, земельных правотношений, а также правотношений, возникающих в связи с дорожно-транспортным происшествием.',
    de: 'zu Fragen des Familien-, Kredit-, Grundstücksrechts sowie zu Angelegenheiten, die im Zusammenhang mit Verkehrsunfällen stehen.',
    en: 'on matters relating to family, credit, land rights, and issues arising from road traffic accidents.',
    fr: "sur des questions relatives au droit de la famille, au crédit, au droit foncier et aux problèmes découlant d'accidents de la route.",
    es: 'sobre asuntos relacionados con la familia, los derechos crediticios, los derechos de la tierra y los problemas derivados de accidentes de tráfico.',
    cs: 'o otázkách týkajících se rodinného, ​​úvěrového a pozemkového práva, a otázek vyplývajících z dopravních nehod.',
  },
  cert5: {
    ua: 'Цей сертифікат можна самому використати, або подарувати будь-якій іншій особі. Рідні, друзі, знайомі тощо.',
    ru: 'Этот сертификат можно использовать самому или подарить любому другому человеку. Родным, друзьям, знакомым и т. д.',
    de: 'Dieses Zertifikat kann von sich selbst verwendet oder als Geschenk an jede andere Person weitergegeben werden. Familie, Freunde, Bekannte, usw.',
    en: 'This certificate can be used by oneself or given as a gift to any other person. Family, friends, acquaintances, etc.',
    fr: 'Ce certificat peut être utilisé par vous-même ou offert en cadeau à une autre personne. Famille, amis, connaissances, etc.',
    es: 'Este certificado puede ser utilizado por uno mismo o regalado a cualquier otra persona. Familia, amigos, conocidos, etc.',
    cs: 'Tento certifikát lze použít osobně nebo darovat jiné osobě. Rodina, přátelé, známí atd.',
  },
  cert6: {
    ua: 'Порядок та умови використання сертифіката описані в самому сертифікаті',
    ru: 'Порядок и условия использования сертификата описаны в самом сертификате',
    de: 'Die Bedingungen und Konditionen zur Verwendung des Zertifikats sind im Zertifikat selbst beschrieben.',
    en: 'The terms and conditions of use of the certificate are described within the certificate itself.',
    fr: "Les modalités et conditions d'utilisation du certificat sont décrites dans le certificat lui-même.",
    es: 'Los términos y condiciones de uso del certificado se describen en el propio certificado.',
    cs: 'Podmínky a podmínky používání certifikátu jsou popsány v samotném certifikátu.',
  },
  cert7: {
    ua: 'Переглянути сертифікат',
    ru: 'Посмотреть сертификат',
    de: 'Zertifikat anzeigen',
    en: 'View certificate',
    fr: 'Voir le certificat',
    es: 'Ver certificado',
    cs: 'Zobrazit certifikát',
  },

  //quickQuestion
  assistant: {
    ua: 'Помічник',
    ru: 'Ассистент',
    de: 'Assistent',
    en: 'Assistant',
    fr: 'Assistant',
    es: 'Asistente',
    cs: 'Asistent',
  },
  alert: {
    ua: 'Увага!',
    ru: 'Внимание!',
    de: 'Achtung!',
    en: 'Attention!',
    fr: 'Attention!',
    es: '¡Atención!',
    cs: 'Pozor!',
  },
  inputQuickQuestion: {
    ua: 'Напишіть повідомлення...',
    ru: 'Напишите сообщение...',
    de: 'Schreiben Sie eine Nachricht...',
    en: 'Write a message...',
    fr: 'Écrivez un message...',
    es: 'Escribe un mensaje...',
    cs: 'Napište zprávu...',
  },
  answerQuickQuestion: {
    ua: 'Оператор відповідає...',
    ru: 'Оператор отвечает...',
    de: 'Der Operator antwortet...',
    en: 'The operator is answering...',
    fr: "L'opérateur répond...",
    es: 'El operador está respondiendo...',
    cs: 'Operátor odpovídá...',
  },
  quickQuestionTitle1: {
    ua: 'Для використання чату завершіть реєстрацію.',
    ru: 'Для использования чата завершите регистрацию.',
    de: 'Um den Chat zu verwenden, vervollständigen Sie die Registrierung.',
    en: 'To use the chat, complete the registration.',
    fr: "Pour utiliser le chat, complétez l'inscription.",
    es: 'Para usar el chat, complete el registro.',
    cs: 'Chcete-li použít chat, dokončete registraci.',
  },
  quickQuestionsIntroTitle: {
    ua: 'Задайте своє питання!',
    ru: 'Задайте свой вопрос!',
    de: 'Stellen Sie Ihre Frage!',
    en: 'Ask your question!',
    fr: 'Posez votre question!',
    es: '¡Haz tu pregunta!',
    cs: 'Položte svou otázku!',
  },
  quickQuestionsIntroText: {
    ua: 'Мене звати єАдвокат. Я завжди на зв’язку, готова відповісти на Ваші питання.',
    ru: 'Меня зовут еАдвокат. Я всегда на связи, готова ответить на Ваши вопросы.',
    de: 'Mein Name ist eAnwalt. Ich bin immer erreichbar und bereit, Ihre Fragen zu beantworten.',
    en: 'My name is eLawyer. I am always online, ready to answer your questions.',
    fr: 'Je m’appelle eAvocat. Je suis toujours en ligne, prêt à répondre à vos questions.',
    es: 'Mi nombre es eAbogado. Siempre estoy en línea, listo para responder a tus preguntas.',
    cs: 'Jmenuji se eAdvokát. Jsem vždy online, připraven odpovědět na vaše otázky.',
  },

  //chatDozhimsAI
  chatDozhimsAIFirstMessage: {
    ua: `Дякуємо Вам за співпрацю з АО "Захист". Ми щиро цінуємо, що Ви довірили нам ведення Вашої справи. Нам було приємно допомогти Вам досягти успішного результату.
    Ми пишаємося тим, що змогли допомогти Вам, і сподіваємося, що зможемо зробити ще більше для Вас у майбутньому. Якщо у вас є ще питання або вам потрібна допомога, не соромтеся зв'язатися з нами. Дякуємо, що вибрали нас!`,
    ru: `Благодарим вас за сотрудничество с АО "Защита". Мы искренне ценим, что вы доверили нам ведение вашего дела. Нам было приятно помочь вам достичь успешного результата.
    Мы гордимся тем, что смогли помочь вам, и надеемся, что сможем сделать для вас еще больше в будущем. Если у вас есть дополнительные вопросы или вам нужна помощь, не стесняйтесь обращаться к нам. Спасибо, что выбрали нас!`,
    de: `Vielen Dank für die Zusammenarbeit mit der Anwaltskanzlei "Zahist". Wir schätzen es sehr, dass Sie uns Ihr Anliegen anvertraut haben. Es war uns eine Freude, Ihnen zu einem erfolgreichen Ergebnis zu verhelfen.
    Wir sind stolz darauf, Ihnen geholfen zu haben, und hoffen, dass wir in Zukunft noch mehr für Sie tun können. Wenn Sie weitere Fragen haben oder Hilfe benötigen, zögern Sie nicht, uns zu kontaktieren. Vielen Dank, dass Sie sich für uns entschieden haben!`,
    en: `Thank you for working with the Advocates Association "Zahist". We sincerely appreciate your trust in us to handle your case. It has been a pleasure to help you achieve a successful outcome.
    We take pride in being able to assist you and hope to do even more for you in the future. If you have any further questions or need assistance, please don't hesitate to reach out to us. Thank you for choosing us!`,
    fr: `Merci de travailler avec l'Association des Avocats "Zahist". Nous vous remercions sincèrement de votre confiance en nous pour gérer votre affaire. Ce fut un plaisir de vous aider à atteindre un résultat satisfaisant.
    Nous sommes fiers de pouvoir vous aider et espérons pouvoir faire encore plus pour vous à l'avenir. Si vous avez d'autres questions ou avez besoin d'aide, n'hésitez pas à nous contacter. Merci de nous avoir choisis !`,
    es: `Gracias por trabajar con la Asociación de Abogados "Zahist". Agradecemos sinceramente su confianza en nosotros para manejar su caso. Ha sido un placer ayudarle a lograr un resultado exitoso.
    Nos enorgullece poder ayudarle y esperamos poder hacer aún más por usted en el futuro. Si tiene más preguntas o necesita asistencia, no dude en comunicarse con nosotros. ¡Gracias por elegirnos!`,
    cs: `Děkujeme, že jste spolupracovali s Advokátní asociací „Zahist“. Opravu si vážíme, že jste nám svěřili vedení vašeho případu. Bylo nám potěšením pomoci vám dosáhnout úspěšného výsledku.
    Jsme hrdí na to, že jsme vám mohli pomoci, a doufáme, že pro vás budeme moci udělat ještě víc v budoucnu. Pokud máte další dotazy nebo potřebujete pomoc, neváhejte nás kontaktovat. Děkujeme, že jste si nás vybrali!`,
  },
  chatDozhimsAIFirstMessageConsultation: {
    ua: `Дякуємо за ваше звернення до Адвокатського об'єднання "Захист". Ми завжди готові надати Вам правову допомогу та консультації. Зверніть увагу, що для подальшої роботи з нами необхідно укласти договір. Будь ласка, зв'яжіться з нами для узгодження умов та деталей.
    З повагою,
    Адвокатське об'єднання "Захист"`,
    ru: `Спасибо за обращение в Юридическую ассоциацию "Защита". Мы всегда готовы предоставить вам юридическую помощь и консультации. Обратите внимание, что для продолжения работы с нами необходимо заключить соглашение. Пожалуйста, свяжитесь с нами для согласования условий и деталей.
    С уважением,
    Юридическая ассоциация "Защита"`,
    de: `Vielen Dank für Ihre Anfrage beim Anwaltsverband "Zakhyst". Wir sind stets bereit, Ihnen rechtliche Unterstützung und Beratungen zu bieten. Bitte beachten Sie, dass für die weitere Zusammenarbeit mit uns ein Vertrag abgeschlossen werden muss. Bitte kontaktieren Sie uns, um die Bedingungen und Details zu vereinbaren.
    Mit freundlichen Grüßen,
    Anwaltsverband "Zakhyst"`,
    en: 'Thank you for contacting the Law Association "Zakhyst". We are always ready to provide you with legal assistance and consultations. Please note that to continue working with us, it is necessary to conclude an agreement. Please contact us to agree on the terms and details. Sincerely, Law Association "Zakhyst"',
    fr: `Merci de contacter l'Association des avocats "Zakhyst". Nous sommes toujours prêts à vous fournir une assistance juridique et des consultations. Veuillez noter que pour continuer à travailler avec nous, il est nécessaire de conclure un accord. Veuillez nous contacter pour convenir des termes et des détails. Cordialement, Association des avocats "Zakhyst"`,
    es: `Gracias por contactar con la Asociación de Abogados "Zakhyst". Siempre estamos dispuestos a brindarle asistencia legal y consultas. Tenga en cuenta que para continuar trabajando con nosotros, es necesario concluir un acuerdo. Comuníquese con nosotros para acordar los términos y detalles. Atentamente, Asociación de Abogados "Zakhyst"`,
    cs: `Děkujeme, že jste kontaktovali Advokátní sdružení "Zakhyst". Vždy jsme připraveni poskytnout vám právní pomoc a poradenství. Všimněte si, že k dalšímu spolupráci s námi je nutné uzavřít smlouvu. Prosím, kontaktujte nás, abychom se dohodli na podmínkách a detailech. S pozdravem, Advokátní sdružení "Zakhyst"`,
  },

  chatDozhimsAIApologiesMessage: {
    ua: `Вибачте, звертайтесь на гарячу лінію.`,
    ru: `Извините, пожалуйста, обращайтесь на горячую линию.`,
    de: `Entschuldigung, bitte wenden Sie sich an die Hotline.`,
    en: 'Sorry, please contact the hotline.',
    fr: `Désolé, veuillez contacter la ligne directe.`,
    es: `Lo siento, por favor contacte a la línea directa.`,
    cs: `Omlouváme se, prosím, kontaktujte naši infolinku.`,
  },

  chatDozhimsAIFirstArchiveMessage: {
    ua: `Хочу висловити щиру вдячність за те, що Ви обрали Адвокатське об’єднання «Захист» для вирішення Вашої справи.
    Раді, що ми змогли досягти бажаного результату. Будь ласка, не соромтеся звертатися до нас у майбутньому.
    З повагою!
    Старший інспектор відділу оцінки якості обслуговування Клієнтів Адвокатського об’єднання «Захист»
    Гацелюк Андрій Володимирович`,
    ru: `Хочу выразить искреннюю благодарность за то, что Вы выбрали Юридическую ассоциацию "Защита" для решения Вашего дела.
    Мы рады, что смогли достичь желаемого результата. Пожалуйста, не стесняйтесь обращаться к нам в будущем.
    С уважением,
    Старший инспектор отдела оценки качества обслуживания клиентов Юридической ассоциации "Защита" Гацелюк Андрей Владимирович`,
    de: `Ich möchte Ihnen aufrichtigen Dank aussprechen, dass Sie sich für den Anwaltsverband "Zakhyst" entschieden haben, um Ihre Angelegenheit zu klären.
    Wir freuen uns, dass wir das gewünschte Ergebnis erzielen konnten. Bitte zögern Sie nicht, uns auch in Zukunft zu kontaktieren.
    Mit freundlichen Grüßen,
    Seniorinspektor des Kundenservicequalitätsbewertungsabteilung des Anwaltsverbands "Zakhyst" Hatseliuk Andrii Volodymyrovych`,
    en: `I want to express sincere gratitude for choosing the Law Association "Zakhyst" to handle your case.
    We are glad that we were able to achieve the desired result. Please, do not hesitate to contact us in the future.
    With respect, Senior Inspector of the Customer Service Quality Assessment Department of the Law Association "Zakhyst" Hatseliuk Andrii Volodymyrovych`,
    fr: `Je tiens à exprimer ma sincère gratitude pour avoir choisi l'Association des avocats "Zakhyst" pour traiter votre affaire.
    Nous sommes heureux d'avoir pu atteindre le résultat souhaité. N'hésitez pas à nous contacter à l'avenir.
    Avec respect, Inspecteur principal du département d'évaluation de la qualité du service client de l'Association des avocats "Zakhyst" Hatseliuk Andrii Volodymyrovych`,
    es: `Quiero expresar mi sincero agradecimiento por elegir la Asociación de Abogados "Zakhyst" para manejar su caso.
    Nos alegra haber podido lograr el resultado deseado. No dude en ponerse en contacto con nosotros en el futuro.
    Con respeto, Inspector Senior del Departamento de Evaluación de Calidad del Servicio al Cliente de la Asociación de Abogados "Zakhyst" Hatseliuk Andrii Volodymyrovych`,
    cs: `Chci vyjádřit svou upřímnou vděčnost za to, že jste si vybrali Advokátní sdružení "Zakhyst" pro vyřešení vaší záležitosti.
    Jsme rádi, že jsme mohli dosáhnout požadovaného výsledku. Neváhejte nás v budoucnu kontaktovat.
    S úctou, Senior inspektor oddělení hodnocení kvality zákaznického servisu Advokátního sdružení "Zakhyst" Hatseliuk Andrii Volodymyrovych`,
  },

  chatDozhimsAISecondArchiveMessage: {
    ua: `Вас вітає старший інспектор відділу оцінки якості обслуговування Клієнтів Адвокатського об’єднання «Захист» Гацелюк Андрій Володимирович.
    Якщо у Вас знову виникли юридичні питання або потреба в юридичних послугах, не соромтеся звертатися до нас.
    Безкоштовна гаряча лінія: (0800) 40-33-03
    Ми завжди готові допомогти Вам!`,
    ru: `Вас приветствует старший инспектор отдела оценки качества обслуживания клиентов Адвокатского объединения «Защита» Гацелюк Андрей Владимирович.
    Если у вас снова возникли юридические вопросы или необходимость в юридических услугах, не стесняйтесь обращаться к нам.
    Бесплатная горячая линия: (0800) 40-33-03
    Мы всегда готовы помочь вам!`,
    de: `Sie werden vom leitenden Inspektor des Kundenbetreuungsqualitätsbewertungsabteilungs des Anwaltsverbandes "Zakhyst" Hatseliuk Andrii Volodymyrovych begrüßt.
    Wenn Sie erneut rechtliche Fragen haben oder rechtliche Dienstleistungen benötigen, zögern Sie nicht, uns zu kontaktieren.
    Kostenlose Hotline: (0800) 40-33-03 Wir sind immer bereit, Ihnen zu helfen!`,
    en: `You are greeted by the Senior Inspector of the Customer Service Quality Assessment Department of the Law Association "Zakhyst" Hatseliuk Andrii Volodymyrovych.
    If you have legal questions again or need legal services, do not hesitate to contact us. Toll-free hotline: (0800) 40-33-03 We are always ready to help you!`,
    fr: `Vous êtes accueilli par le directeur principal du département d'évaluation de la qualité du service à la clientèle de l'association d'avocats "Zakhyst", Hatseliuk Andrii Volodymyrovych.
    Si vous avez de nouveau des questions juridiques ou besoin de services juridiques, n'hésitez pas à nous contacter.
    Ligne d'assistance gratuite : (0800) 40-33-03 Nous sommes toujours prêts à vous aider!`,
    es: `Le saluda el Inspector Senior del Departamento de Evaluación de Calidad del Servicio al Cliente de la Asociación de Abogados "Zakhyst", Hatseliuk Andrii Volodymyrovych.
    Si tiene preguntas legales nuevamente o necesita servicios legales, no dude en contactarnos. Línea directa gratuita: (0800) 40-33-03 ¡Siempre estamos listos para ayudarle!`,
    cs: `Vás vítá vedoucí inspektor oddělení hodnocení kvality zákaznického servisu Právnické asociace "Zakhyst" Hatseliuk Andrii Volodymyrovych.
    Pokud máte opět právní otázky nebo potřebujete právní služby, neváhejte nás kontaktovat.
    Bezplatná linka: (0800) 40-33-03 Vždy jsme připraveni vám pomoci!`,
  },

  chatDozhimsAIFirstBirthdayMessage: {
    ua: `від імені Адвокатського об’єднання «Захист», щиро вітаємо Вас з днем народження!
    Нехай цей день буде наповнений радістю, успіхами та великими досягненнями. Бажаємо Вам міцного здоров’я та безмежного щастя!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `От имени Адвокатского объединения «Защита» искренне поздравляем вас с днем рождения!
    Пусть этот день будет наполнен радостью, успехами и большими достижениями. Желаем вам крепкого здоровья и безмерного счастья!
    С уважением!
    Администрация АО «Защита»`,
    de: `Im Namen des Anwaltsverbandes "Zakhyst" gratulieren wir Ihnen herzlich zum Geburtstag! Möge dieser Tag voller Freude, Erfolg und großer Errungenschaften sein.
    Wir wünschen Ihnen beste Gesundheit und unermessliches Glück! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    en: `On behalf of the Law Association "Zakhyst", we sincerely congratulate you on your birthday! May this day be filled with joy, success, and great achievements.
    We wish you strong health and boundless happiness! With respect, Administration of the Law Association "Zakhyst"`,
    fr: `Au nom de l'association d'avocats "Zakhyst", nous vous félicitons sincèrement pour votre anniversaire ! Que ce jour soit rempli de joie, de succès et de grandes réalisations.
    Nous vous souhaitons une bonne santé et un bonheur sans limites ! Avec respect, Administration de l'association d'avocats "Zakhyst"`,
    es: `En nombre de la Asociación de Abogados "Zakhyst", le felicitamos sinceramente por su cumpleaños. ¡Que este día esté lleno de alegría, éxito y grandes logros!
    Le deseamos buena salud y felicidad infinita. Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Jménem Právnické asociace "Zakhyst" vám srdečně gratulujeme k narozeninám! Ať je tento den plný radosti, úspěchů a velkých úspěchů.
    Přejeme vám pevné zdraví a bezmeznou radost! S úctou, administrativa Právnické asociace "Zakhyst"`,
  },

  chatDozhimsAISecondBirthdayMessage: {
    ua: `прийміть наші вітання з днем народження!
    З нагоди Вашого святкового дня, хочемо висловити нашу щиру вдячність за довіру та співпрацю.
    Бажаємо Вам найяскравіших моментів у цей особливий день, а також незламної сили духу, яка буде вас супроводжувати на шляху досягнення усіх Ваших цілей.
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `Примите наши поздравления с днем рождения! В связи с вашим праздничным днем, хотим выразить нашу искреннюю благодарность за доверие и сотрудничество.
    Желаем вам самых ярких моментов в этот особенный день, а также непоколебимой силы духа, которая будет сопровождать вас на пути к достижению всех ваших целей.
    С уважением!
    Администрация АО «Защита»`,
    de: `Akzeptieren Sie unsere Geburtstagsgrüße! Zu diesem besonderen Anlass möchten wir Ihnen unseren aufrichtigen Dank für Ihr Vertrauen und Ihre Zusammenarbeit aussprechen.
    Wir wünschen Ihnen die schönsten Momente an diesem besonderen Tag sowie eine unerschütterliche geistige Stärke, die Sie auf dem Weg zur Erreichung all Ihrer Ziele begleiten wird.
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    en: `Accept our birthday greetings! On the occasion of your special day, we want to express our sincere gratitude for your trust and cooperation.
    We wish you the brightest moments on this special day, as well as unwavering strength of spirit to accompany you on the path to achieving all your goals.
    With respect, Administration of the Law Association "Zakhyst"`,
    fr: `Acceptez nos vœux d'anniversaire ! À l'occasion de votre jour spécial, nous tenons à exprimer notre sincère gratitude pour votre confiance et votre coopération.
    Nous vous souhaitons les moments les plus brillants en ce jour spécial, ainsi qu'une force d'esprit inébranlable qui vous accompagnera sur le chemin de la réalisation de tous vos objectifs.
    Avec respect, Administration de l'association d'avocats "Zakhyst"`,
    es: `¡Acepta nuestros saludos de cumpleaños! Con motivo de tu día especial, queremos expresar nuestra sincera gratitud por tu confianza y cooperación.
    Te deseamos los momentos más brillantes en este día especial, así como una fuerza de espíritu inquebrantable que te acompañe en el camino hacia la consecución de todos tus objetivos.
    Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Přijměte naše narozeninové pozdravy! Při příležitosti vašeho speciálního dne chceme vyjádřit naši upřímnou vděčnost za vaši důvěru a spolupráci.
    Přejeme vám nejjasnější okamžiky v tento výjimečný den, stejně jako neochvějnou sílu ducha, která vás bude provázet na cestě k dosažení všech vašich cílů.
    S úctou, administrativa Právnické asociace "Zakhyst"`,
  },

  chatDozhimsAIFirstNewYearMessage: {
    ua: `Вітаємо Вас з Новим роком 🎉🎊
    Бажаємо, щоб цей рік для Вас був успішним, стабільним та процвітаючим.
    Нехай новий рік принесе Вам лише позитивні емоції та нові можливості.
    Вирішення усіх тимчасових негараздів доручіть нам!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `Поздравляем вас с Новым годом 🎉🎊
    Желаем, чтобы этот год был для вас успешным, стабильным и процветающим.
    Пусть новый год принесет вам только положительные эмоции и новые возможности.
    Решение всех временных неприятностей доверьте нам!
    С уважением!
    Администрация АО «Защита»`,
    de: `Herzlichen Glückwunsch zum neuen Jahr! 🎉🎊 Wir wünschen Ihnen, dass dieses Jahr erfolgreich, stabil und blühend für Sie sein wird.
    Möge das neue Jahr Ihnen nur positive Emotionen und neue Möglichkeiten bringen.
    Überlassen Sie uns die Lösung aller vorübergehenden Probleme! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    en: `Congratulations on the New Year! 🎉🎊 We wish that this year will be successful, stable, and prosperous for you.
    May the new year bring you only positive emotions and new opportunities.
    Entrust the resolution of all temporary troubles to us! With respect, Administration of the Law Association "Zakhyst"`,
    fr: `Félicitations pour la nouvelle année! 🎉🎊 Nous souhaitons que cette année soit pour vous réussie, stable et prospère.
    Que la nouvelle année vous apporte uniquement des émotions positives et de nouvelles opportunités.
    Confiez-nous la résolution de tous les problèmes temporaires! Avec respect, Administration de l'Association des Avocats "Zakhyst"`,
    es: `¡Feliz Año Nuevo! 🎉🎊 Les deseamos que este año sea exitoso, estable y próspero para ustedes.
    Que el nuevo año les traiga solo emociones positivas y nuevas oportunidades.
    ¡Confíen en nosotros para resolver todos los problemas temporales! Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Šťastný Nový rok! 🎉🎊 Přejeme vám, aby byl tento rok úspěšný, stabilní a prosperující.
    Ať vám nový rok přinese jen pozitivní emoce a nové příležitosti.
    Svěřte nám vyřešení všech dočasných problémů! S úctou, správa advokátní asociace "Zakhyst"`,
  },

  chatDozhimsAISecondNewYearMessage: {
    ua: `З Новим роком!
    Бажаємо Вам, неймовірних можливостей, щастя, здоров’я та великих досягнень у новому році! Нехай цей рік принесе Вам багато яскравих моментів та добробуту Вашій сім’ї!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `С Новым годом!
    Желаем вам невероятных возможностей, счастья, здоровья и больших достижений в новом году! Пусть этот год принесет вам много ярких моментов и благополучия вашей семье!
    С уважением!
    Администрация АО «Защита»`,
    de: `Frohes neues Jahr! Wir wünschen Ihnen unglaubliche Möglichkeiten, Glück, Gesundheit und große Erfolge im neuen Jahr! Möge dieses Jahr Ihnen viele schöne Momente und Wohlstand für Ihre Familie bringen!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    en: `Happy New Year! We wish you incredible opportunities, happiness, health, and great achievements in the new year!
    May this year bring you many bright moments and prosperity to your family! With respect, Administration of the Law Association "Zakhyst"`,
    fr: `Bonne année! Nous vous souhaitons d'incroyables opportunités, du bonheur, de la santé et de grandes réalisations dans la nouvelle année!
    Que cette année vous apporte de nombreux moments brillants et prospérité pour votre famille! Avec respect, Administration de l'Association des Avocats "Zakhyst"`,
    es: `¡Feliz Año Nuevo! Les deseamos increíbles oportunidades, felicidad, salud y grandes logros en el nuevo año.
    ¡Que este año les traiga muchos momentos brillantes y prosperidad para su familia! Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Šťastný Nový rok! Přejeme vám úžasné příležitosti, štěstí, zdraví a velké úspěchy v novém roce!
    Ať vám tento rok přinese mnoho jasných momentů a prosperitu vaší rodině! S úctou, správa advokátní asociace "Zakhyst"`,
  },

  chatDozhimsAIFirstIndependenceDayMessage: {
    ua: `Слава Україні!
    Вітаємо Вас з Днем Незалежності України.
    Бажаємо мирного неба, щасливих сердець і нескінченного процвітання для Вас і для усіх українців!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `Слава Украине!
    Поздравляем вас с Днем Независимости Украины.
    Желаем мирного неба, счастливых сердец и бесконечного процветания для вас и для всех украинцев!
    С уважением!
    Администрация АО «Защита»`,
    de: `Ruhm der Ukraine! Herzlichen Glückwunsch zum Unabhängigkeitstag der Ukraine.
    Wir wünschen Ihnen einen friedlichen Himmel, glückliche Herzen und endlosen Wohlstand für Sie und alle Ukrainer! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    en: `Glory to Ukraine! Congratulations on Ukraine's Independence Day.
    We wish you a peaceful sky, happy hearts, and endless prosperity for you and all Ukrainians! With respect, Administration of the Law Association "Zakhyst"`,
    fr: `Gloire à l'Ukraine! Félicitations pour la fête de l'indépendance de l'Ukraine.
    Nous vous souhaitons un ciel paisible, des cœurs heureux et une prospérité sans fin pour vous et tous les Ukrainiens! Avec respect, Administration de l'Association des Avocats "Zakhyst"`,
    es: `¡Gloria a Ucrania! Felicitaciones por el Día de la Independencia de Ucrania.
    ¡Les deseamos un cielo pacífico, corazones felices y una prosperidad infinita para ustedes y todos los ucranianos! Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Sláva Ukrajině! Srdečně vás gratulujeme k Dni nezávislosti Ukrajiny.
    Přejeme vám klidné nebe, šťastná srdce a nekonečné prosperitě pro vás a všechny Ukrajince! S úctou, správa advokátní asociace "Zakhyst"`,
  },

  chatDozhimsAISecondIndependenceDayMessage: {
    ua: `Слава Україні!
    З найщирішими почуттями вітаємо Вас з Днем Незалежності України.
    Нехай Україна процвітає, а душі українців завжди наповнюються гордістю за свою незалежну та мужню країну!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `Слава Украине!
    С искренними чувствами поздравляем вас с Днем Независимости Украины.
    Пусть Украина процветает, а сердца украинцев всегда наполняются гордостью за свою независимую и мужественную страну!
    С уважением!
    Администрация АО «Защита»`,
    en: `Glory to Ukraine! With the sincerest feelings, we congratulate you on Ukraine's Independence Day.
    May Ukraine prosper, and may the hearts of Ukrainians always be filled with pride for their independent and courageous country!
    With respect, Administration of the Law Association "Zakhyst"`,
    de: `Ruhm der Ukraine! Mit den aufrichtigsten Gefühlen gratulieren wir Ihnen zum Unabhängigkeitstag der Ukraine.
    Möge die Ukraine gedeihen, und die Herzen der Ukrainer mögen immer mit Stolz auf ihr unabhängiges und mutiges Land erfüllt sein!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    fr: `Gloire à l'Ukraine ! Avec les sentiments les plus sincères, nous vous félicitons pour le Jour de l'Indépendance de l'Ukraine.
    Que l'Ukraine prospère, et que les cœurs des Ukrainiens soient toujours remplis de fierté pour leur pays indépendant et courageux !
    Respectueusement, Administration de l'Association du Droit "Zakhyst"`,
    es: `¡Gloria a Ucrania! Con los sentimientos más sinceros, le felicitamos por el Día de la Independencia de Ucrania.
    ¡Que Ucrania prospere y que los corazones de los ucranianos siempre estén llenos de orgullo por su país independiente y valiente!
    Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Sláva Ukrajině! S nejupřímnějšími pocity vám gratulujeme k Dni nezávislosti Ukrajiny.
    Ať Ukrajina prosperuje a ať jsou srdce Ukrajinců vždy naplněna hrdostí za svou nezávislou a statečnou zemi!
    S úctou, Správa Právnické asociace "Zakhyst"`,
  },

  chatDozhimsAIFirstChristmasMessage: {
    ua: `З Різдвом Христовим!
    Нехай ця святкова пора наповнить Ваше серце теплом, радістю та благополуччям!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `С Рождеством Христовым!
    Пусть это праздничное время наполнит ваше сердце теплом, радостью и благополучием!
    С уважением!
    Администрация АО «Защита»`,
    en: `Merry Christmas! May this festive season fill your heart with warmth, joy, and well-being! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Frohe Weihnachten! Möge diese festliche Zeit Ihr Herz mit Wärme, Freude und Wohlstand erfüllen! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    fr: `Joyeux Noël ! Que cette période festive remplisse votre cœur de chaleur, de joie et de bien-être ! Respectueusement, Administration de l'Association du Droit "Zakhyst"`,
    es: `¡Feliz Navidad! ¡Que esta temporada festiva llene tu corazón de calidez, alegría y bienestar! Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Veselé Vánoce! Ať vás toto sváteční období naplní teplem, radostí a blahobytem! S úctou, Správa Právnické asociace "Zakhyst"`,
  },

  chatDozhimsAISecondChristmasMessage: {
    ua: `З Різдвом Христовим!
    Нехай зірка Різдва освітить Ваш шлях світлом і надихне на нові досягнення у наступному році!
    Зичимо Вам радості, миру і щастя у ці святкові дні та завжди!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `С Рождеством Христовым!
    Пусть звезда Рождества осветит ваш путь светом и вдохновит на новые достижения в следующем году!
    Желаем вам радости, мира и счастья в эти праздничные дни и всегда!
    С уважением!
    Администрация АО «Защита»`,
    en: `Merry Christmas! May the Christmas star light your way and inspire new achievements in the coming year!
    We wish you joy, peace, and happiness during these festive days and always! With respect, Administration of the Law Association "Zakhyst"`,
    de: `Frohe Weihnachten! Möge der Weihnachtsstern Ihren Weg erleuchten und zu neuen Erfolgen im kommenden Jahr inspirieren!
    Wir wünschen Ihnen Freude, Frieden und Glück in diesen Festtagen und immer! Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    fr: `Joyeux Noël ! Que l'étoile de Noël éclaire votre chemin et inspire de nouvelles réalisations dans l'année à venir !
    Nous vous souhaitons joie, paix et bonheur pendant ces jours de fête et toujours ! Respectueusement, Administration de l'Association du Droit "Zakhyst"`,
    es: `¡Feliz Navidad! ¡Que la estrella de Navidad ilumine tu camino e inspire nuevos logros en el próximo año!
    ¡Te deseamos alegría, paz y felicidad durante estos días festivos y siempre! Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Veselé Vánoce! Ať vás vánoční hvězda osvítí a inspiruje k novým úspěchům v nadcházejícím roce!
    Přejeme vám radost, mír a štěstí během těchto svátečních dní a vždy! S úctou, Správa Právnické asociace "Zakhyst"`,
  },

  chatDozhimsAIFirstDayConstitutionMessage: {
    ua: `З Днем Конституції України!
    Нехай наша конституція завжди буде гарантом прав і свобод кожного громадянина та джерелом національної єдності!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `С Днем Конституции Украины!
    Пусть наша конституция всегда будет гарантом прав и свобод каждого гражданина и источником национального единства!
    С уважением!
    Администрация АО «Защита»`,
    en: `Happy Constitution Day of Ukraine! May our constitution always be a guarantor of the rights and freedoms of every citizen and a source of national unity!
    With respect, Administration of the Law Association "Zakhyst"`,
    de: `Herzlichen Glückwunsch zum Tag der Verfassung der Ukraine!
    Möge unsere Verfassung immer ein Garant für die Rechte und Freiheiten jedes Bürgers und eine Quelle nationaler Einheit sein!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    fr: `Joyeux Jour de la Constitution d'Ukraine ! Que notre constitution soit toujours le garant des droits et des libertés de chaque citoyen et une source d'unité nationale !
    Avec respect, Administration de l'Association des Avocats "Zakhyst"`,
    es: `¡Feliz Día de la Constitución de Ucrania! ¡Que nuestra constitución siempre sea un garante de los derechos y libertades de cada ciudadano y una fuente de unidad nacional!
    Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Šťastný Den ústavy Ukrajiny! Ať je naše ústava vždy zárukou práv a svobod každého občana a zdrojem národní jednoty!
    S úctou, Administrativa Právnické asociace "Zakhyst"`,
  },

  chatDozhimsAISecondDayConstitutionMessage: {
    ua: `З Днем Конституції!
    Щиро вітаю Вас з цим важливим святом! Нехай принципи демократії, правової держави та гарантовані конституційні права завжди керують нашою країною на шляху до процвітання та благополуччя!
    З повагою!
    Адміністрація АО «Захист»`,
    ru: `С Днем Конституции!
    Искренне поздравляю вас с этим важным праздником! Пусть принципы демократии, правового государства и гарантированные конституционные права всегда направляют нашу страну на путь процветания и благополучия!
    С уважением!
    Администрация АО «Защита»`,
    en: `Happy Constitution Day! I sincerely congratulate you on this important holiday!
    May the principles of democracy, the rule of law, and guaranteed constitutional rights always guide our country on the path to prosperity and well-being!
    With respect, Administration of the Law Association "Zakhyst"`,
    de: `Herzlichen Glückwunsch zum Tag der Verfassung! Ich gratuliere Ihnen herzlich zu diesem wichtigen Feiertag!
    Mögen die Prinzipien der Demokratie, des Rechtsstaates und der garantierten verfassungsmäßigen Rechte immer unser Land auf dem Weg zu Wohlstand und Wohlergehen leiten!
    Mit freundlichen Grüßen, Verwaltung des Anwaltsverbandes "Zakhyst"`,
    fr: `Joyeux Jour de la Constitution ! Je vous félicite sincèrement pour cette importante fête !
    Que les principes de la démocratie, de l'État de droit et les droits constitutionnels garantis guident toujours notre pays sur la voie de la prospérité et du bien-être !
    Avec respect, Administration de l'Association des Avocats "Zakhyst"`,
    es: `¡Feliz Día de la Constitución! ¡Le felicito sinceramente en esta importante fiesta!
    ¡Que los principios de la democracia, el Estado de derecho y los derechos constitucionales garantizados siempre guíen a nuestro país por el camino de la prosperidad y el bienestar!
    Con respeto, Administración de la Asociación de Abogados "Zakhyst"`,
    cs: `Šťastný Den ústavy! Upřímně vás zdravím k tomuto důležitému svátku!
    Ať principy demokracie, právního státu a zaručená ústavní práva vždy vedou naši zemi na cestě k prosperitě a blahu!
    S úctou, Administrativa Právnické asociace "Zakhyst"`,
  },

  //QuestionnaireNotification
  startQuestionnaire: {
    ua: 'Анкетування якості обслуговування',
    ru: 'Опрос по качеству обслуживания',
    en: 'Customer Service Survey',
    de: 'Kundendienstumfrage',
    fr: 'Enquête sur la qualité du service',
    es: 'Encuesta de calidad del servicio',
    cs: 'Anketa o kvalitě služeb',
  },
  btnStartQuestionnaire: {
    ua: 'Розпочати анкетування',
    ru: 'Начать опрос',
    en: 'Start the survey',
    de: 'Die Umfrage starten',
    fr: 'Commencer l’enquête',
    es: 'Iniciar la encuesta',
    cs: 'Spustit anketu',
  },
  btnCancelQuestionnaire: {
    ua: 'Залишити на потім',
    ru: 'Оставить на потом',
    en: 'Leave for later',
    de: 'Für später lassen',
    fr: 'Laisser pour plus tard',
    es: 'Dejar para después',
    cs: 'Nechat na později',
  },
  QuestionnaireInfo: {
    ua: 'Будь ласка, потім заповніть анкету. Нам важлива ваша думка.',
    ru: 'Пожалуйста, заполните анкету позже. Ваше мнение важно для нас.',
    en: 'Please, fill out the questionnaire. Your opinion is important to us.',
    de: 'Bitte füllen Sie dann den Fragebogen aus. Ihre Meinung ist uns wichtig.',
    fr: 'Veuillez remplir le questionnaire plus tard. Votre opinion est importante pour nous.',
    es: 'Por favor, complete el cuestionario más tarde. Su opinión es importante para nosotros.',
    cs: 'Prosím, vyplňte dotazník později. Váš názor je pro nás důležitý.',
  },
  questionnaire1: {
    ua: 'Оцініть, будь ласка, якість обслуговування після останнього завершення договору з нами та отримайте на ваш бонусний рахунок',
    ru: 'Пожалуйста, оцените качество обслуживания после завершения последнего договора с нами и получите на ваш бонусный счет',
    en: 'Please evaluate the quality of service following your most recent contract completion with us and receive',
    de: 'Bewerten Sie bitte die Qualität des Service nach Abschluss Ihres letzten Vertrags mit uns und erhalten Sie ',
    fr: 'Veuillez évaluer la qualité du service après l’achèvement de votre dernier contrat avec nous et recevoir',
    es: 'Por favor, evalúe la calidad del servicio tras la finalización de su contrato más reciente con nosotros y reciba',
    cs: 'Prosím, ohodnoťte kvalitu služeb po dokončení vaší nejnovější smlouvy s námi a získejte',
  },
  questionnaire2: {
    ua: 'грн.',
    ru: 'грн.',
    en: 'UAH on your bonus account.',
    de: 'UAH auf Ihr Bonuskonto.',
    fr: 'UAH sur votre compte bonus.',
    es: 'UAH en su cuenta de bonificación.',
    cs: 'UAH na vašem bonusovém účtu.',
  },

  //QuestionnaireArchive
  qualityArchiveBtn: {
    ua: 'Оцінити якість обслуговування',
    ru: 'Оценить качество обслуживания',
    en: 'Evaluate the quality of service',
    de: 'Die Qualität des Service bewerten',
    fr: 'Évaluer la qualité du service',
    es: 'Evaluar la calidad del servicio',
    cs: 'Ohodnotit kvalitu služeb',
  },
  QuestionnaireArchive1: {
    ua: 'Анкета є конфіденційною та не підлягає розголошенню перед адвокатами та юристами!',
    ru: 'Анкета является конфиденциальной и не подлежит раскрытию перед адвокатами и юристами!',
    en: 'The questionnaire is confidential and shall not be disclosed to lawyers and attorneys!',
    de: 'Der Fragebogen ist vertraulich und darf nicht an Anwälte und Rechtsanwälte weitergegeben werden!',
    fr: 'Le questionnaire est confidentiel et ne doit pas être divulgué aux avocats et aux juristes!',
    es: '¡El cuestionario es confidencial y no debe ser divulgado a abogados y juristas!',
    cs: 'Dotazník je důvěrný a nesmí být sdělován právníkům a advokátům!',
  },
  QuestionnaireArchive2: {
    ua: 'Оцініть якість обслуговування за 10-ти бальною шкалою',
    ru: 'Оцените качество обслуживания по 10-балльной шкале',
    en: 'Please rate the quality of service on a 10-point scale.',
    de: 'Bitte bewerten Sie die Servicequalität auf einer 10-Punkte-Skala.',
    fr: 'Veuillez évaluer la qualité du service sur une échelle de 10 points.',
    es: 'Por favor, califique la calidad del servicio en una escala de 10 puntos.',
    cs: 'Prosím, ohodnoťte kvalitu služeb na 10-bodové škále.',
  },
  QuestionnaireArchive3: {
    ua: 'Чи задоволені Ви результатом роботи?',
    ru: 'Вы довольны результатом работы?',
    en: 'Are you satisfied with the result of the work?',
    de: 'Sind Sie mit dem Ergebnis der Arbeit zufrieden?',
    fr: 'Êtes-vous satisfait du résultat du travail?',
    es: '¿Está satisfecho con el resultado del trabajo?',
    cs: 'Jste spokojeni s výsledkem práce?',
  },
  QuestionnaireArchive4: {
    ua: 'Чи задоволені Ви комунікацією з юристами?',
    ru: 'Вы довольны общением с юристами?',
    en: 'Are you satisfied with communication with lawyers?',
    de: 'Sind Sie mit der Kommunikation mit den Anwälten zufrieden?',
    fr: 'Êtes-vous satisfait de la communication avec les avocats?',
    es: '¿Está satisfecho con la comunicación con los abogados?',
    cs: 'Jste spokojeni s komunikací s právníky?',
  },
  QuestionnaireArchive5: {
    ua: 'Чи звернулися би Ви до нас ще раз?',
    ru: 'Обратились бы вы к нам снова?',
    en: 'Would you turn to us again?',
    de: 'Würden Sie sich wieder an uns wenden?',
    fr: 'Vous tourneriez-vous à nouveau vers nous?',
    es: '¿Se volvería a dirigir a nosotros?',
    cs: 'Obrátili byste se na nás znovu?',
  },
  QuestionnaireArchive6: {
    ua: 'Чи порадили би Ви нашу компанію своїм знайомим?',
    ru: 'Рекомендовали бы вы нашу компанию своим знакомым?',
    en: 'Would you recommend our company to your acquaintances?',
    de: 'Würden Sie unser Unternehmen Ihren Bekannten empfehlen?',
    fr: 'Recommanderiez-vous notre entreprise à vos connaissances?',
    es: '¿Recomendaría nuestra empresa a sus conocidos?',
    cs: 'Doporučili byste naši společnost svým známým?',
  },
  QuestionnaireArchive7: {
    ua: 'Чи довіряєте Ви нашій компанії?',
    ru: 'Доверяете ли вы нашей компании?',
    en: 'Do you trust our company?',
    de: 'Vertrauen Sie unserem Unternehmen?',
    fr: 'Faites-vous confiance à notre entreprise?',
    es: '¿Confía en nuestra empresa?',
    cs: 'Důvěřujete naší společnosti?',
  },
  QuestionnaireArchive8: {
    ua: 'Місце для скарг чи пропозицій:',
    ru: 'Место для жалоб или предложений:',
    en: 'Place for complaints or suggestions:',
    de: 'Ort für Beschwerden oder Vorschläge:',
    fr: 'Espace pour les plaintes ou suggestions :',
    es: 'Lugar para quejas o sugerencias:',
    cs: 'Místo pro stížnosti nebo návrhy:',
  },
  QuestionnaireArchive9: {
    ua: 'Не менше 20 символів',
    ru: 'Не менее 20 символов',
    en: 'At least 20 characters',
    de: 'Mindestens 20 Zeichen',
    fr: 'Au moins 20 caractères',
    es: 'Al menos 20 caracteres',
    cs: 'Nejméně 20 znaků',
  },
  QuestionnaireArchive10: {
    ua: 'Як нам стати краще?',
    ru: 'Как нам стать лучше?',
    en: 'How can we become better?',
    de: 'Wie können wir besser werden?',
    fr: 'Comment pouvons-nous nous améliorer?',
    es: '¿Cómo podemos mejorar?',
    cs: 'Jak se můžeme zlepšit?',
  },
  QuestionnaireArchive11: {
    ua: 'Чи сподобався подарунок?',
    ru: 'Вам понравился подарок?',
    en: 'Did you like the gift?',
    de: 'Hat Ihnen das Geschenk gefallen?',
    fr: 'Avez-vous aimé le cadeau?',
    es: '¿Te gustó el regalo?',
    cs: 'Líbil se vám dárek?',
  },

  //registration
  regStep1: {
    ua: 'Завершення реєстрації, крок',
    ru: 'Завершение регистрации, шаг',
    en: 'Registration completion, step',
    de: 'Registrierung abschließen, Schritt',
    fr: "Achèvement de l'inscription, étape",
    es: 'Finalización del registro, paso',
    cs: 'Dokončení registrace, krok',
  },
  regStep1Descr: {
    ua: 'Будь ласка, введіть вашу електронну адресу для завершення процесу реєстрації.\n Ми відправимо Вам листа для підтвердження.\nДякуємо!',
    ru: 'Пожалуйста, введите ваш адрес электронной почты для завершения процесса регистрации.\n Мы отправим вам письмо для подтверждения.\nСпасибо!',
    en: 'Please enter your email address to complete the registration process.\n We will send you a confirmation email.\nThank you!',
    de: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um den Registrierungsprozess abzuschließen.\n Wir werden Ihnen eine Bestätigungs-E-Mail senden.\nDanke!',
    fr: "Veuillez entrer votre adresse e-mail pour compléter le processus d'inscription.\n Nous vous enverrons un e-mail de confirmation.\nMerci!",
    es: 'Por favor, introduzca su dirección de correo electrónico para completar el proceso de registro.\n Le enviaremos un correo electrónico de confirmación.\n¡Gracias!',
    cs: 'Prosím, zadejte svou e-mailovou adresu pro dokončení registračního procesu.\n Pošleme vám e-mail s potvrzením.\nDěkujeme!',
  },
  regStep1Confirm1: {
    ua: 'Ми відправили Вам листа для підтвердження вашої електронної пошти. Будь ласка, перевірте свою скриньку вхідних повідомлень та натисніть на посилання для завершення процесу реєстрації. Якщо ви не отримали листа, перевірте папку «Спам».',
    ru: 'Мы отправили вам письмо для подтверждения вашей электронной почты. Пожалуйста, проверьте свою папку "Входящие" и нажмите на ссылку для завершения процесса регистрации. Если вы не получили письмо, проверьте папку «Спам».',
    en: "We have sent you an email to confirm your email address. Please check your inbox and click on the link to complete the registration process. If you haven't received the email, please check your 'Spam' folder.",
    de: 'Wir haben Ihnen eine E-Mail zur Bestätigung Ihrer E-Mail-Adresse geschickt. Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf den Link, um den Registrierungsprozess abzuschließen. Wenn Sie die E-Mail nicht erhalten haben, überprüfen Sie bitte Ihren «Spam»-Ordner.',
    fr: "Nous vous avons envoyé un e-mail pour confirmer votre adresse e-mail. Veuillez vérifier votre boîte de réception et cliquer sur le lien pour compléter le processus d'inscription. Si vous n'avez pas reçu l'e-mail, veuillez vérifier votre dossier \"Spam\".",
    es: 'Le hemos enviado un correo electrónico para confirmar su dirección de correo electrónico. Por favor, revise su bandeja de entrada y haga clic en el enlace para completar el proceso de registro. Si no ha recibido el correo electrónico, verifique su carpeta de "Spam".',
    cs: 'Poslali jsme vám e-mail k potvrzení vaší e-mailové adresy. Zkontrolujte prosím svou doručenou poštu a klikněte na odkaz pro dokončení registračního procesu. Pokud e-mail nedorazil, zkontrolujte prosím složku "Spam".',
  },
  regStep1Confirm2: {
    ua: 'Перейдіть за посиланням, для підтвердження пошти, яке відправлено на Ваш email ',
    ru: 'Перейдите по ссылке для подтверждения почты, которая была отправлена на ваш email ',
    en: 'Click on the link to confirm your email, which was sent to your email address ',
    de: 'Klicken Sie auf den Link, um Ihre E-Mail-Adresse zu bestätigen, die an Ihre E-Mail-Adresse ',
    fr: 'Cliquez sur le lien pour confirmer votre e-mail, qui a été envoyé à votre adresse e-mail ',
    es: 'Haga clic en el enlace para confirmar su correo electrónico, que fue enviado a su dirección de correo electrónico ',
    cs: 'Klikněte na odkaz pro potvrzení vašeho e-mailu, který byl zaslán na vaši e-mailovou adresu ',
  },
  regStep1Confirm3: {
    ua: ', або введіть код активації вручну.',
    ru: ', или введите код активации вручную.',
    en: ', or enter the activation code manually.',
    de: ' gesendet wurde, oder geben Sie den Aktivierungscode manuell ein.',
    fr: ", ou entrez le code d'activation manuellement.",
    es: ', o ingrese el código de activación manualmente.',
    cs: ', nebo zadejte aktivační kód ručně.',
  },
  regStep1Confirm4: {
    ua: 'Код верифікації',
    ru: 'Код верификации',
    en: 'Verification code',
    de: 'Verifizierungscode',
    fr: 'Code de vérification',
    es: 'Código de verificación',
    cs: 'Ověřovací kód',
  },
  regStep1Confirm5: {
    ua: 'Не бачите листа? Перевірте папку "Спам" або ',
    ru: 'Не видите письмо? Проверьте папку "Спам" или ',
    en: 'Don\'t see the email? Check your "Spam" folder or ',
    de: 'Sie sehen die E-Mail nicht? Überprüfen Sie Ihren "Spam"-Ordner oder ',
    fr: 'Vous ne voyez pas l\'e-mail ? Vérifiez votre dossier "Spam" ou ',
    es: '¿No ve el correo electrónico? Revise su carpeta de "Spam" o ',
    cs: 'Nevidíte e-mail? Zkontrolujte svou složku "Spam" nebo ',
  },
  regStep1Confirm6: {
    ua: 'почніть спочатку',
    ru: 'начните сначала',
    en: 'start over',
    de: 'beginnen Sie von vorne',
    fr: 'commencer à nouveau',
    es: 'comenzar de nuevo',
    cs: 'začít znovu',
  },
  regStep1ConfirmWarn1: {
    ua: 'Цей email вже зареєстрований!',
    ru: 'Этот email уже зарегистрирован!',
    en: 'This email is already registered!',
    de: 'Diese E-Mail ist bereits registriert!',
    fr: 'Cet e-mail est déjà enregistré!',
    es: '¡Este correo electrónico ya está registrado!',
    cs: 'Tento e-mail je již registrován!',
  },
  regStep1ConfirmSuccess: {
    ua: 'Email підтверджено',
    ru: 'Email подтвержден',
    en: 'Email confirmed',
    de: 'E-Mail bestätigt',
    fr: 'E-mail confirmé',
    es: 'Correo electrónico confirmado',
    cs: 'E-mail potvrzen',
  },
  letterSentMessage: {
    ua: 'Лист відправлено',
    ru: 'Письмо отправлено',
    en: 'Email sent',
    de: 'E-Mail gesendet',
    fr: 'E-mail envoyé',
    es: 'Correo electrónico enviado',
    cs: 'E-mail odeslán',
  },

  regStep2Confirm1: {
    ua: 'Будь ласка, введіть ваш номер телефону для завершення процесу реєстрації.',
    ru: 'Пожалуйста, введите ваш номер телефона для завершения процесса регистрации.',
    en: 'Please enter your phone number to complete the registration process.',
    de: 'Bitte geben Sie Ihre Telefonnummer ein, um den Registrierungsprozess abzuschließen.',
    fr: "Veuillez entrer votre numéro de téléphone pour compléter le processus d'inscription.",
    es: 'Por favor, introduzca su número de teléfono para completar el proceso de registro.',
    cs: 'Prosím, zadejte své telefonní číslo pro dokončení registračního procesu.',
  },
  regStep2Confirm2: {
    ua: 'Номер телефону',
    ru: 'Номер телефона',
    en: 'Phone number',
    de: 'Telefonnummer',
    fr: 'Numéro de téléphone',
    es: 'Número de teléfono',
    cs: 'Telefonní číslo',
  },
  regStep2Confirm3: {
    ua: 'На цей номер буде відправлено смс з кодом для підтвердження номера телефону.',
    ru: 'На этот номер будет отправлено СМС с кодом для подтверждения номера телефона.',
    en: 'On this number, an SMS with a confirmation code will be sent.',
    de: 'An diese Nummer wird eine SMS mit einem Bestätigungscode gesendet.',
    fr: 'Un SMS contenant un code de confirmation sera envoyé à ce numéro.',
    es: 'A este número se enviará un SMS con un código de confirmación.',
    cs: 'Na toto číslo bude zaslána SMS s kódem pro potvrzení čísla.',
  },
  regStep2Confirm4: {
    ua: 'Ми відправили Вам смс повідомлення на номер ',
    ru: 'Мы отправили вам СМС-сообщение на номер ',
    en: 'We have sent you an SMS message to the number ',
    de: 'Wir haben Ihnen eine SMS-Nachricht an die Nummer ',
    fr: 'Nous vous avons envoyé un message SMS au numéro ',
    es: 'Te hemos enviado un mensaje SMS al número ',
    cs: 'Poslali jsme vám SMS zprávu na číslo ',
  },
  regStep2Confirm5: {
    ua: 'для підтвердження вашого номера телефону. Будь ласка, перевірте повідомлення та введіть код з смс для завершення реєстрації.',
    ru: 'для подтверждения вашего номера телефона. Пожалуйста, проверьте сообщение и введите код из СМС для завершения регистрации.',
    en: ' to confirm your phone number. Please check the message and enter the code from the SMS to complete the registration.',
    de: ' gesendet, um Ihre Telefonnummer zu bestätigen. Bitte überprüfen Sie die Nachricht und geben Sie den Code aus der SMS ein, um die Registrierung abzuschließen.',
    fr: " pour confirmer votre numéro de téléphone. Veuillez vérifier le message et entrer le code du SMS pour finaliser l'inscription.",
    es: ' para confirmar tu número de teléfono. Por favor, revisa el mensaje e ingresa el código del SMS para completar el registro.',
    cs: ' k potvrzení vašeho telefonního čísla. Zkontrolujte prosím zprávu a zadejte kód ze SMS pro dokončení registrace.',
  },
  regStep2Confirm6: {
    ua: 'Відправити смс повторно',
    ru: 'Отправить СМС повторно',
    en: 'Resend SMS',
    de: 'SMS erneut senden',
    fr: 'Renvoyer le SMS',
    es: 'Reenviar SMS',
    cs: 'Znovu odeslat SMS',
  },
  regStep2Confirm7: {
    ua: 'Перед повторною зміною номеру потрібно почекати',
    ru: 'Перед повторной сменой номера нужно подождать',
    en: 'Before resending the phone number, wait',
    de: 'Bevor wir das Telefonnummer erneut senden, warten',
    fr: 'Avant de renvoyer le numéro de téléphone, veuillez attendre',
    es: 'Antes de reenviar el número de teléfono, espera',
    cs: 'Před opětovným odesláním telefonního čísla počkejte',
  },
  regStep3Confirm1: {
    ua: 'Я підтверджую що ознайомлений з ',
    ru: 'Я подтверждаю, что ознакомлен с ',
    en: 'I confirm that I have read the ',
    de: 'Ich bestätige, dass ich die ',
    fr: "Je confirme que j'ai lu la ",
    es: 'Confirmo que he leído la ',
    cs: 'Potvrzuji, že jsem se seznámil s ',
  },
  regStep3Confirm2: {
    ua: 'політикою конфіденційності',
    ru: 'политикой конфиденциальности',
    en: 'privacy policy',
    de: 'Datenschutzrichtlinie',
    fr: 'politique de confidentialité',
    es: 'política de privacidad',
    cs: 'zásadou ochrany osobních údajů',
  },
  redButton: {
    ua: 'Тривожна кнопка',
    ru: 'Тревожная кнопка',
    en: 'Alarm button',
    de: 'Alarmknopf',
    fr: "Bouton d'alarme",
    es: 'Botón de alarma',
    cs: 'Tlačítko alarmu',
  },
  redButtonDebt: {
    ua: 'Наявний борг',
    ru: 'Имеющийся долг',
    en: 'Existing debt',
    de: 'Bestehende Schulden',
    fr: 'Dette existante',
    es: 'Deuda existente',
    cs: 'Existující dluh',
  },

  // Transactions

  // Need to fix paragraphs with currency

  transactionsRejected: {
    ua: '(відхилено)',
    ru: '(отклонено)',
    en: '(rejected)',
    de: '(abgelehnt)',
    fr: '(rejeté)',
    es: '(rechazado)',
    cs: '(odmítnuto)',
  },
  transactionsPending: {
    ua: '(на підтвердженні)',
    ru: '(в ожидании)',
    en: '(pending)',
    de: '(ausstehend)',
    fr: '(en attente)',
    es: '(pendiente)',
    cs: '(čeká se)',
  },
  transactionsReferralBonus: {
    ua: 'Бонус запрошення',
    ru: 'Бонус за приглашение',
    en: 'Referral Bonus',
    de: 'Empfehlungsbonus',
    fr: 'Bonus de parrainage',
    es: 'Bono de referencia',
    cs: 'Referral bonus',
  },
  transactionsBonusTr: {
    ua: 'Бонусова транзакція',
    ru: 'Бонусная транзакция',
    en: 'Bonus Transaction',
    de: 'Bonus-Transaktion',
    fr: 'Transaction de bonus',
    es: 'Transacción de bonificación',
    cs: 'Bonusová transakce',
  },
  transactionsBalance: {
    ua: 'залишок',
    ru: 'баланс',
    en: 'balance',
    de: 'Guthaben',
    fr: 'solde',
    es: 'saldo',
    cs: 'zůstatek',
  },
  transactionsBlUnknown: {
    ua: 'невідомо',
    ru: 'неизвестно',
    en: 'unknown',
    de: 'unbekannt',
    fr: 'inconnu',
    es: 'desconocido',
    cs: 'neznámý',
  },
  transactionsAccrualBonuses: {
    ua: 'Нарахування бонусу',
    ru: 'Начисление бонуса',
    en: 'Bonus accrual',
    de: 'Bonusgutschrift',
    fr: 'Accumulation de bonus',
    es: 'Acumulación de bonificaciones',
    cs: 'Nárok na bonusy',
  },
  transactionsCashbackForBonuses: {
    ua: 'Оплата бонусами',
    ru: 'Оплата бонусами',
    en: 'Payment with bonuses',
    de: 'Zahlung mit Boni',
    fr: 'Paiement avec des bonus',
    es: 'Pago con bonos',
    cs: 'Platba s bonusy',
  },
  replenishmentBalance: {
    ua: 'Поповнення балансу',
    ru: 'Пополнение баланса',
    en: 'Replenishment of the balance',
    de: 'Kontostand aufladen',
    fr: 'Rapprovisionnement du solde',
    es: 'Reabastecimiento del saldo',
    cs: 'Doplnění zůstatku',
  },
  paymenttoContract: {
    ua: 'Оплата гонорару за договором',
    ru: 'Оплата гонорара по договору',
    en: 'Payment for the contract fee',
    de: 'Zahlung für die Vertragsgebühr',
    fr: 'Paiement pour les frais de contrat',
    es: 'Pago por la tarifa del contrato',
    cs: 'Platba za smluvní poplatek',
  },
  unknownOperation: {
    ua: 'Невідома операція',
    ru: 'Неизвестная операция',
    en: 'Unknown operation',
    de: 'Unbekannte Operation',
    fr: 'Opération inconnue',
    es: 'Operación desconocida',
    cs: 'Neznámá operace',
  },

  // Pages

  // ClientChangePass Page
  clientChangePassWarn1: {
    ua: 'Користувача з таким email не знайдено!',
    ru: 'Пользователя с таким email не найдено!',
    en: 'User with such email not found!',
    de: 'Benutzer mit dieser E-Mail wurde nicht gefunden!',
    fr: 'Aucun utilisateur avec cet email trouvé!',
    es: '¡Usuario con este email no encontrado!',
    cs: 'Uživatel s takovým emailem nebyl nalezen!',
  },
  clientChangePassWarn2_1: {
    ua: 'Ви перевищили ліміт спроб!',
    ru: 'Вы превысили лимит попыток!',
    en: 'You have exceeded the limit of attempts!',
    de: 'Sie haben das Limit der Versuche überschritten!',
    fr: 'Vous avez dépassé la limite des tentatives!',
    es: '¡Has superado el límite de intentos!',
    cs: 'Překročili jste limit pokusů!',
  },
  clientChangePassWarn2_2: {
    ua: 'Спробуйте завтра!',
    ru: 'Попробуйте завтра!',
    en: 'Try again tomorrow!',
    de: 'Versuchen Sie es morgen erneut!',
    fr: 'Réessayez demain!',
    es: '¡Inténtalo de nuevo mañana!',
    cs: 'Zkuste to zítra!',
  },
  clientChangePassWarn3_1: {
    ua: 'Неможливо відновити пароль!',
    ru: 'Невозможно восстановить пароль!',
    en: 'Unable to recover password!',
    de: 'Passwort kann nicht wiederhergestellt werden!',
    fr: 'Impossible de récupérer le mot de passe!',
    es: '¡No se puede recuperar la contraseña!',
    cs: 'Není možné obnovit heslo!',
  },
  clientChangePassWarn3_2: {
    ua: 'Будь ласка, зверніться на гарячу лінію!',
    ru: 'Пожалуйста, обратитесь на горячую линию!',
    en: 'Please contact the hotline!',
    de: 'Bitte wenden Sie sich an die Hotline!',
    fr: "Veuillez contacter la ligne d'assistance!",
    es: '¡Por favor contacta con la línea de ayuda!',
    cs: 'Prosím, kontaktujte infolinku!',
  },
  clientChangePassSuccess: {
    ua: 'Пароль відправлено на ваш телефон!',
    ru: 'Пароль отправлен на ваш телефон!',
    en: 'Password sent to your phone!',
    de: 'Passwort an Ihr Telefon gesendet!',
    fr: 'Mot de passe envoyé sur votre téléphone!',
    es: '¡Contraseña enviada a tu teléfono!',
    cs: 'Heslo bylo odesláno na váš telefon!',
  },
  clientChangePassMenuItem: {
    ua: 'Відновлення паролю',
    ru: 'Восстановление пароля',
    en: 'Password Recovery',
    de: 'Passwortwiederherstellung',
    fr: 'Récupération de mot de passe',
    es: 'Recuperación de contraseña',
    cs: 'Obnova hesla',
  },
  clientCPIsEmailSended: {
    ua: 'Інструкцію щодо відновлення паролю, відправлено на пошту!',
    ru: 'Инструкции по восстановлению пароля отправлены на почту!',
    en: 'Instructions for password recovery have been sent to your email!',
    de: 'Anweisungen zur Passwortwiederherstellung wurden an Ihre E-Mail gesendet!',
    fr: 'Les instructions pour récupérer le mot de passe ont été envoyées à votre email!',
    es: '¡Las instrucciones para la recuperación de la contraseña han sido enviadas a tu correo electrónico!',
    cs: 'Pokyny pro obnovu hesla byly zaslány na váš email!',
  },
  clientCPEmailMessage1: {
    ua: 'Неправильний формат email',
    ru: 'Неверный формат email',
    en: 'Invalid email format',
    de: 'Ungültiges E-Mail-Format',
    fr: "Format d'email invalide",
    es: 'Formato de email no válido',
    cs: 'Neplatný formát emailu',
  },
  clientCPEmailMessage2: {
    ua: 'Введіть ваш email',
    ru: 'Введите ваш email',
    en: 'Enter your email',
    de: 'Geben Sie Ihre E-Mail-Adresse ein',
    fr: 'Entrez votre email',
    es: 'Ingresa tu email',
    cs: 'Zadejte svůj email',
  },
  clientCPPasswordMessage1: {
    ua: 'Введіть пароль',
    ru: 'Введите пароль',
    en: 'Enter password',
    de: 'Geben Sie das Passwort ein',
    fr: 'Entrez le mot de passe',
    es: 'Introduce la contraseña',
    cs: 'Zadejte heslo',
  },
  clientCPSubmitBtn: {
    ua: 'Надіслати',
    ru: 'Отправить',
    en: 'Send',
    de: 'Senden',
    fr: 'Envoyer',
    es: 'Enviar',
    cs: 'Odeslat',
  },
  clientCPPhoneLabel: {
    ua: 'Номер телефону',
    ru: 'Номер телефона',
    en: 'Phone Number',
    de: 'Telefonnummer',
    fr: 'Numéro de téléphone',
    es: 'Número de teléfono',
    cs: 'Telefonní číslo',
  },
  clientCPPhonePlaceholder: {
    ua: 'Телефон',
    ru: 'Телефон',
    en: 'Phone',
    de: 'Telefon',
    fr: 'Téléphone',
    es: 'Teléfono',
    cs: 'Telefon',
  },
  clientCPPhoneBtn: {
    ua: 'Відновити через телефон',
    ru: 'Восстановить через телефон',
    en: 'Recover via phone',
    de: 'Wiederherstellen per Telefon',
    fr: 'Récupérer par téléphone',
    es: 'Recuperar por teléfono',
    cs: 'Obnovit přes telefon',
  },
  clientCPQuestionLink: {
    ua: 'Згадали пароль?',
    ru: 'Вспомнили пароль?',
    en: 'Remembered your password?',
    de: 'Passwort erinnert?',
    fr: 'Vous vous souvenez de votre mot de passe?',
    es: '¿Recordaste tu contraseña?',
    cs: 'Vzpomněli jste si na své heslo?',
  },
  clientConfirmNPTitle: {
    ua: 'Кабінет клієнта',
    ru: 'Кабинет клиента',
    en: 'Client Dashboard',
    de: 'Kunden-Dashboard',
    fr: 'Tableau de bord client',
    es: 'Panel de cliente',
    cs: 'Klientský panel',
  },
  clientConfirmNPSuccess: {
    ua: 'Пароль успішно змінено',
    ru: 'Пароль успешно изменён',
    en: 'Password successfully changed',
    de: 'Passwort erfolgreich geändert',
    fr: 'Mot de passe changé avec succès',
    es: 'Contraseña cambiada con éxito',
    cs: 'Heslo bylo úspěšně změněno',
  },
  clientConfirmNPLabel: {
    ua: 'Новий пароль',
    ru: 'Новый пароль',
    en: 'New Password',
    de: 'Neues Passwort',
    fr: 'Nouveau mot de passe',
    es: 'Nueva contraseña',
    cs: 'Nové heslo',
  },
  clientConfirmNPUpdatePBtn: {
    ua: 'Змінити пароль',
    ru: 'Изменить пароль',
    en: 'Change Password',
    de: 'Passwort ändern',
    fr: 'Changer le mot de passe',
    es: 'Cambiar contraseña',
    cs: 'Změnit heslo',
  },

  // ClientContactUs Page
  clientContactUsTitle1: {
    ua: `Зв'яжіться з нами`,
    ru: 'Свяжитесь с нами',
    en: 'Contact Us',
    de: 'Kontaktieren Sie uns',
    fr: 'Contactez-nous',
    es: 'Contáctenos',
    cs: 'Kontaktujte nás',
  },
  clientContactUsTitle2: {
    ua: 'Безкоштовна гаряча лінія',
    ru: 'Бесплатная горячая линия',
    en: 'Toll-Free Hotline',
    de: 'Kostenlose Hotline',
    fr: "Ligne d'assistance gratuite",
    es: 'Línea directa gratuita',
    cs: 'Bezplatná horká linka',
  },
  clientContactUsDelimiter1: {
    ua: 'Контакти',
    ru: 'Контакты',
    en: 'Contacts',
    de: 'Kontakte',
    fr: 'Contacts',
    es: 'Contactos',
    cs: 'Kontakty',
  },
  clientContactUsDelimiter2: {
    ua: 'Консультація',
    ru: 'Консультация',
    en: 'Consultation',
    de: 'Beratungstermin',
    fr: 'Consultation',
    es: 'Consulta',
    cs: 'Konzultace',
  },
  clientContactUsInfo1: {
    ua: 'Адреса:',
    ru: 'Адрес:',
    en: 'Address:',
    de: 'Adresse:',
    fr: 'Adresse :',
    es: 'Dirección:',
    cs: 'Adresa:',
  },
  Ukraine: {
    ua: 'Україна',
    ru: 'Украина',
    en: 'Ukraine',
    de: 'Ukraine',
    fr: 'Ukraine',
    es: 'Ucrania',
    cs: 'Ukrajina',
  },
  Poland: {
    ua: 'Польща',
    ru: 'Польша',
    en: 'Poland',
    de: 'Polen',
    fr: 'Pologne',
    es: 'Polonia',
    cs: 'Polska',
  },
  CzechRepublic: {
    ua: 'Чехія',
    ru: 'Чехия',
    en: 'Czech Republic',
    de: 'Tschechische Republik',
    fr: 'République tchèque',
    es: 'República Checa',
    cs: 'Česko',
  },
  Germany: {
    ua: 'Німеччина',
    ru: 'Германия',
    en: 'Germany',
    de: 'Deutschland',
    fr: 'Allemagne',
    es: 'Alemania',
    cs: 'Německo',
  },
  clientContactUsInfo2: {
    ua: 'Вся Україна',
    ru: 'Вся Украина',
    en: 'All Ukraine',
    de: 'Ganz Ukraine',
    fr: "Toute l'Ukraine",
    es: 'Toda Ucrania',
    cs: 'Celá Ukrajina',
  },
  clientContactUsInfo3: {
    ua: 'Щодня:',
    ru: 'Каждый день:',
    en: 'Every Day:',
    de: 'Jeden Tag:',
    fr: 'Chaque jour :',
    es: 'Cada día:',
    cs: 'Každý den:',
  },
  clientContactUsInfo4: {
    ua: 'Телефон:',
    ru: 'Телефон:',
    en: 'Phone:',
    de: 'Telefon:',
    fr: 'Téléphone :',
    es: 'Teléfono:',
    cs: 'Telefon:',
  },
  clientContactUsTitle3: {
    ua: 'Слідкуйте за нами в соцмережах',
    ru: 'Следите за нами в социальных сетях',
    en: 'Follow Us on Social Media',
    de: 'Folgen Sie uns in sozialen Medien',
    fr: 'Suivez-nous sur les réseaux sociaux',
    es: 'Síguenos en las redes sociales',
    cs: 'Sledujte nás na sociálních médiích',
  },

  // ClientContractInfo Page
  clientContractInfoChat: {
    ua: 'Чат для договору',
    ru: 'Чат для договора',
    en: 'Contract Chat',
    de: 'Vertragschat',
    fr: 'Chat de contrat',
    es: 'Chat del contrato',
    cs: 'Chat pro smlouvu',
  },
  clientCInfDownloadFileWarn1: {
    ua: 'Для завантаження документу, потрібно вказати дійсний файл.',
    ru: 'Для загрузки документа необходимо указать действительный файл.',
    en: 'To download the document, a valid file is required.',
    de: 'Um das Dokument herunterzuladen, wird eine gültige Datei benötigt.',
    fr: 'Pour télécharger le document, un fichier valide est requis.',
    es: 'Para descargar el documento, se requiere un archivo válido.',
    cs: 'K stažení dokumentu je vyžadován platný soubor.',
  },
  clientCInfDownloadFileWarn2: {
    ua: 'Для завантаження документу, потрібно вказати назву файлу.',
    ru: 'Для загрузки документа необходимо указать название файла.',
    en: 'To download the document, a file name is required.',
    de: 'Um das Dokument herunterzuladen, wird ein Dateiname benötigt.',
    fr: 'Pour télécharger le document, un nom de fichier est requis.',
    es: 'Para descargar el documento, se requiere un nombre de archivo.',
    cs: 'K stažení dokumentu je vyžadován název souboru.',
  },
  clientCInfDownloadFileWarn3: {
    ua: 'Для завантаження документу, потрібно вказати формат файлу.',
    ru: 'Для загрузки документа необходимо указать формат файла.',
    en: 'To download the document, a file format is required.',
    de: 'Um das Dokument herunterzuladen, wird ein Dateiformat benötigt.',
    fr: 'Pour télécharger le document, un format de fichier est requis.',
    es: 'Para descargar el documento, se requiere un formato de archivo.',
    cs: 'K stažení dokumentu je vyžadován formát souboru.',
  },
  clientCInfDownloadFileSuccess: {
    ua: 'Документ успішно завантажено',
    ru: 'Документ успешно загружен',
    en: 'Document successfully downloaded',
    de: 'Dokument erfolgreich heruntergeladen',
    fr: 'Document téléchargé avec succès',
    es: 'Documento descargado con éxito',
    cs: 'Dokument byl úspěšně stažen',
  },
  clientCInfDownloadFileError: {
    ua: 'Помилка при отриманні файлу',
    ru: 'Ошибка при получении файла',
    en: 'Error receiving file',
    de: 'Fehler beim Empfangen der Datei',
    fr: 'Erreur lors de la réception du fichier',
    es: 'Error al recibir el archivo',
    cs: 'Chyba při příjmu souboru',
  },
  clientCInfOnGetFileError1: {
    ua: 'Відсутній ID файлу',
    ru: 'Отсутствует ID файла',
    en: 'File ID missing',
    de: 'Datei-ID fehlt',
    fr: 'ID de fichier manquant',
    es: 'ID de archivo faltante',
    cs: 'Chybí ID souboru',
  },
  // Contract Details
  clientCHotlineInf: {
    ua: 'Інформація про консультацію:',
    ru: 'Информация о консультации:',
    en: 'Information on the consultation:',
    de: 'Informationen über die Konsultation:',
    fr: 'Informations sur la consultation :',
    es: 'Información sobre la consulta:',
    cs: 'Informace o konzultaci:',
  },
  clientCHotlineDate: {
    ua: 'Дата:',
    ru: 'Дата:',
    en: 'Date:',
    de: 'Datum:',
    fr: 'Date :',
    es: 'Fecha:',
    cs: 'Datum:',
  },
  clientCHotlineConducted: {
    ua: 'Проводив:',
    ru: 'Проводил:',
    en: 'Conducted:',
    de: 'Durchgeführt:',
    fr: 'Effectué par :',
    es: 'Realizado por:',
    cs: 'Prováděl:',
  },
  clientCInf: {
    ua: 'Інформація про договір:',
    ru: 'Информация о договоре:',
    en: 'Contract information:',
    de: 'Informationen über den Vertrag:',
    fr: 'Informations sur le contrat :',
    es: 'Información sobre el contrato:',
    cs: 'Informace o smlouvě:',
  },
  clientCNum: {
    ua: 'Номер:',
    ru: 'Номер:',
    en: 'Number:',
    de: 'Nummer:',
    fr: 'Numéro :',
    es: 'Número:',
    cs: 'Číslo:',
  },
  clientCDate: {
    ua: 'Дата укладання',
    ru: 'Дата заключения',
    en: 'Date of conclusion',
    de: 'Datum des Abschlusses',
    fr: 'Date de conclusion',
    es: 'Fecha de conclusión',
    cs: 'Datum uzavření',
  },
  clientCFil: {
    ua: 'Філія:',
    ru: 'Филиал:',
    en: 'Branch:',
    de: 'Niederlassung:',
    fr: 'Branche :',
    es: 'Sucursal:',
    cs: 'Pobočka:',
  },
  clientCPravo: {
    ua: 'Вид правовідносин:',
    ru: 'Вид правоотношений:',
    en: 'Type of legal relationship:',
    de: 'Art des Rechtsverhältnisses:',
    fr: 'Type de relation juridique :',
    es: 'Tipo de relación legal:',
    cs: 'Typ právního vztahu:',
  },
  clientCPravoExactly: {
    ua: 'Що саме:',
    ru: 'Что именно:',
    en: 'What exactly:',
    de: 'Was genau:',
    fr: 'Quoi exactement :',
    es: 'Qué exactamente:',
    cs: 'Co přesně:',
  },
  clientInfo: {
    ua: 'Дані клієнта:',
    ru: 'Данные клиента:',
    en: 'Customer information:',
    de: 'Informationen für Kunden:',
    fr: 'Informations client :',
    es: 'Información del cliente:',
    cs: 'Informace o zákazníkovi:',
  },
  birthTitle: {
    ua: 'День народження',
    ru: 'День рождения',
    en: 'Birthday',
    de: 'Geburtstag',
    fr: 'Anniversaire',
    es: 'Cumpleaños',
    cs: 'Narozeniny',
  },
  clientPassportInf: {
    ua: 'Паспортні дані',
    ru: 'Паспортные данные',
    en: 'Passport details',
    de: 'Reisedaten',
    fr: 'Détails du passeport',
    es: 'Detalles del pasaporte',
    cs: 'Údaje o pasu',
  },
  type: {
    ua: 'Тип',
    ru: 'Тип',
    en: 'Type',
    de: 'Typ',
    fr: 'Type',
    es: 'Tipo',
    cs: 'Typ',
  },
  number: {
    ua: 'Номер',
    ru: 'Номер',
    en: 'Number',
    de: 'Nummer',
    fr: 'Numéro',
    es: 'Número',
    cs: 'Číslo',
  },
  clientPassportIssued: {
    ua: 'Виданий:',
    ru: 'Выдан:',
    en: 'Issued:',
    de: 'Ausgestellt:',
    fr: 'Délivré:',
    es: 'Emitido:',
    cs: 'Vydáno:',
  },
  clientPassportIssueDate: {
    ua: 'Дата видачі:',
    ru: 'Дата выдачи:',
    en: 'Issue date:',
    de: 'Ausgabedatum:',
    fr: 'Date de délivrance:',
    es: 'Fecha de emisión:',
    cs: 'Datum vydání:',
  },
  clientPassportValidDate: {
    ua: 'Дійсний до:',
    ru: 'Действителен до:',
    en: 'Valid until:',
    de: 'Gültig bis:',
    fr: "Valide jusqu'au:",
    es: 'Válido hasta:',
    cs: 'Platný do:',
  },

  // contract payment management
  paymentManagement: {
    ua: 'Менеджмент платежів',
    ru: 'Управление платежами',
    en: 'Payment management',
    de: 'Verwaltung der Zahlungen',
    fr: 'Gestion des paiements',
    es: 'Gestión de pagos',
    cs: 'Správa plateb',
  },
  fullFee: {
    ua: 'Повний гонорар',
    ru: 'Полный гонорар',
    en: 'Full fee',
    de: 'Volle Gebühr',
    fr: 'Frais complets',
    es: 'Tarifa completa',
    cs: 'Plná sazba',
  },
  firstPaymentDate: {
    ua: 'Дата першого платежу',
    ru: 'Дата первого платежа',
    en: 'First payment date',
    de: 'Erstes Zahlungsdatum',
    fr: 'Date du premier paiement',
    es: 'Fecha del primer pago',
    cs: 'Datum první platby',
  },
  exitsOfLawyer: {
    ua: 'Виходи адвоката',
    ru: 'Выходы адвоката',
    en: 'Exits of a lawyer',
    de: 'Ausgänge eines Anwalts',
    fr: "Sorties d'un avocat",
    es: 'Salidas de un abogado',
    cs: 'Vychází advokáta',
  },
  numberOfMonths: {
    ua: 'Кількість місяців',
    ru: 'Количество месяцев',
    en: 'Number of months',
    de: 'Anzahl der Monate',
    fr: 'Nombre de mois',
    es: 'Número de meses',
    cs: 'Počet měsíců',
  },
  totalPaid: {
    ua: 'Всього оплачено',
    ru: 'Всего оплачено',
    en: 'Total paid',
    de: 'Insgesamt bezahlt',
    fr: 'Total payé',
    es: 'Total pagado',
    cs: 'Celkově zaplaceno',
  },
  monthlyPayment: {
    ua: 'Щомісячний платіж',
    ru: 'Ежемесячный платеж',
    en: 'Monthly payment',
    de: 'Monatliche Zahlung',
    fr: 'Paiement mensuel',
    es: 'Pago mensual',
    cs: 'Měsíční platba',
  },
  assignments: {
    ua: 'Виходи',
    ru: 'Выходы',
    en: 'Assignments',
    de: 'Zuweisungen',
    fr: 'Affectations',
    es: 'Asignaciones',
    cs: 'Úkoly',
  },
  debt: {
    ua: 'Борг',
    ru: 'Долг',
    en: 'Debt',
    de: 'Schulden',
    fr: 'Dette',
    es: 'Deuda',
    cs: 'Dluh',
  },
  overpayment: {
    ua: 'Переплата',
    ru: 'Переплата',
    en: 'Overpayment',
    de: 'Überzahlung',
    fr: 'Sursalaire',
    es: 'Sobrepago',
    cs: 'Přeplatek',
  },

  contractTransactions: {
    ua: 'Транзакції по договору',
    ru: 'Транзакции по договору',
    en: 'Contract transactions',
    de: 'Transaktionen über den Vertrag',
    fr: 'Transactions de contrat',
    es: 'Transacciones de contrato',
    cs: 'Smluvní transakce',
  },

  // consult
  clientCInfConsultResult1: {
    ua: 'Консультація відбулася',
    ru: 'Консультация состоялась',
    de: 'Beratung hat stattgefunden',
    en: 'Consultation took place',
    fr: 'La consultation a eu lieu',
    es: 'La consulta tuvo lugar',
    cs: 'Konzultace proběhla',
  },
  clientCInfConsultResult2: {
    ua: 'Консультація не відбулася',
    ru: 'Консультация не состоялась',
    de: 'Beratung hat nicht stattgefunden',
    en: 'Consultation did not take place',
    fr: "La consultation n'a pas eu lieu",
    es: 'La consulta no tuvo lugar',
    cs: 'Konzultace se nekonala',
  },
  clientCInfConsultTitle: {
    ua: 'Запис на консультацію',
    ru: 'Запись на консультацию',
    de: 'Beratungsanmeldung',
    en: 'Consultation Booking',
    fr: 'Réservation de consultation',
    es: 'Reserva de consulta',
    cs: 'Rezervace konzultace',
  },
  clientCInfCorrecpondenceType1: {
    ua: 'Вихідна ',
    ru: 'Исходящая ',
    de: 'Ausgehend ',
    en: 'Outgoing ',
    fr: 'Sortante ',
    es: 'Saliente ',
    cs: 'Odchozí ',
  },
  clientCInfCorrecpondenceType2: {
    ua: 'Вхідна ',
    ru: 'Входящая ',
    de: 'Eingehend ',
    en: 'Incoming ',
    fr: 'Entrante ',
    es: 'Entrante ',
    cs: 'Příchozí ',
  },
  clientCInfCorrecpondenceText1: {
    ua: 'Скачати файл',
    ru: 'Скачать файл',
    de: 'Datei herunterladen',
    en: 'Download file',
    fr: 'Télécharger le fichier',
    es: 'Descargar archivo',
    cs: 'Stáhnout soubor',
  },
  clientCInfCorrecpondenceText2: {
    ua: 'и',
    ru: 'и',
    de: 'und',
    en: 'and',
    fr: 'et',
    es: 'y',
    cs: 'a',
  },
  clientCInfCorrecpondenceText3: {
    ua: 'з пошти',
    ru: 'из почты',
    de: 'von E-Mail',
    en: 'from email',
    fr: "de l'email",
    es: 'de correo electrónico',
    cs: 'z e-mailu',
  },
  clientCInfCorrecpondenceText4: {
    ua: 'шт.',
    ru: 'шт.',
    de: 'Stk.',
    en: 'pcs.',
    fr: 'pièces.',
    es: 'uds.',
    cs: 'ks.',
  },
  clientInComponentPreview: {
    ua: 'Перегляд',
    ru: 'Просмотр',
    de: 'Vorschau',
    en: 'Preview',
    fr: 'Aperçu',
    es: 'Vista previa',
    cs: 'Náhled',
  },
  clientCInfCorrecpondenceFileType1: {
    ua: 'Ви відправили файл куратору.',
    ru: 'Вы отправили файл куратору.',
    de: 'Sie haben dem Kurator eine Datei gesendet.',
    en: 'You sent a file to the curator.',
    fr: 'Vous avez envoyé un fichier au curateur.',
    es: 'Has enviado un archivo al curador.',
    cs: 'Odeslali jste soubor kurátorovi.',
  },
  clientCInfCorrecpondenceFileType2: {
    ua: 'Ви отримали файл від куратора.',
    ru: 'Вы получили файл от куратора.',
    de: 'Sie haben eine Datei vom Kurator erhalten.',
    en: 'You received a file from the curator.',
    fr: 'Vous avez reçu un fichier du curateur.',
    es: 'Has recibido un archivo del curador.',
    cs: 'Obdrželi jste soubor od kurátora.',
  },
  clientCInfChatDownload: {
    ua: 'Скачати файл з чату (1 шт.)',
    ru: 'Скачать файл из чата (1 шт.)',
    de: 'Datei aus dem Chat herunterladen (1 Stk.)',
    en: 'Download file from chat (1 pc.)',
    fr: 'Télécharger le fichier du chat (1 pièce.)',
    es: 'Descargar archivo del chat (1 ud.)',
    cs: 'Stáhnout soubor z chatu (1 ks.)',
  },
  clientCInfCuratorTitle: {
    ua: 'Куратор по договору:',
    ru: 'Куратор по договору:',
    de: 'Vertragskurator:',
    en: 'Contract curator:',
    fr: 'Curateur de contrat :',
    es: 'Curador del contrato:',
    cs: 'Kurátor smlouvy:',
  },
  clientCInfCuratorButton1: {
    ua: 'Написати',
    ru: 'Написать',
    de: 'Schreiben',
    en: 'Write',
    fr: 'Écrire',
    es: 'Escribir',
    cs: 'Napsat',
  },
  clientCInfCuratorButton2: {
    ua: 'Подзвонити',
    ru: 'Позвонить',
    de: 'Anrufen',
    en: 'Call',
    fr: 'Appeler',
    es: 'Llamar',
    cs: 'Zavolat',
  },
  clientCInfCuratorButton3: {
    ua: 'Поскаржитись',
    ru: 'Пожаловаться',
    de: 'Beschweren',
    en: 'Complain',
    fr: 'Se plaindre',
    es: 'Quejarse',
    cs: 'Stěžovat si',
  },
  clientCInfCuratorButton4: {
    ua: 'Оплата',
    ru: 'Оплата',
    de: 'Zahlung',
    en: 'Payment',
    fr: 'Paiement',
    es: 'Pago',
    cs: 'Platba',
  },
  clientCInfCuratorButton5: {
    ua: 'Події',
    ru: 'События',
    de: 'Ereignisse',
    en: 'Events',
    fr: 'Événements',
    es: 'Eventos',
    cs: 'Události',
  },
  clientCInfCuratorButton6: {
    ua: 'Кореспонденція',
    ru: 'Корреспонденция',
    de: 'Korrespondenz',
    en: 'Correspondence',
    fr: 'Correspondance',
    es: 'Correspondencia',
    cs: 'Korespondence',
  },
  clientCInfCuratorButton7: {
    ua: 'Автоматичні списання',
    ru: 'Автоматические списания',
    de: 'Automatische Abbuchungen',
    en: 'Automatic deductions',
    fr: 'Dépôts automatiques',
    es: 'Deducciones automáticas',
    cs: 'Automatické srážky',
  },
  clientCInfCuratorButton8: {
    ua: 'Задачі',
    ru: 'Задачи',
    de: 'Aufgaben',
    en: `Tasks`,
    fr: `Tâches`,
    es: `Tareas`,
    cs: `Úkoly`,
  },
  clientCInfCuratorButton9: {
    ua: `Файли`,
    ru: `Файлы`,
    de: `Dateien`,
    en: `Files`,
    fr: `Fichiers`,
    es: `Archivos`,
    cs: `Soubory`,
  },
  clientCInfCuratorButton10: {
    ua: `Оплати`,
    ru: `Платежи`,
    de: `Zahlungen`,
    en: `Payments`,
    fr: `Paiements`,
    es: `Pagos`,
    cs: `Platby`,
  },
  clientCInfCuratorButton11: {
    ua: 'Менеджмент платежів',
    ru: 'Менеджмент платежей',
    de: 'Zahlungsverwaltung',
    en: 'Payment management',
    fr: 'Gestion des paiements',
    es: 'Gestión de pagos',
    cs: 'Správa plateb',
  },
  previewImage: {
    ua: 'Помилка',
    ru: 'Ошибка',
    de: 'Fehler',
    en: 'Error',
    fr: 'Erreur',
    es: 'Error',
    cs: 'Chyba',
  },
  addFileTabListError1: {
    ua: 'Відсутні дані для відправки',
    ru: 'Отсутствуют данные для отправки',
    de: 'Keine Daten zum Senden',
    en: 'No data to send',
    fr: 'Aucune donnée à envoyer',
    es: 'No hay datos para enviar',
    cs: 'Žádná data k odeslání',
  },
  addFileTabListError2: {
    ua: 'Не вказана функція зворотнього виклику',
    ru: 'Не указана функция обратного вызова',
    en: 'Callback function not specified',
    de: 'Rückruffunktion nicht angegeben',
    fr: 'Fonction de rappel non spécifiée',
    es: 'No se especificó la función de callback',
    cs: 'Callback funkce není určena',
  },
  addFileTabListError3: {
    ua: 'Помилка під час відправки файлу',
    ru: 'Ошибка при отправке файла',
    en: 'Error sending file',
    de: 'Fehler beim Senden der Datei',
    fr: "Erreur lors de l'envoi du fichier",
    es: 'Error al enviar el archivo',
    cs: 'Chyba při odesílání souboru',
  },
  addFileTabListSuccess: {
    ua: 'Файл успішно додано',
    ru: 'Файл успешно добавлен',
    en: 'File successfully added',
    de: 'Datei erfolgreich hinzugefügt',
    fr: 'Fichier ajouté avec succès',
    es: 'Archivo agregado con éxito',
    cs: 'Soubor byl úspěšně přidán',
  },
  addFileTabListWarn: {
    ua: 'У файлі відсутній конкретний формат. Запис неможливий у такому випадку',
    ru: 'В файле отсутствует конкретный формат. Запись невозможна в этом случае',
    en: 'The file does not have a specific format. Writing is not possible in this case',
    de: 'Die Datei hat kein spezifisches Format. Das Schreiben ist in diesem Fall nicht möglich',
    fr: "Le fichier n'a pas de format spécifique. L'écriture n'est pas possible dans ce cas",
    es: 'El archivo no tiene un formato específico. No es posible escribir en este caso',
    cs: 'Soubor nemá specifický formát. V tomto případě není možné zapisovat',
  },
  addFileTabListBtn: {
    ua: 'Додати файл в чат з куратором',
    ru: 'Добавить файл в чат с куратором',
    en: 'Add file to chat with curator',
    de: 'Datei zum Chat mit dem Kurator hinzufügen',
    fr: 'Ajouter un fichier au chat avec le curateur',
    es: 'Agregar archivo al chat con el curador',
    cs: 'Přidat soubor do chatu s kurátorem',
  },
  addFileTabListLoader: {
    ua: 'Зачекайте, йде процес додавання файлу.',
    ru: 'Пожалуйста, подождите, идет процесс добавления файла.',
    en: 'Please wait, file upload in progress.',
    de: 'Bitte warten, Datei wird hochgeladen.',
    fr: 'Veuillez patienter, le téléchargement du fichier est en cours.',
    es: 'Por favor, espere, la carga del archivo está en progreso.',
    cs: 'Prosím, čekejte, probíhá nahrávání souboru.',
  },
  addFileTabListCardTitle: {
    ua: 'Форма для додавання',
    ru: 'Форма для добавления',
    en: 'Form for adding',
    de: 'Formular zum Hinzufügen',
    fr: 'Formulaire pour ajouter',
    es: 'Formulario para agregar',
    cs: 'Formulář pro přidání',
  },
  addFileTabListUploadFile: {
    ua: 'Завантажити файл',
    ru: 'Загрузить файл',
    en: 'Upload file',
    de: 'Datei hochladen',
    fr: 'Télécharger le fichier',
    es: 'Cargar archivo',
    cs: 'Nahrát soubor',
  },
  addFileTabListUpload: {
    ua: 'Завантажити',
    ru: 'Загрузить',
    en: 'Upload',
    de: 'Hochladen',
    fr: 'Télécharger',
    es: 'Cargar',
    cs: 'Nahrát',
  },
  addFileTabListCommentLabel: {
    ua: 'Коментар:',
    ru: 'Комментарий:',
    en: 'Comment:',
    de: 'Kommentar:',
    fr: 'Commentaire:',
    es: 'Comentario:',
    cs: 'Komentář:',
  },
  addFileTabListCommentPlaceholder: {
    ua: 'Додатковий опис.',
    ru: 'Дополнительное описание.',
    en: 'Additional description.',
    de: 'Zusätzliche Beschreibung.',
    fr: 'Description supplémentaire.',
    es: 'Descripción adicional.',
    cs: 'Další popis.',
  },
  descriptionOfService: {
    ua: 'Опис послуги:',
    ru: 'Описание услуги:',
    en: 'Service description:',
    de: 'Leistungsbeschreibung:',
    fr: 'Description du service:',
    es: 'Descripción del servicio:',
    cs: 'Popis služby:',
  },

  //LastEvents

  lastEvents: {
    ua: 'Останні події:',
    ru: 'Последние события:',
    en: 'Last events',
    de: 'Letzte Ereignisse',
    fr: 'Derniers événements',
    es: 'Últimos eventos',
    cs: 'Poslední události',
  },
  lastEventsAddress: {
    ua: 'Адреса',
    ru: 'Адрес',
    en: 'Address',
    de: 'Adresse',
    fr: 'Adresse',
    es: 'Dirección',
    cs: 'Adresa',
  },
  lastEventsTaskComment: {
    ua: 'Коментар до задачі',
    ru: 'Комментарий к задаче',
    en: 'Comment on the task',
    de: 'Kommentar zur Aufgabe',
    fr: 'Commentaire sur la tâche',
    es: 'Comentario sobre la tarea',
    cs: 'Komentář k úkolu',
  },
  lastEventsMessageFrom: {
    ua: 'Повідомлення від:',
    ru: 'Сообщение от:',
    en: 'A message from',
    de: 'Nachricht von:',
    fr: 'Un message de',
    es: 'Un mensaje de',
    cs: 'Zpráva od',
  },
  lastEventsTransaction: {
    ua: 'Транзакція',
    ru: 'Транзакция',
    en: 'Transaction',
    de: 'Transaktion',
    fr: 'Transaction',
    es: 'Transacción',
    cs: 'Transakce',
  },

  lastEventsGoToHotline: {
    ua: 'Перейти до консультацій',
    ru: 'Перейти к консультациям',
    en: 'Go to consultations',
    de: 'Zu den Konsultationen gehen',
    fr: 'Aller aux consultations',
    es: 'Ir a las consultas',
    cs: 'Přejít na konzultace',
  },

  // ClientContracts  Page

  // ComplaintForm
  ComplaintFormModalTitle: {
    ua: 'Поскаржитись',
    ru: 'Пожаловаться',
    en: 'Complaint',
    de: 'Beschwerde',
    fr: 'Plainte',
    es: 'Queja',
    cs: 'Stížnost',
  },
  ComplaintFormModalOkText: {
    ua: 'Відправити',
    ru: 'Отправить',
    en: 'Send',
    de: 'Senden',
    fr: 'Envoyer',
    es: 'Enviar',
    cs: 'Odeslat',
  },
  ComplaintFormTextAreaPlaceholder: {
    ua: 'Опишіть ситуацію...',
    ru: 'Опишите ситуацию...',
    en: 'Describe the situation...',
    de: 'Beschreiben Sie die Situation...',
    fr: 'Décrivez la situation...',
    es: 'Describe la situación...',
    cs: 'Popište situaci...',
  },
  ComplaintFormMinCharactersValidation: {
    ua: 'Не менше 20 символів',
    ru: 'Не менее 20 символов',
    en: 'At least 20 characters',
    de: 'Mindestens 20 Zeichen',
    fr: 'Au moins 20 caractères',
    es: 'Al menos 20 caracteres',
    cs: 'Nejméně 20 znaků',
  },
  // Curator
  CuratorTitle: {
    ua: 'Куратор:',
    ru: 'Куратор:',
    en: 'Curator:',
    de: 'Kurator:',
    fr: 'Curateur:',
    es: 'Curador:',
    cs: 'Kurátor:',
  },
  CuratorName: {
    ua: `Ім'я:`,
    ru: 'Имя:',
    en: 'Name:',
    de: 'Name:',
    fr: 'Nom:',
    es: 'Nombre:',
    cs: 'Jméno:',
  },
  CuratorButton1: {
    ua: 'Написати',
    ru: 'Написать',
    en: 'Write',
    de: 'Schreiben',
    fr: 'Écrire',
    es: 'Escribir',
    cs: 'Napsat',
  },
  CuratorButton2: {
    ua: 'Поскаржитись',
    ru: 'Пожаловаться',
    en: 'Complaint',
    de: 'Beschwerde',
    fr: 'Plainte',
    es: 'Queja',
    cs: 'Stížnost',
  },
  CuratorButton3: {
    ua: 'Завдання',
    ru: 'Задания',
    en: 'Tasks',
    de: 'Aufgaben',
    fr: 'Tâches',
    es: 'Tareas',
    cs: 'Úkoly',
  },
  // EventsItem
  eItemStateOccurred: {
    ua: 'Відбулася',
    ru: 'Произошла',
    en: 'Occurred',
    de: 'Ereignet',
    fr: "S'est produit",
    es: 'Ocurrió',
    cs: 'Dojde k tomu',
  },
  eItemStateNotOccurred: {
    ua: 'Не відбулася',
    ru: 'Не произошла',
    en: 'Not occurred',
    de: 'Nicht stattgefunden',
    fr: "Ne s'est pas produit",
    es: 'No ocurrió',
    cs: 'Nedošlo k tomu',
  },
  eItemStateInProgress: {
    ua: 'В процесі',
    ru: 'В процессе',
    en: 'In progress',
    de: 'In Bearbeitung',
    fr: 'En cours',
    es: 'En progreso',
    cs: 'V procesu',
  },
  eItemComment: {
    ua: 'Коментар:',
    ru: 'Комментарий:',
    en: 'Comment:',
    de: 'Kommentar:',
    fr: 'Commentaire:',
    es: 'Comentario:',
    cs: 'Komentář:',
  },
  // Payments
  paymentsTitle: {
    ua: 'Сплатити по договору:',
    ru: 'Оплатить по договору:',
    en: 'Pay according to the contract:',
    de: 'Zahlung gemäß Vertrag:',
    fr: 'Payer selon le contrat:',
    es: 'Pagar según el contrato:',
    cs: 'Platba podle smlouvy:',
  },
  // PaymentsItem
  paymentsItem1: {
    ua: 'id платежу',
    ru: 'ID платежа',
    en: 'Payment ID',
    de: 'Zahlungs-ID',
    fr: 'ID de paiement',
    es: 'ID de pago',
    cs: 'ID platby',
  },
  paymentsItem2: {
    ua: 'Сума',
    ru: 'Сумма',
    en: 'Amount',
    de: 'Betrag',
    fr: 'Montant',
    es: 'Cantidad',
    cs: 'Částka',
  },

  // PaymentSuccess
  paymentsSuccessText1: {
    ua: 'Дякуємо, ваш платіж прийнято',
    ru: 'Спасибо, ваш платеж принят',
    en: 'Thank you, your payment has been accepted',
    de: 'Vielen Dank, Ihre Zahlung wurde akzeptiert',
    fr: 'Merci, votre paiement a été accepté',
    es: 'Gracias, su pago ha sido aceptado',
    cs: 'Děkujeme, vaše platba byla přijata',
  },
  paymentsSuccessText2: {
    ua: 'Дану карту збережено, для наступних оплат',
    ru: 'Эта карта сохранена для будущих платежей',
    en: 'This card has been saved for future payments',
    de: 'Diese Karte wurde für zukünftige Zahlungen gespeichert',
    fr: 'Cette carte a été enregistrée pour de futurs paiements',
    es: 'Esta tarjeta ha sido guardada para futuros pagos',
    cs: 'Tato karta byla uložena pro budoucí platby',
  },
  paymentsSuccessText3: {
    ua: 'Видалити або замінити на іншу, можна в особистому кабінеті',
    ru: 'Удалить или заменить на другую можно в личном кабинете',
    en: 'You can delete or replace it with another in your personal account',
    de: 'Sie können sie in Ihrem persönlichen Konto löschen oder durch eine andere ersetzen',
    fr: 'Vous pouvez la supprimer ou la remplacer par une autre dans votre compte personnel',
    es: 'Puedes eliminarla o reemplazarla por otra en tu cuenta personal',
    cs: 'Můžete ji smazat nebo nahradit jinou ve svém osobním účtu',
  },
  paymentsSuccessText4: {
    ua: 'ID платежу',
    ru: 'ID платежа',
    en: 'Payment ID',
    de: 'Zahlungs-ID',
    fr: 'ID de paiement',
    es: 'ID de pago',
    cs: 'ID platby',
  },
  paymentsSuccessText5: {
    ua: 'Дані покупця захищено',
    ru: 'Данные покупателя защищены',
    en: 'Buyer data protected',
    de: 'Käuferdaten geschützt',
    fr: "Données de l'acheteur protégées",
    es: 'Datos del comprador protegidos',
    cs: 'Data kupujícího chráněna',
  },
  paymentsSuccessText6: {
    ua: 'Згідно з міжнародним стандартом захисту платежів PCI DSS',
    ru: 'Согласно международному стандарту защиты платежей PCI DSS',
    en: 'According to the international standard for payment protection PCI DSS',
    de: 'Gemäß dem internationalen Standard für Zahlungssicherheit PCI DSS',
    fr: 'Conformément à la norme internationale de protection des paiements PCI DSS',
    es: 'De acuerdo con la norma internacional de protección de pagos PCI DSS',
    cs: 'Podle mezinárodního standardu ochrany plateb PCI DSS',
  },
  paymentsSuccessText7: {
    ua: 'Регулюється міжнародними платіжними системами Visa та Mastercard',
    ru: 'Регулируется международными платежными системами Visa и Mastercard',
    en: 'Regulated by international payment systems Visa and Mastercard',
    de: 'Reguliert von internationalen Zahlungssystemen Visa und Mastercard',
    fr: 'Réglementé par les systèmes de paiement internationaux Visa et Mastercard',
    es: 'Regulado por los sistemas de pago internacionales Visa y Mastercard',
    cs: 'Regulováno mezinárodními platebními systémy Visa a Mastercard',
  },

  // ClientContracts2 Page

  // CC2 ContractsModal
  contractsModalTitle1: {
    ua: 'Поточний договір',
    ru: 'Текущий договор',
    en: 'Current contract',
    de: 'Aktueller Vertrag',
    fr: 'Contrat actuel',
    es: 'Contrato actual',
    cs: 'Aktuální smlouva',
  },
  contractsModalPlaceholder: {
    ua: 'Введіть нову назву',
    ru: 'Введите новое название',
    en: 'Enter a new name',
    de: 'Geben Sie einen neuen Namen ein',
    fr: 'Entrez un nouveau nom',
    es: 'Introduce un nuevo nombre',
    cs: 'Zadejte nové jméno',
  },
  contractsModalTitle2: {
    ua: 'Інші договори',
    ru: 'Другие договоры',
    en: 'Other contracts',
    de: 'Andere Verträge',
    fr: 'Autres contrats',
    es: 'Otros contratos',
    cs: 'Jiné smlouvy',
  },
  hideBtn: {
    ua: 'Сховати',
    ru: 'Скрыть',
    en: 'Hide',
    de: 'Verbergen',
    fr: 'Cacher',
    es: 'Ocultar',
    cs: 'Skrýt',
  },
  // CC2 Payments

  CC2PaymentsTitle: {
    ua: 'Оплата по договору',
    ru: 'Оплата по договору',
    en: 'Contract payment',
    de: 'Vertragszahlung',
    fr: 'Paiement du contrat',
    es: 'Pago del contrato',
    cs: 'Platba podle smlouvy',
  },
  closeBtn: {
    ua: 'Закрити',
    ru: 'Закрыть',
    en: 'Close',
    de: 'Schließen',
    fr: 'Fermer',
    es: 'Cerrar',
    cs: 'Zavřít',
  },
  CC2PaymentsIframeTitle: {
    ua: 'Інший сайт',
    ru: 'Другой сайт',
    en: 'Another website',
    de: 'Andere Website',
    fr: 'Autre site',
    es: 'Otro sitio',
    cs: 'Jiná webová stránka',
  },
  // CC2 ContractItem
  contractItem: {
    ua: 'Справа',
    ru: 'Дело',
    en: 'Case',
    de: 'Fall',
    fr: 'Affaire',
    es: 'Caso',
    cs: 'Případ',
  },

  contractIsArchived: {
    ua: 'Архівний',
    ru: 'Архивный',
    en: 'Archived',
    de: 'Archiviert',
    fr: 'Archivé',
    es: 'Archivado',
    cs: 'Archivováno',
  },

  // ClientFeedbacks Page

  // FeedbackAnswer
  FeedbackAnswer: {
    ua: 'З повагою,',
    ru: 'С уважением,',
    en: 'Best regards,',
    de: 'Mit freundlichen Grüßen,',
    fr: 'Cordialement,',
    es: 'Atentamente,',
    cs: 'S pozdravem,',
  },

  // FeedbacksForm
  feedbacksFormInputName: {
    ua: `Введіть ім'я`,
    ru: `Введите имя`,
    en: 'Enter your name',
    de: 'Geben Sie Ihren Namen ein',
    fr: 'Entrez votre nom',
    es: 'Ingrese su nombre',
    cs: 'Zadejte své jméno',
  },
  feedbacksFormEmail: {
    ua: 'Введіть електронну пошту',
    ru: 'Введите электронную почту',
    en: 'Enter your email',
    de: 'Geben Sie Ihre E-Mail-Adresse ein',
    fr: 'Entrez votre email',
    es: 'Ingrese su correo electrónico',
    cs: 'Zadejte svůj e-mail',
  },
  feedbacksFormRating: {
    ua: 'Виберіть рейтинг',
    ru: 'Выберите рейтинг',
    en: 'Select rating',
    de: 'Bewertung auswählen',
    fr: 'Sélectionnez une note',
    es: 'Selecciona una calificación',
    cs: 'Vyberte hodnocení',
  },
  feedbacksFormFeedback: {
    ua: 'Напишіть ваш відгук',
    ru: 'Напишите ваш отзыв',
    en: 'Write your feedback',
    de: 'Schreiben Sie Ihr Feedback',
    fr: 'Écrivez vos commentaires',
    es: 'Escriba su opinión',
    cs: 'Napište svůj názor',
  },
  feedbacksFormTitle: {
    ua: 'Залиште відгук',
    ru: 'Оставьте отзыв',
    en: 'Leave feedback',
    de: 'Feedback hinterlassen',
    fr: 'Laissez un commentaire',
    es: 'Deja tu opinión',
    cs: 'Zanechte zpětnou vazbu',
  },

  feedbackBonusDefaultTitle: {
    ua: 'Вітаємо!',
    ru: 'Поздравляем!',
    en: 'Congratulations!',
    de: 'Herzlichen Glückwunsch!',
    fr: 'Félicitations!',
    es: '¡Felicidades!',
    cs: 'Gratulujeme!',
  },

  feedbackBonusModalTitle: {
    ua: 'Залиште відгук, та отримайте бонус!',
    ru: 'Оставьте отзыв и получите бонус!',
    en: 'Leave a review and get a bonus!',
    de: 'Hinterlassen Sie eine Bewertung und erhalten Sie einen Bonus!',
    fr: 'Laissez un avis et recevez un bonus !',
    es: '¡Deje una reseña y obtenga un bono!',
    cs: 'Zanechte recenzi a získejte bonus!',
  },

  feedbackBonusModalText1: {
    ua: 'Ви отримали 1000 грн на бонусний рахунок',
    ru: 'Вы получили 1000 грн на бонусный счет',
    en: 'You received 1000 UAH to your bonus account',
    de: 'Sie haben 1000 UAH auf Ihr Bonuskonto erhalten',
    fr: 'Vous avez reçu 1000 UAH sur votre compte bonus',
    es: 'Ha recibido 1000 UAH en su cuenta de bonificación',
    cs: 'Obdrželi jste 1000 UAH na svůj bonusový účet',
  },

  feedbackBonusModalText2: {
    ua: 'Опишіть свої враження від роботи з нами у відгуках в Google та отримайте',
    ru: 'Опишите свои впечатления от работы с нами в отзывах на Google и получите',
    en: 'Describe your experience with us in Google reviews and get',
    de: 'Beschreiben Sie Ihre Erfahrungen mit uns in den Google-Bewertungen und erhalten Sie',
    fr: 'Décrivez votre expérience avec nous dans les avis Google et recevez',
    es: 'Describa su experiencia con nosotros en las reseñas de Google y obtenga',
    cs: 'Popište své zkušenosti s námi v recenzích na Google a získejte',
  },

  feedbackBonusModalText3: {
    ua: '1000 грн',
    ru: '1000 грн',
    en: '1000 UAH',
    de: '1000 UAH',
    fr: '1000 UAH',
    es: '1000 UAH',
    cs: '1000 UAH',
  },

  feedbackBonusModalText4: {
    ua: 'на бонусний рахунок',
    ru: 'на бонусный счет',
    en: 'to your bonus account',
    de: 'auf Ihr Bonuskonto',
    fr: 'sur votre compte bonus',
    es: 'a su cuenta de bonificación',
    cs: 'na svůj bonusový účet',
  },

  feedbacksFormText: {
    ua: 'Опишіть ваше враження від співпраці з нами, що Вам сподобалось, а що ні. Дякуючи вашим відгукам, ми розвиваємось і стаємо кращими.',
    ru: 'Опишите ваше впечатление от сотрудничества с нами, что вам понравилось, а что нет. Благодаря вашим отзывам, мы развиваемся и становимся лучше.',
    en: "Describe your experience of working with us, what you liked and what you didn't like. Thanks to your feedback, we grow and become better.",
    de: 'Beschreiben Sie Ihre Erfahrung mit uns, was Ihnen gefallen hat und was nicht. Dank Ihres Feedbacks wachsen wir und werden besser.',
    fr: "Décrivez votre expérience de travail avec nous, ce que vous avez aimé et ce que vous n'avez pas aimé. Grâce à vos commentaires, nous grandissons et nous améliorons.",
    es: 'Describa su experiencia de trabajo con nosotros, lo que le gustó y lo que no le gustó. Gracias a sus comentarios, crecemos y mejoramos.',
    cs: 'Popište svou zkušenost s námi, co se vám líbilo a co ne. Díky vaší zpětné vazbě rosteme a zlepšujeme se.',
  },
  feedbacksFormYourRating: {
    ua: 'Ваша оцінка:',
    ru: 'Ваша оценка:',
    en: 'Your rating:',
    de: 'Ihre Bewertung:',
    fr: 'Votre note :',
    es: 'Tu calificación:',
    cs: 'Vaše hodnocení:',
  },
  feedbacksFormName: {
    ua: `Ваше ім'я`,
    ru: `Ваше имя`,
    en: 'Your name',
    de: 'Ihr Name',
    fr: 'Votre nom',
    es: 'Tu nombre',
    cs: 'Vaše jméno',
  },

  // sendButton

  // ClientHome   Page

  clientHomeBalanceNew: {
    ua: 'Бонуси:',
    ru: 'Бонусы:',
    en: 'Bonuses:',
    de: 'Bonuspunkte:',
    fr: 'Bonus:',
    es: 'Bonos:',
    cs: 'Bonusy:',
  },
  clientHomeTransactionsTitle1: {
    ua: 'Транзакції по бонусам',
    ru: 'Транзакции по бонусам',
    en: 'Bonus transactions',
    de: 'Bonus-Transaktionen',
    fr: 'Transactions de bonus',
    es: 'Transacciones de bonificación',
    cs: 'Bonusové transakce',
  },
  clientHomeTransactionsTitle2: {
    ua: 'Транзакції по рахунку',
    ru: 'Транзакции по счету',
    en: 'Account transactions',
    de: 'Konto-Transaktionen',
    fr: 'Transactions de compte',
    es: 'Transacciones de cuenta',
    cs: 'Účetní transakce',
  },

  clientBalancePayModalPlaceholder: {
    ua: 'Введіть суму поповнення',
    ru: 'Введите сумму пополнения',
    en: 'Enter top-up amount',
    de: 'Geben Sie den Aufladebetrag ein',
    fr: 'Entrez le montant du rechargement',
    es: 'Ingrese la cantidad de recarga',
    cs: 'Zadejte částku dobíjení',
  },
  clientBalancePayModalButtonText: {
    ua: 'Поповнити',
    ru: 'Пополнить',
    en: 'Top Up',
    de: 'Aufladen',
    fr: 'Recharger',
    es: 'Recargar',
    cs: 'Dobít',
  },

  // Bonuses
  clientBonuses1: {
    ua: 'Сума послуги',
    ru: 'Сумма услуги',
    en: 'Service amount',
    de: 'Servicebetrag',
    fr: 'Montant du service',
    es: 'Monto del servicio',
    cs: 'Částka za službu',
  },
  clientBonuses2: {
    ua: 'Використати бонусів',
    ru: 'Использовать бонусы',
    en: 'Use bonuses',
    de: 'Bonusse verwenden',
    fr: 'Utiliser des bonus',
    es: 'Usar bonos',
    cs: 'Použít bonusy',
  },
  clientBonuses3: {
    ua: 'є на рахунку',
    ru: 'есть на счету',
    en: 'available on account',
    de: 'auf dem Konto verfügbar',
    fr: 'disponible sur le compte',
    es: 'disponible en la cuenta',
    cs: 'dostupné na účtu',
  },
  clientBonuses4: {
    ua: 'Недостатньо бонусів',
    ru: 'Недостаточно бонусов',
    en: 'Insufficient bonuses',
    de: 'Nicht genug Bonusse',
    fr: 'Pas assez de bonus',
    es: 'Bonos insuficientes',
    cs: 'Nedostatečné bonusy',
  },
  clientBonuses5: {
    ua: 'До сплати:',
    ru: 'К оплате:',
    en: 'To pay:',
    de: 'Zu zahlen:',
    fr: 'À payer :',
    es: 'A pagar:',
    cs: 'K úhradě:',
  },
  clientBonuses6: {
    ua: 'Використати бонусів:',
    ru: 'Использовать бонусов:',
    en: 'Use bonuses:',
    de: 'Bonusse verwenden:',
    fr: 'Utiliser des bonus :',
    es: 'Usar bonos:',
    cs: 'Použít bonusy:',
  },

  // ClientHotline   Page
  clientHotlineTitle: {
    ua: 'Для запису на консультацію завершіть реєстрацію',
    ru: 'Чтобы записаться на консультацию, завершите регистрацию',
    en: 'To schedule a consultation, please complete the registration',
    de: 'Um eine Beratung zu vereinbaren, bitte vervollständigen Sie die Registrierung',
    fr: "Pour planifier une consultation, veuillez compléter l'inscription",
    es: 'Para programar una consulta, complete el registro',
    cs: 'Chcete-li naplánovat konzultaci, prosím, dokončete registraci',
  },
  clientHotlineSuccessMessage1: {
    ua: 'Послуга:',
    ru: 'Услуга:',
    en: 'Service:',
    de: 'Dienstleistung:',
    fr: 'Service :',
    es: 'Servicio:',
    cs: 'Služba:',
  },
  clientHotlineSuccessMessage2: {
    ua: 'Філія:',
    ru: 'Филиал:',
    en: 'Branch:',
    de: 'Niederlassung:',
    fr: 'Branche :',
    es: 'Sucursal:',
    cs: 'Pobočka:',
  },
  clientHotlineSuccessMessage3: {
    ua: 'Дата:',
    ru: 'Дата:',
    en: 'Date:',
    de: 'Datum:',
    fr: 'Date :',
    es: 'Fecha:',
    cs: 'Datum:',
  },
  clientHotlineSuccessMessage4: {
    ua: 'Початок:',
    ru: 'Начало:',
    en: 'Start time:',
    de: 'Startzeit:',
    fr: 'Heure de début :',
    es: 'Hora de inicio:',
    cs: 'Čas začátku:',
  },
  clientHotlineSuccessMessage5: {
    ua: 'Завершення:',
    ru: 'Завершение:',
    en: 'End time:',
    de: 'Endzeit:',
    fr: 'Heure de fin:',
    es: 'Hora de finalización:',
    cs: 'Konec:',
  },
  clientHotlineSuccessMessage6: {
    ua: 'Будь ласка, очікуйте на підтвердження.',
    ru: 'Пожалуйста, ждите подтверждения.',
    en: 'Please, wait for confirmation.',
    de: 'Bitte warten Sie auf die Bestätigung.',
    fr: 'Veuillez attendre la confirmation.',
    es: 'Por favor, espere la confirmación.',
    cs: 'Prosím, čekejte na potvrzení.',
  },
  clientHotlineSubmitButton: {
    ua: 'Гаразд',
    ru: 'Хорошо',
    en: 'OK',
    de: 'OK',
    fr: "D'accord",
    es: 'Aceptar',
    cs: 'Dobře',
  },

  // ClientInsuranceDriver   Page
  clientIDLoading: {
    ua: 'Завантаження',
    ru: 'Загрузка',
    en: 'Loading',
    de: 'Wird geladen',
    fr: 'Chargement',
    es: 'Cargando',
    cs: 'Načítání',
  },
  clientIDMissing: {
    ua: 'Страховки відсутні',
    ru: 'Страховка отсутствует',
    en: 'Insurance is missing',
    de: 'Versicherung fehlt',
    fr: "L'assurance est manquante",
    es: 'Falta el seguro',
    cs: 'Pojištění chybí',
  },

  // Buttons
  clientIDBtn1: {
    ua: 'Умови',
    ru: 'Условия',
    en: 'Terms',
    de: 'Bedingungen',
    fr: 'Conditions',
    es: 'Términos',
    cs: 'Podmínky',
  },
  clientIDBtn2: {
    ua: 'Транзакції',
    ru: 'Транзакции',
    en: 'Transactions',
    de: 'Transaktionen',
    fr: 'Transactions',
    es: 'Transacciones',
    cs: 'Transakce',
  },

  // ClientLogin   Page
  clientLoginError: {
    ua: 'Не вірні данні',
    ru: 'Неверные данные',
    en: 'Invalid credentials',
    de: 'Ungültige Anmeldeinformationen',
    fr: 'Identifiants invalides',
    es: 'Credenciales no válidas',
    cs: 'Neplatné přihlašovací údaje',
  },
  clientLoginRule: {
    ua: 'Для запису потрібно авторизуватись',
    ru: 'Для записи нужно авторизоваться',
    en: 'You need to log in to register',
    de: 'Sie müssen sich anmelden, um sich zu registrieren',
    fr: 'Vous devez vous connecter pour vous inscrire',
    es: 'Necesita iniciar sesión para registrarse',
    cs: 'Musíte se přihlásit, abyste se zaregistrovali',
  },
  clientBkConsultationRule: {
    ua: 'Для отримання безкоштовної консультації необхідно авторизуватися',
    ru: 'Для получения бесплатной консультации необходимо авторизоваться',
    en: 'You need to log in to get a free consultation',
    de: 'Sie müssen sich anmelden, um eine kostenlose Beratung zu erhalten',
    fr: 'Vous devez vous connecter pour obtenir une consultation gratuite',
    es: 'Necesita iniciar sesión para obtener una consulta gratuita',
    cs: 'Musíte se přihlásit, abyste získali bezplatnou konzultaci',
  },
  clientLoginTitle: {
    ua: 'Кабінет клієнта',
    ru: 'Кабинет клиента',
    en: 'Client Cabinet',
    de: 'Kundenkonto',
    fr: 'Cabinet du client',
    es: 'Gabinete del cliente',
    cs: 'Klientská kancelář',
  },
  clientLoginPhoneMes: {
    ua: 'Телефон не коректний!',
    ru: 'Телефон некорректный!',
    en: 'Phone number is incorrect!',
    de: 'Telefonnummer ist ungültig!',
    fr: 'Le numéro de téléphone est incorrect!',
    es: '¡El número de teléfono es incorrecto!',
    cs: 'Telefonní číslo je neplatné!',
  },
  clientLoginQuestion1: {
    ua: 'Не маєте акаунту?',
    ru: 'Нет аккаунта?',
    en: "Don't have an account?",
    de: 'Sie haben kein Konto?',
    fr: "Vous n'avez pas de compte?",
    es: '¿No tienes una cuenta?',
    cs: 'Nemáte účet?',
  },
  clientLoginQuestion2: {
    ua: 'Забули пароль?',
    ru: 'Забыли пароль?',
    en: 'Forgot your password?',
    de: 'Passwort vergessen?',
    fr: 'Mot de passe oublié?',
    es: '¿Olvidaste tu contraseña?',
    cs: 'Zapomněli jste heslo?',
  },
  clientLoginButton1: {
    ua: 'Зареєструватися',
    ru: 'Зарегистрироваться',
    en: 'Register',
    de: 'Registrieren',
    fr: "S'inscrire",
    es: 'Registrarse',
    cs: 'Registrovat se',
  },
  clientLoginButton2: {
    ua: 'Відновити доступ',
    ru: 'Восстановить доступ',
    en: 'Restore access',
    de: 'Zugriff wiederherstellen',
    fr: "Restaurer l'accès",
    es: 'Restaurar acceso',
    cs: 'Obnovit přístup',
  },

  // ClientMainPage   Page

  // ClientMainPageHotlineItem
  clientMPHItemClockCircleFilled: {
    ua: 'Очікуйте підтвердження',
    ru: 'Ожидайте подтверждения',
    en: 'Awaiting confirmation',
    de: 'Warten auf Bestätigung',
    fr: 'En attente de confirmation',
    es: 'Esperando confirmación',
    cs: 'Čeká se na potvrzení',
  },

  // ClientMainPageHotlines
  clientBalancePayModalTitle: {
    ua: 'Подяка юристу',
    ru: 'Благодарность юристу',
    en: 'Thank Your Lawyer',
    de: 'Dank an Ihren Anwalt',
    fr: 'Remerciez votre avocat',
    es: 'Agradece a tu abogado',
    cs: 'Poděkování vašemu právníkovi',
  },
  clientMainPageHotlinesModalPlaceholder: {
    ua: 'Введіть суму подяки',
    ru: 'Введите сумму благодарности',
    en: 'Enter gratitude amount',
    de: 'Geben Sie den Dankbetrag ein',
    fr: 'Entrez le montant de la gratitude',
    es: 'Introduce la cantidad de agradecimiento',
    cs: 'Zadejte částku vděčnosti',
  },

  // ClientProfile   Page
  clientProfileError1: {
    ua: 'Можливо завантажити фото тільки в JPG форматі!',
    ru: 'Можно загружать фотографии только в формате JPG!',
    en: 'Only JPG format is allowed for photo upload!',
    de: 'Nur das JPG-Format ist für den Fotoupload zulässig!',
    fr: 'Seul le format JPG est autorisé pour le téléchargement de photos !',
    es: '¡Solo se permite el formato JPG para la carga de fotos!',
    cs: 'Pouze formát JPG je povolen pro nahrávání fotografií!',
  },

  clientProfileError2: {
    ua: 'Розмір файлу повинен бути менше 2 мегабайт!',
    ru: 'Размер файла должен быть меньше 2 мегабайт!',
    en: 'File size must be less than 2 megabytes!',
    de: 'Die Dateigröße muss kleiner als 2 Megabyte sein!',
    fr: 'La taille du fichier doit être inférieure à 2 mégaoctets!',
    es: '¡El tamaño del archivo debe ser menor de 2 megabytes!',
    cs: 'Velikost souboru musí být menší než 2 megabajty!',
  },

  clientProfileError3: {
    ua: 'Помилка, Помилка завантаження, спробуйте ще раз',
    ru: 'Ошибка, ошибка загрузки, попробуйте еще раз',
    en: 'Error, Upload error, please try again',
    de: 'Fehler, Upload-Fehler, bitte versuchen Sie es erneut',
    fr: 'Erreur, erreur de téléchargement, veuillez réessayer',
    es: 'Error, error de carga, por favor intente de nuevo',
    cs: 'Chyba, chyba nahrávání, zkuste to prosím znovu',
  },

  clientProfileSuccess: {
    ua: 'Успіх! Ваш профіль оновлено успішно!',
    ru: 'Успех! Ваш профиль успешно обновлен!',
    en: 'Success! Your profile has been updated successfully!',
    de: 'Erfolg! Ihr Profil wurde erfolgreich aktualisiert!',
    fr: 'Succès! Votre profil a été mis à jour avec succès!',
    es: '¡Éxito! ¡Su perfil ha sido actualizado con éxito!',
    cs: 'Úspěch! Váš profil byl úspěšně aktualizován!',
  },

  clientProfileFullName: {
    ua: 'ПІБ',
    ru: 'ФИО',
    en: 'Full Name',
    de: 'Vollständiger Name',
    fr: 'Nom complet',
    es: 'Nombre completo',
    cs: 'Celé jméno',
  },

  ProfileEmail: {
    ua: 'Електронна пошта',
    ru: 'Электронная почта',
    en: 'Email',
    de: 'E-Mail',
    fr: 'E-mail',
    es: 'Correo electrónico',
    cs: 'E-mail',
  },

  accountDeletionTitle: {
    ua: 'Видалити аккаунт',
    ru: 'Удалить аккаунт',
    en: 'Delete Account',
    de: 'Konto löschen',
    fr: 'Supprimer le compte',
    es: 'Eliminar cuenta',
    cs: 'Smazat účet',
  },

  accountDeletionButton1: {
    ua: 'Відмінити',
    ru: 'Отменить',
    en: 'Cancel',
    de: 'Abbrechen',
    fr: 'Annuler',
    es: 'Cancelar',
    cs: 'Zrušit',
  },

  accountDeletionButton2: {
    ua: 'Редагувати Профіль',
    ru: 'Редактировать профиль',
    en: 'Edit Profile',
    de: 'Profil bearbeiten',
    fr: 'Modifier le profil',
    es: 'Editar perfil',
    cs: 'Upravit profil',
  },

  accountDeletionModalTitle: {
    ua: 'Ви Впевнені у видаленні аккаунту?',
    ru: 'Вы уверены, что хотите удалить аккаунт?',
    en: 'Are you sure you want to delete your account?',
    de: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?',
    fr: 'Êtes-vous sûr de vouloir supprimer votre compte?',
    es: '¿Está seguro de que desea eliminar su cuenta?',
    cs: 'Jste si jisti, že chcete smazat svůj účet?',
  },

  accountDeletionModalEnterPhone: {
    ua: 'Для підтвердження введіть ваш номер телефону:',
    ru: 'Для подтверждения введите ваш номер телефона:',
    en: 'To confirm, enter your phone number:',
    de: 'Geben Sie zur Bestätigung Ihre Telefonnummer ein:',
    fr: 'Pour confirmer, entrez votre numéro de téléphone:',
    es: 'Para confirmar, ingrese su número de teléfono:',
    cs: 'Pro potvrzení zadejte své telefonní číslo:',
  },

  accountDeletionModalEnterPhonePlaceholder: {
    ua: 'Введіть ваш номер телефону...',
    ru: 'Введите ваш номер телефона...',
    en: 'Enter your phone number...',
    de: 'Geben Sie Ihre Telefonnummer ein...',
    fr: 'Entrez votre numéro de téléphone...',
    es: 'Ingrese su número de teléfono...',
    cs: 'Zadejte své telefonní číslo...',
  },

  accountDeletionModalEnterPhoneError: {
    ua: 'Номер телефону не співпадає',
    ru: 'Номер телефона не совпадает',
    en: 'Phone number does not match',
    de: 'Telefonnummer stimmt nicht überein',
    fr: 'Le numéro de téléphone ne correspond pas',
    es: 'El número de teléfono no coincide',
    cs: 'Telefonní číslo se neshoduje',
  },

  changingPhone: {
    ua: 'Зміна номера телефону',
    ru: 'Смена номера телефона',
    en: 'Changing Phone Number',
    de: 'Telefonnummer ändern',
    fr: 'Changement de numéro de téléphone',
    es: 'Cambio de número de teléfono',
    cs: 'Změna telefonního čísla',
  },

  changingEmail: {
    ua: 'Зміна електронної пошти',
    ru: 'Смена электронной почты',
    en: 'Changing Email',
    de: 'E-Mail-Adresse ändern',
    fr: "Changement d'email",
    es: 'Cambio de correo electrónico',
    cs: 'Změna e-mailu',
  },

  // ClientReg   Page

  clientRegError1: {
    ua: 'Клієнт з таким номером вже існує',
    ru: 'Клиент с таким номером уже существует',
    en: 'Client with this phone number already exists',
    de: 'Kunde mit dieser Telefonnummer existiert bereits',
    fr: 'Un client avec ce numéro de téléphone existe déjà',
    es: 'El cliente con este número de teléfono ya existe',
    cs: 'Klient s tímto telefonním číslem již existuje',
  },

  clientRegError2: {
    ua: 'Помилка реєстрації',
    ru: 'Ошибка регистрации',
    en: 'Registration error',
    de: 'Registrierungsfehler',
    fr: "Erreur d'inscription",
    es: 'Error de registro',
    cs: 'Chyba registrace',
  },

  clientRegSuccess: {
    ua: 'Реєстрація пройшла успішно',
    ru: 'Регистрация прошла успешно',
    en: 'Registration successful',
    de: 'Registrierung erfolgreich',
    fr: 'Inscription réussie',
    es: 'Registro exitoso',
    cs: 'Registrace byla úspěšná',
  },

  clientRegPageTitle: {
    ua: 'Адвокатське об’єднання «Захист»',
    en: 'Advocacy Association «Zahist»',
    de: 'Anwaltsverband «Zahist»',
    ru: 'Адвокатское объединение «Защита»',
    fr: "Association d'avocats «Zahist»",
    es: 'Asociación de Abogados «Zahist»',
    cs: 'Advokátní sdružení «Zahist»',
  },

  clientRegTitle1: {
    ua: 'Для запису потрібно зареєструватись',
    ru: 'Для записи нужно зарегистрироваться',
    en: 'Registration required to sign up',
    de: 'Registrierung erforderlich, um sich anzumelden',
    fr: "Inscription requise pour s'inscrire",
    es: 'Se requiere registro para inscribirse',
    cs: 'Registrace je nutná pro přihlášení',
  },

  clientRegTitle2: {
    ua: 'Реєстрація клієнта',
    ru: 'Регистрация клиента',
    en: 'Client registration',
    de: 'Kundenregistrierung',
    fr: 'Inscription du client',
    es: 'Registro del cliente',
    cs: 'Registrace klienta',
  },

  clientRegStateLabel: {
    ua: 'Область',
    ru: 'Область',
    en: 'Region',
    de: 'Region',
    fr: 'Région',
    es: 'Región',
    cs: 'Oblast',
  },

  // clientBtn3
  clientRegPhoneMessage1: {
    ua: 'Будь ласка, введіть номер телефону!',
    ru: 'Пожалуйста, введите номер телефона!',
    de: 'Bitte geben Sie Ihre Telefonnummer ein!',
    en: 'Please enter your phone number!',
    fr: 'Veuillez entrer votre numéro de téléphone !',
    es: '¡Por favor ingrese su número de teléfono!',
    cs: 'Prosím, zadejte své telefonní číslo!',
  },
  clientRegPhoneMessage2: {
    ua: 'Номер телефону повинен містити 10 цифр (0681111111).',
    ru: 'Номер телефона должен содержать 10 цифр (0681111111).',
    de: 'Die Telefonnummer muss 10 Ziffern enthalten (0681111111).',
    en: 'The phone number must contain 10 digits (0681111111).',
    fr: 'Le numéro de téléphone doit contenir 10 chiffres (0681111111).',
    es: 'El número de teléfono debe contener 10 dígitos (0681111111).',
    cs: 'Telefonní číslo musí obsahovat 10 číslic (0681111111).',
  },
  clientRegNameMessage: {
    ua: 'Будь ласка, введіть ПІБ!',
    ru: 'Пожалуйста, введите ФИО!',
    de: 'Bitte geben Sie Ihren vollständigen Namen ein!',
    en: 'Please enter your full name!',
    fr: 'Veuillez entrer votre nom complet !',
    es: '¡Por favor ingrese su nombre completo!',
    cs: 'Prosím, zadejte své celé jméno!',
  },
  clientRegStateMessage: {
    ua: 'Будь ласка, введіть свою область!',
    ru: 'Пожалуйста, введите свой регион!',
    de: 'Bitte geben Sie Ihre Region ein!',
    en: 'Please enter your region!',
    fr: 'Veuillez entrer votre région !',
    es: '¡Por favor ingrese su región!',
    cs: 'Prosím, zadejte svůj region!',
  },
  clientRegPasswordMessage: {
    ua: 'Будь ласка, введіть пароль!',
    ru: 'Пожалуйста, введите пароль!',
    de: 'Bitte geben Sie Ihr Passwort ein!',
    en: 'Please enter your password!',
    fr: 'Veuillez entrer votre mot de passe !',
    es: '¡Por favor ingrese su contraseña!',
    cs: 'Prosím, zadejte své heslo!',
  },
  clientRegConfirmPasswordMessage: {
    ua: 'Будь-ласка, підтвердіть пароль!',
    ru: 'Пожалуйста, подтвердите пароль!',
    de: 'Bitte bestätigen Sie Ihr Passwort!',
    en: 'Please confirm your password!',
    fr: 'Veuillez confirmer votre mot de passe !',
    es: '¡Por favor confirme su contraseña!',
    cs: 'Prosím, potvrďte své heslo!',
  },
  clientRegPivacyPolicy: {
    ua: 'Будь ласка, ознайомтеся з політикою конфіденційності.',
    ru: 'Пожалуйста, ознакомьтесь с политикой конфиденциальности.',
    de: 'Bitte lesen Sie die Datenschutzrichtlinie.',
    en: 'Please read the privacy policy.',
    fr: 'Veuillez lire la politique de confidentialité.',
    es: 'Por favor, lea la política de privacidad.',
    cs: 'Prosím, přečtěte si zásady ochrany osobních údajů.',
  },
  clientRegPivacyPolicyCheckbox1: {
    ua: 'Я погоджуюсь з',
    ru: 'Я согласен с',
    de: 'Ich stimme der',
    en: 'I agree to the ',
    fr: "J'accepte les",
    es: 'Acepto la',
    cs: 'Souhlasím s',
  },
  clientRegPivacyPolicyCheckbox2: {
    ua: 'політикою конфіденційності',
    ru: 'политикой конфиденциальности',
    de: 'Datenschutzrichtlinie zu',
    en: 'privacy policy',
    fr: 'politique de confidentialité',
    es: 'política de privacidad',
    cs: 'zásadou ochrany osobních údajů',
  },
  clientRegQuestion: {
    ua: 'Вже зареєстровані?',
    ru: 'Уже зарегистрированы?',
    de: 'Bereits registriert?',
    en: 'Already registered?',
    fr: 'Déjà enregistré ?',
    es: '¿Ya registrado?',
    cs: 'Již zaregistrováni?',
  },
  clientRegText: {
    ua: 'Для видалення облікового запису або отримання додаткової інформації, зверніться в службу підтримки на сторінці контактів.',
    ru: 'Чтобы удалить свою учетную запись или получить дополнительную информацию, обратитесь в службу поддержки на странице контактов.',
    de: 'Um Ihr Konto zu löschen oder weitere Informationen zu erhalten, wenden Sie sich bitte an den Support auf der Kontaktseite.',
    en: 'To delete your account or get additional information, please contact support on the contact page.',
    fr: 'Pour supprimer votre compte ou obtenir des informations supplémentaires, veuillez contacter le support sur la page de contact.',
    es: 'Para eliminar su cuenta o obtener información adicional, comuníquese con el soporte en la página de contacto.',
    cs: 'Pro smazání svého účtu nebo získání dalších informací se prosím obraťte na podporu na kontaktní stránce.',
  },
  // ClientTenders   Page
  clientTendersTitle: {
    ua: 'Для створення тендеру завершіть реєстрацію',
    ru: 'Для создания тендера завершите регистрацию',
    de: 'Vervollständigen Sie die Registrierung, um eine Ausschreibung zu erstellen',
    en: 'Complete registration to create a tender',
    fr: "Complétez l'inscription pour créer un appel d'offres",
    es: 'Complete el registro para crear una licitación',
    cs: 'Dokončete registraci pro vytvoření výběrového řízení',
  },
  // ClientTenderInfo
  clientTTenderInfoTitle1: {
    ua: 'Поки що ніхто не відповів, потрібно зачекати',
    ru: 'Пока никто не ответил, нужно подождать',
    de: 'Noch keine Antworten, bitte warten',
    en: 'No responses yet, please wait',
    fr: 'Pas encore de réponses, veuillez patienter',
    es: 'Aún no hay respuestas, por favor espere',
    cs: 'Zatím žádné odpovědi, prosím čekejte',
  },
  clientTTenderInfoTitle2: {
    ua: 'Обраний виконавець',
    ru: 'Выбранный исполнитель',
    de: 'Ausgewählter Ausführender',
    en: 'Selected performer',
    fr: 'Artisan sélectionné',
    es: 'Ejecutor seleccionado',
    cs: 'Vybraný vykonavatel',
  },
  clientTTenderInfoTitle3: {
    ua: 'Перелік заявок',
    ru: 'Перечень заявок',
    de: 'Liste der Bewerbungen',
    en: 'List of applications',
    fr: 'Liste des candidatures',
    es: 'Lista de solicitudes',
    cs: 'Seznam žádostí',
  },
  clientTTenderDeleteTitle: {
    ua: 'Видалити тендер',
    ru: 'Удалить тендер',
    de: 'Ausschreibung löschen',
    en: 'Delete Tender',
    fr: "Supprimer l'appel d'offres",
    es: 'Eliminar licitación',
    cs: 'Smazat tendr',
  },

  // RespondItem
  respondItemSuccess: {
    ua: 'Пропозицію прийнято',
    ru: 'Предложение принято',
    de: 'Vorschlag akzeptiert',
    en: 'Proposal accepted',
    fr: 'Proposition acceptée',
    es: 'Propuesta aceptada',
    cs: 'Návrh přijat',
  },
  respondItem1: {
    ua: 'Ім`я:',
    ru: 'Имя:',
    de: 'Name:',
    en: 'Name:',
    fr: 'Nom :',
    es: 'Nombre:',
    cs: 'Jméno:',
  },
  respondItem2: {
    ua: 'Дата:',
    ru: 'Дата:',
    de: 'Datum:',
    en: 'Date:',
    fr: 'Date :',
    es: 'Fecha:',
    cs: 'Datum:',
  },
  respondItem3: {
    ua: 'Термін:',
    ru: 'Срок:',
    de: 'Frist:',
    en: 'Deadline:',
    fr: 'Date limite :',
    es: 'Fecha límite:',
    cs: 'Termín:',
  },
  respondItem4: {
    ua: 'Сума:',
    ru: 'Сумма:',
    de: 'Betrag:',
    en: 'Amount:',
    fr: 'Montant :',
    es: 'Cantidad:',
    cs: 'Částka:',
  },
  respondItem5: {
    ua: 'Пропозиція:',
    ru: 'Предложение:',
    de: 'Vorschlag:',
    en: 'Proposal:',
    fr: 'Proposition :',
    es: 'Propuesta:',
    cs: 'Návrh:',
  },
  respondItem6: {
    ua: 'Результат:',
    ru: 'Результат:',
    de: 'Ergebnis:',
    en: 'Result:',
    fr: 'Résultat :',
    es: 'Resultado:',
    cs: 'Výsledek:',
  },

  // ClientTendersList
  clientTendersListTotle: {
    ua: 'Тендери відсутні',
    ru: 'Тендеры отсутствуют',
    de: 'Keine Ausschreibungen vorhanden',
    en: 'No tenders available',
    fr: "Aucun appel d'offres disponible",
    es: 'No hay licitaciones disponibles',
    cs: 'Žádné tendry nejsou k dispozici',
  },
  // CreateTender
  createTenderSuccess: {
    ua: 'Заявка надана',
    ru: 'Заявка подана',
    de: 'Ausschreibung erfolgreich erstellt',
    en: 'Tender created successfully',
    fr: "Appel d'offres créé avec succès",
    es: 'Licitación creada con éxito',
    cs: 'Tendr byl úspěšně vytvořen',
  },
  createTenderProblem: {
    ua: 'Опишіть проблему',
    ru: 'Опишите проблему',
    de: 'Beschreiben Sie das Problem',
    en: 'Describe the problem',
    fr: 'Décrivez le problème',
    es: 'Describa el problema',
    cs: 'Popište problém',
  },
  createTenderTask: {
    ua: 'Опишіть задачу',
    ru: 'Опишите задачу',
    de: 'Beschreiben Sie die Aufgabe',
    en: 'Describe the task',
    fr: 'Décrivez la tâche',
    es: 'Describa la tarea',
    cs: 'Popište úkol',
  },
  createTenderSum: {
    ua: 'Запропонувати суму',
    ru: 'Предложить сумму',
    de: 'Betrag vorschlagen',
    en: 'Propose a sum',
    fr: 'Proposez un montant',
    es: 'Proponer una suma',
    cs: 'Navrhnout částku',
  },
  createTenderSumPlaceholder: {
    ua: 'Запропонувати гонорар адвокату ₴',
    ru: 'Предложить гонорар адвокату ₴',
    de: 'Betrag für den Anwalt vorschlagen ₴',
    en: 'Propose a fee for the lawyer ₴',
    fr: 'Proposez un tarif pour l’avocat ₴',
    es: 'Proponer una tarifa para el abogado ₴',
    cs: 'Navrhnout odměnu pro právníka ₴',
  },
  createTenderTerm: {
    ua: 'Термін виконання',
    ru: 'Срок выполнения',
    de: 'Ausführungsfrist',
    en: 'Execution term',
    fr: "Délai d'exécution",
    es: 'Plazo de ejecución',
    cs: 'Termín provedení',
  },
  createTenderTermPlaceholder: {
    ua: 'Термін',
    ru: 'Срок',
    de: 'Frist',
    en: 'Term',
    fr: 'Délai',
    es: 'Plazo',
    cs: 'Termín',
  },

  // FiliiState   Page

  filiiState: {
    ua: 'Орієнтир:',
    ru: 'Ориентир:',
    de: 'Orientierungspunkt:',
    en: 'Landmark:',
    fr: 'Point de repère:',
    es: 'Hito:',
    cs: 'Orientace:',
  },

  // LawyerCV   Page
  yearForm1: {
    ua: 'роки',
    ru: 'года',
    de: 'Jahre',
    en: 'years',
    fr: 'ans',
    es: 'años',
    cs: 'roky',
  },
  yearForm2: {
    ua: 'років',
    ru: 'лет',
    de: 'Jahre',
    en: 'years',
    fr: 'années',
    es: 'años',
    cs: 'let',
  },
  monthForm1: {
    ua: 'місяць',
    ru: 'месяц',
    de: 'Monat',
    en: 'month',
    fr: 'mois',
    es: 'mes',
    cs: 'měsíc',
  },
  monthForm2: {
    ua: 'місяці',
    ru: 'месяца',
    de: 'Monate',
    en: 'months',
    fr: 'mois',
    es: 'meses',
    cs: 'měsíce',
  },
  monthForm3: {
    ua: 'місяців',
    ru: 'месяцев',
    de: 'Monate',
    en: 'months',
    fr: 'mois',
    es: 'meses',
    cs: 'měsíců',
  },
  positionLawyer1: {
    ua: 'Адвокат',
    ru: 'Адвокат',
    de: 'Anwalt',
    en: 'Lawyer',
    fr: 'Avocat',
    es: 'Abogado',
    cs: 'Právník',
  },
  positionLawyer2: {
    ua: 'Юрист',
    ru: 'Юрист',
    de: 'Jurist',
    en: 'Legal Counsel',
    fr: 'Conseiller juridique',
    es: 'Asesor legal',
    cs: 'Právní poradce',
  },
  lawyerCVPoints1: {
    ua: 'Посада',
    ru: 'Должность',
    de: 'Position',
    en: 'Position',
    fr: 'Poste',
    es: 'Posición',
    cs: 'Pozice',
  },
  lawyerCVPoints2: {
    ua: 'Освіта',
    ru: 'Образование',
    de: 'Ausbildung',
    en: 'Education',
    fr: 'Éducation',
    es: 'Educación',
    cs: 'Vzdělání',
  },
  lawyerCVPoints3: {
    ua: 'Досвід роботи',
    ru: 'Опыт работы',
    de: 'Berufserfahrung',
    en: 'Work Experience',
    fr: 'Expérience professionnelle',
    es: 'Experiencia laboral',
    cs: 'Pracovní zkušenosti',
  },
  lawyerCVPoints4: {
    ua: 'Робочий телефон',
    ru: 'Рабочий телефон',
    de: 'Arbeitstelefon',
    en: 'Work Phone',
    fr: 'Téléphone professionnel',
    es: 'Teléfono de trabajo',
    cs: 'Pracovní telefon',
  },
  lawyerCVPoints5: {
    ua: 'Адреса офісу:',
    ru: 'Адрес офиса:',
    de: 'Büroadresse:',
    en: 'Office Address:',
    fr: 'Adresse du bureau:',
    es: 'Dirección de la oficina:',
    cs: 'Adresa kanceláře:',
  },

  lawyerCVPoints6: {
    ua: 'Додаткова освіта',
    ru: 'Дополнительное образование',
    de: 'Zusätzliche Ausbildung',
    en: 'Additional Education',
    fr: 'Éducation supplémentaire',
    es: 'Educación adicional',
    cs: 'Další vzdělání',
  },
  lawyerCVPoints7: {
    ua: 'Здобутки',
    ru: 'Достижения',
    de: 'Errungenschaften',
    en: 'Achievements',
    fr: 'Réalisations',
    es: 'Logros',
    cs: 'Úspěchy',
  },
  lawyerCVPoints8: {
    ua: 'Судова практика',
    ru: 'Судебная практика',
    de: 'Gerichtspraxis',
    en: 'Court Practice',
    fr: 'Pratique judiciaire',
    es: 'Práctica judicial',
    cs: 'Soudní praxe',
  },
  lawyerCVPoints9: {
    ua: 'Приймальні години:',
    ru: 'Часы приёма:',
    de: 'Kanzleizeiten:',
    en: 'Consultation hours:',
    fr: 'Heures de réception:',
    es: 'Horas de consulta:',
    cs: 'Konzultační hodiny:',
  },
  successfulCases: {
    ua: 'Виграних справ',
    ru: 'Выигранных дел',
    de: 'Erfolgreiche Fälle',
    en: 'Successful Cases',
    fr: 'Affaires gagnées',
    es: 'Casos exitosos',
    cs: 'Úspěšné případy',
  },
  consultationsCounter: {
    ua: 'Консультацій',
    ru: 'Консультаций',
    de: 'Beratungen',
    en: 'Consultations',
    fr: 'Consultations',
    es: 'Consultas',
    cs: 'Konzultace',
  },

  // PartnerHome   Page

  balanceName: {
    ua: 'Внесено номерів:',
    ru: 'Добавлено номеров:',
    de: 'Nummern hinzugefügt:',
    en: 'Numbers added:',
    fr: 'Numéros ajoutés:',
    es: 'Números añadidos:',
    cs: 'Přidaná čísla:',
  },
  clientButtonText: {
    ua: 'Список запрошень',
    ru: 'Список приглашений',
    de: 'Einladungsliste',
    en: 'Invitation list',
    fr: "Liste d'invitations",
    es: 'Lista de invitaciones',
    cs: 'Seznam pozvánek',
  },
  inviteShareLinkText: {
    ua: 'Рекомендуйте нас другу та отримайте 300 грн. та бонуси разом з ним!',
    en: 'Recommend us to a friend and get 300 UAH and bonuses together with him!',
    de: 'Empfehlen Sie uns einem Freund und erhalten Sie 300 UAH und Boni zusammen mit ihm!',
    ru: 'Рекомендуйте нас другу и получите 300 грн. и бонусы вместе с ним!',
    fr: 'Recommandez-nous à un ami et obtenez 300 UAH et des bonus avec lui!',
    es: '¡Recomiéndanos a un amigo y obtén 300 UAH y bonificaciones junto con él!',
    cs: 'Doporučte nás příteli a získejte 300 UAH a bonusy společně s ním!',
  },
  inviteShareLinkDescription: {
    ua: 'Якщо він перейде за посиланням і запишеться на консультацію, ви обидва отримаєте по 300 бонусів! А ще – чим більше друзів ви запросите, тим більше винагород зможете отримати. Це безпрограшний варіант і для вас, і для ваших друзів!',
    ru: 'Если он перейдет по ссылке и запишется на консультацию, вы оба получите по 300 бонусов! Кроме того, чем больше друзей вы пригласите, тем больше наград вы сможете получить. Это беспроигрышный вариант как для вас, так и для ваших друзей!',
    de: 'Wenn er dem Link folgt und sich für eine Beratung anmeldet, erhaltet ihr beide 300 Bonuspunkte! Außerdem: Je mehr Freunde du einlädst, desto mehr Belohnungen kannst du verdienen. Eine echte Win-Win-Situation für dich und deine Freunde!',
    en: "If they follow the link and sign up for a consultation, both of you will receive 300 bonus points! Plus, the more friends you invite, the more rewards you can earn. It's a win-win for you and your friends!",
    fr: "S'ils suivent le lien et s'inscrivent pour une consultation, vous recevrez tous les deux 300 points de bonus! De plus, plus vous invitez d'amis, plus vous pouvez gagner de récompenses. C'est gagnant-gagnant pour vous et vos amis!",
    es: '¡Si siguen el enlace y se inscriben para una consulta, ambos recibirán 300 puntos de bonificación! Además, cuanto más amigos invites, más recompensas podrás ganar. ¡Es un ganar-ganar para ti y tus amigos!',
    cs: 'Pokud následují odkaz a zaregistrují se na konzultaci, oba dostanete 300 bonusových bodů! A čím více přátel pozvete, tím více odměn můžete získat. Je to win-win pro vás i vaše přátele!',
  },

  shareLinkTitle: {
    ua: 'Поділитись посиланням',
    ru: 'Поделиться ссылкой',
    de: 'Link teilen',
    en: 'Share the link',
    fr: 'Partager le lien',
    es: 'Compartir el enlace',
    cs: 'Sdílet odkaz',
  },

  // TransactionsButton
  transactionsBonuses: {
    ua: 'Винагорода за запрошення',
    ru: 'Вознаграждение за приглашение',
    de: 'Empfehlungsprämien',
    en: 'Referral Rewards',
    fr: 'Récompenses de parrainage',
    es: 'Recompensas por referencia',
    cs: 'Odměny za doporučení',
  },
  transactionsBonusesValue: {
    ua: 'залишок:',
    ru: 'остаток:',
    de: 'Saldo:',
    en: 'balance:',
    fr: 'solde:',
    es: 'saldo:',
    cs: 'zůstatek:',
  },
  noTransactions: {
    ua: 'Транзакції відсутні',
    ru: 'Транзакции отсутствуют',
    de: 'Keine Transaktionen',
    en: 'No transactions',
    fr: 'Pas de transactions',
    es: 'Sin transacciones',
    cs: 'Žádné transakce',
  },
  showAll: {
    ua: 'Показати усі',
    ru: 'Показать все',
    de: 'Alle anzeigen',
    en: 'Show all',
    fr: 'Afficher tout',
    es: 'Mostrar todo',
    cs: 'Zobrazit vše',
  },
  // Invite
  inviteTitle: {
    ua: 'Запросити клієнта',
    ru: 'Пригласить клиента',
    de: 'Kunden einladen',
    en: 'Invite client',
    fr: 'Inviter le client',
    es: 'Invitar al cliente',
    cs: 'Pozvat klienta',
  },
  inviteTitle1: {
    ua: 'Залишок запрошень на сьогодні:',
    ru: 'Остаток приглашений на сегодня:',
    de: 'Verbleibende Einladungen für heute:',
    en: 'Remaining invitations for today:',
    fr: "Invitations restantes pour aujourd'hui:",
    es: 'Invitaciones restantes para hoy:',
    cs: 'Zbývající pozvánky na dnes:',
  },
  phoneRule1: {
    ua: 'Введіть номер телефону!',
    ru: 'Введите номер телефона!',
    de: 'Geben Sie die Telefonnummer ein!',
    en: 'Enter phone number!',
    fr: 'Entrez le numéro de téléphone!',
    es: '¡Ingrese el número de teléfono!',
    cs: 'Zadejte telefonní číslo!',
  },
  phoneRule2: {
    ua: 'Номер телефону повинен містити тільки 10 цифр та починатися з 0!',
    ru: 'Номер телефона должен содержать только 10 цифр и начинаться с 0!',
    de: 'Die Telefonnummer muss nur 10 Ziffern enthalten und mit 0 beginnen!',
    en: 'The phone number must contain only 10 digits and start with 0!',
    fr: 'Le numéro de téléphone doit contenir uniquement 10 chiffres et commencer par 0!',
    es: '¡El número de teléfono debe contener solo 10 dígitos y comenzar con 0!',
    cs: 'Telefonní číslo musí obsahovat pouze 10 číslic a začínat 0!',
  },
  // PaymentCard
  paymentCardinfo: {
    ua: 'Ця карта вже прив`язана!',
    ru: 'Эта карта уже привязана!',
    de: 'Diese Karte ist bereits verknüpft!',
    en: 'This card is already linked!',
    fr: 'Cette carte est déjà liée!',
    es: '¡Esta tarjeta ya está vinculada!',
    cs: 'Tato karta je již propojena!',
  },
  paymentCardSuccess1: {
    ua: 'Карту успішно',
    ru: 'Карта успешно',
    de: 'Karte erfolgreich',
    en: 'Card successfully',
    fr: 'Carte réussie',
    es: 'Tarjeta exitosa',
    cs: 'Karta úspěšně',
  },
  paymentCardSuccess2: {
    ua: 'оновлено',
    ru: 'обновлено',
    de: 'aktualisiert',
    en: 'updated',
    fr: 'mise à jour',
    es: 'actualizada',
    cs: 'aktualizováno',
  },
  paymentCardSuccess3: {
    ua: 'додано',
    ru: 'добавлено',
    de: 'hinzugefügt',
    en: 'added',
    fr: 'ajoutée',
    es: 'añadida',
    cs: 'přidáno',
  },
  paymentCardUpdate: {
    ua: 'Оновити',
    ru: 'Обновить',
    de: 'Aktualisieren',
    en: 'Update',
    fr: 'Mettre à jour',
    es: 'Actualizar',
    cs: 'Aktualizovat',
  },
  paymentCardAdd: {
    ua: 'Додати',
    ru: 'Добавить',
    de: 'Hinzufügen',
    en: 'Add',
    fr: 'Ajouter',
    es: 'Agregar',
    cs: 'Přidat',
  },
  numbers: {
    ua: 'цифри',
    ru: 'цифры',
    de: 'Zahlen',
    en: 'digits',
    fr: 'chiffres',
    es: 'dígitos',
    cs: 'číslice',
  },
  paymentCardTitle1: {
    ua: 'Карта для виплат',
    ru: 'Карта для выплат',
    de: 'Zahlungskarte',
    en: 'Payment Card',
    fr: 'Carte de paiement',
    es: 'Tarjeta de pago',
    cs: 'Platební karta',
  },
  paymentCardTitle2: {
    ua: 'Поточна карта: ',
    ru: 'Текущая карта: ',
    de: 'Aktuelle Karte: ',
    en: 'Current card: ',
    fr: 'Carte actuelle : ',
    es: 'Tarjeta actual: ',
    cs: 'Aktuální karta: ',
  },
  paymentCardTitle3: {
    ua: 'Додайте карту для виплат',
    ru: 'Добавьте карту для выплат',
    de: 'Fügen Sie eine Zahlungskarte hinzu',
    en: 'Add a payment card',
    fr: 'Ajouter une carte de paiement',
    es: 'Agregar una tarjeta de pago',
    cs: 'Přidat platební kartu',
  },
  card: {
    ua: 'карту',
    ru: 'карта',
    de: 'Karte',
    en: 'card',
    fr: 'carte',
    es: 'tarjeta',
    cs: 'karta',
  },

  // PartnerHome - Rules
  rules: {
    ua: 'Переглянути правила',
    ru: 'Просмотреть правила',
    de: 'Überprüfung der Regeln',
    en: 'review the rules',
    fr: 'Consulter les règles',
    es: 'revisar las reglas',
    cs: 'zkontrolovat pravidla',
  },
  partnerHomeRulesTitle: {
    ua: 'Партнерська програма АО «Захист»',
    ru: 'Партнёрская программа АО «Защита»',
    de: 'Partnerprogramm der AO "Zaхyst"',
    en: 'Partner Program of AO "Zaхyst"',
    fr: 'Programme de partenariat de l\'AO "Zaхyst"',
    es: 'Programa de Socios de AO "Zaхyst"',
    cs: 'Partnerský program AO "Zaхyst"',
  },
  partnerHomeRulesText1: {
    ua: 'Адвокатське об`єднання «Захист»',
    ru: 'Адвокатское объединение «Защита»',
    de: 'Anwaltsvereinigung "Zaхyst"',
    en: 'Lawyers Association "Zaхyst"',
    fr: 'Association des avocats "Zaхyst"',
    es: 'Asociación de abogados "Zaхyst"',
    cs: 'Advokátní sdružení "Zaхyst"',
  },
  partnerHomeRulesText2: {
    ua: 'найбільша юридична компанія України.',
    ru: 'крупнейшая юридическая компания Украины.',
    de: 'die größte juristische Firma in der Ukraine.',
    en: 'the largest legal company in Ukraine.',
    fr: "la plus grande entreprise juridique d'Ukraine.",
    es: 'la mayor empresa legal de Ucrania.',
    cs: 'největší právní společnost na Ukrajině.',
  },
  partnerHomeRulesText3: {
    ua: 'Ми маємо дуже потужну розгалужену систему територіальних відділень. Наші офіси знаходяться у кожному обласному центрі, а в деяких регіонах, навіть в провінційних містах та селах.',
    ru: 'У нас есть очень мощная разветвленная система территориальных отделений. Наши офисы расположены в каждом областном центре, а в некоторых регионах даже в провинциальных городах и селах.',
    de: 'Wir haben ein sehr leistungsstarkes Netzwerk territorialer Filialen. Unsere Büros befinden sich in jedem Regionalzentrum und in einigen Regionen sogar in Provinzstädten und Dörfern.',
    en: 'We have a very powerful network of territorial branches. Our offices are located in every regional center, and in some regions, even in provincial towns and villages.',
    fr: 'Nous avons un réseau très puissant de succursales territoriales. Nos bureaux sont situés dans chaque centre régional, et dans certaines régions, même dans des villes et villages provinciaux.',
    es: 'Tenemos una red muy poderosa de sucursales territoriales. Nuestros oficinas están ubicadas en cada centro regional, e incluso en algunas regiones, en ciudades y pueblos provinciales.',
    cs: 'Máme velmi silnou síť teritoriálních poboček. Naše kanceláře se nacházejí v každém regionálním centru a v některých regionech dokonce i v provinčních městech a vesnicích.',
  },
  partnerHomeRulesText4: {
    ua: 'Головний офіс компанії знаходиться у м. Одеса, за адресою:',
    ru: 'Главный офис компании находится в г. Одесса, по адресу:',
    de: 'Der Hauptsitz des Unternehmens befindet sich in der Stadt Odessa, unter folgender Adresse:',
    en: 'The main office of the company is located in the city of Odessa, at the address:',
    fr: "Le siège principal de l'entreprise est situé dans la ville d'Odessa, à l'adresse :",
    es: 'La oficina principal de la empresa está ubicada en la ciudad de Odessa, en la dirección:',
    cs: 'Hlavní kancelář společnosti se nachází ve městě Oděsa na adrese:',
  },
  partnerHomeRulesText5: {
    ua: 'вул. Мала Арнаутська, 71, офіс № 6.',
    ru: 'ул. Мала Арнаутская, 71, офис № 6.',
    de: 'Malaya Arnautskaya Str., 71, Büro Nr. 6.',
    en: 'Malaya Arnautskaya St., 71, office № 6.',
    fr: 'rue Malaya Arnautskaya, 71, bureau n° 6.',
    es: 'Calle Malaya Arnautskaya, 71, oficina n° 6.',
    cs: 'Malaya Arnautskaya ulice, 71, kancelář č. 6.',
  },
  partnerHomeRulesText6: {
    ua: 'Так само, як Ви, ми зацікавлені в постійному розвитку, зокрема шляхом залучення та обслуговування нових Клієнтів, тому ми',
    ru: 'Так же, как и Вы, мы заинтересованы в постоянном развитии, в том числе через привлечение и обслуживание новых клиентов, поэтому мы',
    de: 'Genau wie Sie sind wir an ständiger Entwicklung interessiert, auch durch Gewinnung und Betreuung neuer Kunden, deshalb bieten wir Ihnen',
    en: 'Just like you, we are interested in constant development, including by attracting and serving new Clients, so we',
    fr: "Tout comme vous, nous sommes intéressés par un développement constant, notamment en attirant et en servant de nouveaux clients, c'est pourquoi nous",
    es: 'Al igual que usted, estamos interesados en un desarrollo constante, incluyendo la atracción y atención de nuevos clientes, por lo que',
    cs: 'Stejně jako vy, máme zájem o neustálý rozvoj, včetně přitahování a obsluhy nových klientů, proto',
  },
  partnerHomeRulesText7: {
    ua: 'пропонуємо Вам стати нашим Партнером',
    ru: 'предлагаем вам стать нашим Партнером',
    de: 'bieten wir Ihnen an, unser Partner zu werden',
    en: 'offer you to become our Partner',
    fr: 'vous proposons de devenir notre partenaire',
    es: 'le ofrecemos convertirse en nuestro socio',
    cs: 'nabízíme vám, abyste se stali naším partnerem',
  },
  partnerHomeRulesText8: {
    ua: 'у цьому напрямку.',
    ru: 'в этом направлении.',
    de: 'in diese Richtung.',
    en: 'in this direction.',
    fr: 'dans cette direction.',
    es: 'en esta dirección.',
    cs: 'v tomto směru.',
  },
  partnerHomeRulesText9: {
    ua: 'Суть Партнерської програми АО «Захист»',
    ru: 'Суть Партнёрской программы АО «Защита»',
    de: 'Die Essenz des Partnerprogramms der AO "Zaхyst"',
    en: 'The essence of the Partner Program of AO "Zaхyst"',
    fr: 'L\'essence du programme de partenariat de l\'AO "Zaхyst"',
    es: 'La esencia del programa de socios de AO "Zaхyst"',
    cs: 'Podstata partnerského programu AO "Zaхyst"',
  },
  partnerHomeRulesText10: {
    ua: 'Партнер АО «Захист»',
    ru: 'Партнёр АО «Защита»',
    de: 'Partner der AO "Zaхyst"',
    en: 'Partner of AO "Zaхyst"',
    fr: 'Partenaire de l\'AO "Zaхyst"',
    es: 'Socio de AO "Zaхyst"',
    cs: 'Partner AO "Zaхyst"',
  },
  partnerHomeRulesText11: {
    ua: 'це людина, яка рекомендує нашу компанію іншим особам для вирішення своїх юридичних проблем, та отримує за це грошову винагороду у сумі 300 грн. за залучення одного Клієнта.',
    ru: 'это человек, который рекомендует нашу компанию другим лицам для решения своих юридических проблем и получает за это денежное вознаграждение в размере 300 грн. за привлечение одного клиента.',
    de: 'dies ist eine Person, die unser Unternehmen anderen Personen empfiehlt, um ihre rechtlichen Probleme zu lösen, und eine finanzielle Belohnung von 300 UAH für die Vermittlung eines Kunden erhält.',
    en: 'this is a person who recommends our company to other individuals to solve their legal problems and receives a monetary reward of 300 UAH for referring one Client.',
    fr: "c'est une personne qui recommande notre entreprise à d'autres pour résoudre leurs problèmes juridiques et reçoit une récompense monétaire de 300 UAH pour avoir référé un Client.",
    es: 'esta es una persona que recomienda nuestra empresa a otras para resolver sus problemas legales y recibe una recompensa monetaria de 300 UAH por referir a un Cliente.',
    cs: 'to je osoba, která doporučuje naši společnost jiným lidem k vyřešení jejich právních problémů a za to dostává peněžní odměnu ve výši 300 UAH za přivedení jednoho Klienta.',
  },
  partnerHomeRulesText12: {
    ua: 'Партнерський Клієнт',
    ru: 'Партнерский Клиент',
    de: 'Partnerkunde',
    en: 'Partner Client',
    fr: 'Client Partenaire',
    es: 'Cliente Asociado',
    cs: 'Partner Klient',
  },
  partnerHomeRulesText13: {
    ua: 'людина, яка звернулася до АО «Захист» за рекомендацією Партнера. Партнерський Клієнт також отримує 300 грн. на свій бонусний рахунок.',
    ru: 'человек, который обратился в АО «Защита» по рекомендации Партнера. Партнерский Клиент также получает 300 грн. на свой бонусный счет.',
    de: 'eine Person, die sich auf Empfehlung eines Partners an AO "Zaхyst" gewandt hat. Der Partnerkunde erhält ebenfalls 300 UAH auf sein Bonuskonto.',
    en: 'a person who has contacted AO "Zaхyst" on the recommendation of a Partner. The Partner Client also receives 300 UAH to their bonus account.',
    fr: 'une personne qui a contacté l\'AO "Zaхyst" sur la recommandation d\'un Partenaire. Le Client Partenaire reçoit également 300 UAH sur son compte bonus.',
    es: 'una persona que se ha puesto en contacto con AO "Zaхyst" por recomendación de un Socio. El Cliente Asociado también recibe 300 UAH en su cuenta de bonificación.',
    cs: 'osoba, která se na doporučení partnera obrátila na AO "Zaхyst". Partner Klient také obdrží 300 UAH na svůj bonusový účet.',
  },
  partnerHomeRulesText14: {
    ua: 'Як працює Партнерська програма?',
    ru: 'Как работает Партнерская программа?',
    de: 'Wie funktioniert das Partnerprogramm?',
    en: 'How does the Partner Program work?',
    fr: 'Comment fonctionne le Programme Partenaire?',
    es: '¿Cómo funciona el Programa de Socios?',
    cs: 'Jak funguje partnerský program?',
  },
  partnerHomeRulesText15: {
    ua: 'Рекомендуйте будь-якій особі звернутися до нашої компанії та',
    ru: 'Рекомендуйте любому человеку обратиться в нашу компанию и',
    de: 'Empfehlen Sie jedem, sich an unser Unternehmen zu wenden und',
    en: 'Recommend to anyone to contact our company and',
    fr: 'Recommandez à quiconque de contacter notre entreprise et',
    es: 'Recomiende a cualquier persona que se comunique con nuestra empresa y',
    cs: 'Doporučte každému, aby kontaktoval naši společnost a',
  },
  partnerHomeRulesText16: {
    ua: 'додавайте номер телефону',
    ru: 'добавляйте номер телефона',
    de: 'fügen Sie die Telefonnummer hinzu',
    en: 'add the phone number',
    fr: 'ajoutez le numéro de téléphone',
    es: 'agregue el número de teléfono',
    cs: 'přidejte telefonní číslo',
  },
  partnerHomeRulesText17: {
    ua: 'цієї людини у свій особистий кабінет натиснувши на цю кнопку:',
    ru: 'этого человека в свой личный кабинет, нажав на эту кнопку:',
    de: 'diese Person in Ihr persönliches Konto hinzufügen, indem Sie auf diese Schaltfläche klicken:',
    en: 'of this person to your personal account by clicking this button:',
    fr: 'de cette personne à votre compte personnel en cliquant sur ce bouton :',
    es: 'de esta persona a su cuenta personal haciendo clic en este botón:',
    cs: 'této osoby do svého osobního účtu kliknutím na toto tlačítko:',
  },
  partnerHomeRulesText18: {
    ua: 'Щоб переглянути список усіх доданих Вами номерів, натисніть цю кнопку:',
    ru: 'Чтобы просмотреть список всех добавленных вами номеров, нажмите эту кнопку:',
    de: 'Um die Liste aller von Ihnen hinzugefügten Telefonnummern anzuzeigen, klicken Sie auf diese Schaltfläche:',
    en: 'To view the list of all phone numbers you have added, click this button:',
    fr: 'Pour voir la liste de tous les numéros de téléphone que vous avez ajoutés, cliquez sur ce bouton :',
    es: 'Para ver la lista de todos los números de teléfono que ha agregado, haga clic en este botón:',
    cs: 'Chcete-li zobrazit seznam všech telefonních čísel, která jste přidali, klikněte na toto tlačítko:',
  },
  partnerHomeRulesText19: {
    ua: 'Коли Партнерський Клієнт, звертається до нашої компанії,',
    ru: 'Когда Партнерский Клиент обращается в нашу компанию,',
    de: 'Wenn sich der Partnerkunde an unser Unternehmen wendet,',
    en: 'When the Partner Client contacts our company,',
    fr: 'Lorsque le Client Partenaire contacte notre entreprise,',
    es: 'Cuando el Cliente Asociado contacta con nuestra empresa,',
    cs: 'Když se Partner Klient obrátí na naši společnost,',
  },
  partnerHomeRulesText20: {
    ua: 'Ви автоматично отримаєте винагороду у сумі 300 гривень',
    ru: 'Вы автоматически получите вознаграждение в размере 300 гривен',
    de: 'Sie erhalten automatisch eine Belohnung von 300 Hrywnja',
    en: 'you will automatically receive a reward of 300 hryvnias',
    fr: 'vous recevrez automatiquement une récompense de 300 hryvnias',
    es: 'recibirá automáticamente una recompensa de 300 grivnas',
    cs: 'automatically obdržíte odměnu 300 hřiven',
  },
  partnerHomeRulesText21: {
    ua: 'на свій баланс;',
    ru: 'на свой баланс;',
    de: 'auf Ihr Gleichgewicht;',
    en: 'to your balance;',
    fr: 'à votre solde ;',
    es: 'a su saldo;',
    cs: 'na váš zůstatek;',
  },
  partnerHomeRulesText22: {
    ua: 'Натисніть на кнопку меню, оберіть опцію «Прив`язка банківської картки» та заповніть реквізити для виведення грошових коштів',
    ru: 'Нажмите на кнопку меню, выберите опцию «Привязка банковской карты» и заполните реквизиты для вывода денежных средств',
    de: 'Klicken Sie auf die Menütaste, wählen Sie die Option "Verknüpfung einer Bankkarte" und füllen Sie die Details aus, um Geld abzuheben',
    en: 'Click on the menu button, select the "Linking a bank card" option, and fill in the details to withdraw funds',
    fr: "Cliquez sur le bouton du menu, sélectionnez l'option « Liaison d'une carte bancaire » et remplissez les détails pour retirer des fonds",
    es: 'Haga clic en el botón del menú, seleccione la opción "Vinculación de una tarjeta bancaria" y complete los detalles para retirar fondos',
    cs: 'Klepněte na tlačítko menu, vyberte možnost „Propojení bankovní karty“ a vyplňte údaje pro výběr prostředků',
  },
  partnerHomeRulesText23: {
    ua: 'Ви можете',
    ru: 'Вы можете',
    de: 'Sie können',
    en: 'You can',
    fr: 'Vous pouvez',
    es: 'Puede',
    cs: 'Můžete',
  },
  partnerHomeRulesText24: {
    ua: 'вивести гроші',
    ru: 'вывести деньги',
    en: 'withdraw money',
    de: 'Geld abheben',
    fr: "retirer de l'argent",
    es: 'retirar dinero',
    cs: 'vybrat peníze',
  },
  partnerHomeRulesText25: {
    ua: 'з балансу свого кабінету на будь-яку банківську картку коли завгодно, натисканням цю кнопку:',
    ru: 'с баланса своего кабинета на любую банковскую карту в любое время, нажав эту кнопку:',
    en: 'from the balance of your account to any bank card at any time, by clicking this button:',
    de: 'von Ihrem Kontostand auf jede Bankkarte jederzeit, indem Sie diese Schaltfläche drücken:',
    fr: "du solde de votre compte vers n'importe quelle carte bancaire à tout moment, en cliquant sur ce bouton :",
    es: 'del saldo de su cuenta a cualquier tarjeta bancaria en cualquier momento, haciendo clic en este botón:',
    cs: 'zůstatku vašeho účtu na jakoukoli bankovní kartu kdykoliv stisknutím tohoto tlačítka:',
  },
  partnerHomeRulesText26: {
    ua: 'Умови зарахування винагороди на баланс в особистому кабінеті:',
    ru: 'Условия зачисления вознаграждения на баланс в личном кабинете:',
    en: 'Conditions for crediting the reward to the balance in the personal account:',
    de: 'Bedingungen für die Gutschrift der Belohnung auf dem Guthaben im persönlichen Konto:',
    fr: 'Conditions de crédit de la récompense sur le solde du compte personnel :',
    es: 'Condiciones para acreditar la recompensa en el saldo de la cuenta personal:',
    cs: 'Podmínky pro připsání odměny na zůstatek v osobním účtu:',
  },
  partnerHomeRulesText27: {
    ua: 'Для зарахування винагороди на баланс особистого кабінету Партнера, необхідно',
    ru: 'Для зачисления вознаграждения на баланс личного кабинета Партнера необходимо',
    en: "To credit the reward to the balance of the Partner's personal account, it is necessary",
    de: 'Um die Belohnung auf das Guthaben des persönlichen Kontos des Partners zu buchen, ist es erforderlich,',
    fr: 'Pour créditer la récompense sur le solde du compte personnel du partenaire, il est nécessaire',
    es: 'Para acreditar la recompensa en el saldo de la cuenta personal del socio, es necesario',
    cs: 'Pro připsání odměny na zůstatek osobního účtu partnera je nutné',
  },
  partnerHomeRulesText28: {
    ua: 'виконання таких умов:',
    ru: 'выполнение следующих условий:',
    en: 'to fulfill the following conditions:',
    de: 'die folgenden Bedingungen zu erfüllen:',
    fr: 'remplir les conditions suivantes :',
    es: 'cumplir las siguientes condiciones:',
    cs: 'splnění následujících podmínek:',
  },
  partnerHomeRulesText29: {
    ua: 'Внесення номеру телефона людини в особистий кабінет',
    ru: 'Внесение номера телефона человека в личный кабинет',
    en: "Entering a person's phone number into the personal account",
    de: 'Eingabe der Telefonnummer einer Person in das persönliche Konto',
    fr: "Saisir le numéro de téléphone d'une personne dans le compte personnel",
    es: 'Ingresar el número de teléfono de una persona en la cuenta personal',
    cs: 'Zadání telefonního čísla osoby do osobního účtu',
  },
  partnerHomeRulesText30: {
    ua: 'раніше',
    ru: 'ранее',
    en: 'previously',
    de: 'zuvor',
    fr: 'auparavant',
    es: 'anteriormente',
    cs: 'dříve',
  },
  partnerHomeRulesText31: {
    ua: ', ніж ця людина звернеться до нашої компанії;',
    ru: ', чем этот человек обратится в нашу компанию;',
    en: ', before this person contacts our company;',
    de: ', bevor diese Person sich an unser Unternehmen wendet;',
    fr: ', avant que cette personne ne contacte notre entreprise ;',
    es: ', antes de que esta persona se comunique con nuestra empresa;',
    cs: ', než se tato osoba obrátí na naši společnost;',
  },
  partnerHomeRulesText32: {
    ua: 'При зверненні до нашої компанії, людина проходить анкетування на предмет того, як вона дізналася про нас, на що вона повинна зазначити, що',
    ru: 'При обращении в нашу компанию человек проходит опрос о том, как он узнал о нас, на что он должен указать, что',
    en: 'When contacting our company, the person undergoes a survey about how they learned about us, on which they should indicate that',
    de: 'Bei der Kontaktaufnahme mit unserem Unternehmen durchläuft die Person eine Umfrage darüber, wie sie von uns erfahren hat, worauf sie angeben sollte, dass',
    fr: "Lorsqu'il contacte notre entreprise, la personne passe une enquête sur la manière dont elle a appris notre existence, sur laquelle elle doit indiquer que",
    es: 'Al contactar con nuestra empresa, la persona pasa una encuesta sobre cómo se enteró de nosotros, en la que debe indicar que',
    cs: 'Při kontaktu s naší společností osoba prochází dotazníkem o tom, jak se o nás dozvěděla, na což by měla uvést, že',
  },
  partnerHomeRulesText33: {
    ua: 'звернулася за рекомендацією',
    ru: 'обратилась по рекомендации',
    en: 'turned for recommendation',
    de: 'sich auf Empfehlung wandte',
    fr: "s'est tourné pour une recommandation",
    es: 'se dirigió por recomendación',
    cs: 'se obrátila na doporučení',
  },
  partnerHomeRulesText34: {
    ua: 'Якщо один і той самий номер телефону буде внесений декількома Партнерами, винагороду отримає той із Партнерів, який вніс телефон людини в свій особистий кабінет',
    ru: 'Если один и тот же номер телефона будет внесен несколькими Партнерами, вознаграждение получит тот из Партнеров, который внес телефон человека в свой личный кабинет',
    en: "If the same phone number is entered by several Partners, the reward will be received by the Partner who entered the person's phone number into their personal account",
    de: 'Wenn dieselbe Telefonnummer von mehreren Partnern eingegeben wird, erhält die Belohnung der Partner, der die Telefonnummer der Person in sein persönliches Konto eingegeben hat',
    fr: 'Si le même numéro de téléphone est saisi par plusieurs partenaires, la récompense sera reçue par le partenaire qui a saisi le numéro de téléphone de la personne dans son compte personnel',
    es: 'Si el mismo número de teléfono es ingresado por varios socios, la recompensa será recibida por el socio que ingresó el número de teléfono de la persona en su cuenta personal',
    cs: 'Pokud je stejné telefonní číslo zadáno několika partnery, odměnu obdrží partner, který zadal telefonní číslo osoby do svého osobního účtu',
  },
  partnerHomeRulesText35: {
    ua: 'останній',
    ru: 'последний',
    en: 'the last',
    de: 'der letzte',
    fr: 'le dernier',
    es: 'el último',
    cs: 'poslední',
  },
  partnerHomeRulesText36: {
    ua: 'Після Вашої рекомендації та внесення номеру телефону, людина повинна протягом',
    ru: 'После вашей рекомендации и внесения номера телефона человек должен в течение',
    en: 'After your recommendation and entering the phone number, the person must within',
    de: 'Nach Ihrer Empfehlung und Eingabe der Telefonnummer muss die Person innerhalb von',
    fr: 'Après votre recommandation et la saisie du numéro de téléphone, la personne doit le faire dans les',
    es: 'Después de su recomendación e ingresar el número de teléfono, la persona debe hacerlo en un plazo de',
    cs: 'Po vaší doporučení a zadání telefonního čísla musí osoba do',
  },
  partnerHomeRulesText37: {
    ua: '2-х місяців',
    ru: '2 месяцев',
    en: '2 months',
    de: '2 Monaten',
    fr: '2 mois',
    es: '2 meses',
    cs: '2 měsíců',
  },
  partnerHomeRulesText38: {
    ua: 'звернутися до нас;',
    ru: 'обратиться к нам;',
    en: 'contact us;',
    de: 'sich an uns wenden;',
    fr: 'nous contacter ;',
    es: 'contactarnos;',
    cs: 'obratit se na nás;',
  },
  partnerHomeRulesText39: {
    ua: 'При укладенні з нами договору про надання адвокатських послуг, сума оплати повинна бути',
    ru: 'При заключении с нами договора на предоставление юридических услуг сумма оплаты должна быть',
    de: 'Bei Abschluss eines Vertrags mit uns über die Erbringung von Rechtsdienstleistungen muss der Zahlungsbetrag sein',
    en: 'When concluding an agreement with us for the provision of legal services, the amount of payment must be',
    fr: "Lors de la conclusion d'un accord avec nous pour la fourniture de services juridiques, le montant du paiement doit être",
    es: 'Al concluir un acuerdo con nosotros para la prestación de servicios legales, el monto del pago debe ser',
    cs: 'Při uzavření smlouvy s námi na poskytování právních služeb musí být částka platby',
  },
  partnerHomeRulesText40: {
    ua: 'не менше 1000 грн',
    ru: 'не менее 1000 грн',
    de: 'mindestens 1000 UAH',
    en: 'at least 1000 UAH',
    fr: 'au moins 1000 UAH',
    es: 'al menos 1000 UAH',
    cs: 'alespoň 1000 UAH',
  },
  partnerHomeRulesText41: {
    ua: 'Технічні обмеження при внесенні номерів телефону в особистий кабінет:',
    ru: 'Технические ограничения при внесении номеров телефонов в личный кабинет:',
    de: 'Technische Einschränkungen beim Hinzufügen von Telefonnummern zum persönlichen Konto:',
    en: 'Technical limitations when adding phone numbers to the personal account:',
    fr: "Limitations techniques lors de l'ajout de numéros de téléphone au compte personnel :",
    es: 'Limitaciones técnicas al agregar números de teléfono a la cuenta personal:',
    cs: 'Technická omezení při přidávání telefonních čísel do osobního účtu:',
  },
  partnerHomeRulesText42: {
    ua: 'Двічі',
    ru: 'Дважды',
    de: 'Zweimal',
    en: 'Twice',
    fr: 'Deux fois',
    es: 'Dos veces',
    cs: 'Dvakrát',
  },
  partnerHomeRulesText43: {
    ua: 'вносити один і той самий номер телефону в особистий кабінет, технічно неможливо;',
    ru: 'вносить один и тот же номер телефона в личный кабинет технически невозможно;',
    de: 'technisch unmöglich, dieselbe Telefonnummer zweimal in das persönliche Konto einzugeben;',
    en: 'technically impossible to enter the same phone number into the personal account twice;',
    fr: "techniquement impossible d'entrer le même numéro de téléphone dans le compte personnel deux fois ;",
    es: 'técnicamente imposible ingresar el mismo número de teléfono en la cuenta personal dos veces;',
    cs: 'technicky nemožné zadat stejné telefonní číslo do osobního účtu dvakrát;',
  },
  partnerHomeRulesText44: {
    ua: '2. Задля виключення маніпуляцій з телефонними базами даних, технічна можливість внесення телефонів в особистий кабінет Партнера',
    ru: '2. С целью исключения манипуляций с телефонными базами данных, техническая возможность внесения телефонов в личный кабинет Партнера',
    de: '2. Um Manipulationen mit Telefonverzeichnissen zu verhindern, besteht die technische Möglichkeit, Telefone in das persönliche Konto des Partners einzugeben',
    en: "2. In order to prevent manipulations with telephone databases, the technical possibility of entering phones into the Partner's personal account",
    fr: "2. Afin de prévenir les manipulations avec les bases de données téléphoniques, la possibilité technique d'entrer des téléphones dans le compte personnel du Partenaire",
    es: '2. Con el fin de prevenir manipulaciones con bases de datos telefónicas, la posibilidad técnica de ingresar teléfonos en la cuenta personal del Socio',
    cs: '2. Aby se zabránilo manipulaci s telefonními databázemi, existuje technická možnost zadávat telefony do osobního účtu Partnera',
  },
  partnerHomeRulesText45: {
    ua: 'Обмежена п`ятьма номерами на день.',
    ru: 'Ограничена пятью номерами в день.',
    de: 'Auf fünf Nummern pro Tag beschränkt.',
    en: 'Limited to five numbers per day.',
    fr: 'Limité à cinq numéros par jour.',
    es: 'Limitado a cinco números por día.',
    cs: 'Omezeno na pět čísel za den.',
  },
  partnerHomeRulesText46: {
    ua: 'Переваги співпраці з нашою компанією на умовах Партнерства:',
    ru: 'Преимущества сотрудничества с нашей компанией на условиях Партнерства:',
    de: 'Vorteile der Zusammenarbeit mit unserem Unternehmen unter Partnerschaftsbedingungen:',
    en: 'Advantages of cooperation with our company under Partnership conditions:',
    fr: 'Avantages de la coopération avec notre entreprise dans le cadre de conditions de Partenariat :',
    es: 'Ventajas de la cooperación con nuestra empresa en virtud de condiciones de Asociación:',
    cs: 'Výhody spolupráce s naší společností na základě partnerských podmínek:',
  },
  partnerHomeRulesText47: {
    ua: '1. Пасивний дохід.',
    ru: '1. Пассивный доход.',
    de: '1. Passives Einkommen.',
    en: '1. Passive income.',
    fr: '1. Revenu passif.',
    es: '1. Ingreso pasivo.',
    cs: '1. Pasivní příjem.',
  },
  partnerHomeRulesText48: {
    ua: 'Ви гарантовано отримуєте винагороду у разі, якщо людина з Вашого списку звернеться до наc протягом 2-х місяців, після Вашої рекомендації.',
    ru: 'Вы гарантированно получите вознаграждение в случае, если человек из вашего списка обратится к нам в течение 2-х месяцев после вашей рекомендации.',
    de: 'Sie erhalten garantiert eine Belohnung, wenn eine Person aus Ihrer Liste uns innerhalb von 2 Monaten nach Ihrer Empfehlung kontaktiert.',
    en: 'You are guaranteed to receive a reward if a person from your list contacts us within 2 months after your recommendation.',
    fr: 'Vous êtes garanti de recevoir une récompense si une personne de votre liste nous contacte dans les 2 mois suivant votre recommandation.',
    es: 'Se garantiza que recibirá una recompensa si una persona de su lista se pone en contacto con nosotros dentro de los 2 meses posteriores a su recomendación.',
    cs: 'Zaručujeme vám, že obdržíte odměnu, pokud se osoba z vašeho seznamu obrátí na nás do 2 měsíců po vaší doporučení.',
  },
  partnerHomeRulesText49: {
    ua: '2. Легкий та зручний інтерфейс особистого кабінету.',
    ru: '2. Удобный и простой интерфейс личного кабинета.',
    de: '2. Einfache und bequeme Benutzeroberfläche des persönlichen Kontos.',
    en: '2. Easy and convenient personal account interface.',
    fr: '2. Interface personnelle facile et pratique.',
    es: '2. Interfaz de cuenta personal fácil y conveniente.',
    cs: '2. Snadné a pohodlné rozhraní osobního účtu.',
  },
  partnerHomeRulesText50: {
    ua: 'Ми доклали величезних зусиль для того, щоб нашим Партнерам було якомога комфортніше співпрацювати з нами. Ми прибрали усе зайве, та залишили лише найголовніший інструментарій. Проте, якщо у Вас все ж виникнуть технічні проблеми, наші спеціалісти усе миттєво виправлять.',
    en: 'We have made tremendous efforts to make it as comfortable as possible for our Partners to work with us. We have removed all unnecessary things, leaving only the most essential tools. However, if you still encounter technical problems, our specialists will fix them immediately.',
    de: 'Wir haben enorme Anstrengungen unternommen, um es unseren Partnern so bequem wie möglich zu machen, mit uns zusammenzuarbeiten. Wir haben alle unnötigen Dinge entfernt und nur die wichtigsten Werkzeuge zurückgelassen. Wenn Sie jedoch immer noch auf technische Probleme stoßen, werden unsere Spezialisten sie sofort beheben.',
    fr: 'Nous avons déployé des efforts considérables pour rendre la collaboration avec nous aussi confortable que possible pour nos partenaires. Nous avons supprimé tout ce qui était superflu, ne laissant que les outils essentiels. Cependant, si vous rencontrez encore des problèmes techniques, nos spécialistes les corrigeront immédiatement.',
    es: 'Hemos hecho enormes esfuerzos para que sea lo más cómodo posible para nuestros socios trabajar con nosotros. Hemos eliminado todas las cosas innecesarias, dejando solo las herramientas más esenciales. Sin embargo, si aún encuentra problemas técnicos, nuestros especialistas los solucionarán de inmediato.',
    cs: 'Vynaložili jsme obrovské úsilí, abychom našim partnerům usnadnili spolupráci s námi. Odstranili jsme všechny zbytečnosti a ponechali pouze nejdůležitější nástroje. Pokud však narazíte na technické problémy, naši specialisté je okamžitě opraví.',
    ru: 'Мы приложили огромные усилия, чтобы нашим Партнерам было как можно комфортнее сотрудничать с нами. Мы убрали все лишнее и оставили только самые главные инструменты. Однако если у вас все же возникнут технические проблемы, наши специалисты все мгновенно исправят.',
  },
  partnerHomeRulesText51: {
    ua: '3. Конфіденційність.',
    en: '3. Confidentiality.',
    de: '3. Vertraulichkeit.',
    fr: '3. Confidentialité.',
    es: '3. Confidencialidad.',
    cs: '3. Důvěrnost.',
    ru: '3. Конфиденциальность.',
  },
  partnerHomeRulesText52: {
    ua: 'Політика нашої компанії категорично забороняє розголошення будь-яких даних про партнерську співпрацю. Особливу увагу ми приділяємо не розголошенню інформації, щодо винагороди Партнера перед Партнерським Клієнтом.',
    en: "Our company's policy strictly prohibits disclosing any data about partnership cooperation. We pay special attention not to disclose information regarding the Partner's reward to the Partner Client.",
    de: 'Die Politik unseres Unternehmens verbietet streng die Offenlegung aller Daten zur Partnerschaftszusammenarbeit. Wir achten besonders darauf, keine Informationen über die Belohnung des Partners an den Partnerkunden preiszugeben.',
    fr: 'La politique de notre entreprise interdit strictement la divulgation de toute donnée concernant la coopération de partenariat. Nous accordons une attention particulière à ne pas divulguer d’informations concernant la récompense du Partenaire envers le Client Partenaire.',
    es: 'La política de nuestra empresa prohíbe estrictamente la divulgación de cualquier dato sobre la cooperación de asociación. Prestamos especial atención a no revelar información sobre la recompensa del Socio al Cliente Socio.',
    cs: 'Politika naší společnosti přísně zakazuje zveřejňování jakýchkoli údajů o partnerské spolupráci. Zvláštní pozornost věnujeme tomu, abychom nezveřejňovali informace o odměně Partnera vůči Partnerovi Klientovi.',
    ru: 'Политика нашей компании категорически запрещает раскрытие любых данных о партнерском сотрудничестве. Особое внимание мы уделяем нераскрытию информации о вознаграждении Партнера перед Партнерским Клиентом.',
  },
  partnerHomeRulesText53: {
    ua: '4. Взаємна вигода.',
    en: '4. Mutual benefit.',
    de: '4. Gegenseitiger Nutzen.',
    fr: '4. Bénéfice mutuel.',
    es: '4. Beneficio mutuo.',
    cs: '4. Vzájemný prospěch.',
    ru: '4. Взаимная выгода.',
  },
  partnerHomeRulesText54: {
    ua: 'Партнерський Клієнт також отримує 300 гривень бонусів, які він може витратити на оплату послуг адвоката.',
    en: 'The Partner Client also receives 300 hryvnias of bonuses, which he can spend on paying for legal services.',
    de: 'Der Partnerkunde erhält ebenfalls 300 Hrywnja Boni, die er für die Bezahlung von Rechtsdienstleistungen ausgeben kann.',
    fr: 'Le Client Partenaire reçoit également 300 hryvnias de bonus, qu’il peut dépenser pour payer des services juridiques.',
    es: 'El Cliente Socio también recibe 300 grivnas de bonificaciones, que puede gastar en el pago de servicios legales.',
    cs: 'Partner Klient také dostává 300 hřiven bonusů, které může utratit na úhradu právních služeb.',
    ru: 'Партнерский Клиент также получает 300 гривен бонусов, которые он может потратить на оплату услуг адвоката.',
  },
  partnerHomeRulesText55: {
    ua: '5. Особливе відношення.',
    en: '5. Special treatment.',
    de: '5. Besondere Behandlung.',
    fr: '5. Traitement spécial.',
    es: '5. Tratamiento especial.',
    cs: '5. Zvláštní zacházení.',
    ru: '5. Особое отношение.',
  },
  partnerHomeRulesText56: {
    ua: 'Дуже часто у наших Партнерів виникають юридичні проблеми, для вирішення яких вони звертаються до нас, як до фахівців в області права. Загалом, політика обслуговування Клієнтів в нашій компанії забороняє будь-яке особливе ставлення. Усі Клієнти повинні обслуговуватися якісно, а їх проблеми мають бути вирішені в найкоротший строк. Проте до обслуговування наших Партнерів ми підходимо з особливою увагою. Для нас Партнер є VIP Клієнтом, а його справа завжди в пріоритеті та під особливим контролем.',
    en: "Our partners often face legal problems, which they turn to us for as experts in the field of law. In general, our company's customer service policy prohibits any special treatment. All clients should be served with quality, and their problems should be resolved as quickly as possible. However, we treat our partners with special attention. For us, a partner is a VIP client, and their case is always a priority and under special control.",
    de: 'Unsere Partner haben oft rechtliche Probleme, wegen derer sie sich als Experten auf dem Gebiet des Rechts an uns wenden. Im Allgemeinen verbietet die Kundendienstrichtlinie unseres Unternehmens jede Sonderbehandlung. Alle Kunden sollten qualitativ bedient werden, und ihre Probleme sollten so schnell wie möglich gelöst werden. Wir behandeln unsere Partner jedoch mit besonderer Aufmerksamkeit. Für uns ist ein Partner ein VIP-Kunde, und sein Fall hat immer Vorrang und steht unter besonderer Kontrolle.',
    fr: "Nos partenaires sont souvent confrontés à des problèmes juridiques, pour lesquels ils se tournent vers nous en tant qu'experts dans le domaine du droit. En général, la politique de service à la clientèle de notre entreprise interdit tout traitement spécial. Tous les clients doivent être servis avec qualité, et leurs problèmes doivent être résolus aussi rapidement que possible. Cependant, nous accordons une attention particulière à nos partenaires. Pour nous, un partenaire est un client VIP, et son cas est toujours une priorité et sous un contrôle spécial.",
    es: 'Nuestros socios a menudo enfrentan problemas legales, para los cuales se dirigen a nosotros como expertos en el campo del derecho. En general, la política de servicio al cliente de nuestra empresa prohíbe cualquier tratamiento especial. Todos los clientes deben ser atendidos con calidad y sus problemas deben resolverse lo más rápido posible. Sin embargo, tratamos a nuestros socios con especial atención. Para nosotros, un socio es un cliente VIP, y su caso siempre tiene prioridad y está bajo un control especial.',
    cs: 'Naši partneři se často setkávají s právními problémy, s nimiž se obracejí na nás jako na odborníky v oblasti práva. Obecně platí, že politika služeb zákazníkům naší společnosti zakazuje jakékoli zvláštní zacházení. Všichni klienti by měli být obsluhováni kvalitně a jejich problémy by měly být vyřešeny co nejrychleji.',
  },
  partnerHomeRulesText57: {
    ua: 'За детальною інформацією телефонуйте на номер гарячої лінії',
    ru: 'Для получения подробной информации звоните на номер горячей линии.',
    en: 'For detailed information, call the hotline number.',
    de: 'Für detaillierte Informationen rufen Sie die Hotline-Nummer an.',
    fr: 'Pour des informations détaillées, appelez le numéro de la ligne directe.',
    es: 'Para obtener información detallada, llame al número de la línea de atención.',
    cs: 'Pro podrobné informace volejte na číslo horké linky.',
  },
  agreeRulesBtn: {
    ua: 'Погоджуюсь з правилами',
    ru: 'Согласен с правилами',
    en: 'I agree with the rules',
    de: 'Ich stimme den Regeln zu',
    fr: "J'accepte les règles",
    es: 'Estoy de acuerdo con las reglas',
    cs: 'Souhlasím s pravidly.',
  },

  // RedButton Page
  buyRedButtonSuccess: {
    ua: 'тривожну кнопку придбано',
    ru: 'тревожная кнопка куплена',
    en: 'Panic button purchased',
    de: 'Panikknopf gekauft',
    fr: "Bouton d'urgence acheté",
    es: 'Botón de pánico comprado',
    cs: 'Tlačítko paniky zakoupeno.',
  },
  buyRedButtonTitle: {
    ua: 'Купити тривожну кнопку',
    ru: 'Купить тревожную кнопку',
    en: 'Buy panic button',
    de: 'Panikknopf kaufen',
    fr: "Acheter le bouton d'urgence",
    es: 'Comprar botón de pánico',
    cs: 'Koupit tlačítko paniky.',
  },
  activeRedButtonTitle: {
    ua: 'Послуга "Тривожна кнопка" підключена!',
    ru: 'Услуга "Тревожная кнопка" подключена!',
    en: 'The "Panic Button" service is connected!',
    de: 'Der "Panikknopf"-Dienst ist verbunden!',
    fr: 'Le service "Bouton d\'urgence" est connecté !',
    es: '¡El servicio "Botón de pánico" está conectado!',
    cs: 'Služba "Tlačítko paniky" je připojena!',
  },
  cost: {
    ua: 'Вартість',
    ru: 'Стоимость',
    en: 'Cost',
    de: 'Kosten',
    fr: 'Coût',
    es: 'Costo',
    cs: 'Náklady',
  },
  buyBtn: {
    ua: 'Придбати',
    ru: 'Купить',
    en: 'Buy',
    de: 'Kaufen',
    fr: 'Acheter',
    es: 'Comprar',
    cs: 'Koupit',
  },
  redButtonText: {
    ua: 'Після придбання Тривожної кнопки буде підключена автооплата, щомісячний платіж складатиме 911. Кешбек за автооплату',
    ru: 'После покупки тревожной кнопки будет подключена автоплата, ежемесячный платеж составит 911. Кэшбэк за автоплату',
    en: 'After purchasing the Panic button, auto-payment will be enabled, the monthly payment will be 911. Cashback for auto-payment',
    de: 'Nach dem Kauf der Panikschaltfläche wird die Autozahlung aktiviert, die monatliche Zahlung beträgt 911. Cashback für die Autozahlung',
    fr: "Après l'achat du bouton d'urgence, le paiement automatique sera activé, le paiement mensuel sera de 911. Remboursement pour le paiement automatique",
    es: 'Después de comprar el botón de pánico, se habilitará el pago automático, el pago mensual será de 911. Reembolso por pago automático',
    cs: 'Po zakoupení tlačítka paniky bude povolena automatická platba, měsíční platba bude činit 911. Cashback za automatickou platbu.',
  },

  redButtonDescription: {
    ua: 'Відчуйте захист з додатком єАдвокат. Функція «Тривожна кнопка». Миттєва юридична допомога у разі порушення Ваших прав співробітниками: поліції, ТЦК, прокуратури, працівниками інших органів. Один клік і Ви під захистом.',
    ru: 'Ощутите защиту с приложением eAdvokat. Функция «Тревожная кнопка». Мгновенная юридическая помощь в случае нарушения Ваших прав сотрудниками: полиции, ТЦК, прокуратуры, работниками других органов. Один клик — и Вы под защитой.',
    en: 'Feel protected with the eAdvocate app. Panic button function. Instant legal assistance in case of violation of your rights by employees of the police, prosecutors, and other authorities. One click and you are protected.',
    de: 'Fühlen Sie sich mit der eAdvocate App geschützt. Funktion der Panik-Taste. Sofortige Rechtshilfe bei Verletzung Ihrer Rechte durch Mitarbeiter: Polizei, Staatsanwaltschaft, Mitarbeiter anderer Behörden. Ein Klick und Sie sind geschützt.',
    fr: "Ressentez-vous protégé avec l'application eAvocat. Fonction bouton d'urgence. Assistance juridique instantanée en cas de violation de vos droits par des agents de police, des procureurs et d'autres autorités. Un clic et vous êtes protégé.",
    es: 'Siéntase protegido con la aplicación eAbogado. Función de botón de pánico. Asistencia legal instantánea en caso de violación de sus derechos por parte de empleados de la policía, fiscales y otras autoridades. Un clic y está protegido.',
    cs: 'Cítíte se chráněni s aplikací eAdvokat. Funkce tlačítka paniky. Okamžitá právní pomoc v případě porušení vašich práv zaměstnanci policie, prokuratury a dalších orgánů. Jedním kliknutím jste chráněni.',
  },
  redButtonInfoTitle1: {
    ua: 'Вартість послуги:',
    ru: 'Стоимость услуги:',
    en: 'The cost of the service:',
    de: 'Die Kosten des Dienstes:',
    fr: 'Le coût du service :',
    es: 'El costo del servicio:',
    cs: 'Cena služby:',
  },
  redButtonInfoTitle2: {
    ua: 'Що Ви отримуєте?',
    ru: 'Что вы получаете?',
    en: 'What do you get?',
    de: 'Was bekommen Sie?',
    fr: "Qu'obtenez-vous ?",
    es: '¿Qué obtienes?',
    cs: 'Co dostanete?',
  },
  redButtonInfoList1_1: {
    ua: 'Вартість підключення:',
    ru: 'Стоимость подключения:',
    en: 'The cost of connection:',
    de: 'Die Kosten der Verbindung:',
    fr: 'Le coût de la connexion :',
    es: 'El costo de conexión:',
    cs: 'Cena za připojení:',
  },
  redButtonInfoList1_2: {
    ua: 'Вартість обслуговування: 911 грн/місяць',
    ru: 'Стоимость обслуживания: 911 грн/месяц',
    en: 'The cost of service: 911 UAH/month',
    de: 'Die Kosten der Dienstleistung: 911 UAH/Monat',
    fr: 'Le coût du service : 911 UAH/mois',
    es: 'El costo del servicio: 911 UAH/mes',
    cs: 'Cena služby: 911 UAH/měsíc',
  },
  redButtonInfoList2_1: {
    ua: 'Миттєву юридичну допомогу у разі протиправних дій з боку органів державної влади',
    ru: 'Мгновенная юридическая помощь в случае незаконных действий со стороны органов государственной власти',
    en: 'Instant legal assistance in case of unlawful actions by public authorities',
    de: 'Sofortiger Rechtsbeistand bei rechtswidrigen Handlungen von Behörden',
    fr: "Assistance juridique instantanée en cas d'actions illégales de la part des autorités publiques",
    es: 'Asistencia legal instantánea en caso de acciones ilegales por parte de las autoridades públicas',
    cs: 'Okamžitá právní pomoc v případě nezákonných akcí ze strany veřejných orgánů',
  },
  redButtonInfoList2_2: {
    ua: 'Цілодобову юридичну підтримку (чат з професійним адвокатом)',
    ru: 'Круглосуточная юридическая поддержка (чат с профессиональным адвокатом)',
    en: 'Round-the-clock legal support (chat with a professional lawyer)',
    de: 'Rechtliche Unterstützung rund um die Uhr (Chat mit einem Fachanwalt)',
    fr: 'Soutien juridique 24h/24 (chat avec un avocat professionnel)',
    es: 'Apoyo legal las 24 horas (chat con un abogado profesional)',
    cs: 'Právní podpora 24/7 (chat s odborným právníkem)',
  },
  redButtonInfoList2_3: {
    ua: 'Безкоштовні юридичні консультації з приводу дій співробітників поліції, ТЦК, прокуратури чи інших держустанов',
    ru: 'Бесплатные юридические консультации по действиям сотрудников полиции, ТЦК, прокуратуры или других госучреждений',
    en: 'Free legal advice on the actions of police officers, prosecutors or other government agencies',
    de: 'Kostenlose Rechtsberatung zu Handlungen von Polizeibeamten, Staatsanwälten oder anderen staatlichen Institutionen',
    fr: "Consultations juridiques gratuites concernant les actions des agents de police, des procureurs ou d'autres agences gouvernementales",
    es: 'Asesoría legal gratuita sobre las acciones de los agentes de policía, fiscales u otras agencias gubernamentales',
    cs: 'Bezplatné právní poradenství o činnostech policistů, státních zástupců nebo jiných státních agentur',
  },
  redButtonInfoList2_4: {
    ua: 'Правовий аналіз документів в режимі online',
    ru: 'Правовой анализ документов в режиме онлайн',
    en: 'Legal analysis of documents online',
    de: 'Rechtliche Analyse von Dokumenten online',
    fr: 'Analyse juridique de documents en ligne',
    es: 'Análisis legal de documentos en línea',
    cs: 'Právní analýza dokumentů online',
  },
  redBtnModalTitle: {
    ua: 'Додавання кредитної карти',
    ru: 'Добавление кредитной карты',
    en: 'Adding a credit card',
    de: 'Hinzufügen einer Kreditkarte',
    fr: "Ajout d'une carte de crédit",
    es: 'Añadiendo una tarjeta de crédito',
    cs: 'Přidání kreditní karty',
  },
  redBtnModalText1: {
    ua: 'Тепер ви можете купити тривожну кнопку',
    ru: 'Теперь вы можете купить тревожную кнопку',
    en: 'Now you can buy the panic button',
    de: 'Jetzt können Sie die Panikschaltfläche kaufen',
    fr: "Vous pouvez maintenant acheter le bouton d'urgence",
    es: 'Ahora puede comprar el botón de pánico',
    cs: 'Nyní si můžete koupit tlačítko paniky.',
  },

  redBtnModalText2: {
    ua: 'Для придбання тривожної кнопки необхідно додати карту',
    ru: 'Для покупки тревожной кнопки необходимо добавить карту',
    de: 'Um die Panikschaltfläche zu kaufen, müssen Sie eine Karte hinzufügen',
    en: 'To purchase the panic button, you need to add a card',
    fr: 'Pour acheter le bouton panique, vous devez ajouter une carte',
    es: 'Para comprar el botón de pánico, necesitas añadir una tarjeta',
    cs: 'Pro zakoupení tlačítka paniky je nutné přidat kartu',
  },
  popconfirmTitle: {
    ua: 'Бажаєте додати карту?',
    ru: 'Хотите добавить карту?',
    de: 'Möchten Sie eine Karte hinzufügen?',
    en: 'Do you want to add a card?',
    fr: 'Voulez-vous ajouter une carte?',
    es: '¿Quieres añadir una tarjeta?',
    cs: 'Chcete přidat kartu?',
  },
  popconfirmText1: {
    ua: 'Буде проведено оплату на 1грн.',
    ru: 'Будет проведён платёж на 1 грн.',
    de: 'Es wird eine Zahlung von 1 UAH geleistet.',
    en: 'A payment of 1 UAH will be made.',
    fr: 'Un paiement de 1 UAH sera effectué.',
    es: 'Se realizará un pago de 1 UAH.',
    cs: 'Bude provedena platba 1 UAH.',
  },
  popconfirmText2: {
    ua: 'Кошти повернуться назад.',
    ru: 'Средства будут возвращены.',
    de: 'Die Mittel werden zurückerstattet.',
    en: 'Funds will be returned.',
    fr: 'Les fonds seront retournés.',
    es: 'Los fondos serán devueltos.',
    cs: 'Finanční prostředky budou vráceny.',
  },
  yes: {
    ua: 'Так',
    ru: 'Да',
    de: 'Ja',
    en: 'Yes',
    fr: 'Oui',
    es: 'Sí',
    cs: 'Ano',
  },
  no: {
    ua: 'Ні',
    ru: 'Нет',
    de: 'Nein',
    en: 'No',
    fr: 'Non',
    es: 'No',
    cs: 'Ne',
  },
  and: {
    ua: 'і',
    ru: 'и',
    de: 'und',
    en: 'and',
    fr: 'et',
    es: 'y',
    cs: 'a',
  },

  // COMPONENTS
  // AppSuggestionNotification
  alertNotificationButtonLabel: {
    ua: 'Відкрити',
    ru: 'Открыть',
    de: 'Öffnen',
    en: 'Open',
    fr: 'Ouvrir',
    es: 'Abrir',
    cs: 'Otevřít',
  },
  alertNotificationTitle: {
    ua: 'єАдвокат - справа в смартфоні',
    ru: 'еАдвокат - дело в смартфоне',
    de: 'eAnwalt - Fall im Smartphone',
    en: 'eLawyer - case in smartphone',
    fr: 'eAvocat - affaire dans le smartphone',
    es: 'eAbogado - caso en el smartphone',
    cs: 'ePrávník - případ v mobilu',
  },
  alertNotificationText: {
    ua: 'Юридичні консультації з будь-яких питань',
    ru: 'Юридические консультации по любым вопросам',
    de: 'Rechtsberatung zu allen Fragen',
    en: 'Legal consultations on any questions',
    fr: 'Consultations juridiques sur toutes questions',
    es: 'Consultas legales sobre cualquier pregunta',
    cs: 'Právní poradenství k jakýmkoli otázkám',
  },
  // BalanceNew
  partnershipBalance: {
    ua: 'Партнерський баланс',
    ru: 'Партнёрский баланс',
    de: 'Partnerschaftsguthaben',
    en: 'Partnership balance',
    fr: 'Solde de partenariat',
    es: 'Saldo de asociación',
    cs: 'Partnerský zůstatek',
  },
  account: {
    ua: 'Рахунок',
    ru: 'Счёт',
    de: 'Konto',
    en: 'Account',
    fr: 'Compte',
    es: 'Cuenta',
    cs: 'Účet',
  },
  // CardForPayments
  cardForPaymentsSuccess: {
    ua: 'Платіжний засіб видалено.',
    ru: 'Платежное средство удалено.',
    de: 'Zahlungsmethode gelöscht.',
    en: 'Payment method deleted.',
    fr: 'Méthode de paiement supprimée.',
    es: 'Método de pago eliminado.',
    cs: 'Způsob platby byl odstraněn.',
  },
  cardForPaymentsError: {
    ua: 'Не вдалось видалити карту. Спробуйте пізніше.',
    ru: 'Не удалось удалить карту. Попробуйте позже.',
    de: 'Karte konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.',
    en: 'Failed to delete the card. Please try again later.',
    fr: 'Échec de la suppression de la carte. Veuillez réessayer plus tard.',
    es: 'No se pudo eliminar la tarjeta. Por favor, inténtelo de nuevo más tarde.',
    cs: 'Kartu se nepodařilo odstranit. Zkuste to prosím později.',
  },
  cardIsInvalid: {
    ua: 'Карта не дійсна.',
    ru: 'Карта недействительна.',
    de: 'Die Karte ist ungültig.',
    en: 'The card is invalid.',
    fr: "La carte n'est pas valide.",
    es: 'La tarjeta no es válida.',
    cs: 'Karta není platná.',
  },
  bindtheCard: {
    ua: 'Прив`язати карту',
    ru: 'Привязать карту',
    de: 'Karte verknüpfen',
    en: 'Bind the card',
    fr: 'Lier la carte',
    es: 'Vincular la tarjeta',
    cs: 'Propojit kartu',
  },

  popconfirmRemoveTitle: {
    ua: 'Видалення платіжного засобу',
    ru: 'Удаление платёжного средства',
    de: 'Zahlungsmittel entfernen',
    en: 'Remove payment method',
    fr: 'Supprimer le moyen de paiement',
    es: 'Eliminar método de pago',
    cs: 'Odstranit platební metodu',
  },
  popconfirmRemoveDescription: {
    ua: 'Впевнені, що хочете видалити карту?',
    ru: 'Вы уверены, что хотите удалить карту?',
    de: 'Möchten Sie die Karte wirklich entfernen?',
    en: 'Are you sure you want to delete the card?',
    fr: 'Êtes-vous sûr de vouloir supprimer la carte?',
    es: '¿Está seguro de que desea eliminar la tarjeta?',
    cs: 'Jste si jisti, že chcete kartu odstranit?',
  },
  // Chat
  sendingFileError: {
    ua: 'Помилка при відправці файлу',
    ru: 'Ошибка при отправке файла',
    de: 'Fehler beim Senden der Datei',
    en: 'Error sending file',
    fr: "Erreur d'envoi du fichier",
    es: 'Error al enviar el archivo',
    cs: 'Chyba při odesílání souboru',
  },
  // Client
  // Client-InsuranceTransactions
  paid: {
    ua: 'сплачено:',
    ru: 'оплачено:',
    de: 'bezahlt:',
    en: 'paid:',
    fr: 'payé :',
    es: 'pagado:',
    cs: 'zaplatil:',
  },
  // ClientMenu
  // ClientMenu - ClientBalancePayModal
  ClientBPMSuccess1: {
    ua: 'Подяка юристу відправлена',
    ru: 'Спасибо юристу отправлено',
    de: 'Dankesnachricht an den Anwalt gesendet',
    en: 'Thank you message sent to the lawyer',
    fr: "Message de remerciement envoyé à l'avocat",
    es: 'Mensaje de agradecimiento enviado al abogado',
    cs: 'Děkovná zpráva byla odeslána právníkovi',
  },
  ClientBPMSuccess2: {
    ua: 'Баланс поповнено',
    ru: 'Баланс пополнен',
    de: 'Kontostand aufgefüllt',
    en: 'Balance replenished',
    fr: 'Solde rechargé',
    es: 'Saldo recargado',
    cs: 'Zůstatek doplněn',
  },
  // ClientMenu - PayRedButtonDebt
  payRedButtonDebtSuccess: {
    ua: 'Платіж за Тривожну кнопку успішно проведено',
    ru: 'Платеж за Тревожную кнопку успешно произведен',
    de: 'Zahlung für die Paniktaste erfolgreich durchgeführt',
    en: 'Payment for the Panic Button successfully made',
    fr: 'Paiement pour le bouton de panique effectué avec succès',
    es: 'Pago por el botón de pánico realizado con éxito',
    cs: 'Platba za panikovou tlačítko byla úspěšně provedena',
  },
  payRedButtonDebtError: {
    ua: 'На рахунку не достатньо коштів',
    ru: 'Недостаточно средств на счёте',
    de: 'Nicht genügend Geld auf dem Konto',
    en: 'Insufficient funds in the account',
    fr: 'Fonds insuffisants sur le compte',
    es: 'Fondos insuficientes en la cuenta',
    cs: 'Nedostatek prostředků na účtu',
  },
  // ConfirmChangePhone
  success: {
    ua: 'Успіх!',
    ru: 'Успех!',
    de: 'Erfolg!',
    en: 'Success!',
    fr: 'Succès!',
    es: '¡Éxito!',
    cs: 'Úspěch!',
  },
  confirmChangePhoneSuccess1: {
    ua: 'Телефон підтверджено',
    ru: 'Телефон подтверждён',
    de: 'Telefon bestätigt',
    en: 'Phone confirmed',
    fr: 'Téléphone confirmé',
    es: 'Teléfono confirmado',
    cs: 'Telefon potvrzen',
  },
  confirmChangePhoneWarn1: {
    ua: 'Не вдалося отримати підтвердження телефону!',
    ru: 'Не удалось получить подтверждение телефона!',
    de: 'Telefonbestätigung fehlgeschlagen!',
    en: 'Failed to receive phone confirmation!',
    fr: 'Échec de la réception de la confirmation du téléphone!',
    es: '¡No se pudo recibir la confirmación del teléfono!',
    cs: 'Nepodařilo se přijmout potvrzení telefonu!',
  },
  confirmChangePhoneWarn2: {
    ua: 'Не вірний код!',
    ru: 'Неверный код!',
    de: 'Falscher Code!',
    en: 'Incorrect code!',
    fr: 'Code incorrect!',
    es: '¡Código incorrecto!',
    cs: 'Nesprávný kód!',
  },
  confirmChangePhoneWarn3: {
    ua: 'Цей номер телефону вже зареєстрований!',
    ru: 'Этот номер телефона уже зарегистрирован!',
    de: 'Diese Telefonnummer ist bereits registriert!',
    en: 'This phone number is already registered!',
    fr: 'Ce numéro de téléphone est déjà enregistré!',
    es: '¡Este número de teléfono ya está registrado!',
    cs: 'Toto telefonní číslo je již registrováno!',
  },
  confirmChangePhoneSuccess2: {
    ua: 'Код відправлено!',
    ru: 'Код отправлен!',
    de: 'Code gesendet!',
    en: 'Code sent!',
    fr: 'Code envoyé!',
    es: '¡Código enviado!',
    cs: 'Kód odeslán!',
  },
  confirmChangePhoneWarn4: {
    ua: 'Помилка',
    ru: 'Ошибка',
    de: 'Fehler',
    en: 'Error',
    fr: 'Erreur',
    es: 'Error',
    cs: 'Chyba',
  },
  confirmChangePhoneRules1: {
    ua: 'Введіть код',
    ru: 'Введите код',
    de: 'Code eingeben',
    en: 'Enter code',
    fr: 'Entrez le code',
    es: 'Introduce el código',
    cs: 'Zadejte kód',
  },
  confirmChangePhoneRules2: {
    ua: '6 символів',
    ru: '6 символов',
    de: '6 Zeichen',
    en: '6 characters',
    fr: '6 caractères',
    es: '6 caracteres',
    cs: '6 znaků',
  },
  inAMinute: {
    ua: 'через',
    ru: 'через',
    de: 'in',
    en: 'in',
    fr: 'dans',
    es: 'en',
    cs: 'za',
  },
  second: {
    ua: 'с',
    ru: 'с',
    de: 's',
    en: 's',
    fr: 's',
    es: 's',
    cs: 's',
  },
  confirmChangePhoneRules3: {
    ua: 'Введіть номер телефону в форматі 0ХХХХХХХХ',
    ru: 'Введите номер телефона в формате 0ХХХХХХХХ',
    de: 'Geben Sie die Telefonnummer im Format 0XXXXXXXXX ein',
    en: 'Enter phone number in the format 0XXXXXXXXX',
    fr: 'Entrez le numéro de téléphone au format 0XXXXXXXXX',
    es: 'Introduzca el número de teléfono en el formato 0XXXXXXXXX',
    cs: 'Zadejte telefonní číslo ve formátu 0XXXXXXXXX',
  },
  confirmChangePhoneRules4: {
    ua: 'Введіть ваш номер телефону',
    ru: 'Введите ваш номер телефона',
    de: 'Geben Sie Ihre Telefonnummer ein',
    en: 'Enter your phone number',
    fr: 'Entrez votre numéro de téléphone',
    es: 'Introduzca su número de teléfono',
    cs: 'Zadejte své telefonní číslo',
  },

  // GoogleMap
  googleMapTitle: {
    ua: 'Дорога до філії',
    ru: 'Дорога к филиалу',
    en: 'Directions to Branch',
    de: 'Weg zur Filiale',
    fr: 'Itinéraire vers la succursale',
    es: 'Direcciones a la sucursal',
    cs: 'Pokyny k pobočce',
  },
  // HotlineMeetInvite --------!!!!!!!!!!!!!!
  constHotlineMeetInviteText1_1: {
    ua: 'Дистанційну',
    ru: 'Удалённую',
    en: 'Remote',
    de: 'Fern',
    fr: 'À distance',
    es: 'Remota',
    cs: 'Dálkovou',
  },
  constHotlineMeetInviteText1_2: {
    ua: '«Преміум консультацію»',
    ru: '«Премиум консультацию»',
    en: '"Premium consultation"',
    de: '"Premium-Beratung"',
    fr: '"Consultation Premium"',
    es: '"Consulta Premium"',
    cs: '"Prémiová konzultace"',
  },
  constHotlineMeetInviteText1_3: {
    ua: 'консультацію',
    ru: 'консультацию',
    en: 'consultation',
    de: 'Beratung',
    fr: 'consultation',
    es: 'consulta',
    cs: 'konzultaci',
  },
  constHotlineMeetInviteText1_4: {
    ua: 'у місті',
    ru: 'в городе',
    en: 'in the city',
    de: 'in der Stadt',
    fr: 'dans la ville',
    es: 'en la ciudad',
    cs: 've městě',
  },
  constHotlineMeetInviteText2_1: {
    ua: 'Досвідчений фаховий адвокат',
    ru: 'Опытный профессиональный адвокат',
    en: 'Experienced professional lawyer',
    de: 'Erfahrener Fachanwalt',
    fr: 'Avocat professionnel expérimenté',
    es: 'Abogado profesional experimentado',
    cs: 'Zkušený profesionální advokát',
  },
  constHotlineMeetInviteText2_2: {
    ua: 'Юрист відділу',
    ru: 'Юрист отдела',
    en: 'Department lawyer',
    de: 'Abteilungsanwalt',
    fr: 'Avocat de département',
    es: 'Abogado de departamento',
    cs: 'Oddělový advokát',
  },
  constHotlineMeetInviteText2_3: {
    ua: 'очікує Вас',
    ru: 'ожидает вас',
    en: 'is waiting for you',
    de: 'wartet auf Sie',
    fr: 'vous attend',
    es: 'te está esperando',
    cs: 'čeká na vás',
  },
  constHotlineMeetInviteText2_4: {
    ua: 'радо надасть Вам',
    ru: 'с радостью предоставит вам',
    en: 'will gladly provide you with',
    de: 'stellt Ihnen gerne zur Verfügung',
    fr: 'vous fournira avec plaisir',
    es: 'te proporcionará con gusto',
    cs: 'rád vám poskytne',
  },
  constHotlineMeetInviteTitle: {
    ua: 'Адвокатського об’єднання «Захист»',
    ru: 'Адвокатского объединения «Защита»',
    en: 'Law firm "Defense"',
    de: 'Rechtsanwaltskanzlei "Verteidigung"',
    fr: 'Cabinet d\'avocats "Défense"',
    es: 'Firma de abogados "Defensa"',
    cs: 'Advokátní kancelář "Obrana"',
  },
  constHotlineMeetInviteCVTitle: {
    ua: 'Переглянути портфоліо',
    ru: 'Просмотреть портфолио',
    en: 'View CV',
    de: 'Lebenslauf ansehen',
    fr: 'Voir le CV',
    es: 'Ver CV',
    cs: 'Zobrazit životopis',
  },
  constHotlineMeetInviteCV1: {
    ua: 'адвоката',
    ru: 'адвоката',
    en: 'lawyer',
    de: 'Anwalt',
    fr: 'avocat',
    es: 'abogado',
    cs: 'advokáta',
  },
  constHotlineMeetInviteCV2: {
    ua: 'юриста відділу',
    ru: 'юриста отдела',
    en: 'department lawyer',
    de: 'Abteilungsanwalt',
    fr: 'avocat de département',
    es: 'abogado del departamento',
    cs: 'oddělový advokát',
  },
  on: {
    ua: 'на',
    ru: 'на',
    en: 'on',
    de: 'auf',
    fr: 'sur',
    es: 'en',
    cs: 'na',
  },
  thanksForTheOpal: {
    ua: 'Дякуємо за оплату!',
    ru: 'Спасибо за оплату!',
    en: 'Thank you for the payment!',
    de: 'Danke für die Zahlung!',
    fr: 'Merci pour le paiement!',
    es: '¡Gracias por el pago!',
    cs: 'Děkujeme za platbu!',
  },
  hotlineMeetInviteCardTitle: {
    ua: 'НАПРАВЛЕННЯ',
    ru: 'НАПРАВЛЕНИЕ',
    en: 'REFERRAL',
    de: 'WEITERLEITUNG',
    fr: 'ORIENTATION',
    es: 'DERIVACIÓN',
    cs: 'DOPORUČENÍ',
  },
  hotlineMeetInviteCardText1: {
    ua: 'на прийом до адвоката',
    ru: 'на прием к адвокату',
    en: 'for appointment with a lawyer',
    de: 'für einen Termin beim Anwalt',
    fr: 'pour un rendez-vous avec un avocat',
    es: 'para una cita con un abogado',
    cs: 'na schůzku s advokátem',
  },
  hotlineMeetInviteCardText2: {
    ua: 'від',
    ru: 'от',
    en: 'from',
    de: 'von',
    fr: 'de',
    es: 'de',
    cs: 'od',
  },

  hotlineMeetInviteCardText3: {
    ua: 'У разі, якщо у Вас виникли невідкладні справи, у зв’язку з чим необхідно перенести запис на інший час, просимо попередити про це завчасно. Так само ми проінформуємо Вас про будь-які зміни у Вашому записі.',
    ru: 'В случае, если у вас возникли неотложные дела, из-за которых необходимо перенести запись на другое время, просим предупредить об этом заранее. Мы также проинформируем вас о любых изменениях в вашей записи.',
    en: 'In case you have urgent matters and need to reschedule your appointment, please inform us in advance. We will also inform you about any changes to your appointment.',
    de: 'Im Falle von dringenden Angelegenheiten, bei denen Sie Ihren Termin verschieben müssen, informieren Sie uns bitte im Voraus. Wir informieren Sie auch über etwaige Änderungen an Ihrem Termin.',
    fr: 'En cas d’affaires urgentes nécessitant le report de votre rendez-vous, veuillez nous en informer à l’avance. Nous vous informerons également de tout changement concernant votre rendez-vous.',
    es: 'En caso de que tenga asuntos urgentes y necesite reprogramar su cita, por favor infórmenos con antelación. También le informaremos sobre cualquier cambio en su cita.',
    cs: 'V případě, že máte naléhavé záležitosti a potřebujete přeložit svou schůzku na jiný čas, prosíme, informujte nás předem. Také vás budeme informovat o jakýchkoli změnách ve vaší schůzce.',
  },
  hotlineMeetInviteCardText4: {
    ua: 'ПРИ НАЯВНОСТІ, візьміть з собою:',
    ru: 'ПРИ НАЛИЧИИ, возьмите с собой:',
    en: 'IF AVAILABLE, please bring with you:',
    de: 'WENN VERFÜGBAR, bringen Sie bitte mit:',
    fr: 'SI DISPONIBLE, veuillez apporter avec vous:',
    es: 'SI ESTÁ DISPONIBLE, por favor traiga consigo:',
    cs: 'POKUD JE TO DOSTUPNÉ, vezměte prosím s sebou:',
  },
  hotlineMeetInviteCardText5: {
    ua: 'посвідчення особи;',
    ru: 'удостоверение личности;',
    en: 'ID card;',
    de: 'Ausweis;',
    fr: "carte d'identité;",
    es: 'tarjeta de identificación;',
    cs: 'osobní doklad;',
  },
  hotlineMeetInviteCardText6: {
    ua: 'документи по справі.',
    ru: 'документы по делу.',
    en: 'documents related to the case.',
    de: 'Dokumente zum Fall.',
    fr: 'documents liés à l’affaire.',
    es: 'documentos relacionados con el caso.',
    cs: 'dokumenty související se záležitostí.',
  },
  hotlineMeetInviteCardNiceBtn1: {
    ua: 'Анкетування після консультації',
    ru: 'Опрос после консультации',
    en: 'Post-consultation survey',
    de: 'Umfrage nach der Beratung',
    fr: 'Sondage après la consultation',
    es: 'Encuesta post-consulta',
    cs: 'Průzkum po konzultaci',
  },
  hotlineMeetInviteCardNiceBtn2: {
    ua: 'Подарунок',
    ru: 'Подарок',
    en: 'Gift',
    de: 'Geschenk',
    fr: 'Cadeau',
    es: 'Regalo',
    cs: 'Dárek',
  },
  hotlineMeetInviteCardMeetData1: {
    ua: 'З метою підвищення якості обслуговування, просимо після консультації клацнути на кнопку "Анкетування після консультації" та відповісти на декілька запитань. Ваша думка буде дуже важливою для нас.',
    ru: 'С целью повышения качества обслуживания, просим после консультации нажать на кнопку "Опрос после консультации" и ответить на несколько вопросов. Ваше мнение будет очень важным для нас.',
    en: 'In order to improve the quality of service, we kindly ask you to click on the "Post-consultation survey" button after the consultation and answer a few questions. Your opinion will be very important to us.',
    de: 'Um die Servicequalität zu verbessern, bitten wir Sie, nach der Beratung auf die Schaltfläche "Nachberatungsumfrage" zu klicken und einige Fragen zu beantworten. Ihre Meinung ist uns sehr wichtig.',
    fr: 'Pour améliorer la qualité du service, nous vous prions de cliquer sur le bouton "Sondage après la consultation" après la consultation et de répondre à quelques questions. Votre avis est très important pour nous.',
    es: 'Con el fin de mejorar la calidad del servicio, le pedimos amablemente que haga clic en el botón "Encuesta post-consulta" después de la consulta y responda a algunas preguntas. Su opinión será muy importante para nosotros.',
    cs: 'Abychom zlepšili kvalitu služeb, prosíme vás, abyste po konzultaci klikli na tlačítko "Průzkum po konzultaci" a odpověděli na několik otázek. Váš názor pro nás bude velmi důležitý.',
  },
  hotlineMeetInviteCardMeetData2: {
    ua: 'Подарунок зможете отримати після консультації. Не забудьте клацнути тоді кнопку "Подарунок", зареєструватися, якщо ви у нас вперше, та пройти анкетування з пройденої консультації. Після анкетування, Вам буде подаровано сертифікат на 3 безкоштовні офісні консультації зі сімейних, кредитних, земельних правовідносин та ДТП. Цей сертифікат можна самому використати, або подарувати будь-якій іншій особі. Рідні, друзі, знайомі тощо.',
    ru: 'Вы получите подарок после консультации. Не забудьте нажать кнопку "Подарок", зарегистрироваться, если вы у нас впервые, и пройти опрос по прошедшей консультации. После завершения опроса вам будет выдан сертификат на 3 бесплатные офисные консультации по семейным, кредитным, земельным правам и ДТП. Этот сертификат можно использовать самому или подарить другому человеку. Родным, друзьям, знакомым и т. д.',
    en: 'You will receive a gift after the consultation. Don`t forget to click the "Gift" button, register if you are new to us, and complete the survey on the consultation you attended. After completing the survey, you will receive a certificate for 3 free office consultations on family, credit, land legal relations, and traffic accidents. This certificate can be used by yourself or given to any other person. Relatives, friends, acquaintances, etc.',
    de: 'Sie erhalten ein Geschenk nach der Beratung. Vergessen Sie nicht, auf die Schaltfläche "Geschenk" zu klicken, sich zu registrieren, wenn Sie neu bei uns sind, und die Umfrage zur besuchten Beratung abzuschließen. Nach Abschluss der Umfrage erhalten Sie einen Gutschein für 3 kostenlose Büroberatungen zu Familien-, Kredit-, Grundstücksrechts- und Verkehrsunfallfragen. Dieser Gutschein kann von Ihnen selbst verwendet oder an eine andere Person weitergegeben werden. Verwandte, Freunde, Bekannte usw.',
    fr: 'Vous recevrez un cadeau après la consultation. N\'oubliez pas de cliquer sur le bouton "Cadeau", de vous inscrire si vous êtes nouveau chez nous et de compléter le sondage sur la consultation à laquelle vous avez assisté. Après avoir complété le sondage, vous recevrez un certificat pour 3 consultations de bureau gratuites sur les relations juridiques familiales, de crédit, de terrain et d\'accidents de la circulation. Ce certificat peut être utilisé par vous-même ou donné à toute autre personne. Parents, amis, connaissances, etc.',
    es: 'Recibirás un regalo después de la consulta. No olvides hacer clic en el botón "Regalo", registrarte si eres nuevo con nosotros y completar la encuesta sobre la consulta a la que asististe. Después de completar la encuesta, recibirás un certificado por 3 consultas de oficina gratuitas sobre relaciones legales familiares, de crédito, de tierras y accidentes de tráfico. Este certificado puede ser utilizado por ti mismo o entregado a cualquier otra persona. Familiares, amigos, conocidos, etc.',
    cs: 'Dostanete dárek po konzultaci. Nezapomeňte kliknout na tlačítko "Dárek", zaregistrovat se, pokud jste u nás noví, a dokončit průzkum o konzultaci, které jste se zúčastnili. Po dokončení průzkumu obdržíte certifikát na 3 bezplatné kancelářské konzultace v oblasti rodinného práva, úvěrového práva, pozemkového práva a dopravních nehod. Tento certifikát lze použít sami nebo darovat jiné osobě. Příbuzní, přátelé, známí atd.',
  },
  hotlineMeetInviteCardText7: {
    ua: 'Також, наразі у нас діє сезонна АКЦІЯ! При укладанні Договору, Ви гарантовано отримаєте 20% знижку на послуги Адвокатського об’єднання «Захист».',
    ru: 'Также у нас сейчас действует сезонная АКЦИЯ! При заключении Договора вы гарантированно получите 20% скидку на услуги Адвокатского объединения «Защита».',
    en: 'Also, we currently have a seasonal PROMOTION! When signing the Contract, you will receive a guaranteed 20% discount on the services of the "Protection" Law Firm.',
    de: 'Außerdem haben wir derzeit eine saisonale AKTION! Beim Abschluss des Vertrags erhalten Sie einen garantierten Rabatt von 20% auf die Dienstleistungen der Anwaltskanzlei "Schutz".',
    fr: 'De plus, nous avons actuellement une PROMOTION saisonnière! En signant le contrat, vous bénéficierez d\'une réduction garantie de 20 % sur les services du cabinet d\'avocats "Protection".',
    es: 'Además, actualmente tenemos una PROMOCIÓN estacional. Al firmar el contrato, recibirá un descuento garantizado del 20 % en los servicios del bufete de abogados "Protección".',
    cs: 'Také máme v současnosti sezonní AKCI! Při podpisu smlouvy obdržíte zaručenou slevu 20 % na služby advokátní kanceláře "Ochrana".',
  },
  hotlineMeetInviteCardText8: {
    ua: 'Приходьте до нас! Ми будемо раді пригостити Вас чаєм і надати відповіді на усі Ваші юридичні питання!',
    ru: 'Приходите к нам! Мы будем рады угостить вас чаем и ответить на все ваши юридические вопросы!',
    en: 'Come to us! We will be happy to treat you with tea and provide answers to all your legal questions!',
    de: 'Kommen Sie zu uns! Wir freuen uns, Sie mit Tee zu bewirten und Antworten auf alle Ihre rechtlichen Fragen zu geben!',
    fr: 'Venez chez nous ! Nous serons ravis de vous offrir du thé et de répondre à toutes vos questions juridiques !',
    es: '¡Ven a nosotros! Estaremos encantados de ofrecerte té y responder a todas tus preguntas legales.',
    cs: 'Přijďte k nám! Budeme rádi, že vás pohostíme čajem a zodpovíme všechny vaše právní dotazy!',
  },
  hotlineMeetInviteCardText9: {
    ua: 'Телефон гарячої лінії:',
    ru: 'Телефон горячей линии:',
    en: 'Hotline phone number:',
    de: 'Hotline-Telefonnummer:',
    fr: 'Numéro de téléphone de la ligne directe :',
    es: 'Número de teléfono de la línea directa:',
    cs: 'Telefonní číslo horké linky:',
  },
  hotlineMeetInviteCardText10: {
    ua: 'Також можете написати нам через соцмережі:',
    ru: 'Вы также можете написать нам через соцсети:',
    en: 'You can also write to us via social networks:',
    de: 'Sie können uns auch über soziale Netzwerke schreiben:',
    fr: 'Vous pouvez également nous écrire via les réseaux sociaux :',
    es: 'También puede escribirnos a través de las redes sociales:',
    cs: 'Můžete nám také napsat prostřednictvím sociálních sítí:',
  },
  telegram: {
    ua: 'Телеграм',
    ru: 'Телеграм',
    en: 'Telegram',
    de: 'Telegramm',
    fr: 'Telegram',
    es: 'Telegram',
    cs: 'Telegram',
  },
  viber: {
    ua: 'Вайбер',
    ru: 'Вайбер',
    en: 'Viber',
    de: 'Viber',
    fr: 'Viber',
    es: 'Viber',
    cs: 'Viber',
  },

  hotlineMeetInviteCardText11: {
    ua: 'Наш сайт:',
    ru: 'Наш сайт:',
    en: 'Our website:',
    de: 'Unsere Website:',
    fr: 'Notre site web:',
    es: 'Nuestro sitio web:',
    cs: 'Naše webové stránky:',
  },
  hotlineMeetInviteCardText12: {
    ua: 'Тепер Ви можете користуватися своїм особистим кабінетом. Якщо Ви у нас вперше, то не забудьте зареєструватися в ньому. Ви можете використовувати його багатий функціонал зв`язаний з Вашим договором, записуватися на нові консультації, зв`язуватися швидко через нього з нами, або навіть самому заробляти використовуючи нашу партнерську програму.',
    ru: 'Теперь вы можете пользоваться своим личным кабинетом. Если вы у нас впервые, не забудьте зарегистрироваться в нем. Вы можете использовать его богатый функционал, связанный с вашим договором, записываться на новые консультации, быстро связываться с нами через него или даже зарабатывать, используя нашу партнерскую программу.',
    en: "Now you can use your personal account. If you are new to us, don't forget to register in it. You can use its rich functionality related to your contract, sign up for new consultations, quickly communicate through it with us, or even earn money using our affiliate program.",
    de: 'Jetzt können Sie Ihr persönliches Konto verwenden. Wenn Sie neu bei uns sind, vergessen Sie nicht, sich dafür zu registrieren. Sie können seine umfangreichen Funktionen im Zusammenhang mit Ihrem Vertrag nutzen, sich für neue Beratungen anmelden, schnell über ihn mit uns kommunizieren oder sogar Geld verdienen, indem Sie unser Partnerprogramm nutzen.',
    fr: "Vous pouvez désormais utiliser votre compte personnel. Si vous êtes nouveau chez nous, n'oubliez pas de vous y inscrire. Vous pouvez utiliser ses nombreuses fonctionnalités liées à votre contrat, vous inscrire à de nouvelles consultations, communiquer rapidement avec nous ou même gagner de l'argent grâce à notre programme d'affiliation.",
    es: 'Ahora puede usar su cuenta personal. Si es nuevo con nosotros, no olvide registrarse en ella. Puede usar su rica funcionalidad relacionada con su contrato, inscribirse para nuevas consultas, comunicarse rápidamente con nosotros a través de ella o incluso ganar dinero utilizando nuestro programa de afiliados.',
    cs: 'Nyní můžete používat svůj osobní účet. Pokud jste u nás poprvé, nezapomeňte se zaregistrovat. Můžete využívat jeho bohatou funkčnost spojenou s vaší smlouvou, přihlašovat se na nové konzultace, rychle s námi komunikovat nebo dokonce vydělávat peníze prostřednictvím našeho partnerského programu.',
  },
  hotlineMeetInviteCardText13: {
    ua: 'Адреси головних відділень на карті:',
    ru: 'Адреса главных отделений на карте:',
    en: 'Addresses of main branches on the map:',
    de: 'Adressen der Hauptfilialen auf der Karte:',
    fr: 'Adresses des principales agences sur la carte :',
    es: 'Direcciones de las principales sucursales en el mapa:',
    cs: 'Adresy hlavních poboček na mapě:',
  },
  hotlineMeetInviteCardButton1: {
    ua: 'Помітити як «Відправлено»',
    ru: 'Отметить как «Отправлено»',
    en: 'Mark as "Sent"',
    de: 'Als "Gesendet" markieren',
    fr: 'Marquer comme « Envoyé »',
    es: 'Marcar como "Enviado"',
    cs: 'Označit jako "Odesláno"',
  },
  hotlineMeetInviteCardButton2: {
    ua: 'Немає Вайберу',
    ru: 'Нет Вайбера',
    en: 'No Viber',
    de: 'Kein Viber',
    fr: 'Pas de Viber',
    es: 'No hay Viber',
    cs: 'Žádný Viber',
  },
  // InvitedClients
  confirmed: {
    ua: 'підтверджено',
    ru: 'подтверждено',
    en: 'confirmed',
    de: 'bestätigt',
    fr: 'confirmé',
    es: 'confirmado',
    cs: 'potvrzeno',
  },
  notConfirmed: {
    ua: 'не підтверджено',
    ru: 'не подтверждено',
    en: 'not confirmed',
    de: 'nicht bestätigt',
    fr: 'non confirmé',
    es: 'no confirmado',
    cs: 'nepotvrzeno',
  },
  // Partner-InvitesList
  partnerInvitesListTitle: {
    ua: 'Список запрошених клієнтів',
    ru: 'Список приглашенных клиентов',
    en: 'List of Invited Clients',
    de: 'Liste der eingeladenen Kunden',
    fr: 'Liste des clients invités',
    es: 'Lista de clientes invitados',
    cs: 'Seznam pozvaných klientů',
  },
  // Partner-PartnerButtons
  partnerPartnerButtonsList: {
    ua: 'Список',
    ru: 'Список',
    en: 'List',
    de: 'Liste',
    fr: 'Liste',
    es: 'Lista',
    cs: 'Seznam',
  },
  partnerPartnerButtonsInvitations: {
    ua: 'запрошень',
    ru: 'приглашений',
    en: 'invitations',
    de: 'Einladungen',
    fr: 'invitations',
    es: 'invitaciones',
    cs: 'pozvánek',
  },
  partnerPartnerButtonsClient: {
    ua: 'клієнта',
    ru: 'клиента',
    en: 'client',
    de: 'Kunde',
    fr: 'client',
    es: 'cliente',
    cs: 'klienta',
  },
  partnerPartnerButtonsPayment: {
    ua: 'виплату',
    ru: 'выплату',
    en: 'payment',
    de: 'Zahlung',
    fr: 'paiement',
    es: 'pago',
    cs: 'platbu',
  },

  // Partner-RequestPayment
  partnerRequestPaymentWarn1_1: {
    ua: 'Не вистачає бонусів!',
    ru: 'Недостаточно бонусов!',
    en: 'Insufficient bonuses!',
    de: 'Nicht genügend Boni!',
    fr: 'Bonus insuffisant!',
    es: '¡Bonos insuficientes!',
    cs: 'Nedostatek bonusů!',
  },
  partnerRequestPaymentWarn1_2: {
    ua: 'Перевищено на',
    ru: 'Превышено на',
    en: 'Exceeded by',
    de: 'Überschritten um',
    fr: 'Dépassement de',
    es: 'Superado en',
    cs: 'Překročeno o',
  },
  partnerRequestPaymentWarn2_1: {
    ua: 'Введіть коректне значення!',
    ru: 'Введите корректное значение!',
    en: 'Enter a valid value!',
    de: 'Geben Sie einen gültigen Wert ein!',
    fr: 'Entrez une valeur valide!',
    es: '¡Ingrese un valor válido!',
    cs: 'Zadejte platnou hodnotu!',
  },
  partnerRequestPaymentWarn2_2: {
    ua: 'Сума не може бути від`ємною!',
    ru: 'Сумма не может быть отрицательной!',
    en: 'Amount cannot be negative!',
    de: 'Betrag kann nicht negativ sein!',
    fr: 'Le montant ne peut pas être négatif!',
    es: '¡La cantidad no puede ser negativa!',
    cs: 'Částka nemůže být záporná!',
  },
  partnerRequestPaymentInfo: {
    ua: 'Запит на виплату успішно додано!',
    ru: 'Запрос на выплату успешно добавлен!',
    en: 'Payment request successfully added!',
    de: 'Auszahlungsanfrage erfolgreich hinzugefügt!',
    fr: 'Demande de paiement ajoutée avec succès!',
    es: '¡Solicitud de pago añadida con éxito!',
    cs: 'Žádost o výplatu byla úspěšně přidána!',
  },
  partnerRequestPaymentWarn3: {
    ua: 'Помилка!',
    ru: 'Ошибка!',
    en: 'Error!',
    de: 'Fehler!',
    fr: 'Erreur!',
    es: '¡Error!',
    cs: 'Chyba!',
  },
  partnerRequestPaymentModalTitle: {
    ua: 'Запросити виплату',
    ru: 'Запросить выплату',
    en: 'Request Payment',
    de: 'Auszahlung anfordern',
    fr: 'Demander un paiement',
    es: 'Solicitar pago',
    cs: 'Žádat o výplatu',
  },
  partnerRequestPaymentModalText1: {
    ua: 'Карта для виплат:',
    ru: 'Карта для выплат:',
    en: 'Payment card:',
    de: 'Karte für Auszahlungen:',
    fr: 'Carte de paiement :',
    es: 'Tarjeta de pago:',
    cs: 'Platba karta:',
  },
  partnerRequestPaymentModalText2: {
    ua: 'Додайте карту для виплат в меню партнера!',
    ru: 'Добавьте карту для выплат в меню партнера!',
    en: 'Add a payment card in the partner menu!',
    de: 'Fügen Sie eine Auszahlungskarte im Partnermenü hinzu!',
    fr: 'Ajoutez une carte de paiement dans le menu partenaire!',
    es: '¡Agregue una tarjeta de pago en el menú del socio!',
    cs: 'Přidejte platební kartu v nabídce partnera!',
  },
  partnerRequestPaymentModalText3: {
    ua: 'Баланс:',
    ru: 'Баланс:',
    en: 'Balance:',
    de: 'Saldo:',
    fr: 'Solde :',
    es: 'Saldo:',
    cs: 'Zůstatek:',
  },
  partnerRequestPaymentModalMessage: {
    ua: 'Введіть значення в межах балансу',
    ru: 'Введите значение в пределах баланса',
    en: 'Enter a value within the balance',
    de: 'Geben Sie einen Wert innerhalb des Guthabens ein',
    fr: 'Entrez une valeur dans la limite du solde',
    es: 'Ingrese un valor dentro del saldo',
    cs: 'Zadejte hodnotu v rámci zůstatku',
  },
  // SelectFilWithRole
  selectFilWithRoleTitle: {
    ua: 'Оберіть філію',
    ru: 'Выберите филиал',
    en: 'Select Branch',
    de: 'Filiale auswählen',
    fr: 'Sélectionner la succursale',
    es: 'Seleccionar sucursal',
    cs: 'Vyberte pobočku',
  },
  allFiliaOptGroup: {
    ua: 'Всі філії',
    ru: 'Все филиалы',
    en: 'All Branches',
    de: 'Alle Filialen',
    fr: 'Toutes les succursales',
    es: 'Todas las sucursales',
    cs: 'Všechny pobočky',
  },

  // SwiperInsurance
  // InsuranceType1: {
  //   ua: 'При ДТП',
  //   en: 'In case of car accident',
  //   de: 'Im Falle eines Autounfalls',
  // },
  // InsuranceType2: {
  //   ua: 'Військового',
  //   en: 'Military',
  //   de: 'Militär',
  // },
  // InsuranceType3: {
  //   ua: 'Моряка',
  //   en: 'Seaman',
  //   de: 'Seemann',
  // },
  // InsuranceType4: {
  //   ua: 'Призовника',
  //   en: 'Conscript',
  //   de: 'Wehrpflichtiger',
  // },
  buyInsuranceBtn: {
    ua: 'Купити страховку',
    ru: 'Купить страховку',
    en: 'Buy insurance',
    de: 'Versicherung kaufen',
    fr: 'Acheter une assurance',
    es: 'Comprar seguro',
    cs: 'Koupit pojištění',
  },
  // TimeLine
  timeLineText: {
    ua: '(Авто-оновлення даних)',
    ru: '(Авто-обновление данных)',
    en: '(Auto-update data)',
    de: '(Datenautomatisierung)',
    fr: '(Mise à jour automatique des données)',
    es: '(Actualización automática de datos)',
    cs: '(Automatické aktualizace dat)',
  },

  //*!----------utils ---------
  // Loader
  loader: {
    ua: 'Зачекайте...',
    ru: 'Подождите...',
    en: 'Please wait...',
    de: 'Bitte warten Sie...',
    fr: 'Veuillez patienter...',
    es: 'Por favor espera...',
    cs: 'Prosím, čekejte...',
  },
  loader1: {
    ua: 'Завантаження...',
    ru: 'Загрузка...',
    en: 'Please wait...',
    de: 'Bitte warten Sie...',
    fr: 'Chargement...',
    es: 'Cargando...',
    cs: 'Načítání...',
  },
  saveChanges: {
    ua: 'Зберегти зміни',
    ru: 'Сохранить изменения',
    en: 'Save Changes',
    de: 'Änderungen speichern',
    fr: 'Enregistrer les modifications',
    es: 'Guardar cambios',
    cs: 'Uložit změny',
  },

  // EnterLink
  enterLink: {
    ua: 'Вхід',
    ru: 'Вход',
    en: 'Login',
    de: 'Einloggen',
    fr: 'Connexion',
    es: 'Iniciar sesión',
    cs: 'Přihlášení',
  },
  enter: {
    ua: 'Увійти',
    ru: 'Войти',
    en: 'Enter',
    de: 'Eingeben',
    fr: 'Entrer',
    es: 'Entrar',
    cs: 'Vstoupit',
  },

  // required field
  requiredField: {
    ua: "Обов'язкове поле",
    ru: 'Обязательное поле',
    en: 'Required field',
    de: 'Erforderliches Feld',
    fr: 'Champ requis',
    es: 'Campo requerido',
    cs: 'Povinné pole',
  },

  // currency
  currency: {
    ua: 'грн',
    ru: 'грн',
    en: 'USD',
    de: 'EUR',
    fr: 'EUR',
    es: 'USD',
    cs: 'CZK',
  },

  // club card
  clubCardWarn_1: {
    ua: 'Ви вже користуєтесь тарифом',
    ru: 'Вы уже пользуетесь тарифом',
    en: 'You are already using the tariff',
    de: 'Sie nutzen bereits den Tarif',
    fr: 'Vous utilisez déjà le tarif',
    es: 'Ya está utilizando la tarifa',
    cs: 'Již používáte tarif',
  },
  clubCardWarn_2: {
    ua: 'з аналогічним строком активності.',
    ru: 'с аналогичным сроком активности.',
    en: 'with a similar period of validity.',
    de: 'mit einer ähnlichen Gültigkeitsdauer.',
    fr: 'avec une période de validité similaire.',
    es: 'con un período de validez similar.',
    cs: 's podobnou platností.',
  },
  clubCardWarnText_1: {
    ua: 'Новий тариф почне діяти тільки після закінчення строку дії клубної карти по старому тарифу!',
    ru: 'Новый тариф начнет действовать только после окончания срока действия клубной карты по старому тарифу!',
    en: 'The new tariff will only take effect after the expiration of the current club card under the old tariff!',
    de: 'Der neue Tarif tritt erst nach Ablauf der aktuellen Clubkarte mit dem alten Tarif in Kraft!',
    fr: "Le nouveau tarif n'entrera en vigueur qu'après l'expiration de la carte de club actuelle selon l'ancien tarif!",
    es: '¡El nuevo tarifa solo entrará en vigor después de la expiración de la tarjeta de club actual bajo la antigua tarifa!',
    cs: 'Nový tarif začne platit až po uplynutí platnosti aktuální klubové karty podle starého tarifu!',
  },
  clubCardWarnText_2: {
    ua: 'А саме, з',
    ru: 'А именно, с',
    en: 'Namely, from',
    de: 'Nämlich, ab',
    fr: 'À savoir, à partir de',
    es: 'Es decir, desde',
    cs: 'A to, od',
  },
  clubCardWarnTextFreeCard: {
    ua: 'Безкоштовна карта',
    ru: 'Бесплатная карта',
    en: 'Free card',
    de: 'Kostenlose Karte',
    fr: 'Carte gratuite',
    es: 'Tarjeta gratuita',
    cs: 'Zdarma karta',
  },

  clubCardTitleFront: {
    ua: 'Адвокатське об`єднання "Захист"',
    ru: 'Адвокатское объединение "Защита"',
    en: 'Law Firm "Zahist"',
    de: 'Anwaltskanzlei "Zahist"',
    fr: 'Cabinet d\'avocats "Zahist"',
    es: 'Firma de abogados "Zahist"',
    cs: 'Advokátní kancelář "Zahist"',
  },

  clubCardTitleBack: {
    ua: 'Найбільша юридична компанія України',
    ru: 'Крупнейшая юридическая компания Украины',
    en: 'The largest law firm in Ukraine',
    de: 'Die größte Anwaltskanzlei der Ukraine',
    fr: "Le plus grand cabinet d'avocats en Ukraine",
    es: 'La mayor firma de abogados en Ucrania',
    cs: 'Největší právnická firma na Ukrajině',
  },
  raiseStatus: {
    ua: 'Підвищити статус',
    ru: 'Повысить статус',
    en: 'Raise status',
    de: 'Status erhöhen',
    fr: 'Élever le statut',
    es: 'Aumentar el estado',
    cs: 'Zvýšit status',
  },
  payByClubCardSuccess: {
    ua: 'Сплачено клубною картою',
    ru: 'Оплачено клубной картой',
    en: 'Paid by club card',
    de: 'Mit Clubkarte bezahlt',
    fr: 'Payé par carte de club',
    es: 'Pagado con tarjeta de club',
    cs: 'Zaplatil klubovou kartou',
  },
  clubCardAdvantages: {
    silver: {
      0: {
        ua: 'Стандартне обслуговування',
        ru: 'Стандартное обслуживание',
        en: 'Standard service',
        de: 'Standarddienst',
        fr: 'Service standard',
        es: 'Servicio estándar',
        cs: 'Standardní servis',
      },
      1: {
        ua: 'Доступ до штучного інтелекту єАдвокат',
        ru: 'Доступ к искусственному интеллекту єАдвокат',
        en: 'Access to AI єAdvocate',
        de: 'Zugang zu KI єAdvokat',
        fr: "Accès à l'IA єAvocat",
        es: 'Acceso a la IA єAbogado',
        cs: 'Přístup k AI єAdvokát',
      },
    },
    gold: {
      0: {
        ua: 'VIP обслуговування',
        ru: 'VIP обслуживание',
        en: 'VIP service',
        de: 'VIP-Dienst',
        fr: 'Service VIP',
        es: 'Servicio VIP',
        cs: 'VIP servis',
      },
      1: {
        ua: 'Доступ до штучного інтелекту єАдвокат',
        ru: 'Доступ к искусственному интеллекту єАдвокат',
        en: 'Access to AI єAdvocate',
        de: 'Zugang zu KI єAdvokat',
        fr: "Accès à l'IA єAvocat",
        es: 'Acceso a la IA єAbogado',
        cs: 'Přístup k AI єAdvokát',
      },
      2: {
        ua: 'Персональний адвокат 8 годин, 5 днів на тиждень',
        ru: 'Персональный адвокат 8 часов, 5 дней в неделю',
        en: 'Personal lawyer 8 hours, 5 days a week',
        de: 'Persönlicher Anwalt 8 Stunden, 5 Tage die Woche',
        fr: 'Avocat personnel 8 heures, 5 jours par semaine',
        es: 'Abogado personal 8 horas, 5 días a la semana',
        cs: 'Osobní advokát 8 hodin, 5 dní v týdnu',
      },
    },
    platinum: {
      0: {
        ua: 'Premium обслуговування',
        ru: 'Премиум обслуживание',
        en: 'Premium service',
        de: 'Premium-Dienst',
        fr: 'Service premium',
        es: 'Servicio premium',
        cs: 'Prémiová služba',
      },
      1: {
        ua: 'Доступ до штучного інтелекту єАдвокат',
        ru: 'Доступ к искусственному интеллекту єАдвокат',
        en: 'Access to AI єAdvocate',
        de: 'Zugang zu KI єAdvokat',
        fr: "Accès à l'IA єAvocat",
        es: 'Acceso a la IA єAbogado',
        cs: 'Přístup k AI єAdvokát',
      },
      2: {
        ua: 'Персональний адвокат 24 години, 7 днів на тиждень',
        ru: 'Персональный адвокат 24 часа, 7 дней в неделю',
        en: 'Personal lawyer 24 hours, 7 days a week',
        de: 'Persönlicher Anwalt 24 Stunden, 7 Tage die Woche',
        fr: 'Avocat personnel 24 heures, 7 jours sur 7',
        es: 'Abogado personal 24 horas, 7 días a la semana',
        cs: 'Osobní advokát 24 hodin, 7 dní v týdnu',
      },
      3: {
        ua: 'Терміновий виїзд адвоката на адресу по всій Україні',
        ru: 'Срочный выезд адвоката по адресу по всей Украине',
        en: 'Urgent lawyer visit at any address across Ukraine',
        de: 'Dringender Anwaltseinsatz an jeder Adresse in der Ukraine',
        fr: "Déplacement urgent de l'avocat à toute adresse en Ukraine",
        es: 'Visita urgente del abogado a cualquier dirección en Ucrania',
        cs: 'Naléhavá návštěva advokáta na jakoukoli adresu po celé Ukrajině',
      },
    },
  },

  clubCardType: {
    silver: {
      ua: 'Срібна',
      ru: 'Серебряная',
      en: 'Silver',
      de: 'Silber',
      fr: 'Argent',
      es: 'Plata',
      cs: 'Stříbrná',
    },
    gold: {
      ua: 'Золота',
      ru: 'Золотая',
      en: 'Gold',
      de: 'Gold',
      fr: 'Or',
      es: 'Oro',
      cs: 'Zlatá',
    },
    platinum: {
      ua: 'Платинова',
      ru: 'Платиновая',
      en: 'Platinum',
      de: 'Platin',
      fr: 'Platine',
      es: 'Platino',
      cs: 'Platinová',
    },
  },
  buyClubCard: {
    ua: 'Купити карту',
    ru: 'Купить карту',
    en: 'Buy a card',
    de: 'Karte kaufen',
    fr: 'Acheter une carte',
    es: 'Comprar una tarjeta',
    cs: 'Koupit kartu',
  },
  upgradeClubCard: {
    ua: 'Підвищити рівень',
    ru: 'Повысить уровень',
    en: 'Upgrade Level',
    de: 'Stufe Erhöhen',
    fr: 'Améliorer le niveau',
    es: 'Mejorar nivel',
    cs: 'Zvýšit úroveň',
  },
  servicesBalance: {
    ua: 'Баланс послуг',
    ru: 'Баланс услуг',
    en: 'Service balance',
    de: 'Leistungsbilanz',
    fr: 'Solde des services',
    es: 'Saldo de servicios',
    cs: 'Zůstatek služeb',
  },
  expiredAt: {
    ua: 'Дійсна до:',
    ru: 'Действительно до:',
    en: 'Valid until:',
    de: 'Gültig bis:',
    fr: "Valide jusqu'au:",
    es: 'Válido hasta:',
    cs: 'Platné do:',
  },
  cardTypeTitle: {
    ua: 'Тип вашої карти',
    ru: 'Тип вашей карты',
    en: 'Type of your card',
    de: 'Typ Ihrer Karte',
    fr: 'Type de votre carte',
    es: 'Tipo de su tarjeta',
    cs: 'Typ vaší karty',
  },
  currentPeriod: {
    ua: 'Ви обрали період',
    ru: 'Вы выбрали период',
    en: 'You have chosen the period',
    de: 'Sie haben den Zeitraum gewählt',
    fr: 'Vous avez choisi la période',
    es: 'Ha elegido el período',
    cs: 'Vybrali jste období',
  },
  choosePeriod: {
    ua: 'Обирай більший період та заощаджуй до',
    ru: 'Выберите более длительный период и сэкономьте до',
    en: 'Choose a longer period and save up to',
    de: 'Wählen Sie einen längeren Zeitraum und sparen Sie bis zu',
    fr: "Choisissez une période plus longue et économisez jusqu'à",
    es: 'Elija un período más largo y ahorre hasta',
    cs: 'Vyberte delší období a ušetřete až',
  },
  currencyUah: {
    ua: 'грн',
    ru: 'грн',
    en: 'UAH',
    de: 'UAH',
    fr: 'UAH',
    es: 'UAH',
    cs: 'UAH',
  },
  month: {
    ua: 'місяць',
    ru: 'месяц',
    en: 'month',
    de: 'Monat',
    fr: 'mois',
    es: 'mes',
    cs: 'měsíc',
  },

  savings: {
    ua: 'Економія:',
    ru: 'Экономия:',
    en: 'Savings:',
    de: 'Ersparnisse:',
    fr: 'Économies:',
    es: 'Ahorros:',
    cs: 'Úspory:',
  },
  clubCardPeriod: {
    0: {
      ua: '1 місяць',
      ru: '1 месяц',
      en: '1 month',
      de: '1 Monat',
      fr: '1 mois',
      es: '1 mes',
      cs: '1 měsíc',
    },
    1: {
      ua: '3 місяці',
      ru: '3 месяца',
      en: '3 months',
      de: '3 Monate',
      fr: '3 mois',
      es: '3 meses',
      cs: '3 měsíce',
    },
    2: {
      ua: '6 місяців',
      ru: '6 месяцев',
      en: '6 months',
      de: '6 Monate',
      fr: '6 mois',
      es: '6 meses',
      cs: '6 měsíců',
    },
    3: {
      ua: '1 рік',
      ru: '1 год',
      en: '1 year',
      de: '1 Jahr',
      fr: '1 an',
      es: '1 año',
      cs: '1 rok',
    },
    4: {
      ua: '3 роки',
      ru: '3 года',
      en: '3 years',
      de: '3 Jahre',
      fr: '3 ans',
      es: '3 años',
      cs: '3 roky',
    },
  },
  totalPrice: {
    ua: 'До оплати:',
    ru: 'К оплате:',
    en: 'Total to pay:',
    de: 'Gesamt zu zahlen:',
    fr: 'Total à payer:',
    es: 'Total a pagar:',
    cs: 'Celkem k úhradě:',
  },
  clubCardPurchased: {
    ua: 'Ви придбали клубну карту.',
    ru: 'Вы приобрели клубную карту.',
    en: 'You have purchased a club card.',
    de: 'Sie haben eine Clubkarte gekauft.',
    fr: 'Vous avez acheté une carte de club.',
    es: 'Has comprado una tarjeta de club.',
    cs: 'Zakoupili jste klubovou kartu.',
  },
  clubCardServicesStatic: {
    accessToApp: {
      ua: 'Доступ до базового функціоналу "єАдвокат"',
      ru: 'Доступ к базовому функционалу "еАдвокат"',
      en: 'Access to the basic functionality of "eAdvocate"',
      de: 'Zugang zur Grundfunktionalität von "eAdvocate"',
      fr: 'Accès à la fonctionnalité de base de "eAdvocate"',
      es: 'Acceso a la funcionalidad básica de "eAdvocate"',
      cs: 'Přístup k základní funkčnosti "eAdvocate"',
    },
    accessToRegisters: {
      ua: 'Доступ до реєстрів',
      ru: 'Доступ к реестрам',
      en: 'Access to registers',
      de: 'Zugang zu Registern',
      fr: 'Accès aux registres',
      es: 'Acceso a los registros',
      cs: 'Přístup k registrům',
    },
    yes: {
      ua: 'Так',
      ru: 'Да',
      en: 'Yes',
      de: 'Ja',
      fr: 'Oui',
      es: 'Sí',
      cs: 'Ano',
    },
    no: {
      ua: 'Ні',
      ru: 'Нет',
      en: 'No',
      de: 'Nein',
      fr: 'Non',
      es: 'No',
      cs: 'Ne',
    },
  },
  clubCardServices: {
    0: {
      ua: 'Швидке питання',
      ru: 'Быстрый вопрос',
      en: 'Quick question',
      de: 'Schnelle Frage',
      fr: 'Question rapide',
      es: 'Pregunta rápida',
      cs: 'Rychlá otázka',
    },
    1: {
      ua: 'Консультація',
      ru: 'Консультация',
      en: 'Consultation',
      de: 'Beratung',
      fr: 'Consultation',
      es: 'Consulta',
      cs: 'Konzultace',
    },
    2: {
      ua: 'Подарувати консультацію знайомому',
      ru: 'Подарить консультацию знакомому',
      en: 'Give a consultation to a friend',
      de: 'Verschenken Sie eine Beratung an einen Freund',
      fr: 'Offrir une consultation à un ami',
      es: 'Regala una consulta a un amigo',
      cs: 'Darujte konzultaci příteli',
    },
    3: {
      ua: 'Зустріч з адвокатом по справі',
      ru: 'Встреча с адвокатом по делу',
      en: 'Meeting with a lawyer on the case',
      de: 'Treffen mit einem Anwalt im Fall',
      fr: "Rencontre avec un avocat sur l'affaire",
      es: 'Reunión con un abogado sobre el caso',
      cs: 'Setkání s právníkem k případu',
    },
    4: {
      ua: 'Преміум консультація',
      ru: 'Премиум консультация',
      en: 'Premium consultation',
      de: 'Premium-Beratung',
      fr: 'Consultation premium',
      es: 'Consulta premium',
      cs: 'Prémiová konzultace',
    },
    5: {
      ua: 'Послуга "Тривожна кнопка"',
      ru: 'Услуга "Тревожная кнопка"',
      en: 'Service "Alarm button"',
      de: 'Service "AlarmKnopf"',
      fr: 'Service "Bouton d\'alarme"',
      es: 'Servicio "Botón de alarma"',
      cs: 'Služba "Alarmovací tlačítko"',
    },
    6: {
      ua: 'Участь адвоката у переговорах',
      ru: 'Участие адвоката в переговорах',
      en: 'Lawyer participation in negotiations',
      de: 'Teilnahme des Anwalts an Verhandlungen',
      fr: "Participation de l'avocat aux négociations",
      es: 'Participación del abogado en las negociaciones',
      cs: 'Účast advokáta na jednáních',
    },
    7: {
      ua: 'Претензійна робота',
      ru: 'Претензионная работа',
      en: 'Claim work',
      de: 'Anspruchsarbeit',
      fr: 'Travail de réclamation',
      es: 'Trabajo de reclamación',
      cs: 'Nároková práce',
    },
    8: {
      ua: 'Підготовка документу',
      ru: 'Подготовка документа',
      en: 'Document preparation',
      de: 'Dokumentenvorbereitung',
      fr: 'Préparation de document',
      es: 'Preparación de documentos',
      cs: 'Příprava dokumentu',
    },
    9: {
      ua: 'Письмова консультація',
      ru: 'Письменная консультация',
      en: 'Written consultation',
      de: 'Schriftliche Beratung',
      fr: 'Consultation écrite',
      es: 'Consulta escrita',
      cs: 'Písemná konzultace',
    },
    10: {
      ua: 'Вихід адвоката в суд',
      ru: 'Выход адвоката в суд',
      en: 'Lawyer`s appearance in court',
      de: 'Anwesenheit des Anwalts vor Gericht',
      fr: "Apparition de l'avocat au tribunal",
      es: 'Aparición del abogado en el tribunal',
      cs: 'Přítomnost advokáta u soudu',
    },
    11: {
      ua: 'АІ асистент',
      ru: 'AI ассистент',
      en: 'AI assistant',
      de: 'AI-Assistent',
      fr: 'Assistant AI',
      es: 'Asistente AI',
      cs: 'AI asistent',
    },
    12: {
      ua: 'Особистий консультант 8 годин',
      ru: 'Личный консультант 8 часов',
      en: 'Personal assistant 8 hours',
      de: 'Persoenlicher Assistent 8 Stunden',
      fr: 'Assistant personnel 8 heures',
      es: 'Asistente personal 8 horas',
      cs: 'Osobní asistent 8 hodin',
    },
    13: {
      ua: 'Особистий консультант 24 години',
      ru: 'Личный консультант 24 часа',
      en: 'Personal assistant 24 hours',
      de: 'Persoenlicher Assistent 24 Stunden',
      fr: 'Assistant personnel 24 heures',
      es: 'Asistente personal 24 horas',
      cs: 'Osobní asistent 24 hodin',
    },
  },
  aiAssistantTitle: {
    ua: 'АІ асистент',
    ru: 'AI ассистент',
    en: 'AI assistant',
    de: 'AI-Assistent',
    fr: 'Assistant AI',
    es: 'Asistente AI',
    cs: 'AI asistent',
  },

  serviceDescription: {
    0: {
      ua: 'Ви отримаєте відповідь на своє питання 24/7, забезпечуючи постійну підтримку та допомогу.',
      ru: 'Вы получите ответ на ваш вопрос 24/7, обеспечивая постоянную поддержку и помощь.',
      en: 'You will receive an answer to your question 24/7, ensuring constant support and assistance.',
      de: 'Sie erhalten rund um die Uhr eine Antwort auf Ihre Frage, was ständige Unterstützung und Hilfe gewährleistet.',
      fr: 'Vous recevrez une réponse à votre question 24h/24 et 7j/7, garantissant un soutien et une assistance constants.',
      es: 'Recibirás respuesta a tu pregunta 24/7, asegurando apoyo y asistencia constantes.',
      cs: 'Obdržíte odpověď na svou otázku 24/7, což zajišťuje neustálou podporu a pomoc.',
    },
    1: {
      ua: 'Отримайте професійну консультацію з адвокатом безпосередньо у відділенні.',
      ru: 'Получите профессиональную консультацию с адвокатом непосредственно в отделении.',
      en: 'Get professional consultation with a lawyer directly at the branch.',
      de: 'Erhalten Sie eine professionelle Beratung mit einem Anwalt direkt in der Filiale.',
      fr: 'Obtenez une consultation professionnelle avec un avocat directement à l’agence.',
      es: 'Obtén una consulta profesional con un abogado directamente en la sucursal.',
      cs: 'Získejte profesionální konzultaci s právníkem přímo na pobočce.',
    },
    2: {
      ua: 'Подаруйте консультацію з адвокатом своєму знайомому, щоб він отримав необхідну юридичну підтримку.',
      ru: 'Подарите консультацию с адвокатом своему знакомому, чтобы он получил необходимую юридическую поддержку.',
      en: 'Give a lawyer consultation to a friend so they can receive the necessary legal support.',
      de: 'Schenken Sie einem Freund eine Anwaltsberatung, damit er die notwendige rechtliche Unterstützung erhält.',
      fr: 'Offrez une consultation juridique à un ami pour qu’il puisse recevoir le soutien juridique nécessaire.',
      es: 'Regala una consulta de abogado a un amigo para que pueda recibir el apoyo legal necesario.',
      cs: 'Darujte konzultaci s právníkem svému příteli, aby mohl získat potřebnou právní podporu.',
    },
    3: {
      ua: 'Проведіть зустріч з адвокатом для обговорення вашої справи у відділенні.',
      ru: 'Проведите встречу с адвокатом для обсуждения вашего дела в отделении.',
      en: 'Have a meeting with a lawyer to discuss your case at the branch.',
      de: 'Führen Sie ein Treffen mit einem Anwalt, um Ihren Fall in der Filiale zu besprechen.',
      fr: 'Rencontrez un avocat pour discuter de votre affaire à l’agence.',
      es: 'Mantén una reunión con un abogado para discutir tu caso en la sucursal.',
      cs: 'Uspořádejte schůzku s právníkem, abyste prodiskutovali svůj případ na pobočce.',
    },
    4: {
      ua: 'Отримайте преміум консультацію з досвідченим адвокатом у нашому відділенні.',
      ru: 'Получите премиум консультацию с опытным адвокатом в нашем отделении.',
      en: 'Receive premium consultation with an experienced lawyer at our branch.',
      de: 'Erhalten Sie eine Premium-Beratung mit einem erfahrenen Anwalt in unserer Filiale.',
      fr: 'Recevez une consultation premium avec un avocat expérimenté dans notre agence.',
      es: 'Recibe una consulta premium con un abogado experimentado en nuestra sucursal.',
      cs: 'Získejte prémiovou konzultaci s zkušeným právníkem na naší pobočce.',
    },
    5: {
      ua: 'Миттєва юридична допомога у разі порушення ваших прав співробітниками різних органів. Один клік - і ви під захистом.',
      ru: 'Мгновенная юридическая помощь в случае нарушения ваших прав сотрудниками различных органов. Один клик - и вы под защитой.',
      en: 'Instant legal assistance in case of violation of your rights by employees of various authorities. One click and you are under protection.',
      de: 'Sofortige rechtliche Unterstützung bei Verletzung Ihrer Rechte durch Mitarbeiter verschiedener Behörden. Ein Klick und Sie sind geschützt.',
      fr: 'Assistance juridique instantanée en cas de violation de vos droits par des employés de diverses autorités. Un clic et vous êtes protégé.',
      es: 'Asistencia legal instantánea en caso de violación de sus derechos por parte de empleados de diversas autoridades. Un clic y está protegido.',
      cs: 'Okamžitá právní pomoc v případě porušení vašich práv zaměstnanci různých orgánů. Jeden klik a jste chráněni.',
    },
    6: {
      ua: 'Адвокат бере участь у переговорах, забезпечуючи юридичну підтримку та представництво.',
      ru: 'Адвокат участвует в переговорах, обеспечивая юридическую поддержку и представительство.',
      en: 'A lawyer participates in negotiations, providing legal support and representation.',
      de: 'Ein Anwalt nimmt an den Verhandlungen teil und bietet rechtliche Unterstützung und Vertretung.',
      fr: 'Un avocat participe aux négociations, fournissant un soutien juridique et une représentation.',
      es: 'Un abogado participa en negociaciones, brindando apoyo legal y representación.',
      cs: 'Právník se účastní jednání, poskytuje právní podporu a zastupování.',
    },
    7: {
      ua: 'Повний супровід у претензійній роботі для захисту ваших прав та інтересів.',
      ru: 'Полное сопровождение в претензионной работе для защиты ваших прав и интересов.',
      en: 'Full support in claim work to protect your rights and interests.',
      de: 'Umfassende Unterstützung bei der Anspruchsarbeit zum Schutz Ihrer Rechte und Interessen.',
      fr: 'Soutien complet dans le travail de réclamation pour protéger vos droits et intérêts.',
      es: 'Apoyo completo en el trabajo de reclamación para proteger sus derechos e intereses.',
      cs: 'Kompletní podpora v oblasti nároků na ochranu vašich práv a zájmů.',
    },
    8: {
      ua: 'Підготовка юридичних документів з урахуванням всіх необхідних вимог та стандартів.',
      ru: 'Подготовка юридических документов с учетом всех необходимых требований и стандартов.',
      en: 'Preparation of legal documents considering all necessary requirements and standards.',
      de: 'Vorbereitung von Rechtsdokumenten unter Berücksichtigung aller notwendigen Anforderungen und Standards.',
      fr: 'Préparation de documents juridiques en tenant compte de toutes les exigences et normes nécessaires.',
      es: 'Preparación de documentos legales considerando todos los requisitos y estándares necesarios.',
      cs: 'Příprava právních dokumentů s ohledem na všechny potřebné požadavky a standardy.',
    },
    9: {
      ua: 'Отримайте письмову консультацію з детальними рекомендаціями від адвоката.',
      ru: 'Получите письменную консультацию с подробными рекомендациями от адвоката.',
      en: 'Receive a written consultation with detailed recommendations from a lawyer.',
      de: 'Erhalten Sie eine schriftliche Beratung mit detaillierten Empfehlungen eines Anwalts.',
      fr: 'Recevez une consultation écrite avec des recommandations détaillées d’un avocat.',
      es: 'Recibe una consulta escrita con recomendaciones detalladas de un abogado.',
      cs: 'Obdržíte písemnou konzultaci s podrobnými doporučeními od právníka.',
    },
    10: {
      ua: 'Адвокат представлятиме ваші інтереси у суді, забезпечуючи професійний захист.',
      ru: 'Адвокат будет представлять ваши интересы в суде, обеспечивая профессиональную защиту.',
      en: 'A lawyer will represent your interests in court, providing professional defense.',
      de: 'Ein Anwalt wird Ihre Interessen vor Gericht vertreten und professionellen Schutz bieten.',
      fr: 'Un avocat représentera vos intérêts au tribunal, assurant une défense professionnelle.',
      es: 'Un abogado representará sus intereses en la corte, brindando una defensa profesional.',
      cs: 'Právník bude zastupovat vaše zájmy u soudu a poskytovat profesionální ochranu.',
    },
  },

  emailVerification: {
    ua: 'На жаль, ви не зможете бачити свій статус у реєстрах розшуку, поки не підтвердите свою електронну пошту.',
    ru: 'К сожалению, вы не сможете видеть свой статус в реестрах поиска, пока не подтвердите свою электронную почту.',
    de: 'Leider können Sie Ihren Status in den Suchregistern nicht einsehen, bis Sie Ihre E-Mail verifiziert haben.',
    en: 'Unfortunately, you will not be able to see your status in the search registers until you verify your email.',
    fr: 'Malheureusement, vous ne pourrez pas voir votre statut dans les registres de recherche tant que vous n’aurez pas vérifié votre e-mail.',
    es: 'Desafortunadamente, no podrá ver su estado en los registros de búsqueda hasta que verifique su correo electrónico.',
    cs: 'Bohužel nebudete moci vidět svůj stav v registračních registrech, dokud neověříte svůj e-mail.',
  },
  bonusRetrieval: {
    ua: 'На жаль, ви не зможете забрати свій бонус, поки не підтвердите свою електронну пошту.',
    ru: 'К сожалению, вы не сможете получить свой бонус, пока не подтвердите свою электронную почту.',
    de: 'Leider können Sie Ihren Bonus nicht abholen, bis Sie Ihre E-Mail verifiziert haben.',
    en: 'Unfortunately, you will not be able to claim your bonus until you verify your email.',
    fr: 'Malheureusement, vous ne pourrez pas réclamer votre bonus tant que vous n’aurez pas vérifié votre e-mail.',
    es: 'Desafortunadamente, no podrá reclamar su bono hasta que verifique su correo electrónico.',
    cs: 'Bohužel nebudete moci vyzvednout svůj bonus, dokud neověříte svůj e-mail.',
  },
  chatAccess: {
    ua: 'На жаль, ви не зможете бачити свої чати та вести переписку з юристами, поки не підтвердите свою електронну пошту.',
    ru: 'К сожалению, вы не сможете видеть свои чаты и общаться с юристами, пока не подтвердите свою электронную почту.',
    de: 'Leider können Sie Ihre Chats nicht einsehen und nicht mit Anwälten kommunizieren, bis Sie Ihre E-Mail verifiziert haben.',
    en: 'Unfortunately, you will not be able to see your chats and communicate with lawyers until you verify your email.',
    fr: 'Malheureusement, vous ne pourrez pas voir vos discussions et communiquer avec les avocats tant que vous n’aurez pas vérifié votre e-mail.',
    es: 'Desafortunadamente, no podrá ver sus chats ni comunicarse con abogados hasta que verifique su correo electrónico.',
    cs: 'Bohužel nebudete moci vidět své chaty a komunikovat s právníky, dokud neověříte svůj e-mail.',
  },
  emailNotVerifiedTitle: {
    ua: 'Ви не підтвердили пошту!',
    ru: 'Вы не подтвердили почту!',
    de: 'Sie haben Ihre E-Mail nicht verifiziert!',
    en: 'You have not verified your email!',
    fr: 'Vous n’avez pas vérifié votre e-mail !',
    es: '¡No has verificado tu correo electrónico!',
    cs: 'Neověřili jste svůj e-mail!',
  },
  verifyEmailButton: {
    ua: 'Підтвердити пошту',
    ru: 'Подтвердить почту',
    de: 'E-Mail verifizieren',
    en: 'Verify email',
    fr: 'Vérifier l’e-mail',
    es: 'Verificar correo electrónico',
    cs: 'Ověřit e-mail',
  },
  emailAuthenticationTitle: {
    ua: 'Аутентифікація пошти',
    ru: 'Аутентификация почты',
    de: 'E-Mail-Authentifizierung',
    en: 'Email authentication',
    fr: 'Authentification par e-mail',
    es: 'Autenticación por correo electrónico',
    cs: 'Ověření e-mailu',
  },

  // ticker
  tickerPromoDay: {
    ua: 'Акції дня',
    ru: 'Акции дня',
    de: 'Angebote des Tages',
    en: 'Deals of the day',
    fr: 'Offres du jour',
    es: 'Ofertas del día',
    cs: 'Akce dne',
  },
  tickerCharity: {
    ua: 'Частина витрачених грошей йде на допомогу ЗСУ',
    ru: 'Часть потраченных денег идет на помощь ВСУ',
    de: 'Ein Teil des ausgegebenen Geldes kommt den Streitkräften der Ukraine zugute',
    en: 'Part of the money spent goes to help the Armed Forces of Ukraine',
    fr: 'Une partie de l’argent dépensé est destinée à aider les Forces armées ukrainiennes',
    es: 'Parte del dinero gastado va a ayudar a las Fuerzas Armadas de Ucrania',
    cs: 'Část utracených peněz jde na pomoc ozbrojeným silám Ukrajiny',
  },
  installment: {
    ua: 'Розстрочка',
    ru: 'Рассрочка',
    de: 'Zahlungsbetrag',
    en: 'Installment',
    fr: 'Paiement échelonné',
    es: 'Cuota',
    cs: 'Splátka',
  },
  assignment: {
    ua: 'Виходи',
    ru: 'Задания',
    de: 'Zuweisungen',
    en: 'Assignments',
    fr: 'Attributions',
    es: 'Asignaciones',
    cs: 'Úkoly',
  },
  monthlyPayments: {
    ua: 'Щомісячні платежі',
    ru: 'Ежемесячные платежи',
    de: 'Monatliche Zahlungen',
    en: 'Monthly payments',
    fr: 'Paiements mensuels',
    es: 'Pagos mensuales',
    cs: 'Měsíční platby',
  },
  firstPayment: {
    ua: 'Перший платіж',
    ru: 'Первый платеж',
    de: 'Erste Zahlung',
    en: 'First payment',
    fr: 'Premier paiement',
    es: 'Primer pago',
    cs: 'První platba',
  },
  return30Percents: {
    ua: 'повернути 30%',
    ru: 'вернуть 30%',
    de: 'rendite 30 %',
    en: 'return 30%',
    fr: 'retourner 30%',
    es: 'devolver 30%',
    cs: 'vrátit 30%',
  },

  // ClientLogin Page
  // clientLoginError: {
  //   ua: 'Помилка входу',
  //   en: 'Login Error',
  //   de: 'Anmeldefehler',
  // },
  // clientLoginPhoneMes: {
  //   ua: 'Введіть ваш номер телефону',
  //   en: 'Enter your phone number',
  //   de: 'Geben Sie Ihre Telefonnummer ein',
  // },

  // clientLoginRule: {
  //   ua: 'Правила використання',
  //   en: 'Terms of Use',
  //   de: 'Nutzungsbedingungen',
  // },

  clientDriverInsuranceTitle: {
    ua: 'Юридична страховка водіїв транспортних засобів',
    ru: 'Юридическая страховка водителей транспортных средств',
    de: 'Rechtsschutz für Fahrer von Fahrzeugen',
    en: 'Legal insurance for drivers of vehicles',
    fr: 'Assurance juridique pour les conducteurs de véhicules',
    es: 'Seguro legal para conductores de vehículos',
    cs: 'Právní pojištění pro řidiče vozidel',
  },
  clientProfileName: {
    ua: "Ім'я",
    ru: 'Имя',
    de: 'Name',
    en: 'Name',
    fr: 'Nom',
    es: 'Nombre',
    cs: 'Jméno',
  },
  clientProfileEmail: {
    ua: 'Електронна пошта',
    ru: 'Электронная почта',
    de: 'E-Mail',
    en: 'Email',
    fr: 'E-mail',
    es: 'Correo electrónico',
    cs: 'E-mail',
  },
  clientProfilePhone: {
    ua: 'Телефон',
    ru: 'Телефон',
    de: 'Telefon',
    en: 'Phone',
    fr: 'Téléphone',
    es: 'Teléfono',
    cs: 'Telefon',
  },
  clientProfileEnableBiometric: {
    ua: 'Біометрична автентифікація',
    ru: 'Биометрическая аутентификация',
    de: 'Biometrische Authentifizierung',
    en: 'Biometric authentication',
    fr: 'Authentification biométrique',
    es: 'Autenticación biométrica',
    cs: 'Biometrické ověření',
  },
  clientProfileUpdateButton: {
    ua: 'Оновити профіль',
    ru: 'Обновить профиль',
    de: 'Profil aktualisieren',
    en: 'Update Profile',
    fr: 'Mettre à jour le profil',
    es: 'Actualizar perfil',
    cs: 'Aktualizovat profil',
  },
  clientProfileCancelButton: {
    ua: 'Скасувати',
    ru: 'Отменить',
    de: 'Abbrechen',
    en: 'Cancel',
    fr: 'Annuler',
    es: 'Cancelar',
    cs: 'Zrušit',
  },

  // Hotline Meet Invite EAdvokat block
  eAdvokatImageDescription: {
    ua: 'Зображення клієнтського мобільного додатку Адвокатське об’єднання «Захист», котрий надає багатий та гнучкий функціонал, що дозволяє клієнту працювати над своєю справою у реальному часі.',
    ru: 'Изображение клиентского мобильного приложения Адвокатского объединения «Защита», которое предоставляет богатый и гибкий функционал, позволяющий клиенту работать над своим делом в реальном времени.',
    de: 'Die Darstellung der Kunden-Mobilanwendung des Anwaltsverbandes «Zahist», die eine umfangreiche und flexible Funktionalität bietet und es dem Kunden ermöglicht, in Echtzeit an seinem Fall zu arbeiten.',
    en: 'The depiction of the client mobile application of the Advocacy Association «Zahist», which provides a rich and flexible functionality, allowing the client to work on their case in real-time.',
    fr: 'L’image de l’application mobile client de l’Association d’Avocats «Zahist», qui offre une fonctionnalité riche et flexible, permettant au client de travailler sur son affaire en temps réel.',
    es: 'La representación de la aplicación móvil del cliente de la Asociación de Abogados «Zahist», que proporciona una funcionalidad rica y flexible, permitiendo al cliente trabajar en su caso en tiempo real.',
    cs: 'Zobrazení mobilní aplikace pro klienty sdružení advokátů «Zahist», která poskytuje bohatou a flexibilní funkčnost, umožňující klientovi pracovat na svém případu v reálném čase.',
  },
  yourCabinet: {
    ua: 'Ваш кабінет:',
    ru: 'Ваш кабинет:',
    de: 'Ihr Kabinett:',
    en: 'Your cabinet:',
    fr: 'Votre cabinet :',
    es: 'Su gabinete:',
    cs: 'Vaše kabinet:',
  },
  appForSmartphones: {
    ua: 'Додаток для смартфонів:',
    ru: 'Приложение для смартфонов:',
    de: 'Smartphone-Anwendung:',
    en: 'Smartphone application:',
    fr: 'Application pour smartphones :',
    es: 'Aplicación para smartphones:',
    cs: 'Aplikace pro smartphony:',
  },

  // Webinars
  webinar: {
    ua: 'Вебінар: ',
    ru: 'Вебинар: ',
    de: 'Webinar: ',
    en: 'Webinar: ',
    fr: 'Webinaire : ',
    es: 'Webinario: ',
    cs: 'Webinář: ',
  },
  buyWebinar: {
    ua: 'Придбати',
    ru: 'Купить',
    de: 'Kaufen',
    en: 'Buy',
    fr: 'Acheter',
    es: 'Comprar',
    cs: 'Koupit',
  },
  webinarPurchased: {
    ua: 'Ви придбали вебінар.',
    ru: 'Вы купили вебинар.',
    de: 'Sie haben ein Webinar gekauft.',
    en: 'You have purchased a webinar.',
    fr: 'Vous avez acheté un webinaire.',
    es: 'Ha comprado un seminario web.',
    cs: 'Zakoupili jste webinář.',
  },
  webinarStatuses: [
    {
      ua: 'Заплановано',
      ru: 'Запланировано',
      de: 'Geplant',
      en: 'Scheduled',
      fr: 'Prévu',
      es: 'Programado',
      cs: 'Naplánováno',
    },
    {
      ua: 'Розпочався',
      ru: 'Начался',
      de: 'Gestartet',
      en: 'Started',
      fr: 'Commencé',
      es: 'Comenzado',
      cs: 'Začalo',
    },
    {
      ua: 'Закінчився',
      ru: 'Завершился',
      de: 'Beendet',
      en: 'Ended',
      fr: 'Terminé',
      es: 'Terminado',
      cs: 'Skončilo',
    },
  ],
  webinarPrice: {
    ua: 'Ціна:',
    ru: 'Цена:',
    de: 'Preis:',
    en: 'Price:',
    fr: 'Prix :',
    es: 'Precio:',
    cs: 'Cena:',
  },
  webinarDate: {
    ua: 'Дата:',
    ru: 'Дата:',
    de: 'Datum:',
    en: 'Date:',
    fr: 'Date :',
    es: 'Fecha:',
    cs: 'Datum:',
  },
  webinarTeaser: {
    ua: 'Тизер',
    ru: 'Тизер',
    de: 'Teaser',
    en: 'Teaser',
    fr: 'Teaser',
    es: 'Teaser',
    cs: 'Teaser',
  },
  openStream: {
    ua: 'Перейти',
    ru: 'Перейти',
    de: 'Öffnen',
    en: 'Open',
    fr: 'Ouvrir',
    es: 'Abrir',
    cs: 'Otevřít',
  },
  // == Register pages == //

  successMessage: {
    base: {
      ua: 'Результати дивіться нижче.',
      ru: 'Результаты смотрите ниже.',
      de: 'Ergebnisse siehe unten.',
      en: 'See the results below.',
      fr: 'Voir les résultats ci-dessous.',
      es: 'Vea los resultados a continuación.',
      cs: 'Výsledky najdete níže.',
    },
    alimony: {
      ua: 'Успіх! Боржники з аліментів.',
      ru: 'Успех! Должники по алиментам.',
      de: 'Erfolg! Unterhaltsschuldner.',
      en: 'Successful search in the alimony debtors registry!',
      fr: 'Succès ! Recherche réussie dans le registre des débiteurs alimentaires !',
      es: '¡Éxito! Búsqueda exitosa en el registro de deudores de pensiones alimenticias.',
      cs: 'Úspěch! Úspěšné vyhledávání v registru dlužníků na výživné!',
    },
    corruptioner: {
      ua: 'Успіх! Реєстр корупціонерів.',
      ru: 'Успех! Реестр коррупционеров.',
      de: 'Erfolg! Register der Korrupten.',
      en: 'Successful search in the corruption registries!',
      fr: 'Succès ! Recherche réussie dans les registres de corruption !',
      es: '¡Éxito! Búsqueda exitosa en los registros de corrupción.',
      cs: 'Úspěch! Úspěšné vyhledávání v registrech korupce!',
    },
    wanted: {
      ua: 'Успіх! Особи в розшуку.',
      ru: 'Успех! Ищемые лица.',
      de: 'Erfolg! Gesuchte Personen.',
      en: 'Successful search in the wanted persons registry!',
      fr: 'Succès ! Recherche réussie dans le registre des personnes recherchées !',
      es: '¡Éxito! Búsqueda exitosa en el registro de personas buscadas.',
      cs: 'Úspěch! Úspěšné vyhledávání v registru hledaných osob!',
    },
  },

  registerOpts: {
    alimony: {
      debtorName: {
        ua: "Ім'я боржника:",
        ru: 'Имя должника:',
        de: 'Name des Schuldners:',
        en: "Debtor's Name:",
        fr: 'Nom du débiteur :',
        es: 'Nombre del deudor:',
        cs: 'Jméno dlužníka:',
      },
      debtorBirthDate: {
        ua: 'Дата народження боржника:',
        ru: 'Дата рождения должника:',
        de: 'Geburtsdatum des Schuldners:',
        en: "Debtor's Birth Date:",
        fr: 'Date de naissance du débiteur :',
        es: 'Fecha de nacimiento del deudor:',
        cs: 'Datum narození dlužníka:',
      },
      bankAccount: {
        ua: 'Рахунок банку:',
        ru: 'Банковский счет:',
        de: 'Bankkonto:',
        en: 'Bank Account:',
        fr: 'Compte bancaire :',
        es: 'Cuenta bancaria:',
        cs: 'Bankovní účet:',
      },
      creditorName: {
        ua: "Ім'я кредитора:",
        ru: 'Имя кредитора:',
        de: 'Name des Gläubigers:',
        en: "Creditor's Name:",
        fr: 'Nom du créancier :',
        es: 'Nombre del acreedor:',
        cs: 'Jméno věřitele:',
      },
      dvsCode: {
        ua: 'Код ДВС:',
        ru: 'Код ДВС:',
        de: 'DVS-Code:',
        en: 'DVS Code:',
        fr: 'Code DVS :',
        es: 'Código DVS:',
        cs: 'DVS kód:',
      },
      emailAddr: {
        ua: 'Електронна адреса:',
        ru: 'Электронный адрес:',
        de: 'E-Mail-Adresse:',
        en: 'Email Address:',
        fr: 'Adresse e-mail :',
        es: 'Dirección de correo electrónico:',
        cs: 'E-mailová adresa:',
      },
      erbDebtorBirthDate: {
        ua: 'Дата народження боржника (ЕРБ):',
        ru: 'Дата рождения должника (ЕРБ):',
        de: 'Geburtsdatum des Schuldners (ERB):',
        en: "Debtor's Birth Date (ERB):",
        fr: 'Date de naissance du débiteur (ERB) :',
        es: 'Fecha de nacimiento del deudor (ERB):',
        cs: 'Datum narození dlužníka (ERB):',
      },
      erbDebtorName: {
        ua: "Ім'я боржника (ЕРБ):",
        ru: 'Имя должника (ЕРБ):',
        de: 'Name des Schuldners (ERB):',
        en: "Debtor's Name (ERB):",
        fr: 'Nom du débiteur (ERB) :',
        es: 'Nombre del deudor (ERB):',
        cs: 'Jméno dlužníka (ERB):',
      },
      erbVpOrdernum: {
        ua: 'Номер замовлення (ЕРБ)',
        ru: 'Номер заказа (ЕРБ)',
        de: 'Bestellnummer (ERB):',
        en: 'Order Number (ERB):',
        fr: 'Numéro de commande (ERB) :',
        es: 'Número de pedido (ERB):',
        cs: 'Číslo objednávky (ERB):',
      },
      orgName: {
        ua: 'Назва організації:',
        ru: 'Название организации:',
        de: 'Name der Organisation:',
        en: 'Organization Name:',
        fr: "Nom de l'organisation :",
        es: 'Nombre de la organización:',
        cs: 'Název organizace:',
      },
      phoneNum: {
        ua: 'Телефонний номер:',
        ru: 'Телефонный номер:',
        de: 'Telefonnummer:',
        en: 'Phone Number:',
        fr: 'Numéro de téléphone :',
        es: 'Número de teléfono:',
        cs: 'Telefonní číslo:',
      },
      vpBeginDate: {
        ua: 'Дата початку виконавчого провадження:',
        ru: 'Дата начала исполнительного производства:',
        de: 'Beginn der Vollstreckungsverfahren:',
        en: 'Start Date of Enforcement Proceedings:',
        fr: "Date de début des procédures d'exécution :",
        es: 'Fecha de inicio de los procedimientos de ejecución:',
        cs: 'Datum zahájení výkonu rozhodnutí:',
      },
      vpOrdernum: {
        ua: 'Номер виконавчого провадження:',
        ru: 'Номер исполнительного производства:',
        de: 'Vollstreckungsverfahren-Nummer:',
        en: 'Enforcement Proceedings Number:',
        fr: "Numéro de la procédure d'exécution :",
        es: 'Número de procedimientos de ejecución:',
        cs: 'Číslo výkonu rozhodnutí:',
      },
      vpState: {
        ua: 'Стан виконавчого провадження:',
        ru: 'Состояние исполнительного производства:',
        de: 'Status des Vollstreckungsverfahrens:',
        en: 'State of Enforcement Proceedings:',
        fr: "État des procédures d'exécution :",
        es: 'Estado de los procedimientos de ejecución:',
        cs: 'Stav výkonu rozhodnutí:',
      },
    },
    corruptioner: {
      fullName: {
        ua: "Повне ім'я:",
        ru: 'Полное имя:',
        de: 'Vollständiger Name:',
        en: 'Full Name:',
        fr: 'Nom complet :',
        es: 'Nombre completo:',
        cs: 'Celé jméno:',
      },
      courtCaseNumber: {
        ua: 'Номер справи:',
        ru: 'Номер дела:',
        de: 'Aktenzeichen:',
        en: 'Case Number:',
        fr: "Numéro de l'affaire :",
        es: 'Número de caso:',
        cs: 'Číslo případu:',
      },
      offenseName: {
        ua: 'Назва правопорушення:',
        ru: 'Название правонарушения:',
        de: 'Bezeichnung der Straftat:',
        en: 'Name of Offense:',
        fr: "Nom de l'infraction :",
        es: 'Nombre de la infracción:',
        cs: 'Název přestupku:',
      },
      punishment: {
        ua: 'Покарання:',
        ru: 'Наказание:',
        de: 'Strafe:',
        en: 'Punishment:',
        fr: 'Punition :',
        es: 'Castigo:',
        cs: 'Trest:',
      },
      sentenceDate: {
        ua: 'Дата вироку:',
        ru: 'Дата приговора:',
        de: 'Urteilsdatum:',
        en: 'Sentence Date:',
        fr: 'Date de la sentence :',
        es: 'Fecha de la sentencia:',
        cs: 'Datum rozsudku:',
      },
      sentenceNumber: {
        ua: 'Номер вироку:',
        ru: 'Номер приговора:',
        de: 'Urteilsnummer:',
        en: 'Sentence Number:',
        fr: 'Numéro de la sentence :',
        es: 'Número de la sentencia:',
        cs: 'Číslo rozsudku:',
      },
      punishmentStartDate: {
        ua: 'Дата початку покарання:',
        ru: 'Дата начала наказания:',
        de: 'Beginn des Strafvollzugs:',
        en: 'Punishment Start Date:',
        fr: 'Date de début de la punition :',
        es: 'Fecha de inicio del castigo:',
        cs: 'Datum zahájení trestu:',
      },
      courtName: {
        ua: 'Суд:',
        ru: 'Суд:',
        de: 'Gericht:',
        en: 'Court:',
        fr: 'Tribunal :',
        es: 'Corte:',
        cs: 'Soud:',
      },
      codexArticleName: {
        ua: 'Стаття кодексу:',
        ru: 'Статья кодекса:',
        de: 'Kodexartikel:',
        en: 'Code Article:',
        fr: 'Article du code :',
        es: 'Artículo del código:',
        cs: 'Článek kodexu:',
      },
    },
    wanted: {
      full_name: {
        ua: "Повне ім'я:",
        ru: 'Полное имя:',
        de: 'Vollständiger Name:',
        en: 'Full Name:',
        fr: 'Nom complet :',
        es: 'Nombre completo:',
        cs: 'Celé jméno:',
      },
      birth_date: {
        ua: 'Дата народження:',
        ru: 'Дата рождения:',
        de: 'Geburtsdatum:',
        en: 'Date of Birth:',
        fr: 'Date de naissance :',
        es: 'Fecha de nacimiento:',
        cs: 'Datum narození:',
      },
      lost_date: {
        ua: 'Дата зникнення:',
        ru: 'Дата исчезновения:',
        de: 'Verschwindungsdatum:',
        en: 'Date of Disappearance:',
        fr: 'Date de disparition :',
        es: 'Fecha de desaparición:',
        cs: 'Datum zmizení:',
      },
      sex: {
        ua: 'Стать:',
        en: 'Sex:',
        de: 'Geschlecht:',
        ru: 'Пол:',
        fr: 'Sexe:',
        es: 'Sexo:',
        cs: 'Pohlaví:',
      },
      article_crim: {
        ua: 'Стаття кримінального кодексу:',
        en: 'Criminal Code Article:',
        de: 'Strafgesetzbuch-Artikel:',
        ru: 'Статья уголовного кодекса:',
        fr: 'Article du Code pénal:',
        es: 'Artículo del Código Penal:',
        cs: 'Článek trestního zákoníku:',
      },
      lost_place: {
        ua: 'Місце зникнення:',
        en: 'Place of Disappearance:',
        de: 'Ort des Verschwindens:',
        ru: 'Место исчезновения:',
        fr: 'Lieu de disparition:',
        es: 'Lugar de desaparición:',
        cs: 'Místo zmizení:',
      },
      ovd: {
        ua: 'ОВД:',
        en: 'OVD:',
        de: 'OVD:',
        ru: 'ОВД:',
        fr: 'OVD:',
        es: 'OVD:',
        cs: 'OVD:',
      },
      category: {
        ua: 'Категорія:',
        en: 'Category:',
        de: 'Kategorie:',
        ru: 'Категория:',
        fr: 'Catégorie:',
        es: 'Categoría:',
        cs: 'Kategorie:',
      },
      status_text: {
        ua: 'Статус:',
        en: 'Status:',
        de: 'Status:',
        ru: 'Статус:',
        fr: 'Statut:',
        es: 'Estado:',
        cs: 'Status:',
      },
    },
  },
  registerTitles: {
    search: {
      ua: 'Пошук в реєстрах',
      en: 'Search in Registers',
      de: 'Suche in Registern',
      ru: 'Поиск в реестрах',
      fr: 'Recherche dans les registres',
      es: 'Buscar en los registros',
      cs: 'Hledat v registrech',
    },
    allRegisters: {
      ua: 'Всі реєстри',
      en: 'All Registers',
      de: 'Alle Register',
      ru: 'Все реестры',
      fr: 'Tous les registres',
      es: 'Todos los registros',
      cs: 'Všechny registry',
    },
    findMe: {
      ua: 'Знайти себе',
      en: 'Find Myself',
      de: 'Mich finden',
      ru: 'Найти себя',
      fr: 'Me trouver',
      es: 'Encontrarme',
      cs: 'Najít sebe',
    },
    selectRegistry: {
      ua: 'Оберіть реєстр',
      en: 'Select Registry',
      de: 'Register auswählen',
      ru: 'Выберите реестр',
      fr: 'Sélectionnez le registre',
      es: 'Seleccionar registro',
      cs: 'Vyberte registr',
    },
    alimonyDebtors: {
      ua: 'Боржники аліментів',
      en: 'Alimony Debtors',
      de: 'Unterhaltsdebitoren',
      ru: 'Должники по алиментам',
      fr: 'Débiteurs alimentaires',
      es: 'Deudores de pensiones alimenticias',
      cs: 'Dlužníci na výživném',
    },
    registerOfCorruptioners: {
      ua: 'Реєстр корупціонерів',
      en: 'Register of Corruptioners',
      de: 'Register der Korruption',
      ru: 'Реестр коррупционеров',
      fr: 'Registre des corrompus',
      es: 'Registro de corruptos',
      cs: 'Registr korupčníků',
    },
    wantedPersons: {
      ua: 'Особи у розшуку',
      en: 'Wanted Persons',
      de: 'Gesuchte Personen',
      ru: 'Разыскиваемые лица',
      fr: 'Personnes recherchées',
      es: 'Personas buscadas',
      cs: 'Hledané osoby',
    },
    subTitle: {
      alimony: {
        ua: 'Боржники аліментів',
        en: 'Alimony Debtors',
        de: 'Unterhaltsdebitoren',
        ru: 'Должники по алиментам',
        fr: 'Débiteurs alimentaires',
        es: 'Deudores de pensiones alimenticias',
        cs: 'Dlužníci na výživném',
      },
      corruptioner: {
        ua: 'Реєстр корупціонерів',
        en: 'Register of Corruptioners',
        de: 'Register der Korruption',
        ru: 'Реестр коррупционеров',
        fr: 'Registre des corrompus',
        es: 'Registro de corruptos',
        cs: 'Registr korupčníků',
      },
      wanted: {
        ua: 'Особи у розшуку',
        en: 'Wanted Persons',
        de: 'Gesuchte Personen',
        ru: 'Разыскиваемые лица',
        fr: 'Personnes recherchées',
        es: 'Personas buscadas',
        cs: 'Hledané osoby',
      },
    },
    sectionName: {
      ua: 'Розрахунок по реестрах',
      en: 'Reconciliation by Registers',
      de: 'Abrechnung nach Registern',
    },

    search: {
      alimony: {
        ua: 'Пошук за ПІБ',
        en: 'Search by Full Name',
        de: 'Suche nach Vollständigem Namen',
        ru: 'Поиск по ФИО',
        fr: 'Recherche par nom complet',
        es: 'Buscar por nombre completo',
        cs: 'Hledat podle celého jména',
      },
      corruptioner: {
        ua: 'Пошук за ПІБ, або покаранням',
        en: 'Search by Full Name or Punishment',
        de: 'Suche nach Vollständigem Namen oder Strafe',
        ru: 'Поиск по ФИО или наказанию',
        fr: 'Recherche par nom complet ou punition',
        es: 'Buscar por nombre completo o castigo',
        cs: 'Hledat podle celého jména nebo trestu',
      },
      wanted: {
        ua: 'Пошук за ПІБ',
        en: 'Search by Full Name',
        de: 'Suche nach Vollständigem Namen',
        ru: 'Поиск по ФИО',
        fr: 'Recherche par nom complet',
        es: 'Buscar por nombre completo',
        cs: 'Hledat podle celého jména',
      },
    },
    searchBtn: {
      ua: 'Пошук',
      en: 'Search',
      de: 'Suche',
      ru: 'Поиск',
      fr: 'Recherche',
      es: 'Buscar',
      cs: 'Hledat',
    },
    searchResults: {
      ua: 'Результати пошуку',
      en: 'Search Results',
      de: 'Suchergebnisse',
      ru: 'Результаты поиска',
      fr: 'Résultats de recherche',
      es: 'Resultados de búsqueda',
      cs: 'Výsledky hledání',
    },
    findMeInReg: {
      ua: 'Знайти себе в реєстрах',
      en: 'Find Myself in Registers',
      de: 'Mich in Registern finden',
      ru: 'Найти себя в реестрах',
      fr: 'Me trouver dans les registres',
      es: 'Encontrarme en los registros',
      cs: 'Najít sebe v registrech',
    },
    totalAmount: {
      ua: 'Загальна сума',
      en: 'Total Amount',
      de: 'Gesamtbetrag',
      ru: 'Общая сумма',
      fr: 'Montant total',
      es: 'Importe total',
      cs: 'Celková částka',
    },
    nothingFound: {
      ua: 'Нічого не знайдено',
      en: 'Nothing Found',
      de: 'Nichts gefunden',
      ru: 'Ничего не найдено',
      fr: 'Rien trouvé',
      es: 'Nada encontrado',
      cs: 'Nic nenalezeno',
    },
    priceForOneRegister: {
      ua: 'Ціна за один реєстр',
      en: 'Price per register',
      de: 'Preis pro Register',
      ru: 'Цена за один реестр',
      fr: 'Prix par registre',
      es: 'Precio por registro',
      cs: 'Cena za jeden registr',
    },
    priceForTheService: {
      ua: 'Ціна за послугу',
      en: 'Price for the service',
      de: 'Preis für den Service',
      ru: 'Цена за услугу',
      fr: 'Prix pour le service',
      es: 'Precio por el servicio',
      cs: 'Cena za službu',
    },
  },
  registerError: {
    enterValue: {
      ua: 'Введіть значення.',
      en: 'Enter value.',
      de: 'Geben Sie einen Wert ein.',
      ru: 'Введите значение.',
      fr: 'Entrez une valeur.',
      es: 'Introduzca un valor.',
      cs: 'Zadejte hodnotu.',
    },
    notEnoughFunds: {
      ua: 'Не достатньо коштів.',
      en: 'Not enough funds.',
      de: 'Nicht genügend Mittel.',
      ru: 'Недостаточно средств.',
      fr: 'Pas assez de fonds.',
      es: 'Fondos insuficientes.',
      cs: 'Nedostatek prostředků.',
    },
    topUpYourBalance: {
      ua: 'Будь ласка, поповніть баланс.',
      ru: 'Пожалуйста, пополните баланс.',
      de: 'Bitte füllen Sie Ihr Guthaben auf.',
      en: 'Please top up your balance.',
      fr: 'Veuillez recharger votre solde.',
      es: 'Por favor, recargue su saldo.',
      cs: 'Prosím, doplňte svůj zůstatek.',
    },
    selectRegisters: {
      ua: 'Оберіть реєстри.',
      ru: 'Выберите реестры.',
      de: 'Wählen Sie Register aus.',
      en: 'Select registers.',
      fr: 'Sélectionnez les registres.',
      es: 'Seleccione registros.',
      cs: 'Vyberte registry.',
    },
  },
  clientHotline: {
    selectHotlineType: {
      ua: 'Оберіть тип консультації',
      ru: 'Выберите тип консультации',
      de: 'Wählen Sie die Art der Beratung',
      en: 'Select consultation type',
      fr: 'Sélectionnez le type de consultation',
      es: 'Seleccione el tipo de consulta',
      cs: 'Vyberte typ konzultace',
    },
    selectHotlineState: {
      ua: 'Оберіть область',
      ru: 'Выберите область',
      de: 'Wählen Sie die Region',
      en: 'Select state',
      fr: "Sélectionnez l'état",
      es: 'Seleccione la región',
      cs: 'Vyberte oblast',
    },
    hotlineLimitExceed: {
      ua: 'Перевищено ліміт записів на тиждень',
      ru: 'Превышен лимит записей на неделю',
      de: 'Die Höchstzahl der Einträge pro Woche wurde überschritten',
      en: 'The limit of entries per week has been exceeded',
      fr: 'La limite des entrées par semaine a été dépassée',
      es: 'Se ha superado el límite de entradas por semana',
      cs: 'Byl překročen limit záznamů za týden',
    },
    youHaveAnAppointment: {
      ua: 'Ви вже маєте запис на',
      ru: 'У вас уже есть запись на',
      de: 'Sie haben bereits einen Termin für',
      en: 'You already have an appointment for',
      fr: 'Vous avez déjà un rendez-vous pour',
      es: 'Ya tiene una cita para',
      cs: 'Již máte schůzku na',
    },
    topFilials: {
      ua: 'Топ філій за напрямком',
      ru: 'Топ филиалов по направлению',
      de: 'Top-Filialen nach Richtung',
      en: 'Top branches by direction',
      fr: 'Top des agences par direction',
      es: 'Principales sucursales por dirección',
      cs: 'Top pobočky podle směru',
    },
    district: {
      ua: 'Окружна',
      ru: 'Округ',
      de: 'Bezirk',
      en: 'District',
      fr: 'District',
      es: 'Distrito',
      cs: 'Okres',
    },
    byRegion: {
      ua: 'За Областю',
      ru: 'По области',
      de: 'Nach Region',
      en: 'By region',
      fr: 'Par région',
      es: 'Por región',
      cs: 'Podle oblasti',
    },
    includedInDistrict: {
      ua: 'Входять в округ',
      ru: 'Входят в округ',
      de: 'Im Bezirk enthalten',
      en: 'Included in district',
      fr: 'Inclus dans le district',
      es: 'Incluido en el distrito',
      cs: 'Zahrnuto v okrese',
    },
    rest: {
      ua: 'Решта',
      ru: 'Остальное',
      de: 'Der Rest',
      en: 'The rest',
      fr: 'Le reste',
      es: 'El resto',
      cs: 'Zbytek',
    },
  },
  partnerClientsSMS: {
    consultation: {
      ua: 'Вам призначено консультацію на',
      ru: 'Вам назначена консультация на',
      de: 'Sie haben einen Termin für ein Beratungsgespräch unter',
      en: 'You have a consultation appointment on',
      fr: 'Vous avez un rendez-vous de consultation le',
      es: 'Tiene una cita de consulta el',
      cs: 'Na konzultaci jste domluveni na',
    },
    referral: {
      ua: 'Посилання на направлення',
      ru: 'Ссылка на направление',
      de: 'Empfehlungslink',
      en: 'Referral link',
      fr: 'Lien de référence',
      es: 'Enlace de referencia',
      cs: 'Odkaz na doporučení',
    },
  },

  // == Partner pages == //
  accessDenied: {
    ua: 'Доступ заборонено',
    ru: 'Доступ запрещен',
    de: 'Zugriff verweigert',
    en: 'Access denied',
    fr: 'Accès refusé',
    es: 'Acceso denegado',
    cs: 'Přístup odepřen',
  },
  payAtention: {
    ua: 'Зверніть увагу!',
    ru: 'Обратите внимание!',
    de: 'Beachten Sie!',
    en: 'Pay attention!',
    fr: 'Faites attention!',
    es: 'Presta atención!',
    cs: 'Dávejte pozor!',
  },
  noSMSOnThisNumber: {
    ua: 'На цей номер не будуть приходити сповіщення',
    ru: 'На этот номер не будут приходить уведомления',
    de: 'Auf dieser Nummer werden keine Benachrichtigungen empfangen',
    en: 'No notifications will be sent to this number',
    fr: 'Aucune notification ne sera envoyée à ce numéro',
    es: 'No se enviarán notificaciones a este número',
    cs: 'Na tento číslo nebudou odesílány žádné oznámení',
  },
  phoneValidationError: {
    ua: 'Телефонний код може містити лише цифри або знак "+"',
    ru: 'Телефонный код может содержать только цифры или знак "+"',
    de: 'Die Telefonvorwahl darf nur Zahlen oder das Zeichen "+" enthalten',
    en: 'The phone code can only contain numbers or the "+" sign',
    fr: 'Le code téléphonique ne peut contenir que des chiffres ou le signe "+"',
    es: 'El código de teléfono solo puede contener números o el signo "+"',
    cs: 'Telefonní kód může obsahovat pouze čísla nebo znak "+"',
  },

  // == List page == //

  hotlines: {
    ua: 'Записи',
    ru: 'Записи',
    de: 'Termine',
    en: 'Appointments',
    fr: 'Rendez-vous',
    es: 'Citas',
    cs: 'Termíny',
  },
  addHotline: {
    ua: 'Додати запис',
    ru: 'Добавить запись',
    de: 'Termin hinzufügen',
    en: 'Add appointment',
    fr: 'Ajouter un rendez-vous',
    es: 'Agregar cita',
    cs: 'Přidat termín',
  },
  noHotlines: {
    ua: 'Немає записів',
    ru: 'Нет записей',
    de: 'Keine Termine',
    en: 'No appointments',
    fr: 'Pas de rendez-vous',
    es: 'No hay citas',
    cs: 'Žádné termíny',
  },
  removeHotlineAlert: {
    ua: 'Ви впевнені, що хочете видалити запис?',
    ru: 'Вы уверены, что хотите удалить запись?',
    de: 'Sind Sie sicher, dass Sie den Termin löschen möchten?',
    en: 'Are you sure you want to delete the appointment?',
    fr: 'Êtes-vous sûr de vouloir supprimer le rendez-vous?',
    es: '¿Estás seguro de que quieres eliminar la cita?',
    cs: 'Jste si jisti, že chcete smazat termín?',
  },
  chooseDateForShowHotlines: {
    ua: 'Для перегляду записів оберіть дату',
    ru: 'Для просмотра записей выберите дату',
    de: 'Wählen Sie ein Datum, um die Termine anzuzeigen',
    en: 'Choose a date to view appointments',
    fr: 'Choisissez une date pour afficher les rendez-vous',
    es: 'Elija una fecha para ver las citas',
    cs: 'Vyberte datum pro zobrazení termínů',
  },
  removeHotlineValidationError: {
    ua: 'Для видалення запису потрібно вказати чинного клієнта',
    ru: 'Для удаления записи нужно указать действующего клиента',
    de: 'Um den Termin zu löschen, muss ein aktiver Kunde angegeben werden',
    en: 'To delete an appointment, you need to specify an active client',
    fr: 'Pour supprimer un rendez-vous, vous devez spécifier un client actif',
    es: 'Para eliminar una cita, debe especificar un cliente activo',
    cs: 'Chcete-li smazat termín, musíte uvést aktivního klienta',
  },
  reportMeeting: {
    ua: 'Звітувати про зустріч',
    ru: 'Отчет о встрече',
    de: 'Bericht über das Treffen',
    en: 'Report Meeting',
    fr: 'Rapport de réunion',
    es: 'Informe de reunión',
    cs: 'Zpráva o setkání',
  },
  contractAdded: {
    ua: 'Договір додано',
    ru: 'Договор добавлен',
    de: 'Vertrag hinzugefügt',
    en: 'Contract added',
    fr: 'Contrat ajouté',
    es: 'Contrato añadido',
    cs: 'Smlouva přidána',
  },
  addContract: {
    ua: 'Додати договір',
    ru: 'Добавить договор',
    de: 'Vertrag hinzufügen',
    en: 'Add contract',
    fr: 'Ajouter un contrat',
    es: 'Agregar contrato',
    cs: 'Přidat smlouvu',
  },
  editContract: {
    ua: 'Редагувати договір',
    ru: 'Редактировать договор',
    de: 'Vertrag bearbeiten',
    en: 'Edit contract',
    fr: 'Modifier le contrat',
    es: 'Editar contrato',
    cs: 'Upravit smlouvu',
  },
  historyWithAmount: {
    ua: 'Історія ({historyAmount})',
    ru: 'История ({historyAmount})',
    de: 'Geschichte ({historyAmount})',
    en: 'History ({historyAmount})',
    fr: 'Histoire ({historyAmount})',
    es: 'Historia ({historyAmount})',
    cs: 'Historie ({historyAmount})',
  },
  fullName: {
    ua: 'ПІБ',
    ru: 'ФИО',
    de: 'Vollständiger Name',
    en: 'Full Name',
    fr: 'Nom complet',
    es: 'Nombre completo',
    cs: 'Celé jméno',
  },
  meetingResult: {
    ua: 'Результат зустрічі:',
    ru: 'Результат встречи:',
    de: 'Ergebnis des Treffens:',
    en: 'Meeting Result:',
    fr: 'Résultat de la réunion:',
    es: 'Resultado de la reunión:',
    cs: 'Výsledek setkání:',
  },
  meetingResultShort: {
    ua: 'Рез. зустр.:',
    ru: 'Рез. встр.:',
    de: 'Ergebnis des Treffens:',
    en: 'Meeting Res.:',
    fr: 'Rés. réun.:',
    es: 'Res. reu.:',
    cs: 'Výs. setk.:',
  },
  meetingResultValues: {
    1: {
      ua: 'Була',
      ru: 'Была',
      de: 'War',
      en: 'Was',
      fr: 'Était',
      es: 'Era',
      cs: 'Byla',
    },
    2: {
      ua: 'Не була',
      ru: 'Не была',
      de: 'War nicht',
      en: 'Was not',
      fr: 'N’était pas',
      es: 'No era',
      cs: 'Nebyla',
    },
    3: {
      ua: 'Не вірний номер',
      ru: 'Не верный номер',
      de: 'Falsche Nummer',
      en: 'Wrong number',
      fr: 'Mauvais numéro',
      es: 'Número equivocado',
      cs: 'Nesprávné číslo',
    },
  },
  createdAt: {
    ua: 'Створено:',
    ru: 'Создано:',
    de: 'Erstellt am:',
    en: 'Created At:',
    fr: 'Créé à:',
    es: 'Creado en:',
    cs: 'Vytvořeno:',
  },
  chooseEndTime: {
    ua: 'Будь ласка, оберіть кінцевий час.',
    ru: 'Пожалуйста, выберите конечное время.',
    de: 'Bitte wählen Sie die Endzeit.',
    en: 'Please choose the end time.',
    fr: "Veuillez choisir l'heure de fin.",
    es: 'Por favor, elija la hora de finalización.',
    cs: 'Vyberte prosím konečný čas.',
  },
  fillAll: {
    ua: 'Будь ласка, заповніть всі поля.',
    ru: 'Пожалуйста, заполните все поля.',
    de: 'Bitte füllen Sie alle Felder aus.',
    en: 'Please fill in all fields.',
    fr: 'Veuillez remplir tous les champs.',
    es: 'Por favor, complete todos los campos.',
    cs: 'Vyplňte prosím všechna pole.',
  },
  successfullyCreated: {
    ua: 'Успішно створено',
    ru: 'Успешно создано',
    de: 'Erfolgreich erstellt',
    en: 'Successfully created',
    fr: 'Créé avec succès',
    es: 'Creado con éxito',
    cs: 'Úspěšně vytvořeno',
  },
  successfullyUpdated: {
    ua: 'Успішно оновлено',
    ru: 'Успешно обновлено',
    de: 'Erfolgreich aktualisiert',
    en: 'Successfully updated',
    fr: 'Mis à jour avec succès',
    es: 'Actualizado con éxito',
    cs: 'Úspěšně aktualizováno',
  },
  errorWhileCreating: {
    ua: 'Помилка при створенні',
    ru: 'Ошибка при создании',
    de: 'Fehler beim Erstellen',
    en: 'Error while creating',
    fr: 'Erreur lors de la création',
    es: 'Error al crear',
    cs: 'Chyba při vytváření',
  },
  errorWhileUpdating: {
    ua: 'Помилка при оновленні',
    ru: 'Ошибка при обновлении',
    de: 'Fehler beim Aktualisieren',
    en: 'Error while updating',
    fr: 'Erreur lors de la mise à jour',
    es: 'Error al actualizar',
    cs: 'Chyba při aktualizaci',
  },
  editHotline: {
    ua: 'Редагувати запис',
    ru: 'Изменить запись',
    de: 'Termin bearbeiten',
    en: 'Edit appointment',
    fr: 'Modifier le rendez-vous',
    es: 'Editar cita',
    cs: 'Upravit termín',
  },
  addHotline: {
    ua: 'Додати запис',
    ru: 'Добавить запись',
    de: 'Termin hinzufügen',
    en: 'Add appointment',
    fr: 'Ajouter un rendez-vous',
    es: 'Agregar cita',
    cs: 'Přidat termín',
  },
  foundUser: {
    ua: 'Знайдено користувача',
    ru: 'Найден пользователь',
    de: 'Benutzer gefunden',
    en: 'User found',
    fr: 'Utilisateur trouvé',
    es: 'Usuario encontrado',
    cs: 'Uživatel nalezen',
  },
  enterPrice: {
    ua: 'Введіть вартість',
    ru: 'Введите стоимость',
    de: 'Geben Sie den Preis ein',
    en: 'Enter the price',
    fr: 'Entrez le prix',
    es: 'Introduzca el precio',
    cs: 'Zadejte cenu',
  },
  enterDate: {
    ua: 'Введіть дату',
    ru: 'Введите дату',
    de: 'Geben Sie das Datum ein',
    en: 'Enter the date',
    fr: 'Entrez la date',
    es: 'Introduzca la fecha',
    cs: 'Zadejte datum',
  },
  hotlineConsultationsByNumber: {
    ua: 'Консультації по номеру',
    ru: 'Консультации по номеру',
    de: 'Beratungen nach Nummer',
    en: 'Consultations by number',
    fr: 'Consultations par numéro',
    es: 'Consultas por número',
    cs: 'Konzultace podle čísla',
  },
  hotlineQueTitle: {
    ua: 'Чи відбулася зустріч?',
    ru: 'Состоялась ли встреча?',
    de: 'Hat das Treffen stattgefunden?',
    en: 'Did the meeting take place?',
    fr: 'La réunion a-t-elle eu lieu?',
    es: '¿Se llevó a cabo la reunión?',
    cs: 'Proběhlo setkání?',
  },
  // == Contract page == //
  contracts: {
    ua: 'Договори',
    ru: 'Договоры',
    de: 'Verträge',
    en: 'Contracts',
    fr: 'Contrats',
    es: 'Contratos',
    cs: 'Smlouvy',
  },
  paidWythCash: {
    ua: 'Оплачено готівкою',
    ru: 'Оплачено наличными',
    de: 'Bar bezahlt',
    en: 'Paid with cash',
    fr: 'Payé en espèces',
    es: 'Pagado en efectivo',
    cs: 'Zaplaceno hotově',
  },
  addService: {
    ua: 'Додати послугу',
    ru: 'Добавить услугу',
    de: 'Service hinzufügen',
    en: 'Add service',
    fr: 'Ajouter un service',
    es: 'Agregar servicio',
    cs: 'Přidat službu',
  },
  describeService: {
    ua: 'Опишіть послугу',
    ru: 'Опишите услугу',
    de: 'Beschreiben Sie den Service',
    en: 'Describe the service',
    fr: 'Décrivez le service',
    es: 'Describa el servicio',
    cs: 'Popište službu',
  },
  addingNewContract: {
    ua: 'Додаваємо новий договір',
    ru: 'Добавляем новый договор',
    de: 'Neuen Vertrag hinzufügen',
    en: 'Adding a new contract',
    fr: 'Ajout d’un nouveau contrat',
    es: 'Agregando un nuevo contrato',
    cs: 'Přidání nové smlouvy',
  },
  editingContract: {
    ua: 'Редагуємо договір',
    ru: 'Редактируем договор',
    de: 'Vertrag bearbeiten',
    en: 'Editing contract',
    fr: 'Modification du contrat',
    es: 'Editando contrato',
    cs: 'Editace smlouvy',
  },
  enterlientPhone: {
    ua: 'Введіть телефон клієнта',
    ru: 'Введите телефон клиента',
    de: 'Geben Sie die Telefonnummer des Kunden ein',
    en: 'Enter the client phone',
    fr: 'Entrez le téléphone du client',
    es: 'Introduzca el teléfono del cliente',
    cs: 'Zadejte telefonní číslo klienta',
  },
  clientFullName: {
    ua: 'ПІБ клієнта',
    ru: 'ФИО клиента',
    de: 'Vollständiger Name des Kunden',
    en: 'Client full name',
    fr: 'Nom complet du client',
    es: 'Nombre completo del cliente',
    cs: 'Celé jméno klienta',
  },
  enterClientFullName: {
    ua: 'Введіть ПІБ клієнта',
    ru: 'Введите ФИО клиента',
    de: 'Geben Sie den vollständigen Namen des Kunden ein',
    en: 'Enter the client full name',
    fr: 'Entrez le nom complet du client',
    es: 'Introduzca el nombre completo del cliente',
    cs: 'Zadejte celé jméno klienta',
  },
  clientEmail: {
    ua: 'Email клієнта',
    ru: 'Email клиента',
    de: 'E-Mail des Kunden',
    en: 'Client email',
    fr: 'E-mail du client',
    es: 'Correo electrónico del cliente',
    cs: 'E-mail klienta',
  },
  signDate: {
    ua: 'Дата підписання',
    ru: 'Дата подписания',
    de: 'Unterschriftsdatum',
    en: 'Sign date',
    fr: 'Date de signature',
    es: 'Fecha de firma',
    cs: 'Datum podpisu',
  },

  // == Profile page == //
  profile: {
    ua: 'Профіль',
    ru: 'Профиль',
    de: 'Profil',
    en: 'Profile',
    fr: 'Profil',
    es: 'Perfil',
    cs: 'Profil',
  },

  webinarPrice: {
    ua: 'Ціна вебінару',
    ru: 'Цена вебинара',
    de: 'Webinarpreis',
    en: 'Webinar price',
    fr: 'Prix du webinaire',
    es: 'Precio del seminario web',
    cs: 'Cena webináře',
  },
};

module.exports = {
  languageOptions,
  translation,
};
