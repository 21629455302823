import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import './SelectNew.scss';

const SelectNew = ({ options, control, name, className, onChange: onChangeProp, isSearchable, flattened: isFlattened, ...props }) => {
  const [uniqueId] = useState(() => 'select_' + Math.random().toFixed(5).slice(2));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenuHandler = () => {
    setIsMenuOpen(true);
  };

  const closeMenuHandler = () => {
    setIsMenuOpen(false);
  };

  const flattened = options.flatMap((item) => {
    if (item.options) {
      return item.options;
    }
    return item;
  });

  return (
    <Controller
      name={name}
      rules={props?.rHFormOptions}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          {...props}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: props.disabled ? 'transparent' : 'white',
              borderRadius: 32,
              paddingLeft: 5,
              paddingRight: 5,
            }),
          }}
          id={uniqueId}
          menuIsOpen={isMenuOpen}
          onMenuOpen={openMenuHandler}
          onMenuClose={closeMenuHandler}
          className={`new-select ${className} ${isMenuOpen ? 'select-opening' : 'select-closing'}`}
          value={value && ((isFlattened ? flattened : options).find((option) => option.value === value) ?? { value, label: value })}
          isSearchable={!!isSearchable}
          onInputChange={(inputValue, { action }) => {
            if (action === 'input-change' && inputValue) {
              onChange(inputValue); // Ввод вручную обновляет значение
              onChangeProp?.(inputValue);
            }
          }}
          onChange={(selectedOption) => {
            onChange(selectedOption.value);
            onChangeProp?.(selectedOption.value);
          }}
          options={options}
        />
      )}
    />
  );
};

export default SelectNew;
