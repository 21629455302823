import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PERSONAL_CONSULTANT_CHAT } from 'common_constants/modals';
import { CLUB_CARDS_TYPES } from 'common_constants/business';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { PhoneOutlined, MessageOutlined, WhatsAppOutlined, SendOutlined } from '@ant-design/icons';
import { setModal, setManagerInfo, setPersonalConsultantChat } from '../../store/commonReducer';
import { request } from '../../tools';
import aiAssistantImg from '../../images/woman-ai-assistant.png';

import './Contacts.scss';

const Contacts = ({ homepage, setActiveClass, activeClass }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const managerInfo = useSelector((state) => state.common.managerInfo);
  const personalConsultantChat = useSelector((state) => state.common.personalConsultantChat);
  
  const [wasDragged, setWasDragged] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const hasOnlySilverCard = !clientInfo?.clubCard || ![CLUB_CARDS_TYPES.GOLD, CLUB_CARDS_TYPES.PLATINUM].includes(clientInfo?.clubCard?.cardType);
  const style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  const onClickMenu = () => {
    if (hasOnlySilverCard) {
      handlePersonalConsultantChatOpen();
      return;
    }
    if (wasDragged) {
      setWasDragged(false);
    } else {
      setActiveClass(!activeClass);
      setMenuState(!menuState);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);

    let startX = e.clientX;
    let startY = e.clientY;

    const handleMouseMove = (e) => {
      let deltaX = e.clientX - startX;
      let deltaY = e.clientY - startY;
      setWasDragged(true);

      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));

      startX = e.clientX;
      startY = e.clientY;
    };

    const handleMouseUp = () => {
      setIsDragging(false);

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);

    let startX = e.touches[0].clientX;
    let startY = e.touches[0].clientY;

    const handleTouchMove = (e) => {
      let deltaX = e.touches[0].clientX - startX;
      let deltaY = e.touches[0].clientY - startY;
      setWasDragged(true);

      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));

      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    };

    const handleTouchEnd = () => {
      setIsDragging(false);

      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  const handlePersonalConsultantChatOpen = () => {
    if (hasOnlySilverCard) {
      history.push(CLIENT_ROUTES.CLIENT_QUICK_CHAT.route);
      return;
    }

    dispatch(setModal({ name: PERSONAL_CONSULTANT_CHAT }));
  };

  const getFilManager = () => {
    if (managerInfo?._id) return;

    request('/filii/getFilManagerInfoByFil', { clientFil: clientInfo?.f }, ({ data }) => {
      dispatch(setManagerInfo(data));
    });
  };

  const getPersonalConsultantChat = async () => {
    if (personalConsultantChat?._id) return;

    const clubCard = clientInfo?.clubCard;

    if (!clubCard || ![CLUB_CARDS_TYPES.GOLD, CLUB_CARDS_TYPES.PLATINUM].includes(clubCard.cardType)) return;

    await request(
      '/chatPrivate/getPersonalConsultantChat',
      {},
      ({ data }) => {
        dispatch(setPersonalConsultantChat(data));
      },
      () => {},
    );
  };

  useEffect(() => {
    if (!clientInfo?._id) return;

    getFilManager();
    getPersonalConsultantChat();
  }, []);

  return (
    <div
      className={`contacts${menuState ? ' _opened' : ''} ${!homepage ? ' not-homepage' : ''}`}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      style={style}
    >
      <div className="contacts-dropdown">
        <div className="contacts-dropdown__item">
          <a rel="noopener noreferrer" target="_blank" onClick={handlePersonalConsultantChatOpen}>
            <MessageOutlined />
          </a>
        </div>
        <div className="contacts-dropdown__item">
          <a rel="noopener noreferrer" href={`viber://chat?number=+38${managerInfo?.userPersonalData?.workPhone}`}>
            <WhatsAppOutlined />
          </a>
        </div>
        <div className="contacts-dropdown__item">
          <a href={`tel:+38${managerInfo?.userPersonalData?.workPhone}`}>
            <PhoneOutlined />
          </a>
        </div>
        <div className="contacts-dropdown__item">
          <a rel="noopener noreferrer" target="_blank" href={`https://t.me/+38${managerInfo?.userPersonalData?.workPhone}`}>
            <SendOutlined style={{ transform: 'rotate(-45deg)' }} />
          </a>
        </div>
      </div>
      <div
        className={`contacts-button ${clientInfo?.clubCard.cardType.toLowerCase()}`}
        onClick={onClickMenu}
        style={{
          backgroundImage: `url('${
            hasOnlySilverCard
              ? aiAssistantImg
              : managerInfo?.upa
              ? process.env.REACT_APP_API + `/avatars/${managerInfo.upa}_200.jpeg`
              : 'https://th.bing.com/th/id/OIP.NmdbjgnCEbGC6o4A43nhJgHaHs?pid=ImgDet&w=195&h=202&c=7&dpr=1.5'
          }')`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '100%',
        }}
      >
        <span className="contacts-button__item"></span>
        <span className="contacts-button__item"></span>
      </div>
    </div>
  );
};

export default Contacts;
