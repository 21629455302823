import { useState, useEffect, useRef } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { translation } from 'common_constants/translation';
import Message from '../../components/Chat/Message';
import Spin from '../../components/Spin/Spin';
import Input from '../../components/InputNew/InputNew';
import aiAssistantImg from '../../images/woman-ai-assistant.png';
import { editClientInfo, setQuickChatLoader } from '../../store/commonReducer';
import { error, request } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';
import { ReactComponent as Pen } from '../../images/pen.svg';
import './QuickChat.scss';

const QuickChat = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sendLoader, setSendLoader] = useState(false);
  const [threadId, setThreadId] = useState('');
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { emailVerified, phoneVerified } = clientAuth;
  const quickChatLoader = useSelector((state) => state.common.quickChatLoader);
  const lang = useSelector((state) => state.common.language);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );

  useEffect(() => {
    if (!clientAuth?._id) {
      return;
    }

    dispatch(setMainLoader(true));
    createThread();
    dispatch(setMainLoader(false));
  }, []);

  useEffect(() => {
    if (!quickChatLoader) {
      dispatch(setQuickChatLoader(true));
    }
  }, [quickChatLoader]);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyMessage = (text) => ({
    message: text,
    isSentByCurrentUser: true,
    date: new Date(),
    name: clientInfo.n,
    role: 'user',
  });

  const makeAssistantMessage = (text) => ({
    message: text,
    isSentByCurrentUser: false,
    date: new Date(),
    name: translation.assistant[lang],
    role: 'assistant',
  });

  const handleSend = async () => {
    if (inputValue === '') return;

    setInputValue('');
    setSendLoader(true);

    setMessages((prev) => [...prev, makeMyMessage(inputValue)]);

    await sendMessage(inputValue);

    let errorOccurred;
    let lastMessage;
    do {
      errorOccurred = false;
      try {
        const run = await runsAssistant();

        let status = await checkAssistantRuns(run);

        while (status === 'in_progress' || status === 'queued') {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          status = await checkAssistantRuns(run);
        }

        lastMessage = await getLastMessage(run);
      } catch (error) {
        errorOccurred = true;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } while (errorOccurred);

    if (lastMessage) {
      cache.current.clearAll();
      setSendLoader(false);
      setMessages((prev) => [...prev, makeAssistantMessage(lastMessage)]);
      dispatch(editClientInfo({ clientQuickChatLength: clientInfo.clientQuickChatLength + 2 }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const createThread = () => {
    let errorOccurred;
    do {
      errorOccurred = false;
      try {
        request('/quickChat/createThread', {}, (res) => {
          setThreadId(res.threadId);
        });
      } catch (error) {
        errorOccurred = true;
      }
    } while (errorOccurred);
  };

  const runsAssistant = async () => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');

      const headers = { Authorization: `Bearer ${token}` };

      // todo: use our request method
      const response = await axios.post(
        `${process.env.REACT_APP_API}/quickChat/runAssistant`,
        { threadId: threadId },
        {
          headers: headers,
        },
      );

      return response.data.runId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sendMessage = async (message) => {
    await request('/quickChat/sendMessage', { threadId: threadId, message: message, userId: clientAuth._id }, () => {}, error);
  };

  const checkAssistantRuns = async (runId) => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/quickChat/checkAssistantRun`,
        { threadId: threadId, runId: runId },
        {
          headers: headers,
        },
      );

      return response.data.contour;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getLastMessage = async (runId) => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/quickChat/getLastMessage`,
        { threadId: threadId, userId: clientAuth._id, runId: runId },
        {
          headers: headers,
        },
      );

      return response.data.lastMessage;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <div className="quick-chat-page">
      {emailVerified && phoneVerified ? (
        <div className="quick-chat-page-wrapper">
          <div className="quick-chat-page-chat-container">
            <div className="quick-chat-page-chat-container-list">
              {messages.length === 0 && (
                <div className="quick-chat-page-chat-container-list-intro-message">
                  <div className="quick-chat-page-chat-container-list-intro-message-image" style={{ backgroundImage: `url(${aiAssistantImg})` }} />
                  <div className="quick-chat-page-chat-container-list-intro-message-title">{translation.quickQuestionsIntroTitle[lang]}</div>
                  <p>{translation.quickQuestionsIntroText[lang]}</p>
                </div>
              )}
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height}
                    rowHeight={cache.current.rowHeight}
                    deferredMeasurementCache={cache.current}
                    rowCount={messages.length}
                    scrollToIndex={messages.length - 1}
                    overscanRowCount={0}
                    rowRenderer={({ key, index, style, parent }) => {
                      const message = messages[index];
                      return (
                        <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                          <div style={style}>
                            <Message item={message} onDownload={() => {}} avatarUrl={!message.isSentByCurrentUser && aiAssistantImg} />
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
            {sendLoader && (
              <div className="quick-chat-page-chat-container-list-response-status">
                <span>{translation.answerQuickQuestion[lang]}</span>
                <Pen className="quick-chat-page-chat-container-list-response-status-pen-icon" />
              </div>
            )}
            <div className="quick-chat-page-input-container">
              <Input
                placeholder={translation.inputQuickQuestion[lang]}
                value={inputValue}
                onChange={handleInput}
                onKeyDown={handleKeyDown}
                disabled={sendLoader}
                className="quick-chat-page-input-container-input"
              />
              {sendLoader ? (
                <Spin className="quick-chat-page-input-container-spin" />
              ) : (
                <button onClick={handleSend} className="quick-chat-page-input-container-button">
                  <SendOutlined className="quick-chat-page-input-container-icon" />
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <h1 style={{ textAlign: 'center' }}>{translation.quickQuestionTitle1[lang]}</h1>
          {/* Removed AddClientEmail to prevent double rendering */}
        </>
      )}
    </div>
  );
};

export default QuickChat;
